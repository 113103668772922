import { Box, Divider, Typography } from "@mui/material";
import BundleQualifierPreview from "components/businesses/ecommerce/specials/helpers/BundleQualifierPreview";
import BundleRewardsPreview from "components/businesses/ecommerce/specials/helpers/BundleRewardsPreview";
import { Button } from "components/shared";
import { businessConstants } from "constants";
import { useFormikContext } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";

const ValueTitleBox = ({ value, title }) => {
  return (
    <Box display={"flex"} alignItems="center" columnGap={1}>
      <Typography variant="subtitle1" sx={{ width: 120 }}>
        {title}:{" "}
      </Typography>
      <Typography variant="body2">{value ? value : "--"}</Typography>
    </Box>
  );
};

export default function BundleDetails(props) {
  const { onChange } = props;
  const business = useAppSelector((state) => state.business.info);
  const [imageSrc, setImageSrc] = useState("");
  const formik = useFormikContext();
  function _handleStepChange(step) {
    if (onChange) {
      onChange(step);
    }
  }

  function _handleKeyToDay(key) {
    const dayNameMap = {
      activeSunday: "Sunday",
      activeMonday: "Monday",
      activeTuesday: "Tuesday",
      activeWednesday: "Wednesday",
      activeThursday: "Thursday",
      activeFriday: "Friday",
      activeSaturday: "Saturday",
    };

    return dayNameMap[key] || key;
  }

  const formattedSchedule = Object.keys(formik.values.schedule)
    .filter((key) => formik.values.schedule[key])
    .map(_handleKeyToDay)
    .join(", ");

  useEffect(() => {
    if (formik.values.photoFile instanceof File) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(formik.values.photoFile);
    } else {
      setImageSrc("");
    }
  }, [formik.values.photoFile]);

  const formatDate = (time) => {
    if (!time) {
      return "Present";
    }
    return moment(time).format("MM/DD/YYYY");
  };
  return (
    <React.Fragment>
      {/* Information Box */}
      <Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" sx={{ mb: 1 }}>
            Information
          </Typography>
          <Button onClick={() => _handleStepChange(0)}>Edit</Button>
        </Box>
        <ValueTitleBox value={formik.values.name} title="Name" />
        <ValueTitleBox value={formik.values.description} title="Description" />
        {formik.values.photoFile ? (
          <React.Fragment>
            <Typography variant="subtitle1">Photo:</Typography>
            <div
              style={{
                position: "relative",
                objectFit: "cover",
                width: "200px",
                height: "120px",
                borderRadius: "9px",
                marginTop: "1rem",
              }}
            >
              <img
                src={imageSrc}
                alt="Preview"
                style={{
                  objectFit: "cover",
                  width: "200px",
                  height: "120px",
                  borderRadius: "9px",
                }}
              />
            </div>
          </React.Fragment>
        ) : (
          <ValueTitleBox title="Photo" />
        )}
        <ValueTitleBox value={formik.values.enabled} title="Enabled" />
        <ValueTitleBox value={formik.values.priority} title="Priority" />
        <ValueTitleBox value={formik.values.terms} title="Terms" />
        {formik.values.promoCodeEnabled && (
          <React.Fragment>
            <ValueTitleBox value={formik.values.promoCode} title="Promo Code" />
            <ValueTitleBox
              value={formik.values.promoCodeUsesPerUser}
              title="Promo Code Uses Per User"
            />
          </React.Fragment>
        )}
        <ValueTitleBox
          value={formik.values.maxUsesPerCart}
          title="Max Uses Per User"
        />
      </Box>
      <Divider sx={{ my: 2 }} />
      {/* Schedule Preview */}
      <Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" sx={{ mb: 1 }}>
            Schedule
          </Typography>
          <Button onClick={() => _handleStepChange(1)}>Edit</Button>
        </Box>
        <ValueTitleBox value={formatDate(formik.values.startDate)} title="Start Date" />
        <ValueTitleBox value={formatDate(formik.values.endDate)} title="End Date" />
        <ValueTitleBox value={formattedSchedule} title="Days Active" />
      </Box>
      <Divider sx={{ my: 2 }} />
      {/* Products */}
      <Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" sx={{ mb: 1 }}>
            Bundle Qualifiers
          </Typography>
          <Button onClick={() => _handleStepChange(2)}>Edit</Button>
        </Box>
        <ValueTitleBox
          value={formik.values.menuTypes
            .map(
              (type) =>
                businessConstants.MENU_TYPE_OPTIONS.find(
                  (option) => option.value === type
                ).name
            )
            .join(", ")}
          title="Menu Type"
        />
        <Box marginY={2}>
          {formik.values.bundleQualifiers.map((bundle, index) => (
            <React.Fragment key={`bundle-preview-${index}`}>
              <BundleQualifierPreview
                bundle={bundle}
                businessId={business.id}
                index={index}
              />
            </React.Fragment>
          ))}
        </Box>
        <Box marginY={2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5" sx={{ mb: 1 }}>
              Bundle Rewards
            </Typography>
            <Button onClick={() => _handleStepChange(3)}>Edit</Button>
          </Box>
          {formik.values.bundleRewards.map((reward, idx) => (
            <React.Fragment key={`bundle-preview-${idx}`}>
              <BundleRewardsPreview
                bundleReward={reward}
                businessId={business.id}
                index={idx}
              />
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </React.Fragment>
  );
}
