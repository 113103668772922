import axios, { AxiosRequestConfig } from "axios";
import config from "../config";
import { apiHelper, authHeader } from "../helpers";
import { BusinessIntegration } from "components/business-integrations/types";

export const businessIntegrationsService = {
  getBusinessIntegrations,
  updateBusinessIntegration,
  getBusinessIntegrationByName,
  deleteBusinessIntegration,
  addBusinessIntegration,
  // getBusinessSettings,
  // updateBusinessIntegrationsSettings
};

/**
 * Get Business Integration details
 * @param id
 */
export function getBusinessIntegrationByName(
  id: number,
  name: string,
  type: string
) {
  const requestConfig: AxiosRequestConfig = {
    method: "GET",
    headers: authHeader(),
  };
  return axios
    .get(
      `${config.apiUrl}/business-integrations/get/${type}/${name}/${id}`,
      requestConfig
    )
    .then((response) => response.data)
    .catch(apiHelper.handleError);
}

/**
 * Get Business Integration details
 * @param businessId
 */
export function getBusinessIntegrations(businessId: number) {
  const requestConfig: AxiosRequestConfig = {
    method: "GET",
    headers: authHeader(),
  };

  return axios
    .get(
      `${config.apiUrl}/business-integrations/get/internal/${businessId}`,
      requestConfig
    )
    .then((response) => response.data)
    .catch(apiHelper.handleError);
}

/**
 * Add business integration
 * @param newData
 */
export function addBusinessIntegration(
  newData: Omit<BusinessIntegration, "id">
) {
  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      ...auth,
    },
  };

  return axios
    .post(
      `${config.apiUrl}/business-integrations/create`,
      newData,
      requestConfig
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Update business integration
 * @param newData
 */
export function updateBusinessIntegration(
  id: number,
  newData: Partial<BusinessIntegration>
) {
  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      ...auth,
    },
  };

  return axios
    .patch(
      `${config.apiUrl}/business-integrations/update/${id}`,
      newData,
      requestConfig
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Update business data
 * @param newData
 */
export function deleteBusinessIntegration(id: number) {
  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      ...auth,
    },
  };

  return axios
    .delete(
      `${config.apiUrl}/business-integrations/delete/${id}`,
      requestConfig
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}
