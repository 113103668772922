import React from 'react';
import { FormHelperText, ToggleButton, ToggleButtonGroup, useMediaQuery, useTheme } from '@mui/material';
import { useField, useFormikContext } from 'formik';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const shortDaysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

function DayOfWeekPicker({ name, ...rest }) {
  const { values, setFieldValue } = useFormikContext();
  const [, { error, touched }] = useField(name);
  const selectedDays = values[name] || {};

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dayLabels = isMobile ? shortDaysOfWeek : daysOfWeek;

  const handleDayToggle = (day, fullDay) => {
    const dayKey = `active${fullDay}`;
    const isActive = Boolean(selectedDays[dayKey]);
  
    setFieldValue(`${name}.${dayKey}`, !isActive);
  };

  const handleSelectAll = () => {
    const newSelectedDays = {};
    const allDaysSelected = dayLabels.every((day) => selectedDays[`active${day}`]);
    if (allDaysSelected) {
      dayLabels.forEach((day) => (newSelectedDays[`active${day}`] = false));
    } else {
      dayLabels.forEach((day) => (newSelectedDays[`active${day}`] = true));
    }
    setFieldValue(name, newSelectedDays);
  };

  return (
    <>
      <ToggleButtonGroup value={selectedDays} aria-label="days of the week" name={name} size="small">
        {dayLabels.map((day, index) => (
          <ToggleButton
            key={day}
            value={day}
            color='primary'
            onClick={() => handleDayToggle(day, daysOfWeek[index])}
            selected={Boolean(selectedDays[`active${daysOfWeek[index]}`])}
            sx={{
              bgcolor: (touched && error && !selectedDays[`active${daysOfWeek[index]}`]) ? 'rgba(255, 77, 79, 0.08)' : 'inherit',
              color: (touched && error && !selectedDays[`active${daysOfWeek[index]}`]) ? '#ff4d4f' : 'inherit'
            }}
          >
            {day}
          </ToggleButton>
        ))}
        <ToggleButton onClick={handleSelectAll}>
          {dayLabels.every((day) => selectedDays[`active${day}`]) ? 'Remove All' : 'Select All'}
        </ToggleButton>
      </ToggleButtonGroup>
      {touched && error && <FormHelperText style={{ color: 'red', paddingLeft: 16 }}>{error}</FormHelperText>}
    </>
  );
}

export default DayOfWeekPicker;
