import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";

export default function BundleCollectionWeight({
  weightFilter,
  setWeightFilter,
  weightValue,
  setWeightValue,
  weightFilterErrorMessage,
  setWeightFilterErrorMessage,
  weightValueErrorMessage,
  setWeightValueErrorMessage,
  editing,
}) {
  // * Formik context
  const formik = useFormikContext();
  const { values } = formik;
  const bundleQualifiersWeight = values.bundleQualifiers?.[0]?.requiredWeight;

  // * States
  const [isDisabled, setIsDisabled] = useState(false);
  const [totalWeightSelected, setTotalWeightSelected] = useState(
    bundleQualifiersWeight !== null
  );
  const handleChangeFilter = (event) => {
    const value = event.target.value;
    setWeightFilter(value);
    setWeightFilterErrorMessage(value ? "" : "Please select a weight filter");
  };

  const handleChangeValue = (event) => {
    const value = event.target.value;
    setWeightValue(value);
    setWeightValueErrorMessage(value ? "" : "Please select a weight value");
    if (value === "ANY" || value === "EACH") {
      setWeightFilter("EQUALS");
    }
  };
  const handleWeightFilterDisable = () =>
    weightValue === "ANY" || weightValue === "EACH" ? true : false;

  useEffect(() => {
    setIsDisabled(handleWeightFilterDisable());
  }, [weightValue]); // * Run whenever weightValue changes

  useEffect(() => {
    setIsDisabled(handleWeightFilterDisable());
  }, []); // * Run once on component load

  useEffect(() => {
    if (bundleQualifiersWeight !== null) {
      setTotalWeightSelected(true);
      setWeightFilter("");
      setWeightValue("");
    } else {
      setTotalWeightSelected(false);
    }
  }, [bundleQualifiersWeight]);

  if (totalWeightSelected) {
    return;
  }
  return (
    <div
      style={{
        display: "flex",
        columnGap: "10px",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div>
        <FormControl fullWidth error={!!weightFilterErrorMessage}>
          <InputLabel id="weight-filter-label">Weight Filter</InputLabel>
          <Select
            labelId="weight-filter-label"
            id="weight-filter-select"
            value={weightFilter}
            label="Weight Filter"
            disabled={isDisabled}
            onChange={handleChangeFilter}
            sx={{ width: "200px", height: "54px" }}
          >
            <MenuItem value="EQUALS">Equals</MenuItem>
            <MenuItem value="GREATER_THAN_OR_EQUAL_TO">
              Greater than or equal to
            </MenuItem>
            <MenuItem value="LESS_THAN_OR_EQUAL_TO">
              Less than or equal to
            </MenuItem>
          </Select>
          {!!weightFilterErrorMessage && (
            <FormHelperText>{weightFilterErrorMessage}</FormHelperText>
          )}
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth error={!!weightValueErrorMessage}>
          <InputLabel id="weight-value-label">Weight Value</InputLabel>
          <Select
            labelId="weight-value-label"
            id="weight-value-select"
            value={weightValue}
            label="Weight Value"
            onChange={handleChangeValue}
            sx={{ width: "200px", height: "54px" }}
          >
            <MenuItem value="1g">1g or Gram</MenuItem>
            <MenuItem value="3.5g">3.5g or Eighth</MenuItem>
            <MenuItem value="7g">7g or Quarter</MenuItem>
            <MenuItem value="14g">14g or Half</MenuItem>
            <MenuItem value="28g">28g or Ounce</MenuItem>
            <MenuItem value="EACH">Each</MenuItem>
            <MenuItem value="ANY">Any</MenuItem>
          </Select>
          {!!weightValueErrorMessage && (
            <FormHelperText>{weightValueErrorMessage}</FormHelperText>
          )}
        </FormControl>
      </div>
    </div>
  );
}
