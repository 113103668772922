import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {managementService} from '../services';
import {ApiKey} from '../types/apiKey';
import {RootState} from './store';
import {PURGE} from 'redux-persist';

export const getApiKeys = createAsyncThunk(
  'apiKeys/getAll',
  async () => {
    const response = await managementService.getApiKeys();
    return response as Array<ApiKey>;
  }
)

export const addApiKey = createAsyncThunk(
  'apiKeys/addApiKey',
  async (name: string) => {
    const response = await managementService.addApiKey(name);
    return response as ApiKey;
  }
)

export const deleteApiKey = createAsyncThunk(
  'apiKeys/deleteApiKey',
  async (name: string) => {
    const response = await managementService.deleteApiKey(name);
    return true;
  }
)

export const apiKeyAdapter = createEntityAdapter<ApiKey>();

interface AdditionalState {
  loading: string;
  activeRequestId: number | null;
  error: string | null;
}

const additionalState: AdditionalState = {
  loading: 'idle',
  activeRequestId: null,
  error: null
};

const initialState = apiKeyAdapter.getInitialState(additionalState);

export const apiKeySlice = createSlice({
  name: 'apiKeys',
  initialState,
  reducers: {
    removeApiKey: apiKeyAdapter.removeOne
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApiKeys.pending, (state, action) => {
        state.loading = 'loading'
      })
      .addCase(getApiKeys.fulfilled, (state, action) => {
        apiKeyAdapter.addMany(state, action.payload)
        state.loading = 'idle';
      })
      .addCase(getApiKeys.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = 'Failed to load API keys.';
      })
      .addCase(addApiKey.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(addApiKey.fulfilled, (state, action) => {
        apiKeyAdapter.addOne(state, action.payload);
        state.loading = 'idle';
        state.error = null;
      })
      .addCase(addApiKey.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = 'Failed to add API Key';
      })
      .addCase(PURGE, (state) => {
        apiKeyAdapter.removeAll(state);
      })
  }
});

export default apiKeySlice.reducer;

export const { removeApiKey } = apiKeySlice.actions;

export const {
  selectById: selectApiKeyById,
  selectIds: selectApiKeyIds,
  selectAll: selectAllApiKeys,
  selectTotal: selectTotalApiKeys
} = apiKeyAdapter.getSelectors<RootState>((state) => state.apiKeys);
