export const createProductSearchData = (
  businessId,
  selectedBrands,
  selectedCategories,
  excludedBrands,
  excludedCategories
) => ({
  size: 10000, // Set the size to a large number to retrieve all documents
  _source: ["_id", "brandName", "name", "category", "jointId"],
  query: {
    bool: {
      must: [{ match: { businessId: businessId } }],
      filter: [
        ...(selectedBrands && selectedBrands.length > 0
          ? [{ terms: { brandName: selectedBrands } }]
          : []),
        ...(selectedCategories && selectedCategories.length > 0
          ? [{ terms: { category: selectedCategories } }]
          : []),
      ],
      must_not: [
        ...(excludedBrands && excludedBrands.length > 0
          ? [{ terms: { brandName: excludedBrands } }]
          : []),
        ...(excludedCategories && excludedCategories.length > 0
          ? [{ terms: { category: excludedCategories } }]
          : []),
      ],
    },
  },
});

export const createProductIdSearchData = (businessId, productIds) => ({
  size: 10000,
  _source: ["_id", "brandName", "name", "category", "jointId"],
  query: {
    bool: {
      must: [
        { match: { businessId: businessId } },
        { terms: { _id: productIds } },
      ],
    },
  },
});

export const createCategorySearchData = (
  businessId,
  selectedBrands,
  selectedProducts
) => ({
  size: 0, // We don't need the actual documents
  query: {
    bool: {
      must: [{ match: { businessId: businessId } }],
      filter: [
        ...(selectedBrands && selectedBrands.length > 0
          ? [{ terms: { brandName: selectedBrands } }]
          : []),
        ...(selectedProducts && selectedProducts.length > 0
          ? [{ terms: { jointId: selectedProducts } }]
          : []),
      ],
    },
  },
  aggs: {
    brands: {
      terms: { field: "brandName" },
      aggs: {
        categories: {
          terms: { field: "category", },
          aggs: {
            subcategories: {
              terms: { field: "subCategory",}
            }
          }
        }
      }
    },
    categories: {
      terms: { field: "category", },
      aggs: {
        brands: {
          terms: { field: "brandName" },
          aggs: {
            subcategories: {
              terms: { field: "subCategory",}
            }
          }
        },
        subcategories: {
          terms: { field: "subCategory", include: "[^none,NONE].*" },
        }
      }
    }
  },
});

export const createBrandSearchData = (
  businessId,
  selectedCategories,
  selectedSubCategories,
  selectedProducts,
) => ({
  size: 0, // We don't need the actual documents
  query: {
    bool: {
      must: [{ match: { businessId: businessId } }],
      filter: [
        ...(selectedCategories && selectedCategories.length > 0
          ? [{ terms: { category: selectedCategories } }]
          : []),
        ...(selectedProducts && selectedProducts.length > 0
          ? [{ terms: { _id: selectedProducts } }]
          : []),
        ...(selectedSubCategories && selectedSubCategories.length > 0
          ? [{ terms: { subCategory: selectedSubCategories } }]
          : []), // Filter for subcategories
      ],
    },
  },
  aggs: {
    brands: {
      terms: { field: "brandName", size: 10000 },
      aggs: {
        categories: {
          terms: { field: "category" },
          aggs: {
            subcategories: {
              terms: { field: "subCategory" }
            }
          }
        },
        subcategories: {
          terms: { field: "subCategory" }
        }
      }
    },
  },
});
