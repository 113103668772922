import config from '../config';
import axios from 'axios';
import { locationConstants } from '../constants';

export const locationService = {
    getPredictions,
    getLocationByLatLong,
    getIpLocation,
    getDeviceLocation,
    getStaticStateInfo,
    getStateDetails,
    getStateNameByAbbr,
    getCityDetails,
    getPostalCodeDetails,
    getPolygonCoords,
};

/**
 * [getPredictions description]
 * @param  {[type]} query           [description]
 * @param  {[type]} [deviceLocation=null] [description]
 * @return {[type]}                 [description]
 */
function getPredictions(query, deviceLocation = null) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    let apiUrl = `${config.tomTomApiUrl}/search/2/search/${query}.json?typeahead=true&limit=20&idxSet=Geo&countrySet=US,USA&key=${config.tomTomApiKey}`;
    if (deviceLocation) {
        const position = deviceLocation.locationDetails.position.split(',');
        apiUrl += `&lat=${position[0]}&lon=${position[1]}`
    }
    return axios.get(apiUrl, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

/**
 * Reverse Geo Lookup. Find city location by lat long coordinates
 * @param  {[type]} lat  [description]
 * @param  {[type]} long [description]
 * @return {[type]}      [description]
 */
function getLocationByLatLong(lat, long) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    let apiUrl= `${config.tomTomApiUrl}/search/2/reverseGeocode/${lat},${long}.json?entityType=Municipality&key=${config.tomTomApiKey}`;
    return axios.get(apiUrl, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

/**
 * Get static state details
 * @param  {[type]} stateName [description]
 * @return {[type]}           [description]
 */
function getStaticStateInfo(stateName) {
    const formattedName = stateName.replaceAll('-', ' ');
    const info = (formattedName in locationConstants.STATE_DETAILS) ? locationConstants.STATE_DETAILS[formattedName] : null;
    return info;
}

/**
 * get state details from state query string
 * @param  {[type]} stateQuery            [description]
 * @param  {[type]} [deviceLocation=null] [description]
 * @return {[type]}                       [description]
 */
function getStateDetails(stateQuery, deviceLocation = null) {
    const formattedStateQuery = stateQuery.replaceAll('-', ' ').replaceAll('.', '');
    return getPredictions(formattedStateQuery, deviceLocation)
        .then(results => {
            let stateMatch = null;

            for (let result of results) {
                if (result.entityType === 'CountrySubdivision') {
                    let strippedName = result.address.countrySubdivisionName.replaceAll('.', '').toLowerCase();
                    if (strippedName === formattedStateQuery) {
                        stateMatch = result;
                        break;
                    }
                }
            }

            return stateMatch;
        })
        .catch(handleError);
}

/**
 * [getStateNameByAbbr description]
 * @param  {[type]} stateAbbr [description]
 * @return {[type]}           [description]
 */
function getStateNameByAbbr(stateAbbr) {
    let stateName = null;
    const formattedStateAbbr = stateAbbr.toLowerCase();

    for (var key in locationConstants.STATE_DETAILS) {
        if (locationConstants.STATE_DETAILS[key].abbr === formattedStateAbbr) {
            stateName = key;
        }
    }
    return stateName;
}

/**
 * Get city from city query
 * @param  {[type]} cityQuery             [description]
 * @param  {[type]} stateAbbr             [description]
 * @param  {[type]} [deviceLocation=null] [description]
 * @return {[type]}                       [description]
 */
function getCityDetails(cityQuery, stateAbbr, deviceLocation = null) {
    const formattedCityQuery = cityQuery.replaceAll('-', ' ').replaceAll('.', '');
    const query = `${formattedCityQuery} ${stateAbbr}`;
    return getPredictions(query, deviceLocation)
        .then(results => {
            let cityMatch = null;

            for (let result of results) {
                if (result.entityType === 'Municipality') {
                    let strippedName = result.address.municipality.replaceAll('.', '').replaceAll('-', ' ').toLowerCase();
                    if (strippedName === formattedCityQuery && result.address.countrySubdivision.toLowerCase() === stateAbbr) {
                        cityMatch = result;
                        break;
                    }
                }
            }

            return cityMatch;
        })
        .catch(handleError);
}

/**
 * Get location details from postal code
 * @param  {[type]} postalCode            [description]
 * @param  {[type]} stateAbbr             [description]
 * @param  {[type]} [deviceLocation=null] [description]
 * @return {[type]}                       [description]
 */
function getPostalCodeDetails(postalCode, stateAbbr, deviceLocation = null) {
    return getPredictions(postalCode, deviceLocation)
        .then(results => {
            let postalCodeMatch = null;

            for (let result of results) {
                if(result.entityType === 'PostalCodeArea') {
                    if(result.address.countrySubdivision.toLowerCase() === stateAbbr.toLowerCase()) {
                        postalCodeMatch = result;
                        break;
                    }
                }
            }

            return postalCodeMatch;
        })
        .catch(handleError);
}

/**
 * Get user location from ip address
 * @return {[type]} [description]
 */
function getIpLocation() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        params: {
            'api_key': config.abstractApiKey
        }
    };
    let apiUrl = config.abstractApiUrl;
    return axios.get(apiUrl, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

/**
 * Get users location from device
 * @return {[type]} [description]
 */
function getDeviceLocation() {
    return (
        new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const coords = {latitude: position.coords.latitude, longitude: position.coords.longitude}
                        resolve(coords);
                    },
                    (error) => {
                        handleDeviceLocationError(error);
                        reject(error);
                    },
                    {timeout:7000});
            } else {
                console.log("Geolocation is not supported by this browser.");
            }
        })
    )
}

/**
 * [getPolygonCoords description]
 * @param  {[type]} location [description]
 * @return {[type]}          [description]
 */
function getPolygonCoords(location) {
    let searchQuery = null;
    if (location.entityType === 'CountrySubdivision') {
        searchQuery = location.address.countrySubdivisionName;
    }
    if (location.entityType === 'Municipality') {
        searchQuery = `${location.address.municipality} ${location.address.countrySubdivisionName}`;
    }
    if(location.entityType === 'PostalCodeArea') {
        searchQuery = `${location.address.countrySecondarySubdivision} county ${location.address.countrySubdivisionName}`;
    }

    if (!searchQuery) {
        return null;
    }

    const osmApi = `https://nominatim.openstreetmap.org/search.php`;
    const params = {
        q: searchQuery,
        polygon_geojson: 1,
        format: "json",
    }
    return axios.get(osmApi, {params: params})
        .then(response => {
            return response.data[0];
        })
        .catch(handleError);
}

/**
 * handle api response
 * @param  {[type]} response [description]
 * @return {[type]}          [description]
 */
function handleResponse(response) {
    if ('results' in response.data) {
        return response.data.results;
    }
    if ('addresses' in response.data) {
        return response.data.addresses;
    }
    return response.data;
}

/**
 * handle api error response
 * @param  {[type]} error [description]
 * @return {[type]}       [description]
 */
function handleError(error) {
    if(error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
    } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }
    console.log(error.config);
}


/**
 * Handles error from get device location
 * @param  {[type]} error [description]
 * @return {[type]}       [description]
 */
function handleDeviceLocationError(error) {
    switch(error.code) {
        case error.PERMISSION_DENIED:
            console.log("User denied the request for Geolocation.");
            break;
        case error.POSITION_UNAVAILABLE:
            console.log("Location information is unavailable.");
            break;
        case error.TIMEOUT:
            console.log("The request to get user location timed out.");
            break;
        case error.UNKNOWN_ERROR:
            console.log("An unknown error occurred.");
            break;
        default:
            console.log('Error getting location');
            break;
    }
}
