import React from "react";
import { Typography } from "@mui/material";

interface LabelProps {
  label: string;
  minWidth?: string | number;
}

const Label: React.FC<LabelProps> = ({ label, minWidth = "0px" }) => {
  return (
    <Typography
      variant="h6"
      component="h6"
      style={{ fontWeight: "bold", marginRight: "0.25rem", minWidth }}
    >
      {label}
    </Typography>
  );
};

export default Label;
