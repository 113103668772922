import { Remove } from "@mui/icons-material";
import { IconButton, MenuItem, Select, Stack, Tooltip } from "@mui/material";
import TransitionAlerts from "components/shared/TransitionAlert";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { AlertType } from "types/alertTypes";
import BrandOption from "components/businesses/ecommerce/specials/filters/BrandOption";
import CategoryOption from "components/businesses/ecommerce/specials/filters/CategoryOption";
import styles from "components/businesses/ecommerce/specials/filters/FilterOptions.module.css";
import ProductOption from "components/businesses/ecommerce/specials/filters/ProductOption";
import BothOption from "components/businesses/ecommerce/specials/filters/BothOption";

export default function BundleFilterOptions({
  filter,
  filters,
  filterGroup,
  setFilterGroup,
  index,
  editing
}) {
  // * Formik
  const filterId = `filter-${index}`;
  const formik = useFormikContext();
  const { setFieldValue } = formik;

  // * Errors
  const [weightFilterErrorMessage, setWeightFilterErrorMessage] = useState("");
  const [weightValueErrorMessage, setWeightValueErrorMessage] = useState("");
  const hasErrors =
    formik.errors.bundleQualifiers?.[0]?.customCollection?.filters?.[index] &&
    formik.touched.bundleQualifiers?.[0]?.customCollection?.filters?.[index];

  // * Selections
  const [selectedProducts, setSelectedProducts] = useState(
    filter?.includedProductIds
  );
  const [selectedCategories, setSelectedCategories] = useState(
    filter?.includedCategories
  );
  const [selectedSubCategories, setSelectedSubCategories] = useState(
    filter?.includedSubCategories
  );
  const [selectedBrands, setSelectedBrands] = useState(
    filter?.includedBrandIds
  );
  const [weightFilter, setWeightFilter] = useState(
    filter?.weightFilterOperator || ""
  );
  const [weightValue, setWeightValue] = useState(
    filter?.weightFilterValue || ""
  );

  // * Update the form values when filters changes
  useEffect(() => {
    setSelectedProducts(filter?.includedProductIds);
    setSelectedCategories(
      filter?.includedCategories
    );
    setSelectedSubCategories(
      filter?.includedSubCategories
    );
    setSelectedBrands(filter?.includedBrandIds);
    setWeightFilter(filter?.weightFilterOperator ?? "");
    setWeightValue(filter?.weightFilterValue ?? "");
  }, [filters.length]);

  // * Update the form values when a dependency changes
  useEffect(() => {
    const newFilters = [...filters];
    const filterToAdd = {};

    filterToAdd.includedProductIds = selectedProducts || [];

    filterToAdd.includedCategories = selectedCategories || [];

    filterToAdd.includedSubCategories = selectedSubCategories || [];

    filterToAdd.includedBrandIds = selectedBrands || [];

    filterToAdd.weightFilterOperator = weightFilter || "";

    filterToAdd.weightFilterValue = weightValue || "";

    //  * Complete the new fitler at current index
    newFilters[index] = filterToAdd;

    // * Set the formik filters and bundle qualifiers
    setFieldValue(`bundleQualifiers[0].customCollection.filters`, newFilters);
  }, [
    selectedProducts,
    selectedCategories,
    selectedSubCategories,
    selectedBrands,
    weightFilter,
    weightValue,
  ]);
  // * Logic to render out options
  function renderFilterOptions() {
    switch (filterGroup) {
      case "item":
        return (
          <ProductOption
            filter={filter}
            filterIndex={index}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            weightFilter={weightFilter}
            setWeightFilter={setWeightFilter}
            weightValue={weightValue}
            setWeightValue={setWeightValue}
            weightFilterErrorMessage={weightFilterErrorMessage}
            weightValueErrorMessage={weightValueErrorMessage}
            setWeightValueErrorMessage={setWeightValueErrorMessage}
            setWeightFilterErrorMessage={setWeightFilterErrorMessage}
            editing={editing}
          />
        );
      case "brand":
        return (
          <BrandOption
            filter={filter}
            filterIndex={index}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            selectedSubCategories={selectedSubCategories}
            setSelectedSubCategories={setSelectedSubCategories}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
            selectedProducts={selectedProducts}
            weightFilter={weightFilter}
            setWeightFilter={setWeightFilter}
            weightValue={weightValue}
            setWeightValue={setWeightValue}
            weightFilterErrorMessage={weightFilterErrorMessage}
            weightValueErrorMessage={weightValueErrorMessage}
            setWeightValueErrorMessage={setWeightValueErrorMessage}
            setWeightFilterErrorMessage={setWeightFilterErrorMessage}
          />
        );
      case "category":
        return (
          <CategoryOption
            filter={filter}
            filterIndex={index}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            selectedSubCategories={selectedSubCategories}
            setSelectedSubCategories={setSelectedSubCategories}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
            selectedProducts={selectedProducts}
            weightFilter={weightFilter}
            setWeightFilter={setWeightFilter}
            weightValue={weightValue}
            setWeightValue={setWeightValue}
            weightFilterErrorMessage={weightFilterErrorMessage}
            weightValueErrorMessage={weightValueErrorMessage}
            setWeightValueErrorMessage={setWeightValueErrorMessage}
            setWeightFilterErrorMessage={setWeightFilterErrorMessage}
          />
        );
        case "both":
        return (
          <BothOption
            filter={filter}
            filterIndex={index}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
            selectedProducts={selectedProducts}
            weightFilter={weightFilter}
            setWeightFilter={setWeightFilter}
            weightValue={weightValue}
            setWeightValue={setWeightValue}
            weightFilterErrorMessage={weightFilterErrorMessage}
            weightValueErrorMessage={weightValueErrorMessage}
            setWeightValueErrorMessage={setWeightValueErrorMessage}
            setWeightFilterErrorMessage={setWeightFilterErrorMessage}
          />
        );
      default:
        break;
    }
  }

  // * Remove filter at current index
  function handleRemoveFilterFromDiscount() {
    const newFilters = [...filters];
    newFilters.splice(index, 1);
    formik.setFieldValue(
      `bundleQualifiers[0].customCollection.filters`,
      newFilters
    );

    if (newFilters.length === 0) {
      setFilterGroup(null)
    }
  }

  // * Handling Logic Operator Change
  function handleLogicOperatorChange(event) {
    const newLogicOperator = event.target.value;
    formik.setFieldValue(
      `bundleQualifiers[0].customCollection.logicOperator`,
      newLogicOperator
    );
  }

  return (
    <div id={filterId}>
      <div className={styles.filterContainer}>
        <div className={styles.filterOptions}>{renderFilterOptions()}</div>
        <div>
          <div>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Tooltip title="Remove this filter" placement="top">
                <IconButton
                  color="error"
                  sx={{ backgroundColor: "#ff4d4f15" }}
                  onClick={handleRemoveFilterFromDiscount}
                >
                  <Remove />
                </IconButton>
              </Tooltip>
            </Stack>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "1rem" }}>
        <TransitionAlerts
          title="This Filter Has Errors"
          description={
            formik.errors.bundleQualifiers?.[0]?.customCollection?.filters?.[
              index
            ]
              ? formik.errors.bundleQualifiers[0]?.customCollection?.filters[
                  index
                ]
              : ""
          }
          type={AlertType.Error}
          transitionOpen={hasErrors}
          cannotExit
        />
      </div>
      {filters?.length > 1 && index !== filters?.length - 1 && (
        <div className={styles.filterBottom}>
          <Select
            value={
              formik.values.bundleQualifiers[0].customCollection.logicOperator
            }
            onChange={handleLogicOperatorChange}
          >
            <MenuItem value="AND">AND</MenuItem>
            <MenuItem value="OR">OR</MenuItem>
          </Select>
        </div>
      )}
    </div>
  );
}
