import { USER_ROLES } from "constants/user.constants";
import { AppLayout } from "layout/app-layout";
import { LayoutBox } from "layout/layout-box";
import { Navigation } from "layout/navigation";
import { useAdminNav } from "layout/navigation/hooks";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "redux/hooks";


export function AdminLayout() {
  const currentUser = useAppSelector((state) => state.auth.user);
  const { navItems } = useAdminNav();

  return (
    <AppLayout>
      <Navigation navItems={navItems} navigationType="ADMIN" />
      <LayoutBox>
        {currentUser?.role === USER_ROLES.ADMIN && <Outlet />}
      </LayoutBox>
    </AppLayout>
  );
}
