import React, { useEffect } from "react";

// project import
import SpecialCreate from "components/SpecialCreate/SpecialCreate";
import { getBusiness } from "redux/businessSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getBusinessSpecials } from "redux/nativeEcommerceSlice";
// ==============================|| DASHBOARD - DEFAULT ||============================== //

const BusinessEcommerceSpecialsAdd = () => {
  const business = useAppSelector((state) => state.business.info);
  const dispatch = useAppDispatch();
  const businessId = business?.id;
  const refreshBusiness = () => {
    dispatch(getBusiness(businessId));
    const publicKey =
      business.menuSettings?.jointEcommercePublicKey || "defaultPublicKey";
    dispatch(getBusinessSpecials({ businessId: businessId, key: publicKey }));
  };

  useEffect(() => {
    refreshBusiness();
  }, []);

  return (
    <React.Fragment>
      <SpecialCreate business={business} />
    </React.Fragment>
  );
};

export default BusinessEcommerceSpecialsAdd;
