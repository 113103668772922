function descendingComparator(a, b, orderBy) {
  // Access nested properties safely
  const valueA = orderBy.split(".").reduce((acc, part) => acc && acc[part], a);
  const valueB = orderBy.split(".").reduce((acc, part) => acc && acc[part], b);

  // Handle null/undefined values - consider them "less than" other values
  if (valueA == null) return 1;
  if (valueB == null) return -1;

  // Assuming both values are strings, use localeCompare for case-insensitive sorting
  if (typeof valueA === "string" && typeof valueB === "string") {
    return valueB.localeCompare(valueA, undefined, { sensitivity: "base" });
  } else {
    // Handle other data types or fallback to default comparison
    return valueB > valueA ? -1 : valueB < valueA ? 1 : 0;
  }
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, ix) => [el, ix]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export { descendingComparator, getComparator, stableSort };
