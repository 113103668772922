import { useState, useEffect, useRef } from "react";
import {
  Button,
  Typography,
  Grid,
  Backdrop,
  CircularProgress,
  Box,
  Link,
} from "@mui/material";
import { toast } from "react-hot-toast";
import classes from "../styles.module.css";
import { categories, strains, effects } from "../InventoryManagementConstants";
import { Form, Formik } from "formik";
import { Cannabinoids, Terpenes, Variants, PotencyChips } from "../Helpers";
import { useAppDispatch } from "redux/hooks";
import {
  getProduct,
  updateProduct,
  getProductLog,
} from "redux/nativeEcommerceSlice";

import ProductLogTable from "../ProductLogTable";
import { ArrowBackIos, ReceiptLong, Save } from "@mui/icons-material";
import EditorAccordion from "../editor-accordion/editor-accordion.component";
import CustomTextInput from "../custom-inputs/custom-text-input.component";
import CustomSelect from "../custom-inputs/custom-select.component";
import CustomAutocomplete from "../custom-inputs/custom-autocomplete.component";
import CustomTagAutocomplete from "../custom-inputs/custom-tag-autocomplete.component";
import CustomCheckbox from "../custom-inputs/custom-checkbox.component";
import { NoProductsFound } from "../business-inventory-table";

const ProductEditor = () => {
  const dispatch = useAppDispatch();
  const logRef = useRef<HTMLDivElement>(null);

  const url = window.location.pathname;
  const productId = url.split("/").pop();
  const businessId = url.split("/")[2];
  const [product, setProduct] = useState<any>();
  const [initialValues, setInitialValues] = useState<any>();
  const [error, setError] = useState<string | null>(null);
  const [loading, isLoading] = useState(false);
  const [productLog, setProductLog] = useState([]);
  const [isLogVisible, setIsLogVisible] = useState(false);

  const isInCustomizedFields = (field: string) => {
    return product?.customizedFields?.hasOwnProperty(field);
  };

  const handleRemoveField = async (fieldName: string) => {
    try {
      const fields = product?.customizedFields || {};

      if (fields.hasOwnProperty(fieldName)) {
        const { [fieldName]: removedField, ...newFields } = fields;

        const updatedProduct = {
          ...product,
          customizedFields: newFields,
          hasCustomizedData: Object.keys(newFields).length > 0,
        };

        const response = await dispatch(
          updateProduct({ product: updatedProduct }),
        );
        const updatedProductData = response.payload;

        setProduct(updatedProductData);
        toast.success(
          `Removed ${fieldName} from customized fields. This field will be updated with values from ${product.provider} unless you save further changes.`,
        );
      } else {
        toast.error(`${fieldName} not found in customized fields.`);
      }
    } catch (error) {
      console.error("Error removing field: ", error);
      toast.error("Error removing field. Please try again.");
    }
  };

  const handleToggleLog = async () => {
    if (!isLogVisible) {
      if (!productLog || productLog.length === 0) {
        await fetchProductLog(product.id);
      }
      setTimeout(() => {
        if (logRef.current) {
          logRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
    setIsLogVisible((prevState) => !prevState);
  };

  const fetchProductLog = async (productId: string) => {
    const log = await dispatch(getProductLog({ productId }));
    setProductLog(log.payload?.logs);
  };

  useEffect(() => {
    (async () => {
      isLoading(true);
      try {
        const response = await dispatch(getProduct({ productId }));
        const fetchedProduct = response.payload;
        isLoading(false);

        setProduct(fetchedProduct);
        setInitialValues({
          name: fetchedProduct.name,
          category: fetchedProduct.category,
          subCategory: fetchedProduct.subCategory,
          brand: fetchedProduct.brand.name,
          strainType: fetchedProduct.strainType,
          description: fetchedProduct.description,
          potency: fetchedProduct.potency,
          primaryImage: fetchedProduct.originalPrimaryImage,
          variants: fetchedProduct.variants,
          menuType: fetchedProduct.menuType,
          cannabinoids: fetchedProduct.cannabinoids,
          terpenes: fetchedProduct.terpenes,
          staffPick: fetchedProduct.staffPick,
          effects: fetchedProduct.effects,
          tagList: fetchedProduct.tagList,
        });
      } catch (error) {
        setError("Error fetching product details.");
        isLoading(false);
      }
    })();
  }, [dispatch, productId]);

  const handleSubmit = async (e: any) => {
    try {
      const keysToIgnore = [
        "potency",
        "menuType",
        "cannabinoids",
        "terpenes",
        "variants",
      ];

      const updatedFields: any = {};

      Object.keys(e).forEach((key) => {
        if (!keysToIgnore.includes(key) && e[key] !== initialValues[key]) {
          if (
            key === "effects" &&
            (e[key] === undefined || e[key].length === 0)
          ) {
            return;
          }
          updatedFields[key] = e[key];
        }
      });

      const updatedData = {
        ...product,
        ...updatedFields,
        brand: {
          ...product.brand,
          name: e.brand,
        },
        hasCustomizedData: true,
        customizedFields: {
          ...product.customizedFields,
          ...updatedFields,
        },
      };
      const response = await dispatch(
        updateProduct({
          product: updatedData,
        }),
      );

      setProduct(response.payload);
      setProductLog([]);
      setIsLogVisible(false);
      toast.success("Product updated successfully!");
    } catch (error) {
      console.error("Error updating product: ", error);
      toast.error("Error updating product. Please try again.");
    }
  };

  const showNoProductFoundError = !loading && error;

  if (!product) {
    return (
      <>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!product}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }

  if (showNoProductFoundError) {
    return <NoProductsFound isProductDetailsPage />;
  }

  return (
    <>
      <div className={classes.container}>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, handleChange, setFieldValue }) => {
            const handleEffectsChange = (event: any, newValue: any) => {
              setFieldValue(
                "effects",
                newValue.map((effect: any) => effect.value),
              );
            };
            const handleTagsChange = (
              event: React.SyntheticEvent,
              value: string[],
            ) => {
              setFieldValue("tagList", value);
            };
            return (
              <Form>
                <div>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: { xs: "flex-start", sm: "center" },
                      marginBottom: { xs: 2, sm: 0 },
                    }}
                  >
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Link
                        href={`/businesses/${businessId}/ecommerce/inventory`}
                        sx={{
                          color: "#6c6c6c",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <ArrowBackIos
                            sx={{ fontSize: "12px", height: "12px" }}
                          />
                        </span>
                        <Typography variant="body2" component="div">
                          Back to Products List
                        </Typography>
                      </Link>
                      <Typography variant="h2" component="h2" gutterBottom>
                        Product Manager
                      </Typography>
                    </Box>
                    <Box display="flex" gap={1}>
                      <Button
                        variant="outlined"
                        onClick={handleToggleLog}
                        startIcon={<ReceiptLong />}
                        color="secondary"
                      >
                        {isLogVisible ? "Hide Product Log" : "Show Product Log"}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        startIcon={<Save />}
                      >
                        Save Product
                      </Button>
                    </Box>
                  </Box>
                </div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} order={{ xs: 2, sm: 1 }}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        {values.primaryImage && (
                          <EditorAccordion title="Product Image" keepOpen>
                            <Typography
                              variant="body2"
                              component="p"
                              fontWeight="700"
                              color="textSecondary"
                            >
                              Image
                            </Typography>
                            <img
                              src={values.primaryImage}
                              alt={`${values.name}`}
                              style={{
                                width: "100%",
                                height: "auto",
                                border: "1px solid #ccc",
                                borderRadius: 4,
                              }}
                            />
                          </EditorAccordion>
                        )}
                        {values?.variants.length > 0 && (
                          <EditorAccordion title="Variants & Pricing">
                            <div>
                              <Variants variants={values.variants} />
                            </div>
                          </EditorAccordion>
                        )}

                        <EditorAccordion title="Menu Types">
                          {values?.menuType.length > 0 && (
                            <CustomAutocomplete
                              id="menu-type"
                              label="Menu Type"
                              additionalContent="Read Only"
                              options={["RECREATIONAL", "MEDICAL"].map(
                                (value) => ({ value, label: value }),
                              )}
                              defaultValue={values.menuType.map(
                                (type: string) => ({
                                  value: type,
                                  label: type,
                                }),
                              )}
                              getOptionLabel={(option: any) => option.label}
                              readOnly
                              size="small"
                              multiple
                            />
                          )}
                        </EditorAccordion>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} order={{ xs: 1, sm: 2 }}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <EditorAccordion
                          title="General Information"
                          keepOpen
                          product={product}
                        >
                          <Grid container columnSpacing={2}>
                            <Grid item xs={12} sm={12}>
                              <CustomTextInput
                                label="Name"
                                tooltipTitle={`Click to refresh values from ${product.provider} data.`}
                                onRefreshClick={() => handleRemoveField("name")}
                                isRefreshDisabled={
                                  !isInCustomizedFields("name")
                                }
                                id="productName"
                                name="name"
                                value={values.name}
                                sx={{ flexGrow: 1 }}
                                fullWidth
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CustomSelect
                                label="Category"
                                tooltipTitle={`Click to refresh values from ${product.provider} data.`}
                                onRefreshClick={() =>
                                  handleRemoveField("category")
                                }
                                isRefreshDisabled={
                                  !isInCustomizedFields("category")
                                }
                                name="category"
                                value={values.category}
                                onChange={handleChange}
                                options={categories}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CustomTextInput
                                id="subcategory"
                                label="Subcategory"
                                name="subCategory"
                                value={values.subCategory}
                                sx={{ flexGrow: 1, marginRight: 0 }}
                                onChange={handleChange}
                                tooltipTitle={`Click to refresh values from ${product.provider} data.`}
                                onRefreshClick={() =>
                                  handleRemoveField("subCategory")
                                }
                                isRefreshDisabled={
                                  !isInCustomizedFields("subCategory")
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CustomTextInput
                                id="brand"
                                label="Brand"
                                name="brand"
                                defaultValue={values.brand.name}
                                sx={{ flexGrow: 1, marginRight: 0 }}
                                onChange={handleChange}
                                tooltipTitle={`Click to refresh values from ${product.provider} data.`}
                                onRefreshClick={() =>
                                  handleRemoveField("brand")
                                }
                                isRefreshDisabled={
                                  !isInCustomizedFields("brand")
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CustomSelect
                                label="Strain Type"
                                tooltipTitle={`Click to refresh values from ${product.provider} data.`}
                                onRefreshClick={() =>
                                  handleRemoveField("strainType")
                                }
                                isRefreshDisabled={
                                  !isInCustomizedFields("strainType")
                                }
                                value={values.strainType}
                                onChange={handleChange}
                                sx={{ flexGrow: 1, marginRight: 0 }}
                                name="strainType"
                                options={strains}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <CustomTextInput
                                id="description"
                                label="Description"
                                name="description"
                                defaultValue={values.description}
                                sx={{ width: "100%" }}
                                multiline
                                rows={6}
                                onChange={handleChange}
                                tooltipTitle={`Click to refresh values from ${product.provider} data.`}
                                onRefreshClick={() =>
                                  handleRemoveField("description")
                                }
                                isRefreshDisabled={
                                  !isInCustomizedFields("description")
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <CustomAutocomplete
                                label="Effects"
                                tooltipTitle={`Click to refresh values from ${product.provider} data.`}
                                onRefreshClick={() =>
                                  handleRemoveField("effects")
                                }
                                isRefreshDisabled={
                                  !isInCustomizedFields("effects")
                                }
                                id="effects"
                                options={effects}
                                getOptionLabel={(option: any) => option.label}
                                defaultValue={values.effects.map(
                                  (effectValue: any) =>
                                    effects.find(
                                      (effect) => effect.value === effectValue,
                                    ),
                                )}
                                onChange={handleEffectsChange}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <CustomTagAutocomplete
                                label="Tags"
                                tooltipTitle={`Click to refresh values from ${product.provider} data.`}
                                onRefreshClick={() =>
                                  handleRemoveField("tagList")
                                }
                                isRefreshDisabled={
                                  !isInCustomizedFields("tagList")
                                }
                                value={values?.tagList}
                                onChange={handleTagsChange}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <CustomCheckbox
                                label="Staff Pick"
                                tooltipTitle={`Click to refresh values from ${product.provider} data.`}
                                onRefreshClick={() =>
                                  handleRemoveField("staffPick")
                                }
                                isRefreshDisabled={
                                  !isInCustomizedFields("staffPick")
                                }
                                checked={values.staffPick}
                                onChange={handleChange}
                                name="staffPick"
                              />
                            </Grid>
                          </Grid>
                        </EditorAccordion>
                        {(values?.potency.length > 0 ||
                          values?.cannabinoids.length > 0 ||
                          values?.terpenes.length > 0) && (
                          <EditorAccordion title="Potencies, Cannabinoids & Terpenes">
                            <Box display="flex" flexDirection="column" gap={2}>
                              {values?.potency.length > 0 && (
                                <PotencyChips potencies={values.potency} />
                              )}
                              {values?.cannabinoids.length > 0 && (
                                <Cannabinoids
                                  cannabinoidValues={values.cannabinoids}
                                />
                              )}
                              {values?.terpenes.length > 0 && (
                                <Terpenes terpeneValues={values.terpenes} />
                              )}
                            </Box>
                          </EditorAccordion>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div
        ref={logRef}
        className={
          isLogVisible ? classes.form : `${classes.form} ${classes.invisible}`
        }
      >
        {isLogVisible && productLog && <ProductLogTable data={productLog} />}
      </div>
    </>
  );
};

export default ProductEditor;
