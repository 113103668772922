import React from 'react';
import LottieAnimation from './Lottie';
import loading from '../../assets/animations/loading-bus.json';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useWindowDimensions } from '../../hooks';
import { useTheme } from '@emotion/react';


export function Loading(props) {
  const theme = useTheme();

  const rootStyles = {
    width: '100%',
    textAlign: 'center',
    padding:theme.spacing(3),
    minHeight: 300,
    [theme.breakpoints.down('lg')]: {
      minHeight: 200,
    }
  }
  
  const imgContainerStyles = {
    width: '100%',
      maxWidth: 500,
      margin: '0px auto 10px',
      '& img': {
        display: 'block',
        width: '100%',
        maxWidth: 200,
      }
  }
  
  const windowDimensions = useWindowDimensions();
  return (
    <>
    <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
    <Box display="flex" justifyContent="center" flexDirection="column" style={rootStyles}>
      {!props.noAnimation &&
        <>
          <div style={imgContainerStyles}>
            <LottieAnimation lotti={loading} height={(windowDimensions.width > 1024) ? 250 : 150} width={(windowDimensions.width > 1024) ? 250 : 150} />
          </div>
          <Typography variant="subtitle1" component="div">{props.message}</Typography>
        </>
      }
      {props.noAnimation &&
        <>
          {props.children}
        </>
      }
    </Box>
    </>
  );
}
