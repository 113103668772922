export const businessConstants = {
  DEFAULT_API_TAKE: 10,
  DEFAULT_DISTANCE: 16093.44,
  MAX_RESULTS: 500,
  MAX_IMAGE_FILE_SIZE: 160 * 1024,
  SUPPORTED_IMAGE_FORMATS: [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "image/webp",
    "image/svg+xml",
    "image/jp2",
  ],
  SUPPORT_DELIVERY_ZONE_FORMATS: [".kml"],
  UPDATE_AUTHORITY_OPTIONS: [
    {
      name: "Lynx",
      value: "LYNX",
    },
    {
      name: "Manual",
      value: "MANUAL",
    },
  ],
  MENU_LAYOUT_OPTIONS: [
    {
      name: "5G",
      value: "5g",
    },
    {
      name: "OG",
      value: "original",
    },
    {
      name: "Weedify",
      value: "shopify",
    },
    {
      name: "Cannazon",
      value: "cannazon",
    },
    {
      name: "Elevated",
      value: "elevated",
    },
    {
      name: "Low Key",
      value: "flat",
    },
  ],
  MENU_PROVIDERS: {
    ALLEAVES: "Alleaves",
    COVA: "Cova",
    DUTCHIE: "Dutchie",
    INDICA: "Indica Online",
    FLOURISH: "Flourish",
    FLOWHUB: "Flowhub",
    JANE: "Jane",
    MEADOW: "Meadow",
    TREEZ: "Treez",
    POSABITS: "POSaBIT",
  },
  MENU_PROVIDER_OPTIONS: [
    {
      name: "Alleaves",
      value: "ALLEAVES",
    },
    {
      name: "Cova",
      value: "COVA",
    },
    {
      name: "Dutchie",
      value: "DUTCHIE",
    },
    {
      name: "Indica Online",
      value: "INDICA",
    },
    {
      name: "Flourish",
      value: "FLOURISH",
    },
    {
      name: "Flowhub",
      value: "FLOWHUB",
    },
    {
      name: "Jane",
      value: "JANE",
    },
    {
      name: "Meadow",
      value: "MEADOW",
    },
    {
      name: "Treez",
      value: "TREEZ",
    },
    {
      name: "POSaBIT",
      value: "POSABITS",
    },
  ],
  SPECIAL_STRATEGY_OPTIONS: [
    {
      name: "Normal",
      value: "NORMAL",
    },
    {
      name: "Cumulative",
      value: "CUMULATIVE",
    },
  ],
  TAX_STRATEGY_OPTIONS: [
    {
      name: "Normal",
      value: "NORMAL",
    },
    {
      name: "Cumulative",
      value: "CUMULATIVE",
    },
  ],
  MENU_TYPES: {
    RECREATIONAL: "Recreational",
    MEDICAL: "Medical",
  },
  MENU_TYPE_OPTIONS: [
    {
      name: "Recreational",
      value: "RECREATIONAL",
    },
    {
      name: "Medical",
      value: "MEDICAL",
    },
  ],
  ORDER_TYPES: {
    IN_STORE_PICKUP: "In Store",
    CURBSIDE_PICKUP: "Curbside",
    DRIVE_THRU_PICKUP: "Drive Through",
    DELIVERY: "Delivery",
    KIOSK: "Kiosk",
  },
  SPECIAL_TYPES: {
    PRODUCT: "Product",
    BUNDLE: "Bundle",
  },
  ORDER_TYPE_OPTIONS: [
    {
      name: "In Store",
      value: "IN_STORE_PICKUP",
    },
    {
      name: "Curbside",
      value: "CURBSIDE_PICKUP",
    },
    {
      name: "Drive Through",
      value: "DRIVE_THRU_PICKUP",
    },
    {
      name: "Delivery",
      value: "DELIVERY",
    },
    {
      name: "Kiosk",
      value: "KIOSK",
    },
  ],
  SPECIALS_ORDER_TYPE_OPTIONS: [
    {
      name: "In Store",
      value: "IN_STORE_PICKUP",
    },
    {
      name: "Curbside",
      value: "CURBSIDE_PICKUP",
    },
    {
      name: "Drive Through",
      value: "DRIVE_THRU_PICKUP",
    },
    {
      name: "Delivery",
      value: "DELIVERY",
    },
    {
      name: "Kiosk",
      value: "KIOSK",
    },
  ],
  MEADOW_MENU_TYPE_OPTIONS: [
    {
      name: "Pickup",
      value: "pickup",
    },
    {
      name: "Delivery",
      value: "delivery",
    },
    {
      name: "In Store",
      value: "in-store",
    },
  ],
  DUTCHIE_SORT_OPTIONS: [
    {
      name: "Price Low - High (Dutchie)",
      value: "Price Low - High",
    },
    {
      name: "Price High - Low (Dutchie)",
      value: "Price High - Low",
    },
    {
      name: "Potency Low - High (Dutchie)",
      value: "Potency Low - High",
    },
    {
      name: "Potency High - Low (Dutchie)",
      value: "Potency High - Low",
    },
    {
      name: "Name A - Z (Dutchie)",
      value: "Name A - Z",
    },
    {
      name: "Name Z - A (Dutchie)",
      value: "Name Z - A",
    },
  ],
  JANE_SORT_OPTIONS: [
    {
      name: "Top Rated (Jane)",
      value: "Top Rated",
    },
    {
      name: "Price Low to High (Jane)",
      value: "Price Low to High",
    },
    {
      name: "Price High to Low (Jane)",
      value: "Price High to Low",
    },
    {
      name: "THC Low to High (Jane)",
      value: "THC Low to High",
    },
    {
      name: "THC High to Low (Jane)",
      value: "THC High to Low",
    },
  ],
  ECOMMERCE_SORT_OPTIONS: [
    {
      name: "Default",
      value: "Default",
    },
    {
      name: "Popular",
      value: "Popular",
    },
    {
      name: "Best Selling",
      value: "Best Selling",
    },
    {
      name: "Name",
      value: "Name",
    },
    {
      name: "Brand",
      value: "Brand",
    },
    {
      name: "Price: Low to High",
      value: "Price: Low to High",
    },
    {
      name: "Price: High to Low",
      value: "Price: High to Low",
    },
    {
      name: "THC: Low to High",
      value: "THC: Low to High",
    },
    {
      name: "THC: High to Low",
      value: "THC: High to Low",
    },
    {
      name: "CBD: Low to High",
      value: "CBD: Low to High",
    },
    {
      name: "CBD: High to Low",
      value: "CBD: High to Low",
    },
    {
      name: "Relevance",
      value: "Relevance",
    }
  ],
  DEFAULT_STORE_SELECTOR_METHOD_OPTIONS: [
    {
      name: "Auto",
      value: "auto",
    },
    {
      name: "Manual",
      value: "manual",
    },
  ],
  TAX_TYPES: [
    {
      name: "Cannabis Tax",
      value: "CANNABIS",
    },
    {
      name: "Other Tax",
      value: "OTHER",
    },
    {
      name: "Sales Tax",
      value: "SALES",
    },
  ],
  THC_THRESHOLD_OPERATORS: [
    {
      name: "None",
      value: undefined,
    },
    {
      name: "Equal",
      value: "EQ",
    },
    {
      name: "Greater than",
      value: "GT",
    },
    {
      name: "Greater than or equal to",
      value: "GT_EQ",
    },
    {
      name: "Less than",
      value: "LT",
    },
    {
      name: "Less than or equal to",
      value: "LT_EQ",
    },
  ],
  CATEGORIES: [
    {
      name: "Accessories",
      value: "ACCESSORIES",
    },
    {
      name: "Apparel",
      value: "APPAREL",
    },
    {
      name: "CBD",
      value: "CBD",
    },
    {
      name: "Clones",
      value: "CLONES",
    },
    {
      name: "Concentrates",
      value: "CONCENTRATES",
    },
    {
      name: "Edibles",
      value: "EDIBLES",
    },
    {
      name: "Flower",
      value: "FLOWER",
    },
    {
      name: "Merch",
      value: "MERCH",
    },
    {
      name: "None",
      value: "NONE",
    },
    {
      name: "Oral",
      value: "ORAL",
    },
    {
      name: "Plant",
      value: "PLANT",
    },
    {
      name: "Pre-Rolls",
      value: "PRE_ROLLS",
    },
    {
      name: "Seeds",
      value: "SEEDS",
    },
    {
      name: "Tinctures",
      value: "TINCTURES",
    },
    {
      name: "Topicals",
      value: "TOPICALS",
    },
    {
      name: "Vaporizers",
      value: "VAPORIZERS",
    },
  ],
  PAYMENT_TYPES: [
    {
      name: "Cash",
      value: "CASH",
    },
    {
      name: "Check",
      value: "CHECK",
    },
    {
      name: "Credit Card",
      value: "CREDIT_CARD",
    },
    {
      name: "Debit Card",
      value: "DEBIT_CARD",
    },
    {
      name: "Pay by Phone",
      value: "PHONE",
    },
    {
      name: "Aeropay",
      value: "AEROPAY",
    },
    {
      name: "CanPay",
      value: "CANPAY",
    },
    {
      name: "Stronghold",
      value: "STRONGHOLD",
    },
  ],
};
