import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BusinessIntegration } from "components/business-integrations/types";
import { PURGE } from "redux-persist";
import { businessIntegrationsService } from "services";



interface InitialState {
    integrations: BusinessIntegration[];
    status: "idle" | "loading" | "failed";
    error: string | null;
  }

  const initialState: InitialState = {
    integrations: [],
    status: "idle",
    error: null,
  };

export const getBusinessIntegrationByName = createAsyncThunk(
    "business-integrations/getBusinessIntegrationByName",
    async (data: any) => {
      const { id, name, integrationType } = data;
      const response =
        await businessIntegrationsService.getBusinessIntegrationByName(
          id,
          name,
          integrationType
        );
      return response as BusinessIntegration;
    }
  );
  
  export const getBusinessIntegrations = createAsyncThunk(
    "business-integrations/getBusinessIntegrations",
    async (id: number) => {
      const response = await businessIntegrationsService.getBusinessIntegrations(
        id
      );
      return response as BusinessIntegration[];
    }
  );
  
  export const addBusinessIntegration = createAsyncThunk(
    "business-integrations/addBusinessIntegration",
    async (newData: Omit<BusinessIntegration, "id">, { rejectWithValue }) => {
      if (newData.businessId) {
        const response = await businessIntegrationsService.addBusinessIntegration(
          newData
        );
        return response as BusinessIntegration;
      } else {
        return rejectWithValue("Missing integration ID");
      }
    }
  );
  
  export const updateBusinessIntegration = createAsyncThunk(
    "business-integrations/updateBusinessIntegration",
    async (newData: Partial<BusinessIntegration>, { rejectWithValue }) => {
      if (newData.id) {
        const response =
          await businessIntegrationsService.updateBusinessIntegration(
            newData.id,
            newData
          );
        return response as BusinessIntegration;
      } else {
        return rejectWithValue("Missing integration ID");
      }
    }
  );
  
  export const deleteBusinessIntegration = createAsyncThunk(
    "business-integrations/deleteBusinessIntegration",
    async (integrationId: number) => {
      const response =
        await businessIntegrationsService.deleteBusinessIntegration(
          integrationId
        );
      return response as BusinessIntegration;
    }
  );

  export const businessIntegrationsSlice = createSlice({
    name: "businessIntegrations",
    initialState,
    reducers: {
      clearBusiness(state) {
        state.integrations = [];
        state.status = "idle";
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(PURGE, () => {
          return initialState;
        })
        .addCase(getBusinessIntegrations.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(getBusinessIntegrations.fulfilled, (state, action) => {
          state.status = "idle";
          state.integrations = action.payload;
          state.error = null;
        })
        .addCase(getBusinessIntegrations.rejected, (state, action) => {
          state.status = "failed";
          state.error = "Failed to get integrations";
        })
        .addCase(addBusinessIntegration.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(addBusinessIntegration.fulfilled, (state, action) => {
          state.status = "idle";
          state.integrations.push(action.payload);
          state.error = null;
        })
        .addCase(addBusinessIntegration.rejected, (state, action) => {
          state.status = "failed";
          state.error = "Failed to add integration";
        })
        .addCase(updateBusinessIntegration.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(updateBusinessIntegration.fulfilled, (state, action) => {
          state.status = "idle";
          const newIntegrations = state.integrations.map((integration) => {
            if (integration.id === action.payload.id) {
              return action.payload;
            }
            return integration;
          });
          state.integrations = newIntegrations;
          state.error = null;
        })
        .addCase(updateBusinessIntegration.rejected, (state, action) => {
          state.status = "failed";
          state.error = "Failed to update integrations";
        })
        .addCase(deleteBusinessIntegration.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(deleteBusinessIntegration.fulfilled, (state, action) => {
          state.status = "idle";
          const newIntegrations = state.integrations?.filter(
            (integration) => integration.id !== action.payload.id
          );
          state.integrations = newIntegrations;
          state.error = null;
        })
        .addCase(deleteBusinessIntegration.rejected, (state, action) => {
          state.status = "failed";
          state.error = "Failed to delete integrations";
        });
    },
  });
  
  export default businessIntegrationsSlice.reducer;