import React from "react";
import {
  Typography,
  Box,
  Tooltip,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import UndoIcon from "components/shared/undo-icon.component";

const CustomCheckbox = ({
  label,
  additionalContent,
  tooltipTitle,
  onRefreshClick,
  isRefreshDisabled,
  checked,
  onChange,
  name,
  ...props
}: any) => {
  return (
    <Box display="flex" flexDirection="column" gap={0}>
      <Box display="flex" alignItems="center">
        {/* Container for label and tooltip */}
        <Typography variant="body2" fontWeight="700" color="textSecondary">
          {label} {additionalContent && `(${additionalContent})`}
        </Typography>
        {tooltipTitle && onRefreshClick && (
          <Tooltip title={tooltipTitle}>
            <span>
              <Button
                onClick={onRefreshClick}
                disabled={isRefreshDisabled}
                color="primary"
                sx={{
                  minWidth: 30,
                  color: "#FF5789",
                }}
              >
                <UndoIcon fontSize="inherit" />
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={onChange}
              name={name}
              sx={{
                paddingTop: 0,
              }}
              {...props}
            />
          }
          label=""
        />
      </div>
    </Box>
  );
};

export default CustomCheckbox;
