import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { businessService } from "../services";
import BusinessType, {
  BusinessCreate,
  BusinessUpdate,
} from "../types/business";
import BusinessSettings from "../types/businessSettings";
import { CreateTaxSetting, UpdateTaxSetting } from "../types/native-ecommerce";
import { Reviews } from "types";

interface InitialState {
  info: Partial<BusinessType> | null;
  reviews: Reviews | null;
  settings: Partial<BusinessSettings> | null;
  status: "idle" | "loading" | "failed";
  error: string | null;
}
const initialState: InitialState = {
  info: null,
  reviews: null,
  settings: null,
  status: "idle",
  error: null,
};

export const getBusiness = createAsyncThunk(
  "business/getBusiness",
  async (id: number) => {
    const response = await businessService.getBusiness(id);
    return response as BusinessType;
  }
);

export const updateBusiness = createAsyncThunk(
  "business/updateBusiness",
  async (newData: Partial<BusinessUpdate>) => {
    const response = await businessService.updateBusiness(newData);
    return response as Partial<BusinessType>;
  }
);

export const addBusiness = createAsyncThunk(
  "business/addBusiness",
  async (newData: BusinessCreate) => {
    const response = await businessService.addBusiness(newData);
    return response as Partial<BusinessType>;
  }
);

export const updateBusinessTax = createAsyncThunk(
  "business/updateBusinessTax",
  async (newData: UpdateTaxSetting) => {
    const response = await businessService.updateBusinessTax(newData);
    return response as Partial<BusinessType>;
  }
);

export const addBusinessTax = createAsyncThunk(
  "business/addBusinessTax",
  async (newData: CreateTaxSetting) => {
    const response = await businessService.addBusinessTax(newData);
    return response as Partial<BusinessType>;
  }
);

export const deleteBusinessTax = createAsyncThunk(
  "business/deleteBusinessTax",
  async (data: { businessId: number; taxId: number }) => {
    const response = await businessService.deleteBusinessTax(
      data.businessId,
      data.taxId
    );
    return response as Partial<BusinessType>;
  }
);

export const getReviews = createAsyncThunk(
  "business/getReviews",
  async (id: number) => {
    const response = await businessService.getReviews(id);
    return response as Reviews;
  }
);

export const getBusinessSettings = createAsyncThunk(
  "business/getBusinessSettings",
  async (id: number) => {
    const response = await businessService.getBusinessSettings(id);
    return response as BusinessSettings;
  }
);

export const getPredictions = createAsyncThunk(
  "business/getPredictions",
  async (query: string) => {
    const response = await businessService.getPredictions(query);
    return response.data.businesses;
  }
);

export const updateBusinessSettings = createAsyncThunk(
  "business/updateBusinessSettings",
  async (newData: Partial<BusinessSettings>) => {
    const response = await businessService.updateBusinessSettings(newData);
    return response as Partial<BusinessSettings>;
  }
);

export const deleteDeliveryArea = createAsyncThunk(
  "business/deleteBusinessDeliveryArea",
  async (newData: Partial<BusinessType>) => {
    const response = await businessService.deleteBusinessDeliveryArea(newData);
    return response as Partial<BusinessType>;
  }
);

export const generateApiKey = createAsyncThunk(
  "business/generateApiKey",
  async (newData: Partial<BusinessSettings>) => {
    const response = await businessService.generateApiKey(newData);
    return response as Partial<BusinessSettings>;
  }
);

export const revokeApiKey = createAsyncThunk(
  "business/revokeApiKey",
  async (newData: Partial<BusinessSettings>) => {
    const response = await businessService.revokeApiKey(newData);
    return response as Partial<BusinessSettings>;
  }
);

export const generatePhoneNumber = createAsyncThunk(
  "business/generatePhoneNumber",
  async (newData: Partial<BusinessSettings>) => {
    const response = await businessService.generatePhoneNumber(newData);
    return response as Partial<BusinessSettings>;
  }
);

export const revokePhoneNumber = createAsyncThunk(
  "business/revokePhoneNumber",
  async (newData: Partial<BusinessSettings>) => {
    const response = await businessService.revokePhoneNumber(newData);
    return response as Partial<BusinessSettings>;
  }
);

//const businessesAdapter = createEntityAdapter<BusinessType>();

export const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    clearBusiness(state) {
      state.info = null;
      state.reviews = null;
      state.settings = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => {
        return initialState;
      })
      .addCase(getBusiness.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getBusiness.fulfilled, (state, action) => {
        state.status = "idle";
        state.info = action.payload;
        state.error = null;
      })
      .addCase(getBusiness.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load account";
      })
      .addCase(getPredictions.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load account";
      })
      .addCase(getPredictions.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getPredictions.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(updateBusiness.pending, (state) => {
        //state.status = 'loading';
        state.error = null;
      })
      .addCase(updateBusiness.fulfilled, (state, action) => {
        //state.status = 'idle';
        state.info = Object.assign({}, state.info, action.payload);
        state.error = null;
      })
      .addCase(updateBusiness.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to update business";
      })
      .addCase(deleteDeliveryArea.pending, (state) => {
        //state.status = 'loading';
        state.error = null;
      })
      .addCase(deleteDeliveryArea.fulfilled, (state, action) => {
        //state.status = 'idle';
        state.info = Object.assign({}, state.info, action.payload);
        state.error = null;
      })
      .addCase(deleteDeliveryArea.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to update delivery area";
      })
      .addCase(addBusiness.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addBusiness.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(addBusiness.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to add business";
      })
      .addCase(addBusinessTax.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addBusinessTax.fulfilled, (state, action) => {
        state.status = "idle";
        state.info = Object.assign({}, state.info, action.payload);
        state.error = null;
      })
      .addCase(addBusinessTax.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to add tax";
      })
      .addCase(updateBusinessTax.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateBusinessTax.fulfilled, (state, action) => {
        state.status = "idle";
        state.info = Object.assign({}, state.info, action.payload);
        state.error = null;
      })
      .addCase(updateBusinessTax.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to update tax";
      })
      .addCase(deleteBusinessTax.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteBusinessTax.fulfilled, (state, action) => {
        state.status = "idle";
        state.info = Object.assign({}, state.info, action.payload);
        state.error = null;
      })
      .addCase(deleteBusinessTax.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to delete business tax.";
      })
      .addCase(getReviews.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getReviews.fulfilled, (state, action) => {
        state.status = "idle";
        state.reviews = action.payload;
        state.error = null;
      })
      .addCase(getReviews.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load reviews";
      })
      .addCase(getBusinessSettings.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getBusinessSettings.fulfilled, (state, action) => {
        state.status = "idle";
        state.settings = Object.assign({}, state.settings, action.payload);
        state.error = null;
      })
      .addCase(getBusinessSettings.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to get settings";
      })
      .addCase(updateBusinessSettings.pending, (state) => {
        //state.status = 'loading';
        state.error = null;
      })
      .addCase(updateBusinessSettings.fulfilled, (state, action) => {
        //state.status = 'idle';
        state.settings = Object.assign({}, state.settings, action.payload);
        state.error = null;
      })
      .addCase(updateBusinessSettings.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to update business settings";
      })
      .addCase(generateApiKey.pending, (state) => {
        state.error = null;
      })
      .addCase(generateApiKey.fulfilled, (state, action) => {
        state.settings = Object.assign({}, state.settings, action.payload);
        state.error = null;
      })
      .addCase(generateApiKey.rejected, (state) => {
        state.status = "failed";
        state.error = "Failed to update business settings";
      })
      .addCase(revokeApiKey.pending, (state) => {
        state.error = null;
      })
      .addCase(revokeApiKey.fulfilled, (state, action) => {
        state.settings = Object.assign({}, state.settings, action.payload);
        state.error = null;
      })
      .addCase(revokeApiKey.rejected, (state) => {
        state.status = "failed";
        state.error = "Failed to update business settings";
      })

      .addCase(generatePhoneNumber.pending, (state) => {
        state.error = null;
      })
      .addCase(generatePhoneNumber.fulfilled, (state, action) => {
        state.settings = Object.assign({}, state.settings, action.payload);
        state.error = null;
      })
      .addCase(generatePhoneNumber.rejected, (state) => {
        state.status = "failed";
        state.error = "Failed to update business settings";
      })
      .addCase(revokePhoneNumber.pending, (state) => {
        state.error = null;
      })
      .addCase(revokePhoneNumber.fulfilled, (state, action) => {
        state.settings = Object.assign({}, state.settings, action.payload);
        state.error = null;
      })
      .addCase(revokePhoneNumber.rejected, (state) => {
        state.status = "failed";
        state.error = "Failed to update business settings";
      })
  },
});

export const { clearBusiness } = businessSlice.actions;

export default businessSlice.reducer;
