import { Box, Drawer} from "@mui/material";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { NavigationHeader } from "./nav-header";
import { useLocation } from "react-router-dom";
import { activeId } from "redux/menuSlice";
import menuItem from "menu-items";
import NavGroup from "./nav-group/nav-group.component";
import { APP_DRAWER_WIDTH, NAVIGATION_WIDTH, NAVIGATION_TYPE } from "layout/constants";
import { setMobileOpen } from "redux/layoutSlice";
import { MobileNavigation } from "layout/mobile-navigation";


interface NavigationProps {
  navigationType: keyof typeof NAVIGATION_TYPE;
  navItems?: any[];
}

export function Navigation({ navigationType, navItems }: NavigationProps) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const mobileOpen = useAppSelector((state) => state.layout.mobileOpen);

  const handleDrawerClose = () => {
    dispatch(setMobileOpen(false));
  };

  // Find the defaultId group based on the location.pathname
  const defaultGroup = menuItem.items.find((group: any) =>
    group.children.some((item: any) => {
      const itemUrl = item.url.endsWith("/") ? item.url.slice(0, -1) : item.url; // Remove trailing slash if it exists in item.url
      const locationUrl = location.pathname.endsWith("/")
        ? location.pathname.slice(0, -1)
        : location.pathname; // Remove trailing slash if it exists in location.pathname
      return itemUrl === locationUrl;
    })
  );

  // Dispatch the activeId action with the defaultGroup.id as the payload
  if (defaultGroup) {
    dispatch(activeId({ defaultId: defaultGroup.id }));
  }

  return ( 
    <Box
      component="nav"
      sx={{ width: { md: NAVIGATION_WIDTH }, flexShrink: { md: 0 } }}
      aria-label="navigation links"
    >
      <MobileNavigation navItems={navItems} navigationType={navigationType} />
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={() => {}}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: NAVIGATION_WIDTH, left: APP_DRAWER_WIDTH },
        }}
      >
        <NavigationHeader navigationType={navigationType} />
        <Box sx={{ py: 3 }}>
          <NavGroup navItems={navItems} />
        </Box>
      </Drawer>
      <Drawer
        variant="permanent"
        anchor="left"
        PaperProps={{
          style: {
            position: "relative",
            left: APP_DRAWER_WIDTH,
            width: NAVIGATION_WIDTH,
          },
        }}
        sx={{
          position: "absolute",
          height: "100vh",
          width: NAVIGATION_WIDTH,
          flexShrink: 0,
          whiteSpace: "nowrap",
          boxSizing: "border-box",
          zIndex: 1,
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: NAVIGATION_WIDTH },
        }}
      >
        <NavigationHeader navigationType={navigationType} />
        <Box sx={{ py: 3 }}>
          <NavGroup navItems={navItems} />
        </Box>
      </Drawer>
    </Box>
  );
}
