import { useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import { MyAccountMenu } from "./";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { logout } from "redux/authSlice";
import { LogoutOutlined } from "@ant-design/icons";
import { setMobileOpen } from "redux/layoutSlice";

interface MyAccountButtonProps {
  closeDrawerOnClick?: boolean;
}

export const MyAccountButton: React.FC<MyAccountButtonProps> = ({closeDrawerOnClick}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.auth.user);

  const handleLogout = async () => {
    dispatch(logout());
    navigate("/login");
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(setMobileOpen(false));
  };

  const handleClick = () => {
    if(closeDrawerOnClick) {
      dispatch(setMobileOpen(false));
    }
  }

  return (
    <>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: "transparent",
          borderRadius: 1,
          "&:hover": { bgcolor: "transparent" },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="column" spacing={0} alignItems="center" sx={{ p: 0 }}>
          <Avatar alt="profile user" sx={{ width: 32, height: 32, mb: 1 }} />
          <Typography sx={{ fontSize: 11 }}>Account</Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="right-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          //@ts-ignore
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  //@ts-ignore
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down("md")]: {
                    maxWidth: 250,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5, pt: 3, background: "white" }}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Stack
                            direction="column"
                            spacing={1.25}
                            alignItems="center"
                          >
                            <Avatar
                              alt="profile user"
                              sx={{ width: 32, height: 32 }}
                            />
                            <Stack>
                              <Typography variant="h6">{`${currentUser?.firstName} ${currentUser?.lastName}`}</Typography>
                              {/* <Typography variant="body2" color="textSecondary">
                                Software Engineer
                              </Typography> */}
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item>
                          <IconButton
                            size="large"
                            color="secondary"
                            onClick={handleLogout}
                          >
                            <LogoutOutlined />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Box sx={{ borderTop: 1, borderColor: "divider" }}>
                      <MyAccountMenu handleLogout={handleLogout} closeDrawerOnClick={closeDrawerOnClick} buttonClickedFn={handleClick} />
                    </Box>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default MyAccountButton;
