import { combineReducers } from 'redux';
import localforage from 'localforage';
import { persistReducer } from 'redux-persist';
import authReducer from './authSlice';
import businessReducer from './businessSlice';
import businessIntegrationsReducer from './businessIntegrationSlice';
import organizationReducer from './organizationSlice';
import locationReducer from './locationSlice';
import layoutReducer from './layoutSlice';
import usersReducer from './usersSlice';
import reviewsReducer from './reviewsSlice';
import competitorsReducer from "./competitorsSlice";
import mccsReducer from './mccSlice';
import apiKeyReducer from './apiKeysSlice';
import themeReducer from './themeSlice';
import menuReducer from './menuSlice'
import nativeEcommerceReducer from './nativeEcommerceSlice';
import catalogMasterReducer from './catalogMasterSlice';

const rootPersistConfig = {
  key: 'root',
  version: 2,
  storage: localforage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  business: businessReducer,
  businessIntegrations: businessIntegrationsReducer,
  organization: organizationReducer,
  reviews: reviewsReducer,
  competitors: competitorsReducer,
  location: locationReducer,
  users: usersReducer,
  mccs: mccsReducer,
  apiKeys: apiKeyReducer,
  theme: themeReducer,
  menu: menuReducer,
  specials: nativeEcommerceReducer,
  ecommerce: nativeEcommerceReducer,
  catalogMaster: catalogMasterReducer,
  layout: layoutReducer
});
const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export default persistedReducer;