import { Divider, Skeleton, Stack, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import React from "react";
import { useAppSelector } from "redux/hooks";

interface BusinessLoadingBoundaryProps {
  children: React.ReactNode;
}

// FUTURE: Add a prop for loading component so we can do more dynamic loading states for the pages

export const BusinessLoadingBoundary = ({
  children,
}: BusinessLoadingBoundaryProps) => {
  const status = useAppSelector((state) => state.business.status);
  if (status === "loading") {

    return (
      <MainCard sx={{ mt: 2, height: '100%'}}>
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={210} height={35} animation='wave' />
          <Skeleton variant="rounded" width={400} height={20} animation='wave' />
          <Divider sx={{mt: 4}} />
          <Skeleton variant="rounded" width={"100%"} height={"300px"} animation='wave' />
        </Stack>
      </MainCard>
    );
  }

  return <>{children}</>;
};

export default BusinessLoadingBoundary;
