import { Grid, Typography, useMediaQuery } from "@mui/material";
import DatePickerField from "components/shared/special/DatePickerField";
import React from "react";
import Label from "../Extras/Label";
import DayOfWeekPicker from "components/shared/special/DayOfWeekPicker";

const FlexBox = ({ top, children }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [firstChild, ...restChildren] = React.Children.toArray(children);
  return (
    <Grid container alignItems={top ? "top" : "center"} columnGap={1}>
      <Grid
        item
        xs={4}
        sm={3}
        style={{ textAlign: isSmallScreen ? "left" : "left" }}
      >
        {firstChild}
      </Grid>
      {restChildren.map((child, index) => (
        <Grid item xs={8} sm={8} key={index}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

export default function ScheduleForm(props) {
  const {
    editing,
    formField: {
      schedule,
      startDate,
      endDate,
    },
  } = props;

  return (
    <React.Fragment>
      <Typography variant="h4" component="h4" gutterBottom>
        Scheudle
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <FlexBox top>
            <Label label={`${schedule.label}:`} />
            <DayOfWeekPicker name={schedule.name} />
          </FlexBox>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FlexBox>
            <Label label={`${startDate.label}:`} />
            <DatePickerField
              name={startDate.name}
              label={startDate.label}
              minDate={new Date()}
              maxDate={new Date("2050/12/31")}
              disabled={editing ? true : false}
              fullWidth
            />
          </FlexBox>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FlexBox>
            <Label label={`${endDate.label}:`} />
            <DatePickerField
              name={endDate.name}
              label={endDate.label}
              minDate={new Date()}
              maxDate={new Date("2050/12/31")}
              fullWidth
              isEndDate
            />
          </FlexBox>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
