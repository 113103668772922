import { Theme } from "@mui/material";

enum FlexDirection {
  ROW = 'row',
  ROW_REVERSE = 'row-reverse',
  COLUMN = 'column',
  COLUMN_REVERSE = 'column-reverse',
}

const drawerStyles = (theme: Theme) => ({
  width: '450px',
  background: theme.palette.background.paper,
  zIndex: theme.zIndex.drawer + 2002,
});

const drawerHeaderStyles = (theme: Theme) => ({
  height: '75px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  position: 'sticky' as 'sticky',
  top: '0',
  background: theme.palette.secondary.main,
  color: theme.palette.primary.contrastText,
  zIndex: theme.zIndex.drawer + 102,
});

const drawerContentStyles = (theme: Theme) => ({
  padding: theme.spacing(2),
  width: '100%',
  display: 'flex',
  flexDirection: FlexDirection.COLUMN,
  minHeight: '100vh',
});

const drawerFooterStyles = (theme: Theme) => ({
  height: '75px',
  display: 'flex',
  flexDirection: FlexDirection.COLUMN,
  justifyContent: 'center',
  padding: theme.spacing(2),
  position: 'sticky' as 'sticky', // Explicitly set the type of position
  background: theme.palette.background.paper,
  bottom: '0',
  borderTop: `1px solid ${theme.palette.divider}`,
  zIndex: 10,
});

const searchContainerStyles = (theme: Theme) => ({
  position: 'sticky' as 'sticky', // Explicitly set the type of position
  top: '75px',
  padding: theme.spacing(2),
  background: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  zIndex: 10,
  height: '75px',
});

const mapButtonStyles = (theme: Theme) => ({
  display: 'flex',
  flexDirection: FlexDirection.COLUMN,
  alignItems: 'center',
  gap: theme.spacing(1),
  cursor: 'pointer',
});

const mapViewContentStyles = (theme: Theme) => ({
  padding: theme.spacing(2),
});

export {
  drawerStyles,
  drawerHeaderStyles,
  drawerContentStyles,
  drawerFooterStyles,
  searchContainerStyles,
  mapButtonStyles,
  mapViewContentStyles,
};
