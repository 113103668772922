// material-ui
import { Box, List, Typography } from "@mui/material";

// project import
import {NavItem} from "../nav-item";

// ==============================|| NAVIGATION - LIST GROUP ||============================== //
interface NavGroupProps {
  navItems: any;
  title?: string;
  noHighlight?: boolean;
}

const NavGroup: React.FC<NavGroupProps> = ({ navItems, title, noHighlight }) => {
  const items = navItems.map((navItem:any) => {
    return <NavItem key={navItem.id} item={navItem} level={1} noHighlight={noHighlight} />;
  });

  return (
    <List
      subheader={
        title && (
          <Box sx={{ pl: 3, mb: 1.5 }}>
            <Typography variant="subtitle2" color="textSecondary">
              {title}
            </Typography>
          </Box>
        )
      }
      sx={{ mb: 1.5, py: 0, zIndex: 0 }}
    >
      {items}
    </List>
  );
};

export default NavGroup;
