import CloseIcon from "@mui/icons-material/Close";
import { AlertTitle } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

export default function TransitionAlerts({
  title,
  description,
  transitionOpen,
  setTransitionOpen,
  type,
  maxWidth,
  cannotExit,
}) {
  if (!title && !description) return null;

  return (
    <Box sx={{ width: "100%" }} maxWidth={maxWidth ? maxWidth : "100%"}>
      <Collapse in={transitionOpen}>
        <Alert
          severity={type ? type : "info"}
          action={
            !cannotExit && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setTransitionOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )
          }
          sx={{ mb: 2 }}
        >
          {title && <AlertTitle>{title}</AlertTitle>}
          {description && <div>{description}</div>}
        </Alert>
      </Collapse>
    </Box>
  );
}
