import { InfoOutlined } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import TransitionAlerts from "components/shared/TransitionAlert";
import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { AlertType } from "types/alertTypes";
import BundleTotalQuantity from "components/businesses/ecommerce/specials/collections/BundleTotalQuantity";
import BundleTotalSpend from "components/businesses/ecommerce/specials/collections/BundleTotalSpend";
import BundleTotalWeight from "components/businesses/ecommerce/specials/collections/BundleTotalWeight";
import AddBundleQualifiersButton from "components/businesses/ecommerce/specials/filters/AddBundleQualifiersMenu";
import BundleFilterOptions from "components/businesses/ecommerce/specials/filters/BundleFilterOptions";
import NoBundleQualifiers from "components/businesses/ecommerce/specials/filters/NoBundleQualifiers";

const CustomerBuysFilterForm = ({
  businessId,
  editing,
  formField: { bundleQualifiers },
}) => {
  const formik = useFormikContext();
  console.log(formik.values)
  const [qualifiersValue, qualifiersMeta, qualifiersHelpers] = useField(
    bundleQualifiers.name
  );
  const { setValue } = qualifiersHelpers;
  const [filters, setFilters] = useState([]);
  const [transitionOpen, setTransitionOpen] = useState(false);
  const [canDisplayQualifiers, setCanDisplayQualifers] = useState(
    checkIfQualifiers()
  );
  const [filterGroup, setFilterGroup] = useState(null);

  function handleAddQualifiers(qualifier) {
    let updatedValue;
    switch (qualifier) {
      case "item":
        // Add a new filter for "item" qualifier
        const itemFilters = {
          includedProductIds: [],
          includedBrandIds: [],
          includedCategories: [],
          includedSubcategories: [],
        };
        updatedValue = {
          ...qualifiersValue.value[0],
          customCollection: {
            ...qualifiersValue.value[0].customCollection,
            filters: [
              ...qualifiersValue.value[0].customCollection.filters,
              itemFilters,
            ],
          },
        };
        break;
      case "brand":
        // Add a new filter for "brand" qualifier
        const brandFilters = {
          includedBrandIds: [],
        };
        updatedValue = {
          ...qualifiersValue.value[0],
          customCollection: {
            ...qualifiersValue.value[0].customCollection,
            filters: [
              ...qualifiersValue.value[0].customCollection.filters,
              brandFilters,
            ],
          },
        };
        break;
      case "category":
        // Add a new filter for "category" qualifier
        const categoryFilters = {
          includedProductIds: [],
          includedBrandIds: [],
          includedCategories: [],
          includedSubcategories: [],
        };
        updatedValue = {
          ...qualifiersValue.value[0],
          customCollection: {
            ...qualifiersValue.value[0].customCollection,
            filters: [
              ...qualifiersValue.value[0].customCollection.filters,
              categoryFilters,
            ],
          },
        };
        break;
        case "both":
        // Add a new filter for "category" qualifier
        const bothFilters = {
          includedProductIds: [],
          includedBrandIds: [],
          includedCategories: [],
          includedSubcategories: [],
        };
        updatedValue = {
          ...qualifiersValue.value[0],
          customCollection: {
            ...qualifiersValue.value[0].customCollection,
            filters: [
              ...qualifiersValue.value[0].customCollection.filters,
              bothFilters,
            ],
          },
        };
        break;
      case "spend":
        updatedValue = { ...qualifiersValue.value[0], requiredValue: 0 };
        break;
      case "quantity":
        updatedValue = { ...qualifiersValue.value[0], requiredQuantity: 0 };
        break;
      case "weight":
        updatedValue = {
          ...qualifiersValue.value[0],
          requiredWeight: "ANY",
          operator: "EQUALS",
        };
        break;
      default:
        return;
    }

    const newBundleQualifiers = [...qualifiersValue.value];
    newBundleQualifiers[0] = updatedValue;

    setValue(newBundleQualifiers);
  }

  function checkIfQualifiers() {
    if (!qualifiersMeta) {
      return false;
    } else {
      const hasRequiredValue = qualifiersMeta.value[0].requiredValue !== null;
      const hasRequiredWeight = qualifiersMeta.value[0].requiredWeight !== null;
      const hasRequiredQuantity =
        qualifiersMeta.value[0].requiredQuantity !== null;
      if (
        hasRequiredQuantity ||
        hasRequiredValue ||
        hasRequiredWeight ||
        filters.length >= 1
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  useEffect(() => {
    if (
      qualifiersMeta.value &&
      qualifiersMeta.value[0]?.customCollection?.filters
    ) {
      setFilters(qualifiersMeta.value[0]?.customCollection.filters);
    }
    setCanDisplayQualifers(checkIfQualifiers());
  }, [qualifiersMeta]);

  useEffect(() => {
    if (!canDisplayQualifiers) {
      setFilterGroup(null);
    } else {
      if(formik?.values?.filterGroup){
        setFilterGroup(formik.values.filterGroup)
      }
    }
  }, [canDisplayQualifiers]);

  useEffect(() => {
    setFilterGroup(formik.values.filterGroup);
  }, [formik.values.filterGroup]);

  return (
    <div>
      <div style={{ margin: "1rem 0 0" }}>
        <div
          style={{ display: "flex", alignItems: "center", margin: "1rem 0 0" }}
        >
          <Typography
            variant="body1"
            component="p"
            style={{ fontWeight: "bold", marginRight: "0.25rem" }}
          >
            Customer Buys
          </Typography>
          <IconButton
            onClick={() => setTransitionOpen(!transitionOpen)}
            size="small"
            color="info"
          >
            <InfoOutlined fontSize="small" />
          </IconButton>
        </div>
      </div>
      <div>
        <TransitionAlerts
          title="How to Add Filters to Bundle"
          description="To customize your special offer, select brands, categories, and products to include or exclude. Use the weight filter/value for further refinement. Chain filters with AND/OR operators as needed."
          type={AlertType.Info}
          transitionOpen={transitionOpen}
          setTransitionOpen={setTransitionOpen}
        />
      </div>

      {canDisplayQualifiers ? (
        <div>
          {filters.map((filter, index) => (
            <BundleFilterOptions
              key={index}
              filter={filter}
              filters={filters}
              index={index}
              filterGroup={filterGroup}
              setFilterGroup={setFilterGroup}
              editing={editing}
            />
          ))}
          {qualifiersMeta.value[0].requiredQuantity !== null && (
            <BundleTotalQuantity />
          )}
          {qualifiersMeta.value[0].requiredValue !== null && (
            <BundleTotalSpend />
          )}
          {qualifiersMeta.value[0].requiredWeight !== null && (
            <BundleTotalWeight />
          )}
          <AddBundleQualifiersButton
            handleClick={handleAddQualifiers}
            filterGroup={filterGroup}
            setFilterGroup={setFilterGroup}
          />
        </div>
      ) : (
        <NoBundleQualifiers
          handleAddQualifiers={handleAddQualifiers}
          filterGroup={filterGroup}
          setFilterGroup={setFilterGroup}
        />
      )}
    </div>
  );
};

export default CustomerBuysFilterForm;
