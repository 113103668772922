import {
  Chip,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  Autocomplete,
} from "@mui/material";
import FlexBox from "./FlexBox";

interface Variant {
  id: string;
  price: number;
  option: number;
  posData: {
    name: string;
    unit: string;
  };
}

interface Cannabinoid {
  name: string;
}

interface CannabinoidValue {
  cannabinoid: Cannabinoid;
  unit: string;
  value: number;
}

interface Terpene {
  name: string;
}

interface TerpeneValue {
  terpene: Terpene;
  unit: string;
  value: number;
}

export const Variants = ({ variants }: { variants: Variant[] }) => {
  return (
    <div>
      <Typography
        variant="body2"
        component="p"
        fontWeight="700"
        color="textSecondary"
      >
        Variants & Pricing
      </Typography>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "6px" }}>
        {variants.map((variant) => (
          <Chip
            key={variant.id}
            label={
              <>
                <div>{variant.posData?.name}</div>
                <div>
                  {variant.option} ${variant.price?.toFixed(2)}{" "}
                </div>
              </>
            }
            style={{
              backgroundColor: "#f2f4f5",
              color: "#646464",
              fontWeight: 700,
              marginTop: 4,
              padding: "6px 2px",
              height: "auto",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const Cannabinoids = ({
  cannabinoidValues,
}: {
  cannabinoidValues: CannabinoidValue[];
}) => {
  return (
    <div>
      <Typography
        variant="body2"
        component="p"
        fontWeight="700"
        color="textSecondary"
      >
        Cannabinoids
      </Typography>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "6px" }}>
        {cannabinoidValues?.map((cannabinoid) => (
          <Chip
            label={` ${
              cannabinoid?.cannabinoid.name
            }: ${cannabinoid?.value.toFixed(2)} ${cannabinoid?.unit}`}
            style={{
              backgroundColor: "#f2f4f5",
              color: "#646464",
              fontWeight: 700,
              marginTop: 4,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const CannabinoidChips = ({
  cannabinoidValues,
}: {
  cannabinoidValues: CannabinoidValue[];
}) => {
  return (
    <div style={{ display: "flex", alignItems: "baseline" }}>
      <Typography
        variant="body2"
        component="p"
        fontWeight="700"
        color="textSecondary"
      >
        Cannabinoids
      </Typography>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Autocomplete
          multiple
          id="menu-type"
          options={cannabinoidValues.map(
            (cannabinoid) =>
              `${cannabinoid?.cannabinoid.name}: ${cannabinoid?.value.toFixed(
                2,
              )} ${cannabinoid?.unit}`,
          )}
          defaultValue={cannabinoidValues.map(
            (cannabinoid) =>
              `${cannabinoid?.cannabinoid.name}: ${cannabinoid?.value.toFixed(
                2,
              )} ${cannabinoid?.unit}`,
          )}
          readOnly
          renderInput={(params) => <TextField {...params} />}
          sx={{ paddingTop: 2, marginLeft: "5px" }}
          size="small"
        />
      </div>
    </div>
  );
};

export const Terpenes = ({
  terpeneValues,
}: {
  terpeneValues: TerpeneValue[];
}) => {
  return (
    <div>
      <Typography
        variant="body2"
        component="p"
        fontWeight="700"
        color="textSecondary"
      >
        Terpenes
      </Typography>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "6px" }}>
        {terpeneValues?.map((terpeneValue) => (
          <Chip
            label={` ${
              terpeneValue?.terpene.name
            }: ${terpeneValue?.value.toFixed(2)} ${terpeneValue?.unit}`}
            style={{
              marginLeft: "5px",
              backgroundColor: "#f2f4f5",
              color: "#646464",
              fontWeight: 700,
              marginTop: 4,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const TerpeneChips = ({
  terpeneValues,
}: {
  terpeneValues: TerpeneValue[];
}) => {
  return (
    <div style={{ display: "flex", alignItems: "baseline" }}>
      <Typography
        variant="h6"
        component="h6"
        fontWeight="bold"
        sx={{ marginTop: 2 }}
      >
        Terpenes
      </Typography>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Autocomplete
          multiple
          id="menu-type"
          options={terpeneValues.map(
            (terpene) =>
              `${terpene?.terpene.name}: ${terpene?.value.toFixed(2)} ${
                terpene?.unit
              }`,
          )}
          defaultValue={terpeneValues.map(
            (terpene) =>
              `${terpene?.terpene.name}: ${terpene?.value.toFixed(2)} ${
                terpene?.unit
              }`,
          )}
          readOnly
          renderInput={(params) => <TextField {...params} />}
          sx={{ paddingTop: 2, marginLeft: "5px" }}
          size="small"
        />
      </div>
    </div>
  );
};

export const PotencyChips = ({ potencies }: any) => {
  return (
    <div>
      <Typography
        variant="body2"
        component="p"
        fontWeight="700"
        color="textSecondary"
      >
        Potencies
      </Typography>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "6px" }}>
        {potencies?.map((potency: any) => (
          <Chip
            label={` ${potency.type}: ${potency.displayValue}`}
            style={{
              backgroundColor: "#f2f4f5",
              color: "#646464",
              fontWeight: "700",
              marginTop: 4,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const Potency = ({ potency }: any) => {
  return potency.map((potency: any) => (
    <FlexBox>
      <Typography
        variant="h6"
        component="h6"
        style={{ fontWeight: "bold", marginRight: "0.25rem" }}
      >
        {potency.type} Content:
      </Typography>
      <TextField
        disabled
        defaultValue={potency.displayValue}
        sx={{ paddingTop: 2, width: "100%" }}
      />
    </FlexBox>
  ));
};
