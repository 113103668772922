import React from "react";
import Lottie from "lottie-react"

export default function LottieAnimation({ lotti, width, height }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lotti,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie
        loop={defaultOptions.loop}
        autoplay={defaultOptions.autoplay}
        rendererSettings={defaultOptions.rendererSettings}
        animationData={defaultOptions.animationData}
        options={defaultOptions}
        height={height}
        width={width} />
    </div>
  );
}
