import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {PURGE} from 'redux-persist';
import {businessService, searchService} from '../services';
import SearchBusinesses, {SearchBusinessesRequestConfig} from '../types/search';
import {businessConstants} from "../constants";

export const getCompetitors = createAsyncThunk(
  'competitors/getCompetitors',
  async (requestConfig: SearchBusinessesRequestConfig) => {
    if (!requestConfig.postalCode) {
      const business = await businessService.getBusiness(requestConfig.businessId);
      requestConfig.postalCode = business.postalCode;
    }

    const requestOptions:SearchBusinessesRequestConfig = {
      businessId: requestConfig.businessId,
      page: requestConfig.page || 1,
      take: requestConfig.take || businessConstants.DEFAULT_API_TAKE,
      postalCode: requestConfig.postalCode,
      distance: requestConfig.distance || businessConstants.DEFAULT_DISTANCE,
      includeMap: requestConfig.includeMap || true,
    }
    const response = await searchService.searchByPostalCode(requestOptions);
    response.businessId = requestConfig.businessId;
    response.requestConfig = requestOptions;
    return response as SearchBusinesses;
  }
)

const competitorsAdapter = createEntityAdapter<SearchBusinesses>({
  selectId: (competitors) => competitors.businessId,
});

interface InitialState {
  loading: string;
  activeRequestId: number | null;
  error: string | null;
}

const competitorsInitialState: InitialState = {
  loading: 'idle',
  activeRequestId: null,
  error: null
}
const initialState = competitorsAdapter.getInitialState(competitorsInitialState);

const competitorsSlice = createSlice({
  name: 'competitors',
  initialState,
  reducers: {},
  extraReducers: (builder => {
    builder
      .addCase(getCompetitors.pending, (state, action) => {
        state.loading = 'loading';
        state.error = null;
      })
      .addCase(getCompetitors.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.error = null;

        if (!action.payload.meta.page || action.payload.meta.page <= 1) {
          competitorsAdapter.upsertOne(state, action.payload);
        } else {
          const {data, meta, requestConfig} = action.payload;

          if(state.entities[requestConfig.businessId]) {
            // @ts-ignore
            state.entities[requestConfig.businessId].data.businesses = state.entities[requestConfig.businessId].data.businesses.concat(data.businesses);
            // @ts-ignore
            state.entities[requestConfig.businessId].meta = meta;
            // @ts-ignore
            state.entities[requestConfig.businessId].requestConfig = requestConfig;
          }

        }
      })
      .addCase(getCompetitors.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = 'Error loading competitors';
      })
      .addCase(PURGE, (state) => {
        competitorsAdapter.removeAll(state);
      });
  })
});

export default competitorsSlice.reducer;

export const {
  selectById: selectCompetitorsById,
  selectIds: selectCompetitorsIds,
  selectEntities: selectCompetitorsEntities,
  selectAll: selectAllCompetitors,
  selectTotal: selectTotalCompetitors,
} = competitorsAdapter.getSelectors<RootState>((state) => state.competitors);
