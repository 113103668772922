import { createSelector } from '@reduxjs/toolkit';

const selectLoadingStatus = createSelector(
  (state) => state,
  (state) => {
    // Check all state slices for 'loading' status
    return Object.values(state).some(slice => slice.status === 'loading');
  }
);

export default selectLoadingStatus;
