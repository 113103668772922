import React, { useEffect } from "react";

// project import
import BundleCreate from "components/SpecialCreate/BundleCreate";
import { getBusiness } from "redux/businessSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getBusinessSpecials } from "redux/nativeEcommerceSlice";
// ==============================|| DASHBOARD - DEFAULT ||============================== //

const BusinessEcommerceBundlesAdd = () => {
  const business = useAppSelector((state) => state.business.info);
  const dispatch = useAppDispatch();
  const businessId = business?.id;
  const refreshBusiness = () => {
    dispatch(getBusiness(businessId));
    const publicKey =
      business.menuSettings?.jointEcommercePublicKey || "defaultPublicKey";
    dispatch(getBusinessSpecials({ businessId: businessId, key: publicKey }));
  };

  useEffect(() => {
    refreshBusiness();
  }, []);

  return (
    <React.Fragment>
      <BundleCreate business={business} />
    </React.Fragment>
  );
};

export default BusinessEcommerceBundlesAdd;
