import { Add, Remove } from "@mui/icons-material";
import { IconButton, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import TransitionAlerts from "components/shared/TransitionAlert";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { AlertType } from "types/alertTypes";
import SpecialsCollectionWeight from "components/businesses/ecommerce/specials/collections/SpecialsCollectionWeight";
import styles from "components/businesses/ecommerce/specials/filters/FilterOptions.module.css";

const FilterOptions = ({ filter, index, editing }) => {
  const formik = useFormikContext();
  const collectionKey = editing ? "collection" : "customCollection";

  const hasErrors =
    formik.errors.discounts?.[0]?.[collectionKey]?.filters?.[index] &&
    formik.touched.discounts?.[0]?.[collectionKey]?.filters?.[index];

  const [weightFilterErrorMessage, setWeightFilterErrorMessage] = useState("");
  const [weightValueErrorMessage, setWeightValueErrorMessage] = useState("");
  const [weightFilter, setWeightFilter] = useState(
    filter?.weightFilterOperator || ""
  );
  const [weightValue, setWeightValue] = useState(
    filter?.weightFilterValue || ""
  );

  // Update the form values when any of the dependencies change
  useEffect(() => {
    const newFilters = [...formik.values.discounts[0][collectionKey].filters];
    newFilters[index] = {
      ...newFilters[index],
      weightFilterOperator: weightFilter,
      weightFilterValue: weightValue,
    };

    formik.setFieldValue(`discounts[0].${collectionKey}.filters`, newFilters);
  }, [weightFilter, weightValue]);

  // Function to add a new filter one spot after the clicked index
  const handleAddNewFilterToDiscount = () => {
    const defaultFilter = {
      includedProductIds: [],
      includedCategories: [],
      includedSubCategories: [],
      includedBrandIds: [],
      weightFilterOperator: "",
      weightFilterValue: "",
    };

    const newFilters = [...formik.values.discounts[0][collectionKey].filters];
    newFilters.splice(index + 1, 0, defaultFilter); // Insert new filter after the clicked index
    // Update the values in Formik
    formik.setFieldValue(`discounts[0].${collectionKey}.filters`, newFilters);

    const newFilterIndex = index + 1;
    const newFilterElement = document.getElementById(
      `filter-${newFilterIndex}`
    );
    if (newFilterElement) {
      newFilterElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  // Remove filter at current index
  const handleRemoveFilterFromDiscount = () => {
    const newFilters = [...formik.values.discounts[0][collectionKey].filters];
    newFilters.splice(index, 1);
    formik.setFieldValue(`discounts[0].${collectionKey}.filters`, newFilters);
  };

  // Handle Logic Operator change
  const handleLogicOperatorChange = (event) => {
    const newLogicOperator = event.target.value;

    formik.setFieldValue(
      `discounts[0].${collectionKey}.logicOperator`,
      newLogicOperator
    );
  };

  return (
    <div id={`filter-${index}`}>
      <div className={styles.filterContainer}>
        <div className={styles.endColumn}>
          {/* Weight Options */}
          <div className={styles.weightOptions}>
            <SpecialsCollectionWeight
              weightFilter={weightFilter}
              setWeightFilter={setWeightFilter}
              weightValue={weightValue}
              setWeightValue={setWeightValue}
              weightFilterErrorMessage={weightFilterErrorMessage}
              weightValueErrorMessage={weightValueErrorMessage}
              setWeightValueErrorMessage={setWeightValueErrorMessage}
              setWeightFilterErrorMessage={setWeightFilterErrorMessage}
            />
          </div>
        </div>
      </div>
      {/* Add / Remove Filter */}
      <div>
        <Stack
          direction="horizontal"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          {(index === 0 || index > 0) && formik.values.discounts[0][collectionKey].filters.length > 1 && (
            <Tooltip title="Remove this filter" placement="top">
              <IconButton
                color="error"
                sx={{ backgroundColor: "#ff4d4f15", margin: "8px 0" }}
                onClick={handleRemoveFilterFromDiscount}
              >
                <Remove />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Add another filter" placement="bottom">
            <IconButton
              color="primary"
              sx={{ backgroundColor: "#51E07C15", margin: "8px 0" }}
              onClick={handleAddNewFilterToDiscount}
            >
              <Add />
            </IconButton>
          </Tooltip>
        </Stack>
      </div>
      <div style={{ marginTop: ".5rem" }}>
        <TransitionAlerts
          title="This Filter Has Errors"
          description={
            formik.errors.discounts?.[0]?.[collectionKey]?.filters?.[index]
              ? formik.errors.discounts[0][collectionKey].filters[index]
              : ""
          }
          type={AlertType.Error}
          transitionOpen={hasErrors}
          cannotExit
        />
      </div>
      {formik.values.discounts[0][collectionKey].filters.length > 1 && index !== formik.values.discounts[0][collectionKey].filters.length - 1 && (
        <div className={styles.filterBottom}>
          <Select
            value={formik.values.discounts[0][collectionKey].logicOperator}
            onChange={handleLogicOperatorChange}
          >
            <MenuItem value="AND">AND</MenuItem>
            <MenuItem value="OR">OR</MenuItem>
          </Select>
        </div>
      )}
    </div>
  );
};

export default FilterOptions;
