export const formatting = {
  getUrlFriendlyName,
  formatPhoneNumber,
};

/**
 * Format a given string to a url friendly name
 * @param  {[type]} name [description]
 * @return {[type]}      [description]
 */
function getUrlFriendlyName(name) {
  let formattedName = name.replaceAll('.', '')
    .replaceAll(' ', '-')
    .replaceAll("'", '')
    .toLowerCase();
  return formattedName;
}

/**
 * format phone numbers for display
 * @param  {[type]} phone [description]
 * @return {[type]}                   [description]
 */
function formatPhoneNumber(phone) {
  if (!phone) {
    return '';
  }
  const match = phone
    .replace(/\D+/g, '').replace(/^1/, '')
    .match(/([^\d]*\d[^\d]*){1,10}$/)[0];
  const part1 = match.length > 2 ? `(${match.substring(0,3)})` : match;
  const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : '';
  const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : '';
  return `${part1}${part2}${part3}`;
}

/**
 * Format camel case to words
 * @param {string} s
 * @returns {string}
 */
function formatCamelCaseToWords(s) {
  const result = s.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}
