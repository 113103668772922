import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Button, List, IconButton, Typography, InputAdornment, Box
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Check, Search } from '@mui/icons-material';
import styles from './select-dialog.module.css';

interface Brand {
    key: string;
    doc_count: number;
}

interface BrandSelectDialogProps {
    brands: Brand[];
    selectedBrands: Brand[];
    filterIndex: number;
    onChange: (selectedBrands: Brand[], filterIndex: number) => void;
    open: boolean;
    onClose: () => void;
    isSingleSelect?: boolean;
}

const BrandSelectDialog: React.FC<BrandSelectDialogProps> = ({
    brands,
    selectedBrands,
    filterIndex,
    onChange,
    open,
    onClose,
    isSingleSelect
}) => {
    const [search, setSearch] = useState('');
    const [filteredBrands, setFilteredBrands] = useState<Brand[]>(brands);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearch(searchTerm);
        const filtered = searchTerm
            ? brands.filter((brand) =>
                brand.key.toLowerCase().includes(searchTerm)
            )
            : brands;
        setFilteredBrands(filtered);
    };

    const handleClose = () => {
        onClose();
    };

    const handleBrandClick = (brand: Brand) => {
        const isSelected = selectedBrands.some((b) => b.key === brand.key);

        if (isSelected) {
            onChange(
                selectedBrands.filter((item) => item.key !== brand.key),
                filterIndex
            );
        } else {
            if (isSingleSelect) {
                onChange([brand], filterIndex);
            } else {
                onChange(
                    [...selectedBrands, brand],
                    filterIndex
                );
            }
        }
    };

    const handleSelectAllClick = () => {
        if (selectedBrands.length === brands.length) {
            onChange([], filterIndex);
        } else {
            onChange(brands, filterIndex);
        }
    };

    const isBrandSelected = (brand: Brand) => selectedBrands.some((b) => b.key === brand.key);

    useEffect(() => {
        setFilteredBrands(brands);
    }, [brands]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" classes={{ paper: styles.dialogPaper }} PaperProps={{ style: { maxWidth: '500px' } }}>
            <DialogTitle className={styles.dialogTitle}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <TextField
                        fullWidth
                        placeholder="Search brands..."
                        value={search}
                        onChange={handleSearchChange}
                        variant="outlined"
                        className={styles.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                            classes: {
                                notchedOutline: styles.noOutline,
                            },
                        }}
                    />
                </Box>
                {!isSingleSelect && (
                    <div className={styles.selectedCount}>
                        <Typography variant="subtitle2">
                            {selectedBrands.length} BRANDS SELECTED
                        </Typography>
                        <Button onClick={handleSelectAllClick}>
                            {selectedBrands.length === brands.length ? 'Deselect All' : 'Select All'}
                        </Button>
                    </div>
                )}

            </DialogTitle>
            <DialogContent className={styles.dialogContent}>
                <List className={styles.categoryList}>
                    {filteredBrands?.map((brand) => (
                        <div key={brand.key}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" paddingY={1}>
                                <div>
                                    <Typography variant="h5" className={styles.categoryTitle}>{brand.key}</Typography>
                                    <Typography variant="body2" className={styles.secondaryText}>{`${brand.doc_count} products`}</Typography>
                                </div>
                                <IconButton
                                    size='small'
                                    onClick={() => handleBrandClick(brand)}
                                    className={`${styles.iconButton} ${isBrandSelected(brand) ? styles.selected : ''}`}
                                >
                                    {isBrandSelected(brand) ? <Check /> : <AddIcon />}
                                </IconButton>
                            </Box>
                        </div>
                    ))}
                </List>
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
                <Button variant="contained" color="primary" className={styles.continueButton} onClick={handleClose}>
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BrandSelectDialog;
