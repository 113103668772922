import React from 'react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


export function BusinessesListSort({onChange, sortBy, props}) {
  

  const handleChange = (event) => {
    onChange(event.target.value);
  }

  return (
    <>
      <FormControl >
        <InputLabel id="business-sort-select">Sort by</InputLabel>
        <Select
          labelId="business-sort-select"
          id="business-sort-select"
          value={sortBy}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'business-sort-select' }}
        >
          <MenuItem value={'rating'}>Top rated</MenuItem>
          <MenuItem value={'reviews'}>Most reviewed</MenuItem>
          <MenuItem value={'name'}>A-Z</MenuItem>
          <MenuItem value={'-name'}>Z-A</MenuItem>
        </Select>
      </FormControl>
    </>
  )
}
