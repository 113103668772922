import { Remove } from "@mui/icons-material";
import { IconButton, InputAdornment, Stack, Tooltip, Typography } from "@mui/material";
import InputField from "components/shared/special/InputField";
import { useFormikContext } from "formik";
import specialFormModel from "components/SpecialCreate/FormModel/specialFormModel";
const { formId, formField } = specialFormModel;

export default function BundleTotalSpend() {
  // * Formik Context
  const formik = useFormikContext();
  const { setFieldValue, values } = formik;

  // * Handle removing filter
  function handleRemoveFilter() {
    setFieldValue(`bundleQualifiers[0].requiredValue`, null);
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          columnGap: "10px",
          alignItems: "center",
          margin: "16px 0",
          height: "100%",
        }}
      >
        <InputField
          name={`bundleQualifiers[0].requiredValue`}
          label={"Required Spend"}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}

        />
        <div>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Tooltip title="Remove this filter" placement="top">
              <IconButton
                color="error"
                sx={{ backgroundColor: "#ff4d4f15" }}
                onClick={handleRemoveFilter}
              >
                <Remove />
              </IconButton>
            </Tooltip>
          </Stack>
        </div>
      </div>
    </div>
  );
}
