import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

import * as ReactDOM from "react-dom/client";

// scroll bar
import "simplebar/src/simplebar.css";

// third-party
import { Provider } from "react-redux";

// apex-chart
import "assets/third-party/apex-chart.css";

// project import
import reportWebVitals from "./reportWebVitals";
import ThemeCustomization from "themes";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import './index.css';
import { Toaster } from "react-hot-toast";

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ThemeCustomization>
        <RouterProvider router={router} />
        <span
          id="topRewardId"
          style={{
            position: "fixed",
            top: 0,
            left: "50%",
          }}
        />
        <Toaster
          toastOptions={{
            duration: 5000,
          }}
        />
      </ThemeCustomization>
    </PersistGate>
  </Provider>
);

reportWebVitals();