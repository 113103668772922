import axios from "axios";
import config from "config";
import { apiHelper } from "helpers";

function authHeader() {
  return {
    Authorization:
      `ApiKey ${config.elasticSearchApiKey}`,
  };
}

/**
 * Get products
 * @param businessId
 * @param data
 */
export function getProducts(data: any) {
  const auth = authHeader();
  const requestConfig = {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      ...auth,
    },
  };
  return axios
    .post(
      `${config.elasticSearchUrl}/${config.elasticSearchIndex}/_search`,
      data,
      requestConfig
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}
