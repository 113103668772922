import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Button, List, ListItem, ListItemText,
    IconButton, Typography, InputAdornment, Box
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Check, Search } from '@mui/icons-material';
import styles from './select-dialog.module.css';

interface CategoryParentSelectDialogProps {
    categories: { key: string, doc_count: number }[];
    selectedCategories: string[];
    filterIndex: number;
    onChange: (selectedCategories: string[], filterIndex: number) => void;
    open: boolean;
    onClose: () => void;
    isSingleSelect?: boolean;
}

const CategoryParentSelectDialog: React.FC<CategoryParentSelectDialogProps> = ({
    categories,
    selectedCategories,
    filterIndex,
    onChange,
    open,
    onClose,
    isSingleSelect
}) => {
    const [search, setSearch] = useState('');
    const [filteredCategories, setFilteredCategories] = useState(categories);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearch(searchTerm);
        const filtered = searchTerm
            ? categories.filter(category => category.key.toLowerCase().includes(searchTerm))
            : categories;
        setFilteredCategories(filtered);
    };

    const handleClose = () => {
        onClose();
    };

    const handleCategoryClick = (categoryName: string) => {

        const isSelected = selectedCategories.includes(categoryName);

        if (isSelected) {
            onChange(
                selectedCategories.filter(item => item !== categoryName),
                filterIndex
            );
        } else {
            if (isSingleSelect) {
                onChange([categoryName], filterIndex);
                return;
            }
            const updatedCategories = isSelected
                ? selectedCategories.filter(item => item !== categoryName)
                : [...selectedCategories, categoryName];

            onChange(updatedCategories, filterIndex);
        }



    };

    const handleSelectAllClick = () => {
        if (selectedCategories.length === categories.length) {
            onChange([], filterIndex);
        } else {

            onChange(categories.map(category => category.key), filterIndex);
        }
    };

    const isCategorySelected = (categoryName: string) => selectedCategories.includes(categoryName);

    useEffect(() => {
        setFilteredCategories(categories);
    }, [categories]);


    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" classes={{ paper: styles.dialogPaper }} PaperProps={{ style: { maxWidth: '600px' } }}>
            <DialogTitle className={styles.dialogTitle}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <TextField
                        fullWidth
                        placeholder="Search categories..."
                        value={search}
                        onChange={handleSearchChange}
                        variant="outlined"
                        className={styles.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                            classes: {
                                notchedOutline: styles.noOutline,
                            },
                        }}
                    />
                </Box>
                {!isSingleSelect && (
                    <div className={styles.selectedCount}>
                        <Typography variant="subtitle2">
                            {selectedCategories.length} CATEGORIES SELECTED
                        </Typography>
                        <Button onClick={handleSelectAllClick}>
                            {selectedCategories.length === categories.length ? 'Deselect All' : 'Select All'}
                        </Button>
                    </div>
                )}
            </DialogTitle>
            <DialogContent className={styles.dialogContent}>
                <List className={styles.categoryList}>
                    {filteredCategories.map(category => (
                        <div key={category.key}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" paddingY={1}>
                                <div>
                                    <Typography variant="h5" className={styles.categoryTitle}>{category.key}</Typography>
                                    <Typography variant="body2" className={styles.secondaryText}>{category.doc_count} products</Typography>
                                </div>
                                <IconButton
                                    size='small'
                                    onClick={() => handleCategoryClick(category.key)}
                                    className={`${styles.iconButton} ${isCategorySelected(category.key) ? styles.selected : ''}`}
                                >
                                    {isCategorySelected(category.key) ? <Check /> : <AddIcon />}
                                </IconButton>
                            </Box>
                        </div>
                    ))}
                </List>
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
                <Button variant="contained" color="primary" className={styles.continueButton} onClick={handleClose}>
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CategoryParentSelectDialog;
