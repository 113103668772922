import StorefrontIcon from "@mui/icons-material/Storefront";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { BusinessSelect, MobileBusinessSelect } from "components/businesses/business-select";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useAppSelector } from "redux/hooks";
import { businessService } from "services";
import Business from "types/business";


export function BusinessNavHeader({ mobile }: { mobile?: boolean }) {
  const business = useAppSelector((state) => state.business.info) as Business;
  const [businesses, setBusinesses] = useState([]);

  useEffect(() => {
    refreshBusinesses();
  }, []);

  const refreshBusinesses = () => {
    //@ts-ignore
    businessService.getBusinesses().then((r) => {
      setBusinesses(r.data);
    });
  };
  const [businessSelectDialog, setBusinessSelectDialog] = useState(false);

  const handleCopyToClipboard = (text: any) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toast.success("Copied to clipboard!");
  };

  const handleBusinessSelectDialogOpen = () => {
    setBusinessSelectDialog(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: !mobile ? "100%" : "auto",
        color: mobile ? "white" : "inherit",
      }}
    >
      {business && (
        <Box sx={{ width: "100%", maxWidth: mobile ? "200px" : "100%" }}>
          <Grid container>
            <Grid item xs={10}>
              <Box display="flex" alignItems="center" columnGap={1}>
                {!mobile && (<StorefrontIcon sx={{ width: "22px" }} />)}
                <Box
                  sx={{
                    flex: 1,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Tooltip title={business.name} arrow>
                    <Typography
                      variant="body2"
                      component="div"
                      fontWeight={900}
                    >
                      {business.name}
                    </Typography>
                  </Tooltip>
                  <Tooltip title="Click to copy to clipboard" arrow>
                    <Typography
                      variant="caption"
                      component="div"
                      sx={{ cursor: "pointer" }}
                      //@ts-ignore
                      onClick={() => handleCopyToClipboard(business.websiteUrl)}
                    >
                      {
                        //@ts-ignore
                        business.websiteUrl
                      }
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2} textAlign="center">
              <Box>
                <IconButton onClick={handleBusinessSelectDialogOpen} sx={{
                  color: mobile ? "white" : "inherit",
                }}>
                  <UnfoldMoreIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      {businesses.length > 0 && business && (
        mobile ? (
          <MobileBusinessSelect
            businessSelectDialog={businessSelectDialog}
            handleClose={() => {
              setBusinessSelectDialog(false);
            }}
            businesses={businesses}
            business={business}
          />
        ) : (
          <BusinessSelect
            businessSelectDialog={businessSelectDialog}
            handleClose={() => {
              setBusinessSelectDialog(false);
            }}
            businesses={businesses}
            business={business}
          />
        )
      )}

    </Box>
  );
}

export default BusinessNavHeader;
