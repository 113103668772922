import React from "react";

export function FrameWrapper({iFrameUrl, ...props}) {
  const ref = React.useRef();
  const [height, setHeight] = React.useState(props.height || "800px");
  /*
  const onLoad = () => {
    setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
  };*/
  return (
    <>
      <iframe
        ref={ref}
        //onLoad={onLoad}
        id="myFrame"
        src={iFrameUrl}
        width="100%"
        height={height}
        //scrolling="no"
        frameBorder="0"
        style={{
          width: "100%",
          overflow: "auto",
        }}
      ></iframe>
    </>


  );
}
