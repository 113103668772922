import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {adsService} from '../services';
import Mcc, {NewMcc} from '../types/mcc';
import {RootState} from './store';
import {PURGE} from 'redux-persist';

export const getMccs = createAsyncThunk(
  'mccs/getAll',
  async () => {
    const response = await adsService.getMccs();
    return response as Array<Mcc>;
  }
)

export const addMcc = createAsyncThunk(
  'mccs/addMcc',
  async(newMcc: NewMcc) => {
    const response =await adsService.addMcc(newMcc);
    return response as Mcc;
  }
)

export const updateMcc = createAsyncThunk(
  'mccs/updateMcc',
  async (newData: Partial<Mcc>) => {
    const response = await adsService.updateMcc(newData);
    return response as Mcc;
  }
)

export const mccsAdapter = createEntityAdapter<Mcc>();

interface AdditionalState {
  loading: string;
  activeRequestId: number | null;
  error: string | null;
}

const additionalState: AdditionalState = {
  loading: 'idle',
  activeRequestId: null,
  error: null
};

const initialState = mccsAdapter.getInitialState(additionalState);

export const mccSlice = createSlice({
  name: 'mccs',
  initialState,
  reducers: {
    removeMcc: mccsAdapter.removeOne
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMccs.pending, (state, action) => {
        state.loading = 'loading'
      })
      .addCase(getMccs.fulfilled, (state, action) => {
        mccsAdapter.upsertMany(state, action.payload);
        state.loading = 'idle';
      })
      .addCase(getMccs.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = 'Failed to load MCC';
      })
      .addCase(updateMcc.pending, (state) => {
        state.loading = 'loading';
        state.error = null;
      })
      .addCase(updateMcc.fulfilled, (state, action) => {
        const {id, ...changes} = action.payload;
        mccsAdapter.updateOne(state, { id, changes});
        state.loading = 'idle';
        state.error = null;
      })
      .addCase(updateMcc.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = 'Failed to update MCC.'
      })
      .addCase(addMcc.pending, (state) => {
        state.loading = 'loading';
        state.error = null;
      })
      .addCase(addMcc.fulfilled, (state, action) => {
        mccsAdapter.addOne(state, action.payload);
        state.loading = 'idle';
        state.error = null;
      })
      .addCase(addMcc.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = 'Failed to add MCC.'
      })
      .addCase(PURGE, (state) => {
        mccsAdapter.removeAll(state);
      })
  }
});

export default mccSlice.reducer;

export const { removeMcc } = mccSlice.actions;

export const {
  selectById: selectMccById,
  selectIds: selectMccIds,
  selectEntities: selectMccEntities,
  selectAll: selectAllMccs,
  selectTotal: selectTotalMccs
} = mccsAdapter.getSelectors<RootState>((state) => state.mccs);
