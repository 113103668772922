import { Box, Drawer } from "@mui/material";
import LogoBadge from "components/shared/LogoBadge";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import StorefrontIcon from "@mui/icons-material/Storefront";
import DomainIcon from "@mui/icons-material/Domain";
import { useAppSelector } from "redux/hooks";
import { MyAccountButton } from "./my-account";
import { APP_DRAWER_WIDTH } from "layout/constants";
import { MobileNavigation } from "layout/mobile-navigation";
import { RailTab } from "components/shared/rail-tab";

interface AppDrawerProps {
  mobileOpen: boolean;
  isDefaultLayout?: boolean;
}

export const AppDrawer = ({ mobileOpen, isDefaultLayout }: AppDrawerProps) => {
  const currentUser = useAppSelector((state) => state.auth.user);

  return (
    <>
      {isDefaultLayout && (
        <MobileNavigation navigationType="DEFAULT" navItems={[]} />
      )}
      <Drawer
        sx={{
          flexShrink: 0,
          display: { xs: mobileOpen ? 'block' : 'none', md: 'block' },
          "& .MuiDrawer-paper": {
            width: APP_DRAWER_WIDTH,
            boxSizing: "border-box",
          },
          overflow: "visible",
          boxShadow: "none",
        }}
        PaperProps={{
          sx: {
            display: "flex",
            color: "secondary.contrastText",
            backgroundColor: "secondary.main",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            overflow: "visible",
            width: 76,
            boxShadow: "none",
            zIndex: 1300,
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Logo */}
          <Box
            sx={{
              py: 2,
            }}
          >
            <LogoBadge />
          </Box>
          {/* Items */}

          {currentUser?.role === "ADMIN" && (
            <RailTab
              title="Orgs"
              filledIcon={<DomainIcon />}
              outlinedIcon={<DomainIcon />}
              link={"/organizations"}
              closeDrawerOnClick
            />
          )}
          <RailTab
            title="Businesses"
            filledIcon={<StorefrontIcon />}
            outlinedIcon={<StorefrontIcon />}
            link={"/businesses"}
            closeDrawerOnClick
          />
          {currentUser?.role === "ADMIN" && (
            <RailTab
              title="Admin"
              filledIcon={<AdminPanelSettingsIcon />}
              outlinedIcon={<AdminPanelSettingsOutlinedIcon />}
              link={"/admin"}
              closeDrawerOnClick
            />
          )}
          <RailTab
            title="More"
            filledIcon={<MoreHorizIcon />}
            outlinedIcon={<MoreHorizOutlinedIcon />}
            disabled
            hoverMessage="Coming soon!"
            closeDrawerOnClick
          />
        </Box>
        <Box>
          <MyAccountButton closeDrawerOnClick />
        </Box>
      </Drawer>
    </>
  );
};

export default AppDrawer;
