import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Button, List, IconButton, Typography, InputAdornment, Box
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Check, Search } from '@mui/icons-material';
import styles from './select-dialog.module.css';

interface Product {
    _index: string;
    _id: string;
    _score: number;
    _source: {
        jointId: string;
        name: string;
        category: string;
        brandName: string;
    };
}

interface ProductSelectDialogProps {
    products: Product[];
    selectedProducts: Product[];
    filterIndex: number;
    onChange: (selectedProducts: Product[], filterIndex: number) => void;
    open: boolean;
    onClose: () => void;
    isSingleSelect?: boolean;
}

const ProductSelectDialog: React.FC<ProductSelectDialogProps> = ({
    products,
    selectedProducts,
    filterIndex,
    onChange,
    open,
    onClose,
    isSingleSelect
}) => {
    const [search, setSearch] = useState('');
    const [filteredProducts, setFilteredProducts] = useState<Product[]>(products.slice(0, 100));

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearch(searchTerm);
        const filtered = searchTerm
            ? products.filter((product) =>
                product._source?.name?.toLowerCase().includes(searchTerm)
            )
            : products;
        setFilteredProducts(filtered);
    };

    const handleClose = () => {
        onClose();
    };

    const handleProductClick = (product: Product) => {
        const isSelected = selectedProducts.some((p) => p._source?.jointId === product._source?.jointId);

        if (isSelected) {
            onChange(
                selectedProducts.filter((item) => item._source?.jointId !== product._source?.jointId),
                filterIndex
            );
        } else {
            if (isSingleSelect) {
                onChange([product], filterIndex);
            } else {
                onChange(
                    [...selectedProducts, product],
                    filterIndex
                );
            }
        }
    };

    const handleSelectAllClick = () => {
        if (selectedProducts.length === products.length) {
            onChange([], filterIndex);
        } else {
            onChange(products, filterIndex);
        }
    };

    const isProductSelected = (product: Product) => selectedProducts.some((p) => p._source?.jointId === product._source?.jointId);

    useEffect(() => {
        setFilteredProducts(products.slice(0, 100));
    }, [products]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" classes={{ paper: styles.dialogPaper }} PaperProps={{ style: { maxWidth: '500px' } }}>
            <DialogTitle className={styles.dialogTitle}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <TextField
                        fullWidth
                        placeholder="Search products..."
                        value={search}
                        onChange={handleSearchChange}
                        variant="outlined"
                        className={styles.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                            classes: {
                                notchedOutline: styles.noOutline,
                            },
                        }}
                    />
                </Box>
                {!isSingleSelect && (
                    <div className={styles.selectedCount}>
                        <Typography variant="subtitle2">
                            {selectedProducts.length} PRODUCTS SELECTED
                        </Typography>
                        <Button onClick={handleSelectAllClick}>
                            {selectedProducts.length === products.length ? 'Deselect All' : 'Select All'}
                        </Button>
                    </div>
                )}
            </DialogTitle>
            <DialogContent className={styles.dialogContent}>
                <List className={styles.categoryList}>
                    {filteredProducts?.map((product) => (
                        <div key={product._source?.jointId}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" paddingY={1}>
                                <div>
                                    <Typography variant="h5" className={styles.categoryTitle}>{product._source?.name}</Typography>
                                    <Typography variant="body2" className={styles.secondaryText}>{`${product._source?.brandName} - ${product._source?.category.toLocaleLowerCase()}`}</Typography>
                                </div>
                                <IconButton
                                    size='small'
                                    onClick={() => handleProductClick(product)}
                                    className={`${styles.iconButton} ${isProductSelected(product) ? styles.selected : ''}`}
                                >
                                    {isProductSelected(product) ? <Check /> : <AddIcon />}
                                </IconButton>
                            </Box>
                        </div>
                    ))}
                </List>
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
                <Button variant="contained" color="primary" className={styles.continueButton} onClick={handleClose}>
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProductSelectDialog;
