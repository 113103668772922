import { UserRole } from "types/user";

interface UserRoleConst {
  ADMIN: UserRole
  USER: UserRole
}

export const userConstants = {
  AGE_COOKIE_NAME: "ageVerified",
  ROLES: {
    ADMIN: "ADMIN",
    USER: "USER",
  },
};

export const USER_ROLES: UserRoleConst = {
  ADMIN: "ADMIN",
  USER: "USER",
};
