import React from 'react';
import { useField } from 'formik';
import {FormControlLabel, Switch, Typography} from '@mui/material';


export const SwitchInput = ({ label, ...props }) => {
  
   const [field, meta, helpers] = useField({type: 'checkbox', ...props});
  const { value } = meta;
  const { setValue } = helpers;
   return (
     <>
         <FormControlLabel
             control={<Switch {...field} {...props} onChange={() => setValue(!value)} color="primary" />}
             label={<Typography variant="body2">{label}</Typography>}
         />

       {meta.touched && meta.error ? (
         <div >{meta.error}</div>
       ) : null}
     </>
   );
 };
