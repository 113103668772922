import React from "react";
import { AppLayout } from "layout/app-layout";
import { Navigation } from "layout/navigation";
import { useBusinessNav } from "layout/navigation/hooks/use-business-nav.hook";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { getBusiness, getBusinessSettings } from "redux/businessSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { LayoutBox } from "layout/layout-box";

export function BusinessLayout() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { businessId: urlBusinessId } = useParams();
  let location = useLocation();
  const stateBusinessId = useAppSelector((state) => state.business.info?.id);
  const { navItems } = useBusinessNav();

  React.useEffect(() => {
    const getCurrentBusiness = async (urlBusinessId: string | number) => {
      const actionResult = await dispatch(getBusiness(+urlBusinessId));
      if (getBusiness.rejected.match(actionResult)) {
        navigate("/404");
      }

      dispatch(getBusinessSettings(+urlBusinessId));
    };

    if (urlBusinessId) {
      getCurrentBusiness(urlBusinessId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, urlBusinessId]);

  return (
    <AppLayout>
      <Navigation navigationType={"BUSINESS"} navItems={navItems} />
      <LayoutBox>{stateBusinessId && <Outlet />}</LayoutBox>
    </AppLayout>
  );
}
