import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {reviewsService} from '../services';
import {RootState} from "./store";
import { Reviews } from 'types';

export const getReviews = createAsyncThunk(
  'reviews/getReviews',
  async (id: number) => {
    const response = await reviewsService.getReviews(id);
    return response as Reviews;
  }
);

const reviewsAdapter = createEntityAdapter<Reviews>({
  selectId: (review) => review.dispensaryId,
});

interface InitialState {
  loading: string;
  activeRequestId: number | null;
  error: string | null;
}

const reviewsInitialState: InitialState = {
  loading: 'idle',
  activeRequestId: null,
  error: null
}

const initialState = reviewsAdapter.getInitialState(reviewsInitialState);

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers:{},
  extraReducers:(builder => {
    builder
      .addCase(getReviews.pending, (state, action) => {
        state.loading = 'loading';
        state.error = null;
      })
      .addCase(getReviews.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.error = null;
        reviewsAdapter.upsertOne(state, action.payload);
      })
      .addCase(getReviews.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = 'Error loading reviews';
      })
  })
});

export default reviewsSlice.reducer;

export const {
  selectById: selectReviewsById,
  selectIds: selectReviewsIds,
  selectEntities: selectReviewEntities,
  selectAll: selectAllReviews,
  selectTotal: selectTotalReviews,
} = reviewsAdapter.getSelectors<RootState>((state) => state.reviews);
