import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import BusinessEcommerceSpecialsAdd from "pages/dashboard/business/BusinessEcommerceSpecialsAdd";
import BusinessEcommerceBundlesAdd from "pages/dashboard/business/BuisinessEcommerceBundlesAdd";
import BusinessEcommerceSpecialsEdit from "pages/dashboard/business/BusinessEcommerceSpecialsEdit";
import ProductEditor from "pages/dashboard/business/inventory-management/product-editor/product-editor.component";

// render - dashboard
const BusinessDashboardPage = Loadable(
  lazy(() => import("pages/businesses/business-dashboard.page")),
);

// render - dashboard business
const BusinessAdsPage = Loadable(
  lazy(() => import("pages/businesses/business-ads.page")),
);
const BusinessThemePage = Loadable(
  lazy(() => import("pages/businesses/business-theme.page")),
);
const BusinessIntegrationsPage = Loadable(
  lazy(() => import("pages/businesses/business-integrations.page")),
);
const BusinessEcommercePage = Loadable(
  lazy(() => import("pages/businesses/business-ecommerce.page")),
);
const BusinessReportsPage = Loadable(
  lazy(() => import("pages/businesses/business-reports.page")),
);
const BusinessReviewsPage = Loadable(
  lazy(() => import("pages/businesses/business-reviews.page")),
);
const BusinessSettingsPage = Loadable(
  lazy(() => import("pages/businesses/business-settings.page")),
);
const BusinessCompetitorsPage = Loadable(
  lazy(() => import("pages/businesses/business-competitors.page")),
);

const BusinessRoutes = [
  {
    path: "",
    element: <BusinessDashboardPage title="Business Dashboard" />,
  },
  {
    path: "ads",
    element: <BusinessAdsPage title="Ads" />,
    handle: {
      crumb: () => "Ads",
    },
  },
  {
    path: "ads/builder",
    element: <BusinessAdsPage title="Ads Builder" />,
    handle: {
      crumb: () => "Builder",
    },
  },
  {
    path: "settings",
    element: <BusinessSettingsPage title="Settings" />,
    handle: {
      crumb: () => "Settings",
    },
  },
  {
    path: "settings/address",
    element: <BusinessSettingsPage title="Address" />,
    handle: {
      crumb: () => "Address",
    },
  },
  {
    path: "settings/contact",
    element: <BusinessSettingsPage title="Contact" />,
    handle: {
      crumb: () => "Contact",
    },
  },
  {
    path: "settings/website",
    element: <BusinessSettingsPage title="Website" />,
    handle: {
      crumb: () => "Website",
    },
  },
  {
    path: "settings/social-media",
    element: <BusinessSettingsPage title="Social Media" />,
    handle: {
      crumb: () => "Social Media",
    },
  },
  {
    path: "settings/joint",
    element: <BusinessSettingsPage title="Joint" />,
    handle: {
      crumb: () => "Joint",
    },
  },
  {
    path: "reports",
    element: <BusinessReportsPage title="Reports" />,
    handle: {
      crumb: () => "Reports",
    },
  },
  {
    path: "reviews",
    element: <BusinessReviewsPage title="Reviews" />,
    handle: {
      crumb: () => "Reviews",
    },
  },
  {
    path: "ecommerce",
    element: <BusinessEcommercePage title="Menu" />,
    handle: {
      crumb: () => "Ecommerce",
    },
  },
  {
    path: "ecommerce/ordering",
    element: <BusinessEcommercePage title="Ordering" />,
    handle: {
      crumb: () => "Ordering",
    },
  },
  {
    path: "ecommerce/delivery-area",
    element: <BusinessEcommercePage title="Delivery Area" />,
    handle: {
      crumb: () => "Delivery Area",
    },
  },
  {
    path: "ecommerce/categories",
    element: <BusinessEcommercePage title="Categories" />,
    handle: {
      crumb: () => "Categories",
    },
  },
  {
    path: "ecommerce/taxes",
    element: <BusinessEcommercePage title="Taxes" />,
    handle: {
      crumb: () => "Taxes",
    },
  },
  {
    path: "ecommerce/specials",
    element: <BusinessEcommercePage title="Specials" />,
    handle: {
      crumb: () => "Specials",
    },
  },
  {
    path: "ecommerce/specials/add",
    element: <BusinessEcommerceSpecialsAdd />,
  },
  {
    path: "ecommerce/bundles/add",
    element: <BusinessEcommerceBundlesAdd />,
  },
  {
    path: "ecommerce/specials/:specialId/edit",
    element: <BusinessEcommerceSpecialsEdit />,
  },
  {
    path: "integrations",
    element: <BusinessIntegrationsPage title="Integrations" />,
    handle: {
      crumb: () => "Integrations",
    },
  },
  {
    path: "integrations/marketplace",
    element: <BusinessIntegrationsPage title="Integrations" />,
    handle: {
      crumb: () => "Integrations Marketplace",
    },
  },
  {
    path: "theme",
    element: <BusinessThemePage title="Theme" />,
    handle: {
      crumb: () => "Theme",
    },
  },
  {
    path: "competitors",
    element: <BusinessCompetitorsPage title="Competitors" />,
    handle: {
      crumb: () => "Competitors",
    },
  },
  {
    path: "ecommerce/inventory",
    element: <BusinessEcommercePage title="Inventory" />,
    handle: {
      crumb: () => "Inventory",
    },
  },
  {
    path: "ecommerce/inventory/view/:productId",
    element: <ProductEditor />,
  },
];

export default BusinessRoutes;
