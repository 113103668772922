import { createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import { nativeEcommerceService } from "../services/nativeEcommerce.service";
import {
  CreateMapping,
  CreatePosabitsProviderSettings,
  CreateTaxSetting,
  CreateTreezProviderSettings,
  Mapping,
  PosabitsProviderSettings,
  Schedule,
  Special,
  TaxSettings,
  TreezProviderSettings,
  UpdateTaxSetting,
} from "../types/native-ecommerce";
import { UpdateSchedule } from "../types/native-ecommerce/updateSchedule";

export const getPosabitsProviderSettings = createAsyncThunk(
  "nativeEcommerce/getPosabitsSettings",
  async (businessId: number) => {
    const response = await nativeEcommerceService.getPosabitsProviderSettings(
      businessId
    );
    return response as PosabitsProviderSettings;
  }
);

export const getTreezProviderSettings = createAsyncThunk(
  "nativeEcommerce/getTreezSettings",
  async (businessId: number) => {
    const response = await nativeEcommerceService.getTreezProviderSettings(
      businessId
    );
    return response as TreezProviderSettings;
  }
);

export const savePosabitsSettings = createAsyncThunk(
  "nativeEcommerce/savePosabitsSettings",
  async (settings: CreatePosabitsProviderSettings) => {
    const response = await nativeEcommerceService.savePosabitsSettings(
      settings
    );
    return response as PosabitsProviderSettings;
  }
);

export const saveTreezSettings = createAsyncThunk(
  "nativeEcommerce/saveTreezSettings",
  async (settings: CreateTreezProviderSettings) => {
    const response = await nativeEcommerceService.saveTreezSettings(settings);
    return response as TreezProviderSettings;
  }
);

export const listPosabitsSettings = createAsyncThunk(
  "nativeEcommerce/listPosabitsSettings",
  async () => {
    const response =
      await nativeEcommerceService.listPosabitsProviderSettings();
    return response as PosabitsProviderSettings[];
  }
);

export const listTreezSettings = createAsyncThunk(
  "nativeEcommerce/listTreezSettings",
  async () => {
    const response = await nativeEcommerceService.listTreezProviderSettings();
    return response as TreezProviderSettings[];
  }
);

export const getTaxes = createAsyncThunk(
  "nativeEcommerce/getTaxes",
  async (businessId: number) => {
    const response = await nativeEcommerceService.getTaxes(businessId);
    return response as TaxSettings[];
  }
);

export const createTax = createAsyncThunk(
  "nativeEcommerce/createTax",
  async (setting: CreateTaxSetting) => {
    const response = await nativeEcommerceService.createTax(setting);
    return response as TaxSettings;
  }
);

export const updateTax = createAsyncThunk(
  "nativeEcommerce/updateTax",
  async (setting: UpdateTaxSetting) => {
    const response = await nativeEcommerceService.updateTax(setting);
    return response as TaxSettings;
  }
);

export const deleteTax = createAsyncThunk(
  "nativeEcommerce/deleteTax",
  async (id: number) => {
    await nativeEcommerceService.deleteTax(id);
  }
);

export const getSchedule = createAsyncThunk(
  "nativeEcommerce/getSchedule",
  async (id: string) => {
    const response = await nativeEcommerceService.getSchedule(id);
    return response as Schedule;
  }
);

export const getSchedules = createAsyncThunk(
  "nativeEcommerce/getSchedules",
  async (queryString: string) => {
    const response = await nativeEcommerceService.getSchedules(queryString);
    return response as Schedule[];
  }
);

export const addSchedule = createAsyncThunk(
  "nativeEcommerce/createSchedule",
  async (schedule: Schedule) => {
    const response = await nativeEcommerceService.addSchedule(schedule);
    return response as Schedule;
  }
);

export const updateSchedule = createAsyncThunk(
  "nativeEcommerce/updateSchedule",
  async (schedule: UpdateSchedule) => {
    const response = await nativeEcommerceService.updateSchedule(schedule);
    return response as Schedule;
  }
);

export const deleteSchedule = createAsyncThunk(
  "nativeEcommerce/deleteSchedule",
  async (id: string) => {
    const response = await nativeEcommerceService.deleteSchedule(id);
    return response;
  }
);

export const addSpecial = createAsyncThunk(
  "nativeEcommerce/addSpecial",
  async ({ special, key }: { special: Special; key: string }) => {
    const response = await nativeEcommerceService.addSpecial(special, key);
    return response as Special;
  }
);

export const addSpecialImage = createAsyncThunk(
  "nativeEcommerce/addSpecialImage",
  async ({ id, image, key }: { id: string, image: File, key: string }) => {
    const response = await nativeEcommerceService.addSpecialImage(id, image, key);
    return response as Special;
  }
);

export const deleteSpecial = createAsyncThunk(
  "nativeEcommerce/deleteSpecial",
  async ({ specialId, key }: { specialId: string; key: string }) => {
    const response = await nativeEcommerceService.deleteSpecial(specialId, key);
    return response;
  }
);

export const editSpecial = createAsyncThunk(
  "nativeEcommerce/editSpecial",
  async ({
    specialId,
    special,
    key,
  }: {
    specialId: string;
    special: Special;
    key: string;
  }) => {
    const response = await nativeEcommerceService.editSpecial(
      specialId,
      special,
      key
    );
    return response as Special;
  }
);

export const getBusinessSpecials = createAsyncThunk(
  "nativeEcommerce/getBusinessSpecials",
  async ({ businessId, key }: { businessId: number; key: string }) => {
    const response = await nativeEcommerceService.getBusinessSpecials(
      businessId,
      key
    );
    return response;
  }
);

export const getSpecialById = createAsyncThunk(
  "nativeEcommerce/getSpecialById",
  async ({ specialId, key }: { specialId: string; key: string }) => {
    const response = await nativeEcommerceService.getSpecialById(
      specialId,
      key
    );
    return response;
  }
);

export const getProductsByBusinessId = createAsyncThunk(
 "nativeEcommerce/getProductsByBusinessId",
 async ({ businessId }: { businessId: any }) => {
  const response = await nativeEcommerceService.getProductsById(
    businessId,
  );
  return response;
}
);

export const updateProduct = createAsyncThunk(
  "nativeEcommerce/updateProduct",
  async ({ product}: { product: any }) => {
   const response = await nativeEcommerceService.updateProductDetails(
     product,
   );
   return response;
 }
 );

 export const getProduct = createAsyncThunk(
  "nativeEcommerce/getProduct",
  async ({ productId}: { productId: any }) => {
   const response = await nativeEcommerceService.getProductDetails(
     productId
   );
   return response;
 }
 );

 export const getProductLog = createAsyncThunk(
  "nativeEcommerce/getProduct",
  async ({ productId}: { productId: string }) => {
   const response = await nativeEcommerceService.fetchProductLog(
     productId
   );
   return response;
 }
 );

export const pruneEcommerceProducts = createAsyncThunk(
  "nativeEcommerce/pruneEcommerceProducts",
  async ({ businessId }: { businessId: number }) => {
    const response = await nativeEcommerceService.pruneEcommerceProducts(businessId);
    return response;
  }
);

export const syncEcommerceProducts = createAsyncThunk(
  "nativeEcommerce/syncEcommerceProducts",
  async ({ businessId }: { businessId: number }) => {
    const response = await nativeEcommerceService.syncEcommerceProducts(businessId);
    return response;
  }
);

export const addCollection = createAsyncThunk(
  "nativeEcommerce/addCollection",
  async ({ collection, key }: { collection: Special; key: string }) => {
    const response = await nativeEcommerceService.addCollection(collection, key);
    return response as Special;
  }
);

export const getBusinessCollections = createAsyncThunk(
  "nativeEcommerce/getBusinessCollections",
  async ({ businessId, key }: { businessId: string; key: string }) => {
    const response = await nativeEcommerceService.getBusinessCollections(
      businessId,
      key
    );
    return response;
  }
);

export const getMappings = createAsyncThunk(
    'nativeEcommerce/getMappings',
    async (businessId: number) => {
        const response = await nativeEcommerceService.getMappings(businessId);
        return response as Mapping[];
    }
)

export const getCategoryMappings = createAsyncThunk(
    'nativeEcommerce/getCategoryMappings',
    async (businessId: number) => {
        const response = await nativeEcommerceService.getCategoryMappings(businessId);
        return response as Mapping[];
    }
)

export const createMapping = createAsyncThunk(
    'nativeEcommerce/createMapping',
    async (payload: CreateMapping[]) => {
        const response = await nativeEcommerceService.createMapping(payload);
        return response as Mapping[];
    }
);

interface InitialState {
  taxSettings: TaxSettings[] | null;
  schedules: Schedule[] | null;
  special: Special | null;
  businessSpecials: Special[] | null;
  posabitsSettings: PosabitsProviderSettings[] | null;
  treezSettings: TreezProviderSettings[] | null;
  mappings: Mapping[] | null; // Added from dev branch
  status: "idle" | "loading" | "failed";
  error: string | null;
}

const initialState: InitialState = {
  taxSettings: null,
  posabitsSettings: null,
  treezSettings: null,
  schedules: null,
  special: null,
  businessSpecials: null,
  mappings: [], // Added from dev branch
  status: "idle",
  error: null,
};

const nativeEcommerceSlice = createSlice({
  name: "nativeEcommerce",
  initialState,
  reducers: {
    clearEcommerce(state) {
      state.taxSettings = null;
      state.schedules = null;
      state.special = null;
      state.businessSpecials = null;
      state.posabitsSettings = null;
      state.treezSettings = null;
      state.mappings = [];
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listPosabitsSettings.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(listPosabitsSettings.fulfilled, (state, action) => {
        state.status = "idle";
        state.posabitsSettings = action.payload;
        state.error = null;
      })
      .addCase(listPosabitsSettings.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load posabits settings.";
      })
      .addCase(listTreezSettings.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(listTreezSettings.fulfilled, (state, action) => {
        state.status = "idle";
        state.treezSettings = action.payload;
        state.error = null;
      })
      .addCase(listTreezSettings.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load treez settings.";
      })
      .addCase(getSchedules.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getSchedules.fulfilled, (state, action) => {
        state.status = "idle";
        state.schedules = action.payload;
        state.error = null;
      })
      .addCase(getSchedules.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load schedules.";
      })
      .addCase(addSchedule.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addSchedule.fulfilled, (state, action) => {
        state.status = "idle";
        state.schedules = Object.assign({}, state.schedules, action.payload);
        state.error = null;
      })
      .addCase(addSchedule.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to add schedule.";
      })
      .addCase(updateSchedule.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateSchedule.fulfilled, (state, action) => {
        state.status = "idle";
        state.schedules = Object.assign({}, state.schedules, action.payload);
        state.error = null;
      })
      .addCase(updateSchedule.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to update schedule.";
      })
      .addCase(deleteSchedule.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteSchedule.fulfilled, (state, action) => {
        state.status = "idle";
        state.schedules = Object.assign({}, state.schedules, action.payload);
        state.error = null;
      })
      .addCase(deleteSchedule.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to delete schedule.";
      })
      .addCase(addSpecial.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addSpecial.fulfilled, (state, action) => {
        state.status = "idle";
        state.special = Object.assign({}, state.special, action.payload);
        state.error = null;
      })
      .addCase(addSpecial.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to add special.";
      })
      .addCase(addSpecialImage.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(addSpecialImage.fulfilled, (state, action) => {
        state.status = 'idle';
        state.special = Object.assign({}, state.special, action.payload);
        state.error = null;
        // Update the state accordingly, e.g., state.image = action.payload;
      })
      .addCase(addSpecialImage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = 'Failed to upload image.';
      })
      .addCase(deleteSpecial.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteSpecial.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(deleteSpecial.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to delete special.";
      })
      .addCase(editSpecial.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(editSpecial.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(editSpecial.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to edit special.";
      })
      .addCase(getBusinessSpecials.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getBusinessSpecials.fulfilled, (state, action) => {
        state.status = "idle";
        state.businessSpecials = action.payload;
        state.error = null;
      })
      .addCase(getBusinessSpecials.rejected, (state, action) => {
        state.status = "failed";
        state.businessSpecials = [];
        state.error = "Failed to load specials.";
      })
      .addCase(getMappings.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getMappings.fulfilled, (state, action) => {
        state.status = "idle";
        state.mappings = action.payload;
        state.error = null;
      })
      .addCase(getMappings.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load mappings.";
      })
      .addCase(getCategoryMappings.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCategoryMappings.fulfilled, (state, action) => {
        state.status = "idle";
        state.mappings = action.payload;
        state.error = null;
      })
      .addCase(getCategoryMappings.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load category mappings.";
      })
      .addCase(createMapping.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createMapping.fulfilled, (state, action) => {
        state.status = "idle";
        state.mappings = action.payload;
        state.error = null;
      })
      .addCase(createMapping.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to create mapping.";
      })
      .addCase(getProductsByBusinessId.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getProductsByBusinessId.fulfilled, (state, action) => {
        state.status = "idle";
        state.mappings = action.payload;
        state.error = null;
      })
      .addCase(getProductsByBusinessId.rejected, (state) => {
        state.status = "failed";
        state.error = "Failed to get products for this business.";
      })
      .addCase(updateProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.status = "idle";
        state.mappings = action.payload;
        state.error = null;
      })
      .addCase(updateProduct.rejected, (state) => {
        state.status = "failed";
        state.error = "Failed to update this product.";
      })
      .addCase(getProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.status = "idle";
        state.mappings = action.payload;
        state.error = null;
      })
      .addCase(getProduct.rejected, (state) => {
        state.status = "failed";
        state.error = "Failed to get product data.";
      });
  },
});


export const { clearEcommerce } = nativeEcommerceSlice.actions;

export default nativeEcommerceSlice.reducer;
