import { Remove } from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import InputField from "components/shared/special/InputField";
import SelectField from "components/shared/special/SelectField";
import { useField, useFormikContext } from "formik";
import specialFormModel from "components/SpecialCreate/FormModel/specialFormModel";
import { Button } from "components/shared";
import ProductSelectDialog from "../../dialogs/select-dialog/product-select-dialog";
import { useEffect, useState } from "react";
import { createProductSearchData } from "../../modals/data/productSearchData";
import { getProducts } from "services/productService";
import { useAppSelector } from "redux/hooks";
import { set } from "lodash";

const { formField } = specialFormModel;

export default function ProductRewardOption({ rewardIndex, rewards }) {
  const business = useAppSelector((state) => state.business.info);
  const businessId = business?.id;
  const formik = useFormikContext();
  console.log(formik)
  const { values, setFieldValue } = formik;
  const { bundleRewards } = formField;
  const valueFieldName = `bundleRewards[${rewardIndex}].value`;
  const weightFieldName = `bundleRewards[${rewardIndex}].weight`;
  const qtyFieldName = `bundleRewards[${rewardIndex}].quantity`;
  const [products, setProducts] = useState([]);
  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const selectedProduct = [values.bundleRewards[rewardIndex]?.productId || []];

  const handleOpenProductDialog = () => {
    setProductDialogOpen(true);
  };

  const handleCloseProductDialog = () => {
    setProductDialogOpen(false);
  };

  const handleProductChange = (selectedProduct) => {
    if (selectedProduct.length === 0) {
      setFieldValue(`bundleRewards[${rewardIndex}].productId`, "");
    } else {
      const selected = selectedProduct[0];
      if (selected && selected._source) {
        setFieldValue(`bundleRewards[${rewardIndex}].productId`, selected);
      }
    }
  };

  const handleRemoveReward = () => {
    const newRewards = [...rewards];
    newRewards.splice(rewardIndex, 1);
    setFieldValue([bundleRewards.name], newRewards);
  };

  const fetchData = async () => {
    if (businessId) {
      const productData = createProductSearchData(businessId);
      const productResponse = await getProducts(productData);
      const products = productResponse?.hits?.hits || [];
      setProducts(products);
    }
  };

  useEffect(() => {
    fetchData();
  }, [businessId]);

  const productId = values.bundleRewards?.[rewardIndex]?.productId._id;

  return (
    <div style={{ margin: "1rem 0" }}>
      <Typography variant="subtitle2" fontWeight={"bold"}>
        Each item for
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "8px 0",
            width: "100%"
          }}
        >
          <div
            style={{
              display: "flex",
              margin: "8px 0",
              columnGap: "8px",
            }}
          >
            <div style={{ flex: 1 }}>
              {productId ? (
                <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} fullWidth border={"1px solid rgba(0, 0, 0, 0.23)"} borderRadius={"4px"} px={2} height={"100%"} minHeight="50px">
                  <Typography variant="h5" component="p">
                    {products.find((product) => product._id === productId)?._source.name || "Cannot find product name"}
                  </Typography>
                  <Button onClick={handleOpenProductDialog}>Edit</Button>
                </Box>
              ) : (
                <Button fullWidth variant="outlined" onClick={handleOpenProductDialog}>Add Product</Button>
              )}
              {products.length > 0 && (
                <ProductSelectDialog
                  products={products}
                  selectedProducts={selectedProduct}
                  filterIndex={rewardIndex}
                  onChange={(selectedProducts) => handleProductChange(selectedProducts)}
                  open={productDialogOpen}
                  onClose={handleCloseProductDialog}
                  isSingleSelect
                />
              )}
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "8px 0", columnGap: "8px" }}
          >
            <FormControl fullWidth sx={{ flex: 1 }}>
              <InputLabel id="discount-type-value-label">
                Discount Type*
              </InputLabel>
              <SelectField
                error={false}
                labelId={`currency-type-label-${rewardIndex}`}
                id={`bundleRewards[${rewardIndex}].discountType`}
                name={`bundleRewards[${rewardIndex}].discountType`}
                sx={{ height: "52px" }}
                renderValue={(selected) => {
                  if (selected === "TARGET_PRICE") {
                    return "$ specific price";
                  } else if (selected === "PERCENTAGE") {
                    return "%";
                  } else if (selected === "DOLLAR_AMOUNT") {
                    return "$";
                  } else {
                    return null;
                  }
                }}
                MenuProps={{
                  style: {
                    zIndex: 2000,
                  },
                }}
              >
                <MenuItem value="DOLLAR_AMOUNT">
                  This item for a dollar amount off
                </MenuItem>
                <MenuItem value="PERCENTAGE">
                  This item for a percentage off
                </MenuItem>
                <MenuItem value="TARGET_PRICE">
                  This item for a specific price
                </MenuItem>
              </SelectField>
            </FormControl>
            <FormControl fullWidth sx={{ flex: 2 }}>
              <InputField
                error={false}
                helperText=""
                name={valueFieldName}
                label="Discount Value*"
                type="number"
                fullWidth
                InputProps={{
                  sx: { height: 52 },
                }}
              />
            </FormControl>
            <FormControl fullWidth sx={{ flex: 2 }}>
              <InputLabel id="weight-value-label">Weight Value*</InputLabel>
              <SelectField
                error={false}
                labelId="weight-value-label"
                id="weight-value-select"
                label="Weight Value"
                name={weightFieldName}
                sx={{ height: "52px" }}
              >
                <MenuItem value="1g">1g or Gram</MenuItem>
                <MenuItem value="3.5g">3.5g or Eighth</MenuItem>
                <MenuItem value="7g">7g or Quarter</MenuItem>
                <MenuItem value="14g">14g or Half</MenuItem>
                <MenuItem value="28g">28g or Ounce</MenuItem>
                <MenuItem value="EACH">Each</MenuItem>
                <MenuItem value="ANY">Any</MenuItem>
              </SelectField>
            </FormControl>
            <FormControl fullWidth sx={{ flex: 1 }}>
              <InputField
                error={false}
                helperText=""
                name={qtyFieldName}
                label="Up to qty*"
                type="number"
                fullWidth
                InputProps={{
                  sx: { height: 52 },
                }}
              />
            </FormControl>
          </div>
        </div>
        <div style={{ marginLeft: "4px", padding: "0 8px" }}>
          <Stack direction="column" justifyContent="center">
            <Tooltip title="Remove this reward" placement="top">
              <IconButton
                color="error"
                sx={{ backgroundColor: "#ff4d4f15" }}
                onClick={handleRemoveReward}
              >
                <Remove />
              </IconButton>
            </Tooltip>
          </Stack>
        </div>
      </div>
    </div>
  );
}
