import axios, { AxiosRequestConfig } from "axios";
import config from "../config";
import { businessConstants } from "../constants";
import { apiHelper, authHeader } from "../helpers";
import { SearchBusinessesRequestConfig } from "../types/search";

export const searchService = {
  searchByPostalCode,
  getBusinessOrganization,
  getProducts,
};
function authHeaderSpecial() {
  return {
    Authorization:
      "ApiKey QWdQNEJvc0IwV2hOdjdhbHFPWEo6VzZRRFZvbUtTR21NR1U1blY2dHpaQQ==",
  };
}
/**
 * Get products based on some criteria
 * @param businessId The ID of the business for which to retrieve products
 */
function getProducts(businessId: number) {
  const auth = authHeaderSpecial();

  const requestConfig = {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      ...auth,
    },
  };
  const data = {
    query: {
      terms: {
        businessId: ["5133"],
      },
    },
    aggs: {
      brands: {
        terms: {
          field: "brandName",
          size: 500,
        },
      },
    },
    _source: true,
    size: 1000,
  };
  const apiUrl =
    "https://7275bd7572e64cc390cee25c32ad848b.us-central1.gcp.cloud.es.io:443/ecommerce-production/products";
  return axios.post(apiUrl, data, requestConfig).then(function (response) {
    let data = response.data;
  });
}

/**
 * Search Businesses by Postal Code
 * @param requestConfig
 */
function searchByPostalCode(requestConfig: SearchBusinessesRequestConfig) {
  const auth = authHeader();
  const requestOptions: AxiosRequestConfig = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...auth,
    },
    params: {
      page: requestConfig.page || 1,
      take: requestConfig.take || businessConstants.DEFAULT_API_TAKE,
      distance: requestConfig.distance || false,
    },
  };

  if (requestConfig.includeMap) {
    requestOptions.params.includeMap = true;
  }

  return axios
    .get(
      `${config.apiUrl}/search/internal/postalCode/${requestConfig.postalCode}`,
      requestOptions
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Get search predictions
 * @param query
 */
function getBusinessOrganization(query: string, businessId: number) {
  const requestConfig: AxiosRequestConfig = {
    method: "GET",
    headers: authHeader(),
    params: {
      q: query,
    },
  };

  return axios
    .get(`${config.apiUrl}/search/internal/${businessId}/organization`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}
