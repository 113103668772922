import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "../../shared/Button";
import { useAppDispatch } from "redux/hooks";
import { getBusiness, getBusinessSettings } from "redux/businessSlice";
import { useNavigate, useParams } from "react-router-dom";
import { businessService } from "services";
import { debounce } from "lodash";
import { getBusinessSpecials, getCategoryMappings } from "redux/nativeEcommerceSlice";
import { BusinessSelectDrawerItem } from "./index";


interface MobileBusinessSelectProps {
  businessSelectDialog: boolean;
  handleClose: () => void;
  businesses: any[];
  business: any;
}

export const MobileBusinessSelect: React.FC<MobileBusinessSelectProps> = ({
  businessSelectDialog,
  handleClose,
  businesses,
  business,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [activeBusinessId, setActiveBusinessId] = useState(business.id);
  const activeRadioRef = useRef<HTMLInputElement | null>(null);
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const params = useParams();
  const [predictions, setPredictions] = useState<any[]>([]); // Store predictions
  const defaultBusinesses = businesses;

  const fetchPredictions = useMemo(
    () =>
      debounce(async (query: string, callback: (predictions: any[]) => void) => {
        const results = await businessService.getPredictions(query);
        const predictions = results.data.businesses;
        setPredictions(predictions); // Update predictions
        callback(predictions);
      }, 200, { leading: false, trailing: true }),
    []
  );

  useEffect(() => {
    setActiveBusinessId(business.id);
  }, [business]);

  useEffect(() => {
    if (businessSelectDialog && activeRadioRef.current) {
      activeRadioRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [businessSelectDialog]);

  useEffect(() => {
    if (!searchQuery && drawerRef.current && activeRadioRef.current) {
      activeRadioRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (searchQuery && drawerRef.current) {
      drawerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (businessSelectDialog) {
      setActiveBusinessId(activeBusinessId);
      setTimeout(() => {
        if (activeRadioRef.current) {
          activeRadioRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 300);
    }
  }, [businessSelectDialog]);

  useEffect(() => {
    if (activeRadioRef.current) {
      activeRadioRef.current.focus();
    }
  }, [activeBusinessId]);

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    fetchPredictions(query, (predictions) => {
      // You can handle predictions here if needed
    });
  };

  const handleBusinessSelect = async () => {
    if (params.businessId) {
      const newUrl = window.location.pathname.replace(
        `/${params.businessId}`,
        `/${activeBusinessId}`
      );
      navigate(newUrl);
    }
    handleClose();
    const businessResponse = await dispatch(getBusiness(activeBusinessId)) as any;
    const publicKey = businessResponse.payload.menuSettings?.jointEcommercePublicKey || 'defaultPublicKey';
    dispatch(getBusinessSettings(activeBusinessId));
    dispatch(getBusinessSpecials({ businessId: activeBusinessId, key: publicKey }));
    dispatch(getCategoryMappings(activeBusinessId));
  };

  const filteredBusinesses = searchQuery ? predictions : defaultBusinesses;

  return (
    <Dialog open={businessSelectDialog} onClose={handleClose} fullWidth maxWidth="sm" sx={{width: "100%"}}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Select your business</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Search businesses"
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={handleSearchInputChange}
              size="small"
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <FormControl component="fieldset" style={{ marginTop: 2 }}>
          <RadioGroup
            aria-label="businesses"
            name="businesses"
            value={activeBusinessId}
            onChange={(e) => setActiveBusinessId(e.target.value)}
          >
            {filteredBusinesses.map((business) => (
              <React.Fragment key={business.id}>
                <FormControlLabel
                  value={business.id}
                  control={<Radio inputRef={business.id === activeBusinessId ? activeRadioRef : null} />}
                  label={<BusinessSelectDrawerItem business={business} />}
                />
                <Divider />
              </React.Fragment>
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleBusinessSelect} color="primary" variant="contained">
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};
