import React from 'react';

import {Box as MuiBox, Typography} from "@mui/material";


export function WidgetBoxContent(props) {
  

  return (
    <MuiBox >
      <Typography  variant={props.variant || 'body1'} component="div">{props.children}</Typography>
    </MuiBox>
  );
}
