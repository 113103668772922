import React, { useState } from 'react';
import { Drawer, IconButton, Typography, Divider, Box, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import NavGroup from '../navigation/nav-group/nav-group.component';
import { NAVIGATION_TYPE } from 'layout/constants';
import { Button } from 'components/shared';
import { useGeneralNav } from '../navigation/hooks/use-general-nav.hook';
import { BusinessNavHeader } from '../navigation/nav-header/business-nav-header';
import { useAppDispatch } from 'redux/hooks';
import { logout } from 'redux/authSlice';
import { useNavigate } from 'react-router-dom';
import { Logo } from 'components/shared/logo';

interface MobileNavigationProps {
    navItems?: any[];
    navigationType: keyof typeof NAVIGATION_TYPE;
}

export const MobileNavigation: React.FC<MobileNavigationProps> = ({ navItems, navigationType }) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { navItems: generalNavItems } = useGeneralNav();
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = async () => {
        dispatch(logout());
        navigate("/login");
      };

    return (
        <Box
            sx={{
                display: { md: 'none' },
                backgroundColor: theme.palette.secondary.main,
                borderBottom: "2px solid gray",
                p: { xs: 2, md: 0 },
                boxShadow: 2,
                zIndex: 1000,
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                <IconButton
                    edge="start"
                    aria-label="menu"
                    onClick={toggleMenu}
                    sx={{
                        color: 'white',
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Logo width="150px" mobile />
                </Box>
                {navigationType === 'BUSINESS' && <BusinessNavHeader mobile />}
            </Box>
            <Drawer
                anchor="left"
                open={isOpen}
                onClose={toggleMenu}
                ModalProps={{ disableScrollLock: true }}
            >
                <Box
                    sx={{ width: 250, minWidth: '100vw', maxWidth: '80vw', py: 3, px: 2 }}
                    onClick={toggleMenu}
                >
                    {navItems && navItems.length > 0 && (
                        <>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h5" sx={{ mb: 2 }}>
                                    {NAVIGATION_TYPE[navigationType]}
                                </Typography>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={toggleMenu}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <NavGroup navItems={navItems} />
                            <Divider sx={{ my: 2 }} />
                        </>
                    )}
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h5" sx={{ mb: 2 }}>
                            General
                        </Typography>
                        {navItems && navItems.length === 0 && (
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={toggleMenu}
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                    </Box>
                    <NavGroup navItems={generalNavItems} noHighlight />
                    <Box height={36}></Box>
                    <Box position="fixed" bottom="16px" left="0" width="100%" px={2}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleLogout}
                            fullWidth
                            sx={{
                                backgroundColor: '#FCF6F6',
                                color: 'error.main',
                                borderRadius: '10px',
                                border: 'thin solid #ff4d4f',
                                '&:hover': {
                                    backgroundColor: 'error.main',
                                    color: '#FCF6F6',
                                },
                            }}
                        >
                            Sign Out
                        </Button>
                    </Box>
                </Box>
            </Drawer>
        </Box >
    );
};
