import React from "react";
import { useLocation } from "react-router-dom";
import { NavItem } from "../types";
import {
  ApiOutlined,
  ClockCircleOutlined,
  DesktopOutlined,
  HomeOutlined,
  TeamOutlined,
   FileSearchOutlined
} from "@ant-design/icons";
import { USER_ROLES } from "constants/user.constants";


// icons
const icons = {
  ApiOutlined,
  ClockCircleOutlined,
  DesktopOutlined,
  HomeOutlined,
  TeamOutlined,
  FileSearchOutlined
};

export const useAdminNav = () => {
  const [activeItem, setActiveItem] = React.useState<NavItem | null>(null);
  const [items,] = React.useState<NavItem[]>([
    {
      id: "adminHome",
      title: "Admin",
      type: "item",
      url: "/admin",
      icon: icons.HomeOutlined,
      breadcrumbs: true,
      userRoles: [USER_ROLES.ADMIN]
    },
    {
      id: "users",
      title: "Users",
      type: "item",
      url: "/admin/users",
      icon: icons.TeamOutlined,
      breadcrumbs: true,
      userRoles: [USER_ROLES.ADMIN]
    },
    {
      id: "api",
      title: "Api",
      type: "item",
      url: "/admin/api",
      icon: icons.ApiOutlined,
      breadcrumbs: true,
      userRoles: [USER_ROLES.ADMIN]
    },
    {
      id: "mcc",
      title: "Manage Mccs",
      type: "item",
      url: "/admin/mcc",
      icon: icons.DesktopOutlined,
      breadcrumbs: true,
      userRoles: [USER_ROLES.ADMIN]
    },
    {
      id: "catalog-batch-manager",
      title: "Catalog Batch Manager",
      type: "item",
      url: "/admin/catalog-batch-manager",
      icon: icons.FileSearchOutlined,
      breadcrumbs: true,
      userRoles: [USER_ROLES.ADMIN]
    },
    {
      id: "catalog-master-manager",
      title: "Catalog Master Manager",
      type: "item",
      url: "/admin/catalog-master-manager",
      icon: icons.FileSearchOutlined,
      breadcrumbs: true,
      userRoles: [USER_ROLES.ADMIN]
    },
    {
      id: "ecommerce",
      title: "Ecommerce Schedule",
      type: "item",
      url: "/admin/ecommerce",
      icon: icons.ClockCircleOutlined,
      breadcrumbs: true,
      userRoles: [USER_ROLES.ADMIN]
    },
  ]);
  const { pathname } = useLocation();

  React.useEffect(() => {
    // Find and set active nav item
    const currentNavItem = items.find((item) => item.url === pathname);
    setActiveItem(currentNavItem || null);

  }, [pathname, items]);

  return {
    activeItem: activeItem,
    navItems: items,
  };
} 