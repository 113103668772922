export const dateHelpers = {
  getDayLabel,
  militaryToStandard,
  toTimeCompareFormat,
};

/**
 * Get day label
 * @param dayNumber
 * @returns {string}
 */
function getDayLabel(dayNumber) {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return days[Number(dayNumber)];
}

/**
 * Convert Military time string to standard time format
 * @param militaryTime
 * @returns {string}
 */
function militaryToStandard(militaryTime) {
  const militaryHour = Number(militaryTime.slice(0,2));
  const standardMinute = militaryTime.slice(2);
  let amOrPm = 'am';
  let standardHour = militaryHour;

  if (militaryHour >= 12) {
    amOrPm = 'pm';
  }

  if (militaryHour >= 13) {
    standardHour = militaryHour - 12;
  }

  if(militaryHour === 0) {
    standardHour = 12;
  }

  if(militaryHour === 24) {
    standardHour = 12;
    amOrPm = 'am';
  }

  return `${standardHour}:${standardMinute}${amOrPm}`;
}

/**
 * Format time string to comparison friendly int
 * @param time
 * @returns {number}
 */
function toTimeCompareFormat(time) {
  const timeInt = Number(time);
  const timeStr = timeInt.toString();
  const hour = timeInt >= 1000 ? timeStr.slice(0,2) : timeStr.slice(0,1);
  const min = timeInt >= 1000 ? timeStr.slice(2,4) : timeStr.slice(1,3);
  return Number(`${hour}${min}`);
}
