import React from "react";

import logoLightModeMobile from "../../../assets/rrh-logo-light.webp";
import logoLightMode from "../../../assets/rrh-logo.png";
import logoDarkMode from "../../../assets/rrh-logo-white.png";

interface LogoProps {
    theme?: "light" | "dark";
    width?: string;
    mobile?: boolean;
}

export const Logo: React.FC<LogoProps> = ({ theme, width = "100%", mobile = false }) => {

    const getLogoSrc = () => {
        if (mobile) {
            return logoLightModeMobile;
        }
        return theme === "dark" ? logoDarkMode : logoLightMode;
    };
    
    return (
        <div style={{ width: "100%" }}>
            <img
                style={{ display: "block", width: width }}
                src={getLogoSrc()}
                alt="RRH Logo"
            />
        </div>
    );
};
