import { Grid } from "@mui/material";
import CustomerBuysFilterForm from "components/businesses/ecommerce/specials/filters/CustomerBuysFilterForm";
import CheckboxGroup from "components/shared/special/CheckboxGroup";
import React from "react";
import { useAppSelector } from "redux/hooks";
import Label from "../Extras/Label";

export default function CustomerBuysForm(props) {
  const business = useAppSelector((state) => state.business.info);
  const {
    formField,
    editing,
    formField: { menuTypes },
  } = props;
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={7}>
          <Label label={menuTypes.label} />
          <CheckboxGroup
            name={menuTypes.name}
            items={menuTypes.options}
            isArray
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <CustomerBuysFilterForm
            businessId={business.id}
            formField={formField}
            editing={editing}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
