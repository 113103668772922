import { Box } from "@mui/material";
import { useLayoutState } from "hooks/use-layout-state.hooks";
import { AppLayout } from "layout/app-layout";
import { APP_DRAWER_WIDTH, NAVIGATION_WIDTH } from "layout/constants";
import { LayoutBox } from "layout/layout-box";
import { Outlet } from "react-router-dom";

interface DefaultLayoutProps {
  title?: string;
}

export function DefaultLayout({ title }: DefaultLayoutProps) {
  return (
    <AppLayout isDefaultLayout>
      <LayoutBox noSideBar>
        <Outlet />
      </LayoutBox>
    </AppLayout>
  );
}
