import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import config from '../config';
import { apiHelper, authHeader } from '../helpers';
import User, { ChangePasswordDto, CreateUserDto, ForgotPasswordContextDto, RegistrationContextDto, SetUserPasswordDto, UpdateUserDto, UpdateUserProfileDto, UserRegisterDto } from '../types/user';

export const userService = {
  login,
  logout,
  register,
  forgotPassword,
  setPassword,
  getAll,
  getPredictions,
  getUser,
  updateUser,
  updateUserOrganization,
  updateUserProfile,
  deleteUserOrganization,
  invite,
  changePassword,
  setUserPassword,
  getRegistrationContext,
  resendRegistration,
  getForgotPasswordContext,
  resendForgotPassword
};

interface LoginRequest {
  email?: string;
  password?: string;
}

interface ForgotPasswordRequest {
  email: string;
}

interface SetPasswordRequest {
  token: string;
  password: string;
}

interface Token {
  expiresIn: number;
  accessToken: string;
}

interface UserResponse {
  user: User;
  token: Token;
}
/**
 * Login
 * @param loginRequest
 */
function login(loginRequest: LoginRequest) {
  return axios.post(`${config.apiUrl}/auth/login`, { email: loginRequest.email, password: loginRequest.password })
    .then(apiHelper.handleResponse)
    .then((user: AxiosResponse<UserResponse>) => {
      localStorage.setItem('user', JSON.stringify(user));
      return user;
    })
    .catch(apiHelper.handleError);
}

/**
 * Forgot password
 * @param emailRequest 
 */
function forgotPassword(emailRequest: ForgotPasswordRequest) {
  return axios
    .post(`${config.apiUrl}/auth/forgot-password`, emailRequest)
    .then((response) => {
      return response;
    }); // There is no response data when the email is sent - so handleResponse errors out.
}

function setPassword(setPasswordRequest: SetPasswordRequest) {
  return axios.post(`${config.apiUrl}/auth/set-password`, { token: setPasswordRequest.token, password: setPasswordRequest.password });
}

/**
 * Logout
 * @return {[type]} [description]
 */
function logout() {
  localStorage.removeItem('user');
}

/**
 * Finalize setup for user who has been invited
 * @param newUser
 */
function register(newUser: UserRegisterDto) {
  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      ...auth
    },
  };
  return axios.post(`${config.apiUrl}/auth/register`, newUser, requestConfig)
    .then((response) => response.data)
    .catch((e) => {
      if (e.response.data) { console.error("API Response Error", e.response.data); throw e.response.data; }
      console.error(e); throw e;
    });
}
/**
 * Validate/get registration context
 * @param newUser
 */
function getRegistrationContext(token: string): Promise<RegistrationContextDto> {

  const requestConfig: AxiosRequestConfig = {
    params: { token }
  };
  return axios.get(`${config.apiUrl}/auth/registration-context`, requestConfig)
    .then((response) => response.data)
    .catch((e) => {
      if (e.response.data) { console.error("API Response Error", e.response.data); throw e.response.data; }
      console.error(e); throw e;
    });
}

/**
 * Validate/get registration context
 * @param newUser
 */
function resendRegistration(token: string): Promise<RegistrationContextDto> {

  const requestConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    }
  };
  return axios.post(`${config.apiUrl}/auth/resend-registration/token`, { token }, requestConfig)
    .then((response) => response.data)
    .catch((e) => {
      if (e.response.data) { console.error("API Response Error", e.response.data); throw e.response.data; }
      console.error(e); throw e;
    });
}
/**
 * Validate/get forgot password context
 * @param newUser
 */
function getForgotPasswordContext(token: string): Promise<ForgotPasswordContextDto> {

  const requestConfig: AxiosRequestConfig = {
    params: { token }
  };
  return axios.get(`${config.apiUrl}/auth/forgot-password-context`, requestConfig)
    .then((response) => response.data)
    .catch((e) => {
      if (e.response.data) { console.error("API Response Error", e.response.data); throw e.response.data; }
      console.error(e); throw e;
    });
}

/**
 * Validate/get forgot password context
 * @param newUser
 */
function resendForgotPassword(token: string): Promise<ForgotPasswordContextDto> {

  const requestConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    }
  };
  return axios.post(`${config.apiUrl}/auth/resend-forgot-password/token`, { token }, requestConfig)
    .then((response) => response.data)
    .catch((e) => {
      if (e.response.data) { console.error("API Response Error", e.response.data); throw e.response.data; }
      console.error(e); throw e;
    });
}

/**
 * Invite new user(s)
 * @param newUsers
 */
function invite(newUser: CreateUserDto[]) {
  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      ...auth
    },
  };
  return axios.post(`${config.apiUrl}/users`, newUser, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}
/**
 * Admin set user password
 * @param newUsers
 */
function setUserPassword(userId: number, body: SetUserPasswordDto) {
  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      ...auth
    },
  };
  return axios.post(`${config.apiUrl}/users/${userId}/set-password`, body, requestConfig)
    .then((response) => response.data)
    .catch(apiHelper.handleError);
}
/**
 * User Profile Change PAssword
 * @param newUsers
 */
function changePassword(userId: number, body: ChangePasswordDto) {
  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      ...auth
    },
  };
  return axios.post(`${config.apiUrl}/users/${userId}/change-password`, body, requestConfig)
    .then((response) => response.data)
    .catch(apiHelper.handleError);
}
/**
 * Get all users
 */
function getAll() {
  const requestConfig: AxiosRequestConfig = {
    method: 'GET',
    headers: authHeader(),
  };
  return axios.get(`${config.apiUrl}/users?take`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);

}

/**
 * Get all users
 */
export function getUsers(page: number, take: number = 25) {
  const requestConfig: AxiosRequestConfig = {
    method: 'GET',
    headers: authHeader(),
  };
  return axios.get(`${config.apiUrl}/users?page=${page}&take=${take}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);

}

/**
 * Get search predictions
 * @param query
 */
function getPredictions(query: string) {
  const requestConfig: AxiosRequestConfig = {
    method: 'GET',
    headers: authHeader(),
    params: {
      q: query
    }
  };

  return axios.get(`${config.apiUrl}/search/internal/user`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Get user info
 * @param id
 */
function getUser(id: Number) {
  const requestConfig: AxiosRequestConfig = {
    method: 'GET',
    headers: authHeader(),
    params: {
      id: id
    }
  };
  return axios.get(`${config.apiUrl}/users/${id}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);

}

/**
 * Update user
 * @param newData
 */
function updateUser(userId: number, newData: Partial<UpdateUserDto>) {
  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      ...auth
    },
  };
  return axios.patch(`${config.apiUrl}/users/${userId}`, newData, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Update user profile
 * @param newData
 */
function updateUserProfile(userId: number, newData: Partial<UpdateUserProfileDto>) {
  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      ...auth
    },
  };
  return axios.patch(`${config.apiUrl}/users/${userId}/profile`, newData, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}


function updateUserOrganization(userId: number, organizationId: number) {
  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      ...auth
    },
  };

  return axios.post(`${config.apiUrl}/users/${userId}/organizations/${organizationId}`, {}, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}



function deleteUserOrganization(userId: number, organizationId: number) {
  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      ...auth
    }
  };

  return axios.delete(`${config.apiUrl}/users/${userId}/organizations/${organizationId}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

