import React, { useEffect, useState } from 'react';

import Rating from '@mui/material/Rating';
import { useWindowDimensions } from '../../hooks';


export function BusinessRating({business, ...props}) {
    const [rating, setRating] = useState(0.0);
    const [totalReviews, setTotalReviews] = useState(0);
    const windowDimensions = useWindowDimensions();
    const [ratingSize, setRatingSize] = useState(props.size || 'medium');

    useEffect(() => {
        if(windowDimensions.width < 1024) {
            if (props.summary) {
                setRatingSize('medium');
            } else {
                setRatingSize('small');
            }
        }
    }, [windowDimensions.width, props.summary])

    useEffect(() => {
        if (business.rating && business.reviewCount) {
            setRating(business.rating);
            setTotalReviews(business.reviewCount);
        } else {
            if (props.site) {
                // if site prop is set then use reviews from that site
                if (props.site === 'google') {
                    setRating(business.googleRating);
                    setTotalReviews(business.googleReviews);
                } else if (props.site === 'yelp') {
                    setRating(business.yelpRating);
                    setTotalReviews(business.yelpReviewCount);
                }
            } else {
                // else try to use google reviews but then yelp as a backup
                if (business.googleRating) {
                    setRating(business.googleRating);
                    setTotalReviews(business.googleReviews);
                } else if (business.yelpRating) {
                    setRating(business.yelpRating);
                    setTotalReviews(business.yelpReviewCount);
                }
            }
        }


    }, [business, props.site]);

    return (
        totalReviews !== 0 ?
            <div >
                <span >{parseFloat(rating).toFixed(1) || 0.0 }</span>
                <Rating value={parseFloat(rating)} precision={0.5} size={ratingSize} readOnly />
                <span >({totalReviews}) {(business.googlePriceLevel && !props.noPrice) && `- ${business.googlePriceLevel}`}</span>
            </div> : ''
    );
}
