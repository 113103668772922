import { useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

// material-ui
import {Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography} from '@mui/material';
import {activeItem} from 'redux/menuSlice';
import { setMobileOpen } from 'redux/layoutSlice';

// project import


// ==============================|| NAVIGATION - LIST ITEM ||============================== //
interface NavItemProps {
  item:any;
  level: number;
  noHighlight?: boolean;
}

export function NavItem({item, level = 1, noHighlight}:NavItemProps) {
  const dispatch = useDispatch();
  //@ts-ignore
  const { openItem } = useSelector((state) => state.menu);
  const { pathname } = useLocation();
  const Icon = item.icon;
  const itemIcon = item.icon ? <Icon style={{ fontSize: "1rem" }} /> : false;

  const isSelected = openItem.findIndex((id:string) => id === item.id) > -1;

  // active menu item on page load
  useEffect(() => {
    if (noHighlight) return;
    if (pathname.includes(item.url)) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
    // eslint-disable-next-line
  }, [pathname]);

 return (
    <ListItemButton
      disabled={item.disabled}
      component={Link}
      to={item.url}
      selected={isSelected}
      onClick={(() => dispatch(setMobileOpen(false)))}
      sx={{
        zIndex: 1201,
        margin: '0 8px',
        pl: `${level * 28}px`,
        py: level === 1 ? 1.25 : 1,
        border: 'thin solid',
        borderRadius: '10px',
        borderColor: '#fff',
        '&:hover': {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
          border: 'thin solid',
          borderRadius: '10px',
          borderColor: 'primary.main',
        },
        '&.Mui-selected': {
          bgcolor: 'primary.lighter',
          //@ts-ignore
          border: 'thin solid',
          borderRadius: '10px',
          borderColor: 'primary.main',
          color: 'primary.main',
          '&:hover': {
            color: 'primary.main',
            bgcolor: 'primary.lighter'
          }
        }
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: isSelected ? 'primary.main' : 'text.primary',
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      
        <ListItemText
          primary={
            <Typography variant="h6" sx={{ color: isSelected ? 'primary.main' : 'text.primary', fontWeight: isSelected ? '900' : '500' }}>
              {item.title}
            </Typography>
          }
        />
      
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
}

export default NavItem;
