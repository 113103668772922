import { apiHelper, axiosRequestConfig } from "../helpers";
import axios from "axios";
import config from "../config";
import {
  CreateMapping,
  CreatePosabitsProviderSettings,
  CreateTaxSetting,
  CreateTreezProviderSettings,
  Mapping,
  Schedule,
  Special,
  UpdateTaxSetting,
} from "../types/native-ecommerce";
import { UpdateSchedule } from "../types/native-ecommerce/updateSchedule";
import { CreateCatalogBatchProductRequest, GetCatalogBatchBrandListResponse, getCatalogBatchProductDetailResponse, GetCatalogBatchProductListResponse, GetCatalogBatchStrainListResponse, UpdateCatalogBatchProductRequest } from "types/native-ecommerce/catalogBatchProduct";
import { CatalogMasterBrandDto, CatalogMasterBrandSearchDto, CreateCatalogMasterBrandDto, UpdateCatalogMasterBrandDto } from "../types/native-ecommerce/catalog-master/catalog-master-brand.dto";
import { CatalogMasterBrandUploadRowDto, CatalogMasterBrandUploadRowSearchDto, UpdateCatalogMasterBrandUploadRowDto } from "../types/native-ecommerce/catalog-master/catalog-master-brand-upload-row.dto";
import { CatalogMasterBrandUploadDto, CatalogMasterBrandUploadSearchDto, UpdateCatalogMasterBrandsFromUploadDto } from "../types/native-ecommerce/catalog-master/catalog-master-brand-upload.dto";
import { CatalogMasterProductUploadRowSearchDto, UpdateCatalogMasterProductUploadRowDto, CatalogMasterProductUploadRowDto } from "../types/native-ecommerce/catalog-master/catalog-master-product-upload-row.dto";
import { CatalogMasterProductUploadSearchDto, CatalogMasterProductUploadDto, UpdateCatalogMasterProductsFromUploadDto } from "../types/native-ecommerce/catalog-master/catalog-master-product-upload.dto";
import { CatalogMasterProductDto, CatalogMasterProductSearchDto, UpdateCatalogMasterProductDto, CreateCatalogMasterProductDto } from "../types/native-ecommerce/catalog-master/catalog-master-product.dto";
import { CatalogMasterCategoryDto, CatalogMasterCategorySearchDto, UpdateCatalogMasterCategoryDto, CreateCatalogMasterCategoryDto } from "../types/native-ecommerce/catalog-master/catalog-master-category.dto";
import { CatalogMasterSubcategoryDto, CatalogMasterSubcategorySearchDto, UpdateCatalogMasterSubcategoryDto, CreateCatalogMasterSubcategoryDto } from "../types/native-ecommerce/catalog-master/catalog-master-subcategory.dto";
import { CatalogMasterCannabinoidDto, CatalogMasterCannabinoidSearchDto, UpdateCatalogMasterCannabinoidDto, CreateCatalogMasterCannabinoidDto } from "../types/native-ecommerce/catalog-master/catalog-master-cannabinoid.dto";
import { CatalogMasterEffectDto, CatalogMasterEffectSearchDto, UpdateCatalogMasterEffectDto, CreateCatalogMasterEffectDto } from "../types/native-ecommerce/catalog-master/catalog-master-effect.dto";
import { CatalogMasterStrainTypeDto, CatalogMasterStrainTypeSearchDto, UpdateCatalogMasterStrainTypeDto, CreateCatalogMasterStrainTypeDto } from "../types/native-ecommerce/catalog-master/catalog-master-strain-type.dto";
import { CatalogMasterTerpeneDto, CatalogMasterTerpeneSearchDto, UpdateCatalogMasterTerpeneDto, CreateCatalogMasterTerpeneDto } from "../types/native-ecommerce/catalog-master/catalog-master-terpene.dto";

export const nativeEcommerceService = {
  savePosabitsSettings,
  saveTreezSettings,
  listPosabitsProviderSettings,
  listTreezProviderSettings,
  getPosabitsProviderSettings,
  getTreezProviderSettings,
  getTaxes,
  createTax,
  updateTax,
  deleteTax,
  getSchedule,
  getSchedules,
  addSchedule,
  updateSchedule,
  deleteSchedule,
  addSpecial,
  addSpecialImage,
  deleteSpecial,
  editSpecial,
  getBusinessSpecials,
  getBusinessSpecialConfig,
  editBusinessSpecialConfig,
  addCollection,
  getBusinessCollections,
  getCollectionById,
  getSpecialById,
  getMappings,
  getCategoryMappings,
  createMapping: createMappings,
  //inventory management
  getProductsById,
  updateProductDetails,
  getProductDetails,
  fetchProductLog,
  pruneEcommerceProducts,
  syncEcommerceProducts,
  //catalog
  // batch
  getCatalogBatchProducts,
  updateCatalogBatchProduct,
  createCatalogBatchProduct,
  getCatalogBatchProductDetail,
  searchCatalogBatchStrains,
  getCatalogBatchStrainById,
  searchCatalogBatchBrands,
  getCatalogBatchBrandById,
  // end batch
  // master
  getCatalogMasterBrandById,
  getCatalogMasterBrandSearch,
  updateCatalogMasterBrand,
  createCatalogMasterBrand,
  uploadCatalogMasterBrandCsv,
  updateCatalogMasterBrandUploadRow,
  commitCatalogMasterBrandUpload,
  getCatalogMasterBrandUploadSearch,
  getCatalogMasterBrandUploadRowSearch,
  getCatalogMasterProductById,
  getCatalogMasterProductSearch,
  updateCatalogMasterProduct,
  createCatalogMasterProduct,
  uploadCatalogMasterProductCsv,
  updateCatalogMasterProductUploadRow,
  commitCatalogMasterProductUpload,
  getCatalogMasterProductUploadSearch,
  getCatalogMasterProductUploadRowSearch,
  getCatalogMasterCategoryById,
  getCatalogMasterCategorySearch,
  updateCatalogMasterCategory,
  createCatalogMasterCategory,
  getCatalogMasterSubcategoryById,
  getCatalogMasterSubcategorySearch,
  updateCatalogMasterSubcategory,
  createCatalogMasterSubcategory,
  getCatalogMasterEffectById,
  getCatalogMasterEffectSearch,
  updateCatalogMasterEffect,
  createCatalogMasterEffect,
  getCatalogMasterTerpeneById,
  getCatalogMasterTerpeneSearch,
  updateCatalogMasterTerpene,
  createCatalogMasterTerpene,
  getCatalogMasterCannabinoidById,
  getCatalogMasterCannabinoidSearch,
  updateCatalogMasterCannabinoid,
  createCatalogMasterCannabinoid,
  getCatalogMasterStrainTypeById,
  getCatalogMasterStrainTypeSearch,
  updateCatalogMasterStrainType,
  createCatalogMasterStrainType,
  // end master
  //end catalog
};


function savePosabitsSettings(settings: CreatePosabitsProviderSettings) {
  const requestConfig = axiosRequestConfig();

  return axios
    .post(`${config.apiUrl}/menus/providers/posabits`, settings, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function saveTreezSettings(settings: CreateTreezProviderSettings) {
  const requestConfig = axiosRequestConfig();

  return axios
    .post(`${config.apiUrl}/menus/providers/treez`, settings, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function listPosabitsProviderSettings() {
  const requestConfig = axiosRequestConfig();

  return axios
    .get(`${config.apiUrl}/menus/providers/posabits`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function listTreezProviderSettings() {
  const requestConfig = axiosRequestConfig();

  return axios
    .get(`${config.apiUrl}/menus/providers/treez`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getPosabitsProviderSettings(businessId: number) {
  const requestConfig = axiosRequestConfig();

  return axios
    .get(
      `${config.apiUrl}/menus/providers/posabits/${businessId}`,
      requestConfig
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getTreezProviderSettings(businessId: number) {
  const requestConfig = axiosRequestConfig();

  return axios
    .get(`${config.apiUrl}/menus/providers/treez/${businessId}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getTaxes(businessId: number) {
  const requestConfig = axiosRequestConfig();

  return axios
    .get(`${config.apiUrl}/menus/${businessId}/tax`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function createTax(setting: CreateTaxSetting) {
  const requestConfig = axiosRequestConfig();

  return axios
    .post(
      `${config.apiUrl}/menus/${setting.businessId}/tax`,
      setting,
      requestConfig
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function updateTax(setting: UpdateTaxSetting) {
  const requestConfig = axiosRequestConfig();

  return axios
    .patch(
      `${config.apiUrl}/menus/${setting.businessId}/tax`,
      setting,
      requestConfig
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function deleteTax(id: number) {
  const requestConfig = axiosRequestConfig();

  return axios
    .delete(`${config.apiUrl}/menus/tax/${id}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getSchedule(id: string) {
  const requestConfig = axiosRequestConfig();

  return axios
    .get(`${config.ecommerceApiUrl}/scheduler/${id}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getSchedules(queryString: string) {
  const requestConfig = axiosRequestConfig();

  return axios
    .get(`${config.apiUrl}/scheduler?${queryString}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function addSchedule(schedule: Schedule) {
  const requestConfig = axiosRequestConfig();

  return axios
    .post(`${config.apiUrl}/scheduler`, schedule, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function updateSchedule(schedule: UpdateSchedule) {
  const requestConfig = axiosRequestConfig();

  return axios
    .patch(
      `${config.apiUrl}/scheduler/${schedule.id}`,
      schedule,
      requestConfig
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function deleteSchedule(id: string) {
  const requestConfig = axiosRequestConfig();

  return axios
    .delete(`${config.ecommerceApiUrl}/scheduler/${id}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function addSpecial(special: Special, key: string) {
  const requestConfig = axiosRequestConfig();
  requestConfig.headers["x-api-key"] = key;
  return axios
    .post(`${config.apiUrl}/specials`, { ...special, businessId: +special.businessId }, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function addSpecialImage(id: string, image: File, key: string) {
  const requestConfig = axiosRequestConfig(true);
  requestConfig.headers["x-api-key"] = key;
  let formData = new FormData();
  formData.append("image", image)
  return axios
    .post(`${config.apiUrl}/specials/${id}/image`, formData, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError)

}

function deleteSpecial(specialId: string, key: string) {
  const requestConfig = axiosRequestConfig();
  requestConfig.headers["x-api-key"] = key;
  return axios
    .delete(`${config.apiUrl}/specials/${specialId}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function editSpecial(specialId: string, special: Special, key: string) {
  const requestConfig = axiosRequestConfig();
  requestConfig.headers["x-api-key"] = key;
  return axios
    .patch(`${config.apiUrl}/specials/${specialId}`, special, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getBusinessSpecials(businessId: string | number, key: string) {
  const requestConfig = axiosRequestConfig();
  requestConfig.headers["x-api-key"] = key;
  return axios.get(`${config.apiUrl}/specials/business/${businessId}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function editBusinessSpecialConfig(businessId: string | number, specialConfig: any, key: string) {
  const requestConfig = axiosRequestConfig();
  requestConfig.headers["x-api-key"] = key;
  return axios.patch(`${config.apiUrl}/specials/${businessId}/config`, specialConfig, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getBusinessSpecialConfig(businessId: string | number, key: string) {
  const requestConfig = axiosRequestConfig();
  requestConfig.headers["x-api-key"] = key;
  return axios.get(`${config.apiUrl}/specials/${businessId}/config`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getSpecialById(specialId: string, key: string) {
  const requestConfig = axiosRequestConfig();
  requestConfig.headers["x-api-key"] = key;
  return axios.get(`${config.apiUrl}/specials/${specialId}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getProductsById(businessId: any) {
  const requestConfig = axiosRequestConfig();
  return axios.get(`${config.apiUrl}/inventory-management/products/provider?businessId=${businessId}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function updateProductDetails(product: any) {
  const requestConfig = axiosRequestConfig();
  return axios.patch(`${config.apiUrl}/inventory-management/products/update/${product.id}`, product, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getProductDetails(productId: any) {
  const requestConfig = axiosRequestConfig();
  return axios.get(`${config.apiUrl}/inventory-management/products/${productId}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function fetchProductLog(productId: string) {
  const requestConfig = axiosRequestConfig();
  return axios.get(`${config.apiUrl}/inventory-management/products/fetch-product-log/${productId}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function pruneEcommerceProducts(businessId: number) {
  const requestConfig = axiosRequestConfig();
  return axios.post(`${config.apiUrl}/inventory-management/products/index/prune?businessId=${businessId}`, {}, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function syncEcommerceProducts(businessId: number) {
  const requestConfig = axiosRequestConfig();
  return axios.post(`${config.apiUrl}/integrations/menu/${businessId}/sync`, {}, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function addCollection(collection: any, key: string) {
  const requestConfig = axiosRequestConfig();
  requestConfig.headers["x-api-key"] = key;
  return axios
    .post(`${config.apiUrl}/collections`, collection, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getBusinessCollections(businessId: string | number, key: string) {
  const requestConfig = axiosRequestConfig();
  requestConfig.headers["x-api-key"] = key;
  return axios.get(`${config.apiUrl}/collections/business/${businessId}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getCollectionById(collectionId: string, key: string) {
  const requestConfig = axiosRequestConfig();
  requestConfig.headers["x-api-key"] = key;
  return axios.get(`${config.apiUrl}/collections/${collectionId}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getMappings(businessId: number): Promise<Mapping[]> {
  const requestConfig = axiosRequestConfig();

  return axios.get(`${config.apiUrl}/integrations/mappings/${businessId}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getCategoryMappings(businessId: number): Promise<Mapping[]> {
  const requestConfig = axiosRequestConfig();

  return axios.get(`${config.apiUrl}/integrations/mappings/${businessId}/categories`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch((e) => {
      if (e.response && e.response.data.message === "POS_CREDENTIALS_NOT_FOUND") {
        return [];
      }

      return apiHelper.handleError(e);
    });
}

function createMappings(payload: CreateMapping[]): Promise<Mapping[]> {
  const requestConfig = axiosRequestConfig();

  return axios.post(`${config.apiUrl}/integrations/mappings`, payload, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/// Batch Catalog Endpoints
function getCatalogBatchProducts(queryString: string): Promise<GetCatalogBatchProductListResponse> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/batch/products?${queryString}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}
function getCatalogBatchProductDetail(id: string): Promise<getCatalogBatchProductDetailResponse> {
  const requestConfig = axiosRequestConfig();

  return axios
    .get(`${config.apiUrl}/catalog/batch/products/${id}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function updateCatalogBatchProduct(catalogBatchProductId: string, request: UpdateCatalogBatchProductRequest) {
  const requestConfig = axiosRequestConfig();

  return axios
    .patch(`${config.apiUrl}/catalog/batch/products/${catalogBatchProductId}`, request, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function createCatalogBatchProduct(request: CreateCatalogBatchProductRequest) {
  const requestConfig = axiosRequestConfig();

  return axios
    .post(`${config.apiUrl}/catalog/batch/products`, request, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function searchCatalogBatchStrains(queryString: string): Promise<GetCatalogBatchStrainListResponse> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/batch/strains?${queryString}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getCatalogBatchStrainById(id: string): Promise<GetCatalogBatchStrainListResponse> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/batch/strains/${id}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function searchCatalogBatchBrands(queryString: string): Promise<GetCatalogBatchBrandListResponse> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/batch/brands?${queryString}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getCatalogBatchBrandById(id: string): Promise<GetCatalogBatchBrandListResponse> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/batch/brands/${id}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/// Master Catalog endpoints
//master brands
function getCatalogMasterBrandById(id: string): Promise<CatalogMasterBrandDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/brands/${id}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getCatalogMasterBrandSearch(queryString: string): Promise<CatalogMasterBrandSearchDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/brands/search?${queryString}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function updateCatalogMasterBrand(imageFile: Blob, request: UpdateCatalogMasterBrandDto): Promise<CatalogMasterBrandDto> {
  const requestConfig = axiosRequestConfig(true);
  let formData = new FormData();
  if (imageFile) formData.append("imageFile", imageFile);
  formData.append("request", JSON.stringify(request));
  return axios
    .post(`${config.apiUrl}/catalog/master/brands/${request.id}`, formData, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function createCatalogMasterBrand(imageFile: Blob, request: CreateCatalogMasterBrandDto): Promise<CatalogMasterBrandDto> {
  const requestConfig = axiosRequestConfig(true);
  let formData = new FormData();
  if (imageFile) formData.append("imageFile", imageFile);
  formData.append("request", JSON.stringify(request));
  return axios
    .post(`${config.apiUrl}/catalog/master/brands`, formData, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getCatalogMasterBrandUploadSearch(queryString: string): Promise<CatalogMasterBrandUploadSearchDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/brands/uploads/search?${queryString}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getCatalogMasterBrandUploadRowSearch(brandUploadId: string, queryString: string): Promise<CatalogMasterBrandUploadRowSearchDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/brands/uploads/${brandUploadId}/rows/search?${queryString}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function uploadCatalogMasterBrandCsv(file: Blob): Promise<CatalogMasterBrandUploadDto> {
  const requestConfig = axiosRequestConfig(true);
  let formData = new FormData();
  formData.append("file", file)
  return axios
    .post(`${config.apiUrl}/catalog/master/brands/uploads/csv`, formData, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function updateCatalogMasterBrandUploadRow(request: UpdateCatalogMasterBrandUploadRowDto): Promise<CatalogMasterBrandUploadRowDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .patch(`${config.apiUrl}/catalog/master/brands/uploads/${request.brandUploadId}/rows/${request.id}`, request, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function commitCatalogMasterBrandUpload(request: UpdateCatalogMasterBrandsFromUploadDto): Promise<CatalogMasterBrandUploadDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .post(`${config.apiUrl}/catalog/master/brands/uploads/${request.brandUploadId}/commit`, request, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}
//end master brands
//master products
function getCatalogMasterProductById(id: string): Promise<CatalogMasterProductDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/products/${id}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getCatalogMasterProductSearch(queryString: string): Promise<CatalogMasterProductSearchDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/products/search?${queryString}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function updateCatalogMasterProduct(imageFile: Blob, request: UpdateCatalogMasterProductDto): Promise<CatalogMasterProductDto> {
  const requestConfig = axiosRequestConfig(true);
  const formData = new FormData();
  if (imageFile) formData.append("imageFile", imageFile);
  formData.append("request", JSON.stringify(request));
  return axios
    .post(`${config.apiUrl}/catalog/master/products/${request.id}`, formData, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function createCatalogMasterProduct(imageFile: Blob, request: CreateCatalogMasterProductDto): Promise<CatalogMasterProductDto> {
  const requestConfig = axiosRequestConfig(true);
  const formData = new FormData();
  if (imageFile) { formData.append("imageFile", imageFile); }
  formData.append("request", JSON.stringify(request));
  return axios
    .post(`${config.apiUrl}/catalog/master/products`, formData, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getCatalogMasterProductUploadSearch(queryString: string): Promise<CatalogMasterProductUploadSearchDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/products/uploads/search?${queryString}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getCatalogMasterProductUploadRowSearch(productUploadId: string, queryString: string): Promise<CatalogMasterProductUploadRowSearchDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/products/uploads/${productUploadId}/rows/search?${queryString}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function uploadCatalogMasterProductCsv(file: Blob, brandId: string): Promise<CatalogMasterProductUploadDto> {
  const requestConfig = axiosRequestConfig(true);
  let formData = new FormData();
  formData.append("file", file);
  formData.append("brandId", brandId);
  return axios
    .post(`${config.apiUrl}/catalog/master/products/uploads/csv`, formData, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function updateCatalogMasterProductUploadRow(request: UpdateCatalogMasterProductUploadRowDto): Promise<CatalogMasterProductUploadRowDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .patch(`${config.apiUrl}/catalog/master/products/uploads/${request.productUploadId}/rows/${request.id}`, request, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function commitCatalogMasterProductUpload(request: UpdateCatalogMasterProductsFromUploadDto): Promise<CatalogMasterProductUploadDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .post(`${config.apiUrl}/catalog/master/products/uploads/${request.productUploadId}/commit`, request, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}
//end master products
//master category
function getCatalogMasterCategoryById(id: string): Promise<CatalogMasterCategoryDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/categories/${id}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getCatalogMasterCategorySearch(queryString: string): Promise<CatalogMasterCategorySearchDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/categories/search?${queryString}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function updateCatalogMasterCategory(request: UpdateCatalogMasterCategoryDto): Promise<CatalogMasterCategoryDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .patch(`${config.apiUrl}/catalog/master/categories/${request.id}`, request, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function createCatalogMasterCategory(request: CreateCatalogMasterCategoryDto): Promise<CatalogMasterCategoryDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .post(`${config.apiUrl}/catalog/master/categories`, request, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}
//end master category
//master subcategory
function getCatalogMasterSubcategoryById(id: string): Promise<CatalogMasterSubcategoryDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/subcategories/${id}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getCatalogMasterSubcategorySearch(queryString: string): Promise<CatalogMasterSubcategorySearchDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/subcategories/search?${queryString}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function updateCatalogMasterSubcategory(request: UpdateCatalogMasterSubcategoryDto): Promise<CatalogMasterSubcategoryDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .patch(`${config.apiUrl}/catalog/master/subcategories/${request.id}`, request, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function createCatalogMasterSubcategory(request: CreateCatalogMasterSubcategoryDto): Promise<CatalogMasterSubcategoryDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .post(`${config.apiUrl}/catalog/master/subcategories`, request, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}
//end master subcategory
//master effects
function getCatalogMasterEffectById(id: string): Promise<CatalogMasterEffectDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/effects/${id}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getCatalogMasterEffectSearch(queryString: string): Promise<CatalogMasterEffectSearchDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/effects/search?${queryString}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function updateCatalogMasterEffect(request: UpdateCatalogMasterEffectDto): Promise<CatalogMasterEffectDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .patch(`${config.apiUrl}/catalog/master/effects/${request.id}`, request, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function createCatalogMasterEffect(request: CreateCatalogMasterEffectDto): Promise<CatalogMasterEffectDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .post(`${config.apiUrl}/catalog/master/effects`, request, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}
//end master effects
//master terpenes
function getCatalogMasterTerpeneById(id: string): Promise<CatalogMasterTerpeneDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/terpenes/${id}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getCatalogMasterTerpeneSearch(queryString: string): Promise<CatalogMasterTerpeneSearchDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/terpenes/search?${queryString}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function updateCatalogMasterTerpene(imageFile: Blob, request: UpdateCatalogMasterTerpeneDto): Promise<CatalogMasterTerpeneDto> {
  const requestConfig = axiosRequestConfig(true);
  let formData = new FormData();
  if (imageFile) formData.append("imageFile", imageFile);
  formData.append("request", JSON.stringify(request));
  return axios
    .post(`${config.apiUrl}/catalog/master/terpenes/${request.id}`, formData, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function createCatalogMasterTerpene(imageFile: Blob, request: CreateCatalogMasterTerpeneDto): Promise<CatalogMasterTerpeneDto> {
  const requestConfig = axiosRequestConfig(true);
  let formData = new FormData();
  if (imageFile) formData.append("imageFile", imageFile);
  formData.append("request", JSON.stringify(request));
  return axios
    .post(`${config.apiUrl}/catalog/master/terpenes`, formData, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}
//end master terpenes
//master cannabinoids
function getCatalogMasterCannabinoidById(id: string): Promise<CatalogMasterCannabinoidDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/cannabinoids/${id}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getCatalogMasterCannabinoidSearch(queryString: string): Promise<CatalogMasterCannabinoidSearchDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/cannabinoids/search?${queryString}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function updateCatalogMasterCannabinoid(imageFile: Blob, request: UpdateCatalogMasterCannabinoidDto): Promise<CatalogMasterCannabinoidDto> {
  const requestConfig = axiosRequestConfig(true);
  let formData = new FormData();
  if (imageFile) formData.append("imageFile", imageFile);
  formData.append("request", JSON.stringify(request));
  return axios
    .post(`${config.apiUrl}/catalog/master/cannabinoids/${request.id}`, formData, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function createCatalogMasterCannabinoid(imageFile: Blob, request: CreateCatalogMasterCannabinoidDto): Promise<CatalogMasterCannabinoidDto> {
  const requestConfig = axiosRequestConfig(true);
  let formData = new FormData();
  if (imageFile) formData.append("imageFile", imageFile);
  formData.append("request", JSON.stringify(request));
  return axios
    .post(`${config.apiUrl}/catalog/master/cannabinoids`, formData, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}
//end master cannabinoids
//master strain-types
function getCatalogMasterStrainTypeById(id: string): Promise<CatalogMasterStrainTypeDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/strain-types/${id}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getCatalogMasterStrainTypeSearch(queryString: string): Promise<CatalogMasterStrainTypeSearchDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .get(`${config.apiUrl}/catalog/master/strain-types/search?${queryString}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function updateCatalogMasterStrainType(request: UpdateCatalogMasterStrainTypeDto): Promise<CatalogMasterStrainTypeDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .patch(`${config.apiUrl}/catalog/master/strain-types/${request.id}`, request, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function createCatalogMasterStrainType(request: CreateCatalogMasterStrainTypeDto): Promise<CatalogMasterStrainTypeDto> {
  const requestConfig = axiosRequestConfig();
  return axios
    .post(`${config.apiUrl}/catalog/master/strain-types`, request, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}
//end master cannabinoids