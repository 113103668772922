import { memo } from 'react';
import { formatting } from '../../helpers';
import { locationService } from '../../services';
import { BusinessRating } from '.';
import greenMarkerIcon from '../../assets/green-marker.svg';
import blueMarkerIcon from '../../assets/blue-marker.svg';
import { useTheme } from '@emotion/react';

function MapMarker({ isHovered, isActive, business, singleItem, handleMarkerClick, $hover, ...props }) {
    const theme = useTheme();
    const businessStateName = locationService.getStateNameByAbbr(business.stateAbbr);
    const urlStateName = businessStateName && formatting.getUrlFriendlyName(businessStateName);
    const urlCityName = formatting.getUrlFriendlyName(business.city);
    const profileUrl = `https://dev.purplewaze.com/dispensary/us/${urlStateName}/${urlCityName}/${business.searchUrlFragment}`;

    const markerStyle = {
        position: 'absolute',
        transform: 'translate(-50%, -100%)',
        width: 30,
        zIndex: props.isCurrentBusiness ? theme.zIndex.drawer + 104 : theme.zIndex.drawer + 103,
    };

    const markerHoveredStyle = {
        zIndex: 3,
    };

    const infoBoxStyle = {
        display: 'none',
        padding: '1rem',
        borderRadius: '4px',
        background: 'lightgray',
        position: 'absolute',
        transform: 'translateX(-50%)',
        bottom: 45,
        width: 'auto',
        minWidth: 150,
    };

    const showInfoStyle = {
        display: 'block',
        zIndex: 3,
    };

    const infoBoxTitleStyle = {
        fontSize: 14,
        fontWeight: 500,
        display: 'block',
        marginBottom: 5,
    };

    return (
        <>
            {business && (
                <div
                    style={{
                        ...infoBoxStyle,
                        ...(($hover || isHovered || props.showInfoBox) && showInfoStyle),
                    }}
                >
                    <span style={infoBoxTitleStyle}>{business.name}</span>
                    <BusinessRating business={business} noPrice size="small" />
                </div>
            )}

            {singleItem ? (
                <div
                    style={{ ...markerStyle }}
                    onClick={() => {
                        handleMarkerClick(business);
                    }}
                >
                    <img src={!isActive ? blueMarkerIcon : greenMarkerIcon} alt="Map Marker" style={{ width: '100%', display: 'block' }} />
                </div>
            ) : (
                <a
                    style={{ ...markerStyle, ...(($hover || isHovered) && markerHoveredStyle) }}
                    href={profileUrl}
                    target="_blank"
                >
                    <img
                        src={$hover || isHovered ? greenMarkerIcon : props.isCurrentBusiness ? greenMarkerIcon : blueMarkerIcon}
                        alt="Map Marker"
                        style={{ width: '100%', display: 'block' }}
                    />
                </a>
            )}
        </>
    );
}

export const BusinessMapMarker = memo(MapMarker);