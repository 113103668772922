import { Typography, Button, Box } from "@mui/material";

interface NoProductsFoundProps {
  isProductDetailsPage: boolean;
}

export const NoProductsFound = ({
  isProductDetailsPage,
}: NoProductsFoundProps) => {
  return isProductDetailsPage ? (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      textAlign="center"
      sx={{ p: 3 }}
    >
      <Typography variant="h6" gutterBottom>
        Product details not found for this product.
      </Typography>

      <Typography variant="body2" color="textSecondary">
        If this is an error, please contact Rank Really High Support.
      </Typography>

      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={() => window.location.reload()}
      >
        Refresh
      </Button>
    </Box>
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      textAlign="center"
      sx={{ p: 3 }}
    >
      <Typography variant="h6" gutterBottom>
        No products found.
      </Typography>

      <Typography variant="body2" color="textSecondary">
        If this is an error, please contact Rank Really High Support.
      </Typography>

      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={() => window.location.reload()}
      >
        Refresh
      </Button>
    </Box>
  );
};
