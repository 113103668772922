// material-ui
import { Box, Grid } from "@mui/material";

// project import
import PageNavigationBar from "./shared/PageNavigationBar";

const SettingsContainer = ({ children, ...props }) => {
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      {/* row 1 */}
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12}>
            {props.navigationObject && (
              <PageNavigationBar
                navigationObject={props.navigationObject}
                activeTab={props.activeTab}
                onTabChange={props.onTabChange}
              />
            )}
          </Grid>
        </Grid>
        <Box>{children}</Box>
      </Grid>
    </Grid>
  );
};

export default SettingsContainer;
