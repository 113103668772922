import React from "react";
import { useLocation } from "react-router-dom";
import { NavItem } from "../types";
import {
  ApiOutlined,
  ClockCircleOutlined,
  DesktopOutlined,
  HomeOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { USER_ROLES } from "constants/user.constants";

// icons
const icons = {
  ApiOutlined,
  ClockCircleOutlined,
  DesktopOutlined,
  HomeOutlined,
  TeamOutlined
};

export const useGeneralNav = () => {
  const [activeItem, setActiveItem] = React.useState<NavItem | null>(null);
  const [items, ] = React.useState<NavItem[]>([
    {
      id: "businessesGroup",
      title: "Businesses",
      type: "item",
      url: "/businesses",
      icon: icons.HomeOutlined,
      breadcrumbs: true,
      userRoles: []
    },
    {
      id: "organizations",
      title: "Organizations",
      type: "item",
      url: "/organizations",
      icon: icons.TeamOutlined,
      breadcrumbs: true,
      userRoles: []
    },
    {
        id: "adminHome",
        title: "Admin",
        type: "item",
        url: "/admin",
        icon: icons.HomeOutlined,
        breadcrumbs: true,
        userRoles: [USER_ROLES.ADMIN]
      },
  ]);
  const {pathname} = useLocation();

  React.useEffect(() => {
   

  }, [pathname, items]);

  return {
    activeItem: activeItem,
    navItems: items,
  };
} 