import logoLightMode from "../../assets/rrh-icon-dark-background.webp";

const LogoBadge = () => {
  const logoStyles = {
    height: "36px",
    width: "36px",
    minWidth: "36px",
    fontSize: "20px",
    lineHeight: "36px",
    whiteSpace: "nowrap",
    backgroundImage: `url(${logoLightMode})`,
    backgroundSize: "cover",
    boxShadow:
      "0 1px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
  };

  return <div style={logoStyles}></div>;
};

export default LogoBadge;
