import { JSXElementConstructor } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { UserRole } from "types/user";
import selectLoadingStatus from "../redux/selectors/loading.selector";
import Loader from "../components/Loader";

interface PrivateRoutesProps {
  roles?: UserRole[];
  layoutComponent: JSXElementConstructor<any>;
}

export function PrivateRoutes({
  roles,
  layoutComponent: Component,
}: PrivateRoutesProps) {
  const user = useAppSelector((state) => state.auth.user);
  const isLoading = useSelector(selectLoadingStatus);
  if (user && (!roles || roles.includes(user.role))) {
    return (
      <>
        {isLoading && <Loader />}
        <Component>
          <Outlet />
        </Component>
      </>
    );
  }

  return <Navigate to="/login" />;
}
