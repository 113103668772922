import { useCallback, useEffect, useState } from "react";
import Business from "types/business";
import { Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import {
  daysOfWeek,
  formatDisplayTime,
  timeToMinutes,
  toTitleCase,
} from "./store-open-closed.helpers";

interface StoreOpenClosedProps {
  business: Business;
}

const StatusContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const OpenClosedIndicator = styled(Typography)<{ isOpen: boolean }>(
  ({ theme, isOpen }) => ({
    fontWeight: 500,
    color: isOpen ? theme.palette.success.main : theme.palette.error.main,
  }),
);

const AdditionalInfo = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginLeft: theme.spacing(1),
}));

export function StoreOpenClosed({ business, ...props }: StoreOpenClosedProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState("");

  const checkIfOpen = useCallback(
    (date: Date) => {
      if (!business.hours || business.hours.length === 0) {
        setIsOpen(false);
        setStatus("Hours not available");
        return;
      }

      const now = date;
      const todayIndex = now.getDay();
      const currentDay = daysOfWeek[now.getDay()];
      const currentTimeInMinutes = now.getHours() * 60 + now.getMinutes();
      const formattedHours = business.hours;
      const todayHours = formattedHours.find(
        (day) => day.day.toUpperCase() === currentDay,
      ) || {
        day: currentDay,
        open: "0000",
        close: "0000",
        isClosed: true,
        dayIndex: -1,
      };
      const yesterdayIndex = (todayHours.dayIndex - 1 + 7) % 7;
      const yesterdayHours = formattedHours.find(
        (day) => day.dayIndex === yesterdayIndex,
      ) || {
        day: daysOfWeek[yesterdayIndex],
        open: "0000",
        close: "0000",
        isClosed: true,
        dayIndex: yesterdayIndex,
      };

      // Check if open from yesterday's extension
      if (!yesterdayHours.isClosed) {
        const yesterdayCloseTimeInMinutes = timeToMinutes(yesterdayHours.close);
        const yesterdayOpenTimeInMinutes = timeToMinutes(yesterdayHours.open);

        if (
          yesterdayCloseTimeInMinutes <= yesterdayOpenTimeInMinutes &&
          (todayIndex + 7 - yesterdayIndex) % 7 === 1 &&
          currentTimeInMinutes < yesterdayCloseTimeInMinutes
        ) {
          setIsOpen(true);
          setStatus(
            `Open • Closes at ${formatDisplayTime(yesterdayHours.close)}`,
          );
          return;
        }
      }

      // Check today's hours
      if (!todayHours.isClosed) {
        const openTimeInMinutes = timeToMinutes(todayHours.open);
        let closeTimeInMinutes = timeToMinutes(todayHours.close);

        // Adjust close time if it's after midnight
        if (closeTimeInMinutes <= openTimeInMinutes) {
          closeTimeInMinutes += 1440; // Add 24 hours
        }

        if (
          currentTimeInMinutes >= openTimeInMinutes &&
          currentTimeInMinutes < closeTimeInMinutes
        ) {
          setIsOpen(true);
          setStatus(`Open • Closes at ${formatDisplayTime(todayHours.close)}`);
        } else if (currentTimeInMinutes < openTimeInMinutes) {
          setIsOpen(false);
          setStatus(
            `Closed • Opens today at ${formatDisplayTime(todayHours.open)}`,
          );
        } else {
          setIsOpen(false);
          const nextDay = (now.getDay() + 1) % 7;
          const nextDayHours = formattedHours.find(
            (day) => day.dayIndex === nextDay,
          );
          if (nextDayHours && !nextDayHours.isClosed) {
            setStatus(
              `Closed • Opens ${nextDayHours.day} at ${formatDisplayTime(nextDayHours.open)}`,
            );
          } else {
            const nextOpenDay =
              formattedHours.find(
                (day) => !day.isClosed && day.dayIndex > todayHours.dayIndex,
              ) || formattedHours.find((day) => !day.isClosed);
            if (nextOpenDay) {
              setStatus(
                `Closed • Opens ${toTitleCase(nextOpenDay.day)} at ${formatDisplayTime(nextOpenDay.open)}`,
              );
            } else {
              setStatus("Closed");
            }
          }
        }
      } else {
        setIsOpen(false);
        const nextOpenDay =
          formattedHours.find(
            (day) => !day.isClosed && day.dayIndex > todayHours.dayIndex,
          ) || formattedHours.find((day) => !day.isClosed);
        if (nextOpenDay) {
          setStatus(
            `Closed • Opens ${toTitleCase(nextOpenDay.day)} at ${formatDisplayTime(nextOpenDay.open)}`,
          );
        } else {
          setStatus("Closed");
        }
      }
    },
    [business],
  );
  console.log(business);
  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      checkIfOpen(now);
    };
    updateTime(); // Initial check
    const interval = setInterval(updateTime, 60000);
    return () => clearInterval(interval);
  }, [business, checkIfOpen]);

  const [statusIndicator, statusInfo] = status.split("•");

  return (
    <StatusContainer>
      <OpenClosedIndicator variant="body1" isOpen={isOpen}>
        {statusIndicator.trim()}
      </OpenClosedIndicator>
      {statusInfo && (
        <AdditionalInfo variant="body2">•{statusInfo}</AdditionalInfo>
      )}
    </StatusContainer>
  );
}
