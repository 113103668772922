import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

interface TagAutocompleteProps {
  options: string[];
  label: string;
  placeholder: string;
  value: string[];
  onChange: (event: React.SyntheticEvent, value: string[]) => void;
}

const TagAutocomplete: React.FC<TagAutocompleteProps> = ({ options, label, placeholder, value, onChange }) => {
  return (
    <Autocomplete
      multiple
      freeSolo
      options={options}
      value={value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label} placeholder={placeholder} />
      )}
    />
  );
};

export default TagAutocomplete;
