import { InfoOutlined } from "@mui/icons-material";
import { FormHelperText, IconButton, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TransitionAlerts from "components/shared/TransitionAlert";
import InputField from "components/shared/special/InputField";
import { useField, useFormikContext } from "formik";
import { at } from "lodash";
import { useState } from "react";
import { AlertType } from "types/alertTypes";

const CollectionDiscountType = ({ index }) => {
  const formik = useFormikContext();
  const [transitionOpen, setTransitionOpen] = useState(false);
  const [, selectMeta, helpers] = useField(`discounts[${index}].type`);
  const [, valueMeta] = useField(`discounts[${index}].value`);

  function _renderHelperText(meta) {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return <FormHelperText error>{error}</FormHelperText>;
    }
  }

  const _renderSelect = () => (
    <Select
      labelId={`currency-type-label-${index}`}
      id={`discounts[${index}].type`}
      name={`discounts[${index}].type`}
      error={Boolean(_renderHelperText(selectMeta))}
      onChange={(e) => helpers.setValue(e.target.value)}
      value={formik.values.discounts[index].type}
      renderValue={(selected) => {
        if (selected === "DOLLAR_AMOUNT" || selected === "TARGET_PRICE") {
          return "$";
        } else if (selected === "PERCENTAGE") {
          return "%";
        } else {
          return null;
        }
      }}
      MenuProps={{
        style: {
          zIndex: 2000,
        },
      }}
    >
      <MenuItem value="DOLLAR_AMOUNT">This item for a dollar amount off</MenuItem>
      <MenuItem value="PERCENTAGE">This item for a percentage off</MenuItem>
      <MenuItem value="TARGET_PRICE">This item for a specific price</MenuItem>
    </Select>
  );

  const _renderInputField = () => (
    <InputField
      id={`discounts[${index}].value`}
      variant="outlined"
      label="Discount Type"
      name={`discounts[${index}].value`}
      type="number"
      inputProps={{
        style: {
          paddingTop: 17,
          paddingBottom: 17,
        },
      }}
      InputProps={{
        style: {
          paddingLeft: 0,
        },
        startAdornment: (
          <InputAdornment position="end" sx={{ border: 'none' }}>
            <Select
              labelId={`currency-type-label-${index}`}
              id={`discounts[${index}].type`}
              name={`discounts[${index}].type`}
              error={Boolean(_renderHelperText(selectMeta))}
              onChange={formik.handleChange}
              value={formik.values.discounts[index].type}
              renderValue={(selected) => {
                if (selected === "DOLLAR_AMOUNT" || selected === "TARGET_PRICE") {
                  return "$";
                } else if (selected === "PERCENTAGE") {
                  return "%";
                } else {
                  return null;
                }
              }}
              MenuProps={{
                style: {
                  zIndex: 2000,
                },
              }}
              sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 } }} // Remove border from Select component
            >
              <MenuItem value="DOLLAR_AMOUNT">This item for a dollar amount off</MenuItem>
              <MenuItem value="PERCENTAGE">This item for a percentage off</MenuItem>
              <MenuItem value="TARGET_PRICE">This item for a specific price</MenuItem>
            </Select>
          </InputAdornment>
        ),
      }}
    />
  );

  return (
    <div>
      <div style={{ margin: "1rem 0" }}>
        <div style={{ display: "flex", alignItems: "center", margin: "1rem 0" }}>
          <Typography
            variant="body1"
            component="p"
            style={{ fontWeight: "bold", marginRight: "0.25rem" }}
          >
            Discount Value
          </Typography>
          <IconButton onClick={() => setTransitionOpen(!transitionOpen)} size="small" color="info">
            <InfoOutlined fontSize="small" />
          </IconButton>
        </div>
      </div>
      <div>
        <TransitionAlerts
          title="How to Choose the Discount Type"
          description="Select the discount type using the dropdown menu. You can choose from options like percentage off, dollar off, or item for a specific price. Once you've selected the type, enter the corresponding numeric value in the input field."
          type={AlertType.Info}
          transitionOpen={transitionOpen}
          setTransitionOpen={setTransitionOpen}
        />
      </div>
      {_renderInputField()}
      <div style={{ paddingLeft: "15px" }}>{_renderHelperText(selectMeta)}</div>
    </div>
  ); 
};

export default CollectionDiscountType;
