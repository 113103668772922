import React from 'react';

import {Tooltip } from "@mui/material";
import VerifiedIcon from '@mui/icons-material/VerifiedUser';


export function BusinessVerifiedIcon({ business, ...props }) {
  

  if (business.isBusinessVerified || business.isJointClient) {
    return (
      <Tooltip title="Verified Business">
        <VerifiedIcon  fontSize="small" />
      </Tooltip>
    )
  }
  return null;
}
