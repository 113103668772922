// assets
import {
  ApiOutlined,
  ClockCircleOutlined,
  DesktopOutlined,
  HomeOutlined,
  TeamOutlined,
  DatabaseOutlined,
  CloudServerOutlined
} from "@ant-design/icons";
import { USER_ROLES } from "constants";

// icons
const icons = {
  ApiOutlined,
  ClockCircleOutlined,
  DesktopOutlined,
  HomeOutlined,
  TeamOutlined,
  DatabaseOutlined,
  CloudServerOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const admin = {
  id: "admin",
  title: "Admin",
  type: "group",
  children: [
    {
      id: "adminHome",
      title: "Admin",
      type: "item",
      url: "/admin",
      icon: icons.HomeOutlined,
      userRoles: [USER_ROLES.ADMIN]
    },
    {
      id: "users",
      title: "Users",
      type: "item",
      url: "/admin/users",
      icon: icons.TeamOutlined,
      breadcrumbs: true,
      userRoles: [USER_ROLES.ADMIN]
    },
    {
      id: "api",
      title: "Api",
      type: "item",
      url: "/admin/api",
      icon: icons.ApiOutlined,
      breadcrumbs: true,
      userRoles: [USER_ROLES.ADMIN]
    },
    {
      id: "mcc",
      title: "Manage Mccs",
      type: "item",
      url: "/admin/mcc",
      icon: icons.DesktopOutlined,
      breadcrumbs: true,
      userRoles: [USER_ROLES.ADMIN]
    },
    {
      id: "ecommerce-schedule",
      title: "Ecommerce Schedule",
      type: "item",
      url: "/admin/ecommerce/schedules",
      icon: icons.ClockCircleOutlined,
      breadcrumbs: true,
      userRoles: [USER_ROLES.ADMIN]
    },
  ],
};

export default admin;
