import { Box, Tab, Tabs, Tooltip } from '@mui/material';
import { useTheme } from '@emotion/react';


const PageNavigationBar = ({ navigationObject, onTabChange, activeTab }) => {
  const theme = useTheme();
  return (
    <Box borderBottom={1} borderColor="grey.300">
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {navigationObject.map((item, index) => (
          <Tooltip title={item.title}>
            <Box>
              <Tab
                key={index}
                label={item.label}
                value={index}
                disabled={item.disabled}
                onClick={() => onTabChange(index)}
                sx={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  '&.Mui-selected': {
                    color: theme.palette.mode === 'light' ? 'secondary.main' : 'secondary.contrastText',
                  },
                }}
              />
            </Box>
          </Tooltip>
        ))}
      </Tabs>
    </Box>
  );
};

export default PageNavigationBar
