import React from "react";
import { Typography, Box, Tooltip, Button } from "@mui/material";

import TagAutocomplete from "components/shared/tag-autocomplete/tag-autocomplete.component";
import UndoIcon from "components/shared/undo-icon.component";

const CustomTagAutocomplete = ({
  label,
  additionalContent,
  tooltipTitle,
  onRefreshClick,
  isRefreshDisabled,
  value,
  onChange,
  ...props
}: any) => {
  return (
    <Box display="flex" flexDirection="column" gap={0}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {/* Container for label and tooltip */}
        <Typography variant="body2" fontWeight="700" color="textSecondary">
          {label} {additionalContent && `(${additionalContent})`}
        </Typography>
        {tooltipTitle && onRefreshClick && (
          <Tooltip title={tooltipTitle}>
            <span>
              <Button
                onClick={onRefreshClick}
                disabled={isRefreshDisabled}
                color="primary"
                sx={{
                  minWidth: 30,
                  color: "#FF5789",
                }}
              >
                <UndoIcon fontSize="inherit" />
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
      <div>
        <TagAutocomplete
          options={[]}
          placeholder="Add tags"
          value={value}
          onChange={onChange}
          {...props}
        />
      </div>
    </Box>
  );
};

export default CustomTagAutocomplete;
