export default {
  formId: "specialForm",
  formField: {
    name: {
      name: "name",
      label: "Name*",
      requiredErrorMsg: "Name is required",
    },
    description: {
      name: "description",
      label: "Description (optional)",
    },
    tags: {
      name: "tags",
      label: "Tags",
    },
    terms: {
      name: "terms",
      label: "Terms",
    },
    schedule: {
      name: "schedule",
      label: "Scheduled Days*",
      requiredErrorMsg: "At least one day is required",
      options: [
        { key: "activeSunday", label: "Sunday" },
        { key: "activeMonday", label: "Monday" },
        { key: "activeTuesday", label: "Tuesday" },
        { key: "activeWednesday", label: "Wednesday" },
        { key: "activeThursday", label: "Thursday" },
        { key: "activeFriday", label: "Friday" },
        { key: "activeSaturday", label: "Saturday" },
      ],
    },
    startDate: {
      name: "startDate",
      label: "Start Date*",
      requiredErrorMsg: "Start date is required",
      invalidErrorMsg: "Start date is not valid",
    },
    endDate: {
      name: "endDate",
      label: "End Date*",
      requiredErrorMsg: "End date is required",
      invalidErrorMsg: "End date is not valid",
    },
    enabled: {
      name: "enabled",
      label: "Enabled",
    },
    priority: {
      name: "priority",
      label: "Priority",
      requiredErrorMsg: "Priority should have a value greater than 0",
    },
    promoCodeEnabled: {
      name: "promoCodeEnabled",
      label: "Use Promo Code",
    },
    promoCode: {
      name: "promoCode",
      label: "Promo Code",
      requiredErrorMsg: "Please enter a promo code",
    },
    promoCodeUsesPerUser: {
      name: "promoCodeUsesPerUser",
      label: "Promo Code Uses",
      invalidErrorMsg: "You must allow at least one use",
      requiredErrorMsg: "You must allow at least one use",
    },
    maxUsesPerCart: {
      name: "maxUsesPerCart",
      label: "Max Uses",
      invalidErrorMsg: "You must allow at least one use",
      requiredErrorMsg: "Max Uses is required",
    },
    photoFile: {
      name: "photoFile",
      label: "Image",
      description:
        "Select an image for your special. Uploaded images should be in JPEG or PNG formats with a size around 500 x 500 pixels.",
      invalidErrorMsg: "Please select a valid image",
    },
    photoUrl: {
      name: "photoUrl",
      label: "Photo Url",
    },
    menuTypes: {
      name: "menuTypes",
      label: "Available Menu Types*",
      requiredErrorMsg: "At least one menu type should be selected",
      options: [
        { key: "RECREATIONAL", label: "Recreational" },
        { key: "MEDICAL", label: "Medical" },
      ],
    },
    discounts: {
      name: "discounts",
      label: "Discounts",
    },
    bundleQualifiers: {
      name: "bundleQualifiers",
      label: "Bundle Qualifiers"
    },
    bundleRewards: {
      name: "bundleRewards",
      label: "Bundle Rewards"
    },
    requiredItemsInCart: {
      name: "requiredItemsInCart",
      label: "Required Items in Cart"
    },
    requiredTotalWeight: {
      name: "requiredTotalWeight",
      label: "Required Total Weight"
    },
    requiredTotalValue: {
      name: "requiredTotalValue",
      label: "Required Total Value"
    },
  },
};
