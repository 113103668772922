import { createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import { nativeEcommerceService } from "../services/nativeEcommerce.service";
import { CatalogMasterBrandDto, CatalogMasterBrandSearchDto, CreateCatalogMasterBrandDto, UpdateCatalogMasterBrandDto } from "../types/native-ecommerce/catalog-master/catalog-master-brand.dto";
import { CatalogMasterBrandUploadDto, CatalogMasterBrandUploadSearchDto, UpdateCatalogMasterBrandsFromUploadDto } from "../types/native-ecommerce/catalog-master/catalog-master-brand-upload.dto";
import { CatalogMasterBrandUploadRowDto, CatalogMasterBrandUploadRowSearchDto, UpdateCatalogMasterBrandUploadRowDto } from "../types/native-ecommerce/catalog-master/catalog-master-brand-upload-row.dto";
import { UpdateCatalogMasterProductUploadRowDto, CatalogMasterProductUploadRowDto, CatalogMasterProductUploadRowSearchDto } from "../types/native-ecommerce/catalog-master/catalog-master-product-upload-row.dto";
import { CatalogMasterProductUploadDto, CatalogMasterProductUploadSearchDto, UpdateCatalogMasterProductsFromUploadDto } from "../types/native-ecommerce/catalog-master/catalog-master-product-upload.dto";
import { CatalogMasterProductDto, CatalogMasterProductSearchDto, CreateCatalogMasterProductDto, UpdateCatalogMasterProductDto } from "../types/native-ecommerce/catalog-master/catalog-master-product.dto";
import { CatalogMasterCategoryDto, CatalogMasterCategorySearchDto, CreateCatalogMasterCategoryDto, UpdateCatalogMasterCategoryDto } from "../types/native-ecommerce/catalog-master/catalog-master-category.dto";
import { CatalogMasterCannabinoidDto, CatalogMasterCannabinoidSearchDto, CreateCatalogMasterCannabinoidDto, UpdateCatalogMasterCannabinoidDto } from "../types/native-ecommerce/catalog-master/catalog-master-cannabinoid.dto";
import { CatalogMasterEffectDto, CatalogMasterEffectSearchDto, CreateCatalogMasterEffectDto, UpdateCatalogMasterEffectDto } from "../types/native-ecommerce/catalog-master/catalog-master-effect.dto";
import { CatalogMasterStrainTypeDto, CatalogMasterStrainTypeSearchDto, CreateCatalogMasterStrainTypeDto, UpdateCatalogMasterStrainTypeDto } from "../types/native-ecommerce/catalog-master/catalog-master-strain-type.dto";
import { CatalogMasterSubcategoryDto, CatalogMasterSubcategorySearchDto, CreateCatalogMasterSubcategoryDto, UpdateCatalogMasterSubcategoryDto } from "../types/native-ecommerce/catalog-master/catalog-master-subcategory.dto";
import { CatalogMasterTerpeneDto, CatalogMasterTerpeneSearchDto, CreateCatalogMasterTerpeneDto, UpdateCatalogMasterTerpeneDto } from "../types/native-ecommerce/catalog-master/catalog-master-terpene.dto";
//brand thunks
export const getCatalogMasterBrand = createAsyncThunk(
  "nativeEcommerce/catalog/master/getBrandById",
  async (brandId: string) => {
    const response = await nativeEcommerceService.getCatalogMasterBrandById(
      brandId
    );
    return response as CatalogMasterBrandDto;
  }
);

export const getCatalogMasterBrands = createAsyncThunk(
  "nativeEcommerce/catalog/master/brandSearch",
  async (query: string) => {
    const response = await nativeEcommerceService.getCatalogMasterBrandSearch(
      query
    );
    return response as CatalogMasterBrandSearchDto;
  }
);

export const createCatalogMasterBrand = createAsyncThunk(
  "nativeEcommerce/catalog/master/brandCreate",
  async (payload: { imageFile: Blob, request: CreateCatalogMasterBrandDto }) => {
    const response = await nativeEcommerceService.createCatalogMasterBrand(
      payload.imageFile,
      payload.request
    );
    return response as CatalogMasterBrandDto;
  }
);

export const updateCatalogMasterBrand = createAsyncThunk(
  "nativeEcommerce/catalog/master/brandUpdate",
  async (payload: { imageFile: Blob, request: UpdateCatalogMasterBrandDto }) => {
    const response = await nativeEcommerceService.updateCatalogMasterBrand(
      payload.imageFile,
      payload.request
    );
    return response as CatalogMasterBrandDto;
  }
);

export const uploadCatalogMasterBrandsCsv = createAsyncThunk(
  "nativeEcommerce/catalog/master/brandUpload",
  async (file: Blob) => {
    const response = await nativeEcommerceService.uploadCatalogMasterBrandCsv(
      file
    );
    return response as CatalogMasterBrandUploadDto;
  }
);

export const updateCatalogMasterBrandUploadRow = createAsyncThunk(
  "nativeEcommerce/catalog/master/brandUploadRowUpdate",
  async (request: UpdateCatalogMasterBrandUploadRowDto) => {
    const response = await nativeEcommerceService.updateCatalogMasterBrandUploadRow(
      request
    );
    return response as CatalogMasterBrandUploadRowDto;
  }
);

export const getCatalogMasterBrandUploads = createAsyncThunk(
  "nativeEcommerce/catalog/master/brandUploadSearch",
  async (query: string) => {
    const response = await nativeEcommerceService.getCatalogMasterBrandUploadSearch(
      query
    );
    return response as CatalogMasterBrandUploadSearchDto;
  }
);

export const getCatalogMasterBrandUploadRows = createAsyncThunk(
  "nativeEcommerce/catalog/master/brandUploadRowSearch",
  async (payload: { brandUploadId: string, query: string }) => {
    const response = await nativeEcommerceService.getCatalogMasterBrandUploadRowSearch(
      payload.brandUploadId,
      payload.query
    );
    return response as CatalogMasterBrandUploadRowSearchDto;
  }
);
export const commitCatalogMasterBrandUpload = createAsyncThunk(
  "nativeEcommerce/catalog/master/brandUploadCommit",
  async (request: UpdateCatalogMasterBrandsFromUploadDto) => {
    const response = await nativeEcommerceService.commitCatalogMasterBrandUpload(
      request
    );
    return response as CatalogMasterBrandUploadDto;
  }
);
//end brand thunks

//product thunks
export const getCatalogMasterProduct = createAsyncThunk(
  "nativeEcommerce/catalog/master/getProductById",
  async (productId: string) => {
    const response = await nativeEcommerceService.getCatalogMasterProductById(
      productId
    );
    return response as CatalogMasterProductDto;
  }
);

export const getCatalogMasterProducts = createAsyncThunk(
  "nativeEcommerce/catalog/master/productSearch",
  async (query: string) => {
    const response = await nativeEcommerceService.getCatalogMasterProductSearch(
      query
    );
    return response as CatalogMasterProductSearchDto;
  }
);

export const createCatalogMasterProduct = createAsyncThunk(
  "nativeEcommerce/catalog/master/productCreate",
  async (payload: { imageFile: Blob, request: CreateCatalogMasterProductDto }) => {
    const response = await nativeEcommerceService.createCatalogMasterProduct(
      payload.imageFile,
      payload.request
    );
    return response as CatalogMasterProductDto;
  }
);

export const updateCatalogMasterProduct = createAsyncThunk(
  "nativeEcommerce/catalog/master/productUpdate",
  async (payload: { imageFile: Blob, request: UpdateCatalogMasterProductDto }) => {
    const response = await nativeEcommerceService.updateCatalogMasterProduct(
      payload.imageFile,
      payload.request
    );
    return response as CatalogMasterProductDto;
  }
);

export const uploadCatalogMasterProductsCsv = createAsyncThunk(
  "nativeEcommerce/catalog/master/productUpload",
  async (payload: { file: Blob, brandId: string }) => {
    const response = await nativeEcommerceService.uploadCatalogMasterProductCsv(
      payload.file,
      payload.brandId
    );
    return response as CatalogMasterProductUploadDto;
  }
);

export const updateCatalogMasterProductUploadRow = createAsyncThunk(
  "nativeEcommerce/catalog/master/productUploadRowUpdate",
  async (request: UpdateCatalogMasterProductUploadRowDto) => {
    const response = await nativeEcommerceService.updateCatalogMasterProductUploadRow(
      request
    );
    return response as CatalogMasterProductUploadRowDto;
  }
);

export const getCatalogMasterProductUploads = createAsyncThunk(
  "nativeEcommerce/catalog/master/productUploadSearch",
  async (query: string) => {
    const response = await nativeEcommerceService.getCatalogMasterProductUploadSearch(
      query
    );
    return response as CatalogMasterProductUploadSearchDto;
  }
);

export const getCatalogMasterProductUploadRows = createAsyncThunk(
  "nativeEcommerce/catalog/master/productUploadRowSearch",
  async (payload: { productUploadId: string, query: string }) => {
    const response = await nativeEcommerceService.getCatalogMasterProductUploadRowSearch(
      payload.productUploadId,
      payload.query
    );
    return response as CatalogMasterProductUploadRowSearchDto;
  }
);
export const commitCatalogMasterProductUpload = createAsyncThunk(
  "nativeEcommerce/catalog/master/productUploadCommit",
  async (request: UpdateCatalogMasterProductsFromUploadDto) => {
    const response = await nativeEcommerceService.commitCatalogMasterProductUpload(
      request
    );
    return response as CatalogMasterProductUploadDto;
  }
);
//end product thunks
//category thunks
export const getCatalogMasterCategory = createAsyncThunk(
  "nativeEcommerce/catalog/master/getCategoryById",
  async (categoryId: string) => {
    const response = await nativeEcommerceService.getCatalogMasterCategoryById(
      categoryId
    );
    return response as CatalogMasterCategoryDto;
  }
);

export const getCatalogMasterCategories = createAsyncThunk(
  "nativeEcommerce/catalog/master/categorySearch",
  async (query: string) => {
    const response = await nativeEcommerceService.getCatalogMasterCategorySearch(
      query
    );
    return response as CatalogMasterCategorySearchDto;
  }
);

export const createCatalogMasterCategory = createAsyncThunk(
  "nativeEcommerce/catalog/master/categoryCreate",
  async (request: CreateCatalogMasterCategoryDto) => {
    const response = await nativeEcommerceService.createCatalogMasterCategory(
      request
    );
    return response as CatalogMasterCategoryDto;
  }
);

export const updateCatalogMasterCategory = createAsyncThunk(
  "nativeEcommerce/catalog/master/categoryUpdate",
  async (request: UpdateCatalogMasterCategoryDto) => {
    const response = await nativeEcommerceService.updateCatalogMasterCategory(
      request
    );
    return response as CatalogMasterCategoryDto;
  }
);

//end category thunks
//subcategory thunks
export const getCatalogMasterSubcategory = createAsyncThunk(
  "nativeEcommerce/catalog/master/getSubcategoryById",
  async (subcategoryId: string) => {
    const response = await nativeEcommerceService.getCatalogMasterSubcategoryById(
      subcategoryId
    );
    return response as CatalogMasterSubcategoryDto;
  }
);

export const getCatalogMasterSubcategories = createAsyncThunk(
  "nativeEcommerce/catalog/master/subcategorySearch",
  async (query: string) => {
    const response = await nativeEcommerceService.getCatalogMasterSubcategorySearch(
      query
    );
    return response as CatalogMasterSubcategorySearchDto;
  }
);

export const createCatalogMasterSubcategory = createAsyncThunk(
  "nativeEcommerce/catalog/master/subcategoryCreate",
  async (request: CreateCatalogMasterSubcategoryDto) => {
    const response = await nativeEcommerceService.createCatalogMasterSubcategory(
      request
    );
    return response as CatalogMasterSubcategoryDto;
  }
);

export const updateCatalogMasterSubcategory = createAsyncThunk(
  "nativeEcommerce/catalog/master/subcategoryUpdate",
  async (request: UpdateCatalogMasterSubcategoryDto) => {
    const response = await nativeEcommerceService.updateCatalogMasterSubcategory(
      request
    );
    return response as CatalogMasterSubcategoryDto;
  }
);
//end subcategory thunks
//effect thunks
export const getCatalogMasterEffect = createAsyncThunk(
  "nativeEcommerce/catalog/master/getEffectById",
  async (effectId: string) => {
    const response = await nativeEcommerceService.getCatalogMasterEffectById(
      effectId
    );
    return response as CatalogMasterEffectDto;
  }
);

export const getCatalogMasterEffects = createAsyncThunk(
  "nativeEcommerce/catalog/master/effectSearch",
  async (query: string) => {
    const response = await nativeEcommerceService.getCatalogMasterEffectSearch(
      query
    );
    return response as CatalogMasterEffectSearchDto;
  }
);

export const createCatalogMasterEffect = createAsyncThunk(
  "nativeEcommerce/catalog/master/effectCreate",
  async (request: CreateCatalogMasterEffectDto) => {
    const response = await nativeEcommerceService.createCatalogMasterEffect(
      request
    );
    return response as CatalogMasterEffectDto;
  }
);

export const updateCatalogMasterEffect = createAsyncThunk(
  "nativeEcommerce/catalog/master/effectUpdate",
  async (request: UpdateCatalogMasterEffectDto) => {
    const response = await nativeEcommerceService.updateCatalogMasterEffect(
      request
    );
    return response as CatalogMasterEffectDto;
  }
);
//end effect thunks
//terpene thunks
export const getCatalogMasterTerpene = createAsyncThunk(
  "nativeEcommerce/catalog/master/getTerpeneById",
  async (terpeneId: string) => {
    const response = await nativeEcommerceService.getCatalogMasterTerpeneById(
      terpeneId
    );
    return response as CatalogMasterTerpeneDto;
  }
);

export const getCatalogMasterTerpenes = createAsyncThunk(
  "nativeEcommerce/catalog/master/terpeneSearch",
  async (query: string) => {
    const response = await nativeEcommerceService.getCatalogMasterTerpeneSearch(
      query
    );
    return response as CatalogMasterTerpeneSearchDto;
  }
);

export const createCatalogMasterTerpene = createAsyncThunk(
  "nativeEcommerce/catalog/master/terpeneCreate",
  async (payload: { imageFile: Blob, request: CreateCatalogMasterTerpeneDto}) => {
    const response = await nativeEcommerceService.createCatalogMasterTerpene(
      payload.imageFile,
      payload.request
    );
    return response as CatalogMasterTerpeneDto;
  }
);

export const updateCatalogMasterTerpene = createAsyncThunk(
  "nativeEcommerce/catalog/master/terpeneUpdate",
  async (payload: { imageFile: Blob, request: UpdateCatalogMasterTerpeneDto}) => {
    const response = await nativeEcommerceService.updateCatalogMasterTerpene(
      payload.imageFile,
      payload.request
    );
    return response as CatalogMasterTerpeneDto;
  }
);

//end terpene thunks
//cannabinoid thunks
export const getCatalogMasterCannabinoid = createAsyncThunk(
  "nativeEcommerce/catalog/master/getCannabinoidById",
  async (cannabinoidId: string) => {
    const response = await nativeEcommerceService.getCatalogMasterCannabinoidById(
      cannabinoidId
    );
    return response as CatalogMasterCannabinoidDto;
  }
);

export const getCatalogMasterCannabinoids = createAsyncThunk(
  "nativeEcommerce/catalog/master/cannabinoidSearch",
  async (query: string) => {
    const response = await nativeEcommerceService.getCatalogMasterCannabinoidSearch(
      query
    );
    return response as CatalogMasterCannabinoidSearchDto;
  }
);

export const createCatalogMasterCannabinoid = createAsyncThunk(
  "nativeEcommerce/catalog/master/cannabinoidCreate",
  async (payload: { imageFile: Blob, request: CreateCatalogMasterCannabinoidDto}) => {
    const response = await nativeEcommerceService.createCatalogMasterCannabinoid(
      payload.imageFile,
      payload.request
    );
    return response as CatalogMasterCannabinoidDto;
  }
);

export const updateCatalogMasterCannabinoid = createAsyncThunk(
  "nativeEcommerce/catalog/master/cannabinoidUpdate",
  async (payload: { imageFile: Blob, request: UpdateCatalogMasterCannabinoidDto}) => {
    const response = await nativeEcommerceService.updateCatalogMasterCannabinoid(
      payload.imageFile,
      payload.request
    );
    return response as CatalogMasterCannabinoidDto;
  }
);

//end cannabinoid thunks
//strain-type thunks
export const getCatalogMasterStrainType = createAsyncThunk(
  "nativeEcommerce/catalog/master/getStrainTypeById",
  async (straintypeId: string) => {
    const response = await nativeEcommerceService.getCatalogMasterStrainTypeById(
      straintypeId
    );
    return response as CatalogMasterStrainTypeDto;
  }
);

export const getCatalogMasterStrainTypes = createAsyncThunk(
  "nativeEcommerce/catalog/master/strainTypeSearch",
  async (query: string) => {
    const response = await nativeEcommerceService.getCatalogMasterStrainTypeSearch(
      query
    );
    return response as CatalogMasterStrainTypeSearchDto;
  }
);

export const createCatalogMasterStrainType = createAsyncThunk(
  "nativeEcommerce/catalog/master/strainTypeCreate",
  async (request: CreateCatalogMasterStrainTypeDto) => {
    const response = await nativeEcommerceService.createCatalogMasterStrainType(
      request
    );
    return response as CatalogMasterStrainTypeDto;
  }
);

export const updateCatalogMasterStrainType = createAsyncThunk(
  "nativeEcommerce/catalog/master/strainTypeUpdate",
  async (request: UpdateCatalogMasterStrainTypeDto) => {
    const response = await nativeEcommerceService.updateCatalogMasterStrainType(
      request
    );
    return response as CatalogMasterStrainTypeDto;
  }
);

//end strain-type thunks
interface InitialState {
  // catalogMasterBrands: CatalogMasterBrandDto[] | null;
  // catalogMasterBrandCurrentSearch: { pageMeta: PageMetaDto, currentPageIds: string[] } | null;
  status: "idle" | "loading" | "failed";
  error: string | null;
}

const initialState: InitialState = {
  status: "idle",
  error: null,
};

const catalogMasterSlice = createSlice({
  name: "nativeEcommerce/catalog/master",
  initialState,
  reducers: {
    clearCatalogMaster(state) {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    //brand reducers
    builder
      .addCase(getCatalogMasterBrand.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterBrand.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterBrand.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load catalog brand.";
      })
      .addCase(getCatalogMasterBrands.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterBrands.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterBrands.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load catalog brand.";
      })
      .addCase(createCatalogMasterBrand.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createCatalogMasterBrand.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(createCatalogMasterBrand.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to create catalog brand.";
      })
      .addCase(updateCatalogMasterBrand.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateCatalogMasterBrand.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(updateCatalogMasterBrand.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to update catalog brand.";
      })
      .addCase(uploadCatalogMasterBrandsCsv.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(uploadCatalogMasterBrandsCsv.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(uploadCatalogMasterBrandsCsv.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to upload catalog brand csv.";
      })
      .addCase(updateCatalogMasterBrandUploadRow.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateCatalogMasterBrandUploadRow.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(updateCatalogMasterBrandUploadRow.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to update catalog brand upload row.";
      })
      .addCase(getCatalogMasterBrandUploads.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterBrandUploads.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterBrandUploads.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to get catalog brand uploads.";
      })
      .addCase(getCatalogMasterBrandUploadRows.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterBrandUploadRows.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterBrandUploadRows.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to get catalog brand upload rows.";
      })
      .addCase(commitCatalogMasterBrandUpload.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(commitCatalogMasterBrandUpload.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(commitCatalogMasterBrandUpload.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to commit catalog brand upload.";
      })
      //end brand reducers
      //product reducers
      .addCase(getCatalogMasterProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterProduct.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load catalog product.";
      })
      .addCase(getCatalogMasterProducts.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterProducts.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load catalog product.";
      })
      .addCase(createCatalogMasterProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createCatalogMasterProduct.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(createCatalogMasterProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to create catalog product.";
      })
      .addCase(updateCatalogMasterProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateCatalogMasterProduct.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(updateCatalogMasterProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to update catalog product.";
      })
      .addCase(uploadCatalogMasterProductsCsv.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(uploadCatalogMasterProductsCsv.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(uploadCatalogMasterProductsCsv.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to upload catalog product csv.";
      })
      .addCase(updateCatalogMasterProductUploadRow.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateCatalogMasterProductUploadRow.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(updateCatalogMasterProductUploadRow.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to update catalog product upload row.";
      })
      .addCase(getCatalogMasterProductUploads.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterProductUploads.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterProductUploads.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to get catalog product uploads.";
      })
      .addCase(getCatalogMasterProductUploadRows.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterProductUploadRows.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterProductUploadRows.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to get catalog product upload rows.";
      })
      .addCase(commitCatalogMasterProductUpload.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(commitCatalogMasterProductUpload.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(commitCatalogMasterProductUpload.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to commit catalog product upload.";
      })
      //end product reducers
      //category reducers
      .addCase(getCatalogMasterCategory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterCategory.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load catalog category.";
      })
      .addCase(getCatalogMasterCategories.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterCategories.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load catalog category.";
      })
      .addCase(createCatalogMasterCategory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createCatalogMasterCategory.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(createCatalogMasterCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to create catalog category.";
      })
      .addCase(updateCatalogMasterCategory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateCatalogMasterCategory.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(updateCatalogMasterCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to update catalog category.";
      })
      //end category reducers
      //subcategory reducers
      .addCase(getCatalogMasterSubcategory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterSubcategory.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterSubcategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load catalog subcategory.";
      })
      .addCase(getCatalogMasterSubcategories.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterSubcategories.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterSubcategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load catalog subcategory.";
      })
      .addCase(createCatalogMasterSubcategory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createCatalogMasterSubcategory.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(createCatalogMasterSubcategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to create catalog subcategory.";
      })
      .addCase(updateCatalogMasterSubcategory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateCatalogMasterSubcategory.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(updateCatalogMasterSubcategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to update catalog subcategory.";
      })
      //end subcategory reducers
      //effect reducers
      .addCase(getCatalogMasterEffect.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterEffect.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterEffect.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load catalog effect.";
      })
      .addCase(getCatalogMasterEffects.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterEffects.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterEffects.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load catalog effect.";
      })
      .addCase(createCatalogMasterEffect.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createCatalogMasterEffect.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(createCatalogMasterEffect.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to create catalog effect.";
      })
      .addCase(updateCatalogMasterEffect.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateCatalogMasterEffect.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(updateCatalogMasterEffect.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to update catalog effect.";
      })
      //end effect reducers
      //terpene reducers
      .addCase(getCatalogMasterTerpene.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterTerpene.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterTerpene.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load catalog terpene.";
      })
      .addCase(getCatalogMasterTerpenes.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterTerpenes.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterTerpenes.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load catalog terpene.";
      })
      .addCase(createCatalogMasterTerpene.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createCatalogMasterTerpene.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(createCatalogMasterTerpene.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to create catalog terpene.";
      })
      .addCase(updateCatalogMasterTerpene.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateCatalogMasterTerpene.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(updateCatalogMasterTerpene.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to update catalog terpene.";
      })
      //end terpene reducers
      //cannabinoid reducers
      .addCase(getCatalogMasterCannabinoid.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterCannabinoid.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterCannabinoid.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load catalog cannabinoid.";
      })
      .addCase(getCatalogMasterCannabinoids.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterCannabinoids.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterCannabinoids.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load catalog cannabinoid.";
      })
      .addCase(createCatalogMasterCannabinoid.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createCatalogMasterCannabinoid.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(createCatalogMasterCannabinoid.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to create catalog cannabinoid.";
      })
      .addCase(updateCatalogMasterCannabinoid.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateCatalogMasterCannabinoid.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(updateCatalogMasterCannabinoid.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to update catalog cannabinoid.";
      })
      //end cannabinoid reducers
      //strain type reducers
      .addCase(getCatalogMasterStrainType.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterStrainType.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterStrainType.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load catalog strain type.";
      })
      .addCase(getCatalogMasterStrainTypes.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getCatalogMasterStrainTypes.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(getCatalogMasterStrainTypes.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to load catalog strain type.";
      })
      .addCase(createCatalogMasterStrainType.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createCatalogMasterStrainType.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(createCatalogMasterStrainType.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to create catalog strain type.";
      })
      .addCase(updateCatalogMasterStrainType.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateCatalogMasterStrainType.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(updateCatalogMasterStrainType.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Failed to update catalog strain type.";
      })
    //end strain type reducers
  },
});


export const { clearCatalogMaster } = catalogMasterSlice.actions;

export default catalogMasterSlice.reducer;
