import React, { useEffect, useMemo, useRef, useState, ChangeEvent, MutableRefObject } from "react";
import {
  Box,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Grid,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MapIcon from "@mui/icons-material/Map";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Button } from "../../shared/Button";
import { BusinessesMapSelect } from "components/businesses/BusinessesMapSelect";
import { useAppDispatch } from "redux/hooks";
import { getBusiness, getBusinessSettings } from "redux/businessSlice";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { businessService } from "services";
import { debounce } from "lodash";

import { getBusinessSpecials, getCategoryMappings } from "redux/nativeEcommerceSlice";
import { drawerContentStyles, drawerFooterStyles, drawerHeaderStyles, drawerStyles, mapButtonStyles, mapViewContentStyles, searchContainerStyles } from "./styles/business-select.styles";
import Business, { BusinessResponse } from "types/business";
import { BusinessSelectDrawerItem } from "./index";


interface BusinessSelectProps {
  businessSelectDialog: boolean;
  handleClose: () => void;
  businesses: Business[];
  business: Business;
}

export const BusinessSelect: React.FC<BusinessSelectProps> = ({
  businessSelectDialog,
  handleClose,
  businesses,
  business,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isMapView, setIsMapView] = useState<boolean>(false);
  const [activeBusinessId, setActiveBusinessId] = useState<number>(business.id);
  const activeRadioRef = useRef<HTMLElement | null>(null);
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const params = useParams<{ businessId: string }>();
  const [predictions, setPredictions] = useState<Business[]>([]); // Store predictions
  const defaultBusinesses = businesses;

  const fetchPredictions = useMemo(
    () =>
      debounce(
        async (query: string, callback: (predictions: Business[]) => void) => {
          const results = await businessService.getPredictions(query);
          const predictions = results.data.businesses;
          setPredictions(predictions); // Update predictions
          callback(predictions);
        },
        200,
        { leading: false, trailing: true }
      ),
    []
  );

  useEffect(() => {
    setActiveBusinessId(business.id);
  }, [business]);

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    fetchPredictions(query, (predictions) => {
      // You can handle predictions here if needed
    });
  };

  const handleBusinessSelect = async () => {
    if (params.businessId) {
      const newUrl = window.location.pathname.replace(
        `/${params.businessId}`,
        `/${activeBusinessId}`
      );
      navigate(newUrl);
    }
    handleClose();
    const businessResponse = await dispatch(getBusiness(activeBusinessId)) as BusinessResponse;
    const publicKey = businessResponse.payload.menuSettings?.jointEcommercePublicKey || 'defaultPublicKey';
    dispatch(getBusinessSettings(activeBusinessId));
    dispatch(getBusinessSpecials({ businessId: activeBusinessId, key: publicKey }));
    dispatch(getCategoryMappings(activeBusinessId));
  };

  const handleMapViewClick = () => {
    setIsMapView((prevIsMapView) => !prevIsMapView);

    if (activeRadioRef.current && !isMapView) {
      activeRadioRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const filteredBusinesses = searchQuery ? predictions : defaultBusinesses;

  useEffect(() => {
    if (businessSelectDialog && activeRadioRef.current && !isMapView) {
      activeRadioRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [isMapView]);

  useEffect(() => {
    if (
      !searchQuery &&
      drawerRef.current &&
      activeRadioRef.current &&
      !isMapView
    ) {
      activeRadioRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (searchQuery && drawerRef.current && !isMapView) {
      drawerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (businessSelectDialog && !isMapView) {
      setActiveBusinessId(activeBusinessId);
      setTimeout(() => {
        if (activeRadioRef.current) {
          activeRadioRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 300);
    }
  }, [businessSelectDialog]);

  return (
    <Drawer anchor="right" open={businessSelectDialog} onClose={handleClose} sx={{ zIndex: theme.zIndex.drawer + 102 }}>
      <div style={drawerStyles(theme)} ref={drawerRef}>
        <Box style={drawerHeaderStyles(theme)}>
          <Typography variant="h6">Select your business</Typography>
          <IconButton
            color="inherit"
            onClick={() => {
              handleClose();
              setIsMapView(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Grid container style={searchContainerStyles(theme)}>
          <Grid item xs={9}>
            <TextField
              label="Search businesses"
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={handleSearchInputChange}
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <Box style={mapButtonStyles(theme)} onClick={handleMapViewClick}>
              {isMapView ? (
                <FormatListBulletedIcon />
              ) : (
                <MapIcon />
              )}
              <Typography variant="caption">
                {isMapView ? "List View" : "Map View"}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {isMapView ? (
          <Box style={mapViewContentStyles(theme)}>
            <BusinessesMapSelect
              businesses={filteredBusinesses}
              activeBusinessId={activeBusinessId}
              setActiveBusinessId={setActiveBusinessId}
              handleBusinessSelect={handleBusinessSelect}
            />
          </Box>
        ) : (
          <>
            <Box style={drawerContentStyles(theme)}>
              <FormControl sx={{ width: "100%" }}>
                <RadioGroup
                  aria-labelledby="business-select-radio-buttons"
                  defaultValue={activeBusinessId}
                  name="business-select-radio-buttons"
                >
                  {filteredBusinesses.map((business) => (
                    <React.Fragment key={business.id}>
                      <FormControlLabel
                        value={business.id}
                        control={<Radio />}
                        label={<BusinessSelectDrawerItem business={business} />}
                        onClick={() => setActiveBusinessId(business.id)}
                        ref={(el: HTMLElement | null) => {
                          if (business.id === activeBusinessId) {
                            activeRadioRef.current = el;
                          }
                        }}
                      />
                      <Divider />
                    </React.Fragment>
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box style={drawerFooterStyles(theme)}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleBusinessSelect}
                sx={{ textTransform: "capitalize" }}
                fullWidth
              >
                Select Business
              </Button>
            </Box>
          </>
        )}
      </div>
    </Drawer>
  );
};
