import CustomerRecievesFilterForm from "components/businesses/ecommerce/specials/filters/CustomerRecievesFilterForm";
import React from "react";
import { useAppSelector } from "redux/hooks";

export default function CustomerRecievesForm(props) {
  const business = useAppSelector((state) => state.business.info);
  const { formField } = props;

  return (
    <React.Fragment>
      <CustomerRecievesFilterForm
        businessId={business.id}
        formField={formField}
      />
    </React.Fragment>
  );
}
