import axios, { AxiosRequestConfig } from "axios";
import config from '../config';
import { apiHelper, authHeader } from "helpers";
import { BusinessToOrganization, Organization, OrganizationCreate } from "types/organization";

export const organizationService = {
    getOrganization,
    getOrganizations,
    getPredictions,
    getUserOrganizations,
    addOrganization,
    removeOrganization,
    addBusinessToOrganization,
    removeBusinessFromOrganization,
    updateOrganization,
}

function getOrganization(organizationId: number) {
    const auth = authHeader()
    const requestConfig: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...auth
        }
    };
    return axios.get(`${config.apiUrl}/organizations/${organizationId}`, requestConfig)
        .then(apiHelper.handleResponse)
        .catch(apiHelper.handleError);
}

function getOrganizations(organizationIds: string) {
    const auth = authHeader()
    const requestConfig: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...auth
        },
        params: {
            organizationIds,
        }
    };


    return axios.get(`${config.apiUrl}/organizations`, requestConfig)
        .then(apiHelper.handleResponse)
        .catch(apiHelper.handleError);
}

/**
 * Get search predictions
 * @param query
 */
function getPredictions(query: string) {
    const requestConfig: AxiosRequestConfig = {
        method: 'GET',
        headers: authHeader(),
        params: !query ? undefined : {
            q: query
        }
    };

    return axios.get(`${config.apiUrl}/search/internal/organization`, requestConfig)
        .then(apiHelper.handleResponse)
        .catch(apiHelper.handleError);
}

function getUserOrganizations(userId: number) {
    const auth = authHeader()
    const requestConfig: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...auth
        },
    };


    return axios.get(`${config.apiUrl}/organizations/user/${userId}`, requestConfig)
        .then(apiHelper.handleResponse)
        .catch(apiHelper.handleError);
}

function addOrganization(newData: OrganizationCreate) {
    const auth = authHeader();
    const requestConfig: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/json',
            ...auth
        }
    };

    return axios.post(`${config.apiUrl}/organizations`, newData, requestConfig)
        .then(apiHelper.handleResponse)
        .catch(apiHelper.handleError);
}

function removeOrganization(organizationId: number) {
    const auth = authHeader();
    const requestConfig: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/json',
            ...auth
        }
    };

    return axios.delete(`${config.apiUrl}/organizations/${organizationId}`, requestConfig)
        .then(apiHelper.handleResponse)
        .catch(apiHelper.handleError);
}

function addBusinessToOrganization(newData: BusinessToOrganization) {
    const auth = authHeader()
    const requestConfig: AxiosRequestConfig = {
        headers: {
            "Content-Type": "application/json",
            ...auth
        }
    };

    return axios.post(`${config.apiUrl}/organizations/${newData.organizationId}/business/${newData.businessId}`, {}, requestConfig)
        .then(apiHelper.handleResponse)
        .catch(apiHelper.handleError)
}

function removeBusinessFromOrganization(newData: BusinessToOrganization) {
    const auth = authHeader()
    const requestConfig: AxiosRequestConfig = {
        headers: {
            "Content-Type": "application/json",
            ...auth
        }
    };
    return axios.delete(`${config.apiUrl}/organizations/${newData.organizationId}/business/${newData.businessId}`, requestConfig)
        .then(apiHelper.handleResponse)
        .catch(apiHelper.handleError)
}

function updateOrganization(newData: Partial<Organization>) {
    const auth = authHeader();
    const requestConfig: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/json',
            ...auth
        },
    };
    return axios.patch(`${config.apiUrl}/organizations`, newData, requestConfig)
        .then(apiHelper.handleResponse)
        .catch(apiHelper.handleError);
}
