import { CloudUpload } from "@mui/icons-material";
import { Button, styled } from "@mui/material";
import { useField } from "formik";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function PhotoUploadButton(props) {
  const { name, ...rest } = props;
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;

  function _handleFileChange(event) {
    const file = event.target.files[0];
    setValue(file);
    event.target.value = null;
  }

  return (
    <Button
      component="label"
      variant="contained"
      startIcon={<CloudUpload />}
      {...rest}
    >
      Upload Photo
      <VisuallyHiddenInput
        type="file"
        name={name}
        onChange={_handleFileChange}
      />
    </Button>
  );
}
