import config from '../config';

export function authHeader() {
  let user = JSON.parse(localStorage.getItem('user'));
  if (user && user.token) {
    return {
      'Authorization': 'Bearer ' + user.token.accessToken,
      'x-api-key': config.jointApiKey
    };
  } else {
    return { 'x-api-key': config.jointApiKey };
  }
};

export function axiosRequestConfig(file = false) {
  const auth = authHeader();
  return {
    headers: {
      'Content-Type': file ? 'multipart/form-data' : 'application/json',
      ...auth
    }
  }
}

export function ecommerceAuthHeader() {
  return { 'x-api-key': config.jointEcommerceApiKey };
}


