import Grid from '@mui/material/Grid';
import DeliveryIcon from '@mui/icons-material/LocalShippingOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import MedicalIcon from '@mui/icons-material/LocalHospitalOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined';
import AdultUseIcon from '@mui/icons-material/EmojiPeople'



export function BusinessCategories({business}) {

  const values = [];
  if (business && business.attributes) {
    for (const attribute of business.attributes) {
      switch (attribute.key) {
        case 'adultUse':
          values.push(<Grid item xs={6} key={attribute.key}><div ><AdultUseIcon  /> Adult Use</div></Grid>);
          break;
        case 'medical':
          values.push(<Grid item xs={6} key={attribute.key}><div ><MedicalIcon  /> Medical</div></Grid>);
          break;
        case 'has_delivery':
          if (attribute.value === true) {
            values.push(<Grid item xs={6} key={attribute.key}><div ><DeliveryIcon  /> Delivery</div></Grid>);
          }
          break;
        case 'has_in_store_pickup':
          if (attribute.value === true) {
            values.push(<Grid item xs={6} key={attribute.key}><div ><ShoppingCartIcon  /> Online ordering</div></Grid>);
          }
          break;
        case 'has_in_store_shopping':
          if (attribute.value === true) {
            values.push(<Grid item xs={6} key={attribute.key}><div ><StorefrontIcon  /> Storefront</div></Grid>)
          }
          break;
        default:
          values.push(<Grid>default</Grid>)
      }
    }
  }

  return (
    <Grid container spacing={0} alignItems="center" >
      { values }
    </Grid>
  )
}
