import { Box, Card, CardContent, Typography } from "@mui/material";
import AddBundleQualifiersButton from "components/businesses/ecommerce/specials/filters/AddBundleQualifiersMenu";

const NoBundleQualifiers = ({ handleAddQualifiers, setFilterGroup, filterGroup }) => {
  function handleClick(qualifier) {
    if (handleAddQualifiers) {
      handleAddQualifiers(qualifier);
    }
  }

  return (


        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          p={2}
        >
          <Typography variant="subtitle1">
            This bundle has no qualifiers yet!
          </Typography>
          <Typography variant="subtitle2">
            Click the button below to add your first condition.
          </Typography>
          <AddBundleQualifiersButton
            handleClick={handleClick}
            filterGroup={filterGroup}
            setFilterGroup={setFilterGroup}
          />
        </Box>


  );
};

export default NoBundleQualifiers;
