
export function Error(props) {
  
  return (
    <>
      <div >
        {props.children}
      </div>
    </>
  );
}
