import { Box, Typography } from "@mui/material";
import AddBundleRewardsButton from "components/businesses/ecommerce/specials/filters/AddBundleRewardsMenu";

const NoBundleRewards = ({ handleAddRewards, setFilterGroup, filterGroup }) => {
  function handleClick(qualifier) {
    if (handleAddRewards) {
      handleAddRewards(qualifier);
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
      p={2}
    >
      <Typography variant="subtitle1">
        This bundle has no rewards yet!
      </Typography>
      <Typography variant="subtitle2">
        Click the button below to add your first rewards.
      </Typography>
      <AddBundleRewardsButton
        handleClick={handleClick}
        filterGroup={filterGroup}
      />
    </Box>
  );
};

export default NoBundleRewards;
