import React from "react";
import { Field, useField, useFormikContext } from "formik";

import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Error } from "./Error";


export const CheckboxInput = ({ label, options, ...props }) => {
  
  const [meta] = useField({ type: "checkbox", ...props });
  const { value } = meta;
  const { errors } = useFormikContext();

  return (
    <>
      {options && options.length > 0 && (
        <>
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Field
                    as={Checkbox}
                    type="checkbox"
                    name={props.name}
                    value={option.value}
                    color="primary"
                    checked={value && value.includes(option.value)}
                  />
                }
                label={option.name}
              />
            ))}
          </FormGroup>
          {errors[props.name] && <Error>{errors[props.name]}</Error>}
        </>
      )}

      {meta.touched && meta.error ? (
        <div >{meta.error}</div>
      ) : null}
    </>
  );
};
