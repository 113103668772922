import {lazy} from 'react';
import Loadable from 'components/Loadable';
const NotFoundPage = Loadable(lazy(() => import('pages/errors/not-found.page')));

const ErrorRoutes = [
  {
    path: "*",
    element: <NotFoundPage />,
  }
];

export default ErrorRoutes;