import { Box, Typography } from "@mui/material";
import { Breadcrumbs } from ".";

interface PageTitleProps {
  title?: string;
}

export function PageTitle({title}: PageTitleProps) {
  return (
    <Box sx={{ py: 2, mb: 2 }}>
      <Breadcrumbs />
      {title && <Typography variant="h1">{title}</Typography>}
    </Box>
  );
}
