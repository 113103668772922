import React from 'react';
import { Stack } from '@mui/material';
import { DefaultNavHeader } from './default-nav-header';
import { BusinessNavHeader } from './business-nav-header';
import { NAVIGATION_TYPE } from 'layout/constants';

interface NavigationHeaderProps {
  navigationType: keyof typeof NAVIGATION_TYPE;
}

export function NavigationHeader({ navigationType }: NavigationHeaderProps) {
  const renderNavHeader = () => {
    switch (navigationType) {
      case "BUSINESS":
        return <BusinessNavHeader />;
      case NAVIGATION_TYPE.DEFAULT:
      default:
        return <DefaultNavHeader />;
    }
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      width="100%"
      sx={{ px: 2, py: 3, borderBottom: 1, borderColor: 'divider' }}
    >
      {renderNavHeader()}
    </Stack>
  );
}
