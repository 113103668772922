import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Checkbox } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { getProducts } from "services/productService";
import { createBrandSearchData } from "components/businesses/ecommerce/specials/modals/data/productSearchData";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CategoryBrandsAutocomplete({
  businessId,
  setSelectedBrands,
  selectedBrands,
  selectedProducts,
  selectedCategories,
}) {
  const [openIncluded, setOpenIncluded] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = openIncluded && options.length === 0;

  const selectedProductIds =
    selectedProducts?.map((product) => product._source.jointId) ?? [];

  const selectedCategoryIds =
    selectedCategories?.map((category) => category.key) ?? [];

  React.useEffect(() => {
    let active = true;
    const data = createBrandSearchData(
      businessId,
      selectedCategoryIds,
      selectedProductIds
    );

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        const response = await getProducts(data);
        const brands = response?.aggregations?.brands?.buckets.filter(
          (brand) => brand.key !== ""
        ); // Filter out options with empty keys
        if (active) {
          setOptions(brands);
        }
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!openIncluded) {
      setOptions([]);
    }
  }, [openIncluded]);
  
   // * Limit the list to 10 items at a time
   const OPTIONS_LIMIT = 10;
   const filterOptions = createFilterOptions({
     limit: OPTIONS_LIMIT,
   });
  return (
    <div>
      <div style={{ minWidth: "200px", width: "100%" }}>
        <Autocomplete
          id="included-brands"
          multiple
          limitTags={1}
          open={openIncluded}
          value={
            Array.isArray(selectedBrands) ? selectedBrands : []
          }
          sx={{ marginY: 1 }}
          onOpen={() => {
            setOpenIncluded(true);
          }}
          onClose={() => {
            setOpenIncluded(false);
          }}
          onChange={(event, value) => {
            setSelectedBrands(value);
          }}
          disableCloseOnSelect
          isOptionEqualToValue={(option, value) => option?.key === value?.key}
          getOptionLabel={(option) =>
            typeof option === "object" ? option.key ?? option : option
          }
          filterOptions={filterOptions}
          options={options}
          loading={loading}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.key}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Included Brands"
              InputProps={{
                ...params.InputProps,
                style: {
                  width: "280px",
                  paddingRight: "10px",
                },
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </div>
    </div>
  );
}
