import axios, { AxiosRequestConfig } from 'axios';
import config from '../config';
import { apiHelper, authHeader } from '../helpers';

export const reviewsService = {
  getReviews
}

/**
 * Get reviews for a given dispensary
 * @param  {[type]} businessId [description]
 * @return {[type]}              [description]
 */
function getReviews(businessId: number) {
  const auth = authHeader();
  const requestOptions: AxiosRequestConfig  = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...auth
    },
    params: {
      dispensaryId: businessId,
    }
  };
  return axios.get(`${config.apiUrl}/search/internal/reviews`, requestOptions)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}
