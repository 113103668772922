import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Typography,
} from "@mui/material";
import { Button } from "components/shared";

export default function MenuPreview(props) {
  const { name, description, imageSrc, ...rest } = props;
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        {imageSrc && (
          <CardMedia
            component="img"
            height="140"
            image={imageSrc}
            alt="Special Image"
          />
        )}
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {name ? name : "Special Name"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description
              ? description
              : "Ex: This is a limited time offer, while suplies last, and cannot be combined with other specials."}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          Shop Now
        </Button>
      </CardActions>
    </Card>
  );
}
