import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";

const menuItems = [
  { key: "PRODUCT", label: "Individual Items" },
  { key: "BRAND", label: "Items from a Brand" },
  { key: "CATEGORY", label: "Items from a category" },
  { key: "PRICE", label: "Bundled Price" },
  { key: "BUNDLED_DISCOUNT", label: "Bundled Discount" },
  { key: "CART_DISCOUNT", label: "Discount to Cart" },
];

export default function AddBundleRewardsButton({ handleClick, filterGroup }) {
  const formik = useFormikContext();
  const [qualifiersValue] = useField("bundleRewards");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isMenuItemDisabled, setIsMenuItemDisabled] = useState({
    PRODUCT: false,
    BRAND: false,
    CATEGORY: false,
    BUNDLED_DISCOUNT: false,
    PRICE: false,
    CART_DISCOUNT: false,
  });

  useEffect(() => {
    const newIsMenuItemDisabled = {};
    menuItems.forEach((item) => {
      newIsMenuItemDisabled[item.key] = isMenuItemDisabledFn(item.key);
    });
    setIsMenuItemDisabled(newIsMenuItemDisabled);
  }, [filterGroup]);

  const handleButtonClick = (event, qualifier) => {
    setAnchorEl(event.currentTarget);

    if (qualifier) {
      handleClick(qualifier);
      formik.setFieldValue("rewardType", qualifier);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function isMenuItemDisabledFn(menuItem) {
    return Boolean(filterGroup !== menuItem && filterGroup);
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleButtonClick}
        variant="outlined"
        sx={{ margin: "16px auto", maxWidth: "150px" }}
      >
        Add Reward
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.key}
            onClick={(e) => handleButtonClick(e, item.key)}
            disabled={isMenuItemDisabled[item.key]}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
