import React from 'react';

import {Box as MuiBox} from "@mui/material";


export function WidgetBox(props) {
  

  return (
    <MuiBox  {...props}>
      {props.children}
    </MuiBox>
  );
}
