import React from "react";
import { Box } from "@mui/material";
import { AppDrawer } from "./app-drawer";
import { APP_DRAWER_WIDTH } from "layout/constants";
import { useAppSelector } from "redux/hooks";

interface AppLayoutProps {
  children: React.ReactNode;
  isDefaultLayout?: boolean;
}

export const AppLayout = (props: AppLayoutProps) => {
  const mobileOpen = useAppSelector((state) => state.layout.mobileOpen);

  return (
    <Box sx={{ display: { md: 'flex' }, flexDirection: 'row', flexWrap: 'nowrap', width: "100%" }}>
      <AppDrawer
        mobileOpen={mobileOpen}
        isDefaultLayout={props.isDefaultLayout}
      />
      <Box
        component="main"
        sx={{ flexGrow: 1, width: {xs: "100%", md: `calc(100% - ${APP_DRAWER_WIDTH}px)`} }}
      >
        <Box
          sx={{
            display: "block",
            position: "relative",
            width: "100%",
            minWidth: "100%",
            maxWidth: "100%",
          }}
        >
          {props.children}
        </Box>
      </Box>
    </Box>
  );
};

export default AppLayout;
