import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Checkbox } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { getProducts } from "services/productService";
import { createCategorySearchData } from "components/businesses/ecommerce/specials/modals/data/productSearchData";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function BundlesCategoryAutocomplete({
  businessId,
  setSelectedCategories,
  selectedBrands,
  selectedCategories,
}) {
  const [openIncluded, setOpenIncluded] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = openIncluded && options.length === 0;

  const selectedBrandIds = selectedBrands.map((brand) => brand.key) ?? [];

  React.useEffect(() => {
    let active = true;
    const data = createCategorySearchData(businessId, selectedBrandIds);

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        const response = await getProducts(data);
        const categories = response?.aggregations?.categories?.buckets.filter(
          (category) => category.key !== ""
        );
        if (active) {
          setOptions(categories);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!openIncluded) {
      setOptions([]);
    }
  }, [openIncluded]);

  // * Limit the list to 10 items at a time
  const OPTIONS_LIMIT = 10;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
  });
  return (
    <div style={{ display: "flex", columnGap: 10 }}>
      <div style={{ width: "200px", maxWidth: "200px" }}>
        <Autocomplete
          id="included-categories"
          multiple
          limitTags={1}
          open={openIncluded}
          sx={{ marginY: 1 }}
          value={Array.isArray(selectedCategories) ? selectedCategories : []}
          onOpen={() => {
            setOpenIncluded(true);
          }}
          onClose={() => {
            setOpenIncluded(false);
          }}
          onChange={(event, value) => {
            setSelectedCategories(value);
          }}
          disableCloseOnSelect
          isOptionEqualToValue={(option, value) => option.key === value.key}
          getOptionLabel={(option) =>
            typeof option === "object" ? option.key ?? option : option
          }
          filterOptions={filterOptions}
          options={options}
          loading={loading}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.key}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Included Categories"
              InputProps={{
                ...params.InputProps,
                style: {
                  width: "100%",
                  overflowY: "auto",
                },
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </div>
    </div>
  );
}
