import Business from "types/business";
import { Box, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { styled } from "@mui/system";
import { StoreOpenClosed } from "./store-open-closed.component";
import { formatDisplayTime } from "./store-open-closed.helpers";

interface StoreOpenClosedProps {
  business: Business;
}

const StyledBox = styled(Box)(({ theme }) => ({
  maxWidth: 400,
  margin: "0 auto",
  backgroundColor: "white",
  padding: theme.spacing(2),
}));

const StyledTable = styled(Table)(({ theme }) => ({
  "& .MuiTableRow-root": {
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  "& .MuiTableCell-root": {
    padding: theme.spacing(1, 0),
    borderBottom: "none",
  },
}));

const StyledTableRow = styled(TableRow)({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
});

const DayCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 500,
}));

const HoursCell = styled(TableCell)({
  textAlign: "right",
});

export function StoreOpenClosedTable({
  business,
  ...props
}: StoreOpenClosedProps) {
  return (
    <StyledBox>
      {business.hours && business.hours.length > 0 && (
        <StoreOpenClosed business={business} />
      )}
      <StyledTable>
        <TableBody>
          {!business.hours || business.hours.length === 0 ? (
            <StyledTableRow>
              <DayCell>Hours not available</DayCell>
            </StyledTableRow>
          ) : (
            business.hours.map(({ day, open, close, isClosed }) => (
              <StyledTableRow key={day}>
                <DayCell>{day.charAt(0) + day.slice(1).toLowerCase()}</DayCell>
                <HoursCell>
                  {isClosed
                    ? "Closed"
                    : `${formatDisplayTime(open)}–${formatDisplayTime(close)}`}
                </HoursCell>
              </StyledTableRow>
            ))
          )}
        </TableBody>
      </StyledTable>
    </StyledBox>
  );
}
