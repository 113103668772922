import { Box } from "@mui/material";
import { NAVIGATION_WIDTH, APP_DRAWER_WIDTH } from "layout/constants";

interface LayoutBoxProps {
  children: React.ReactNode;
  noSideBar?: boolean;
}

export function LayoutBox({ children, noSideBar }: LayoutBoxProps) {
  return (
    <Box
      sx={{
        width: { xs: "100%", md: `calc(100% - ${noSideBar ? "0" : NAVIGATION_WIDTH}px - ${APP_DRAWER_WIDTH}px)` },
        maxWidth: { xs: "100%", md: `calc(100% - ${noSideBar ? "0" : NAVIGATION_WIDTH}px - ${APP_DRAWER_WIDTH}px)` },
        ml: { xs: 0, md: `calc(${noSideBar ? "0" : NAVIGATION_WIDTH}px + ${APP_DRAWER_WIDTH}px)` },
        height: "100vh",
        overflowY: "scroll",
      }}
    >
      {children}
    </Box>
  );
}
