import React from 'react';
import {Button as MuiButton} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledButton = styled(MuiButton)(({theme}) => ({
  boxShadow: 'none',
  textTransform: 'none'
}));

export function Button({children, ...props}) {
  return (
    <StyledButton {...props}>{children}</StyledButton>
  )
}
