import { createBrowserRouter } from "react-router-dom";
import AuthRoutes from "./auth-routes";
import BusinessRoutes from "./business-routes";
import OrganizationRoutes from "./organization-routes";
import AdminRoutes from "./admin-routes";
import MainRoutes from "./main-routes";
import ErrorRoutes from "./error-routes";

import { PrivateRoutes } from "./private-routes.component";
import { BusinessLayout } from "layout/business-layout";
import { USER_ROLES } from "constants/user.constants";
import { AdminLayout } from "layout/admin-layout";
import { DefaultLayout } from "layout";
import { OrganizationLayout } from "layout/organization-layout";

export default createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoutes layoutComponent={DefaultLayout} />,
    handle: {
      crumb: () => 'Home',
    },
    children: MainRoutes,
  },
  {
    path: "/businesses/:businessId",
    element: <PrivateRoutes layoutComponent={BusinessLayout} />,
    children: BusinessRoutes,
    handle: {
      crumb: () => 'Business Home',
    },
  },
  {
    path: "/organization/:orgId",
    element: <PrivateRoutes layoutComponent={OrganizationLayout} />,
    children: OrganizationRoutes,
    handle: {
      crumb: () => 'Organization Home',
    }
  },
  {
    path: "/admin",
    element: (
      <PrivateRoutes
        roles={[USER_ROLES.ADMIN]}
        layoutComponent={AdminLayout}
      />
    ),
    children: AdminRoutes,
    handle: {
      crumb: () => 'Admin',
    },
  },
  ...AuthRoutes,
  ...ErrorRoutes,
]);
