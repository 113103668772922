import React from 'react';
import { useField } from 'formik';
import TextField from '@mui/material/TextField';



export const TextAreaInput = ({ label, ...props }) => {
  
   const [field, meta] = useField(props);
   return (
     <>
       <TextField
         variant="outlined"
         margin="normal"
         fullWidth
         multiline
         rows={4}
         label={label}
         {...field}
         {...props} />
       {meta.touched && meta.error ? (
         <div >{meta.error}</div>
       ) : null}
     </>
   );
 };
