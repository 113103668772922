import React from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { Button, Grid, Box, Divider } from '@mui/material';
import * as Yup from 'yup';
import { updateBusiness } from '../../redux/businessSlice';
import { useAppDispatch } from '../../redux/hooks';
import toast from 'react-hot-toast';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { EditDrawer } from 'components/shared/drawer';
import { SwitchInput } from 'components/shared';
import { dateHelpers } from 'helpers';

export function EditBusinessHoursDialog({ business, ...props }) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };


  const handleSubmit = async (values, { setSubmitting }) => {
    console.log('values:', values);
    const updateData = { id: business.id, ...values };
    const action = dispatch(updateBusiness(updateData));
    const result = await action;

    if (updateBusiness.fulfilled.match(result)) {
      setSubmitting(false);
      handleClose();
      toast.success('Business hours updated successfully.');
    } else if (updateBusiness.rejected.match(result)) {
      const error = result.error;
      console.error('Error:', error);
      toast.error('Error updating business hours.');
    }
  };

  const parseTime = (timeString) => {
    if (!timeString || typeof timeString !== 'string' || timeString.length !== 4) {
      // Handle invalid or missing timeString
      console.warn('Invalid or missing timeString:', timeString);
      return null;
    }

    const hours = parseInt(timeString.substring(0, 2), 10);
    const minutes = parseInt(timeString.substring(2, 4), 10);

    if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      console.warn('Invalid hours or minutes:', hours, minutes);
      return null;
    }

    return new Date(0, 0, 0, hours, minutes);
  };

  const formatTimeForFormik = (date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}${minutes}`;
  };


  return (
    <EditDrawer title="Edit business hours" open={open} setOpen={setOpen} width="550px">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Formik
          initialValues={{
            hours: business.hours || [],
          }}
          validationSchema={Yup.object({
            hours: Yup.array().of(
              Yup.object().shape({
                dayIndex: Yup.number().required('Day index is required'),
                day: Yup.string().required('Day label is required'),
                isClosed: Yup.boolean().required('Closed status is required'),
                open: Yup.string().when('isClosed', {
                  is: false,
                  then: Yup.string()
                    .matches(/^([01]?[0-9]|2[0-3])[0-5][0-9]$/, 'Invalid time format')
                    .required('Open time is required'),
                  otherwise: Yup.string().notRequired(),
                }),
                close: Yup.string().when('isClosed', {
                  is: false,
                  then: Yup.string()
                    .matches(/^([01]?[0-9]|2[0-3])[0-5][0-9]$/, 'Invalid time format')
                    .required('Close time is required'),
                  otherwise: Yup.string().notRequired(),
                }),
              })
            ),
          })}

          onSubmit={handleSubmit}
        >
          {({ values, errors, isSubmitting, ...formik }) => (
            <Form>
              <FieldArray name="hours">
                {(arrayHelpers) => (
                  <>
                    {values.hours && values.hours.length > 0 ? (
                      values.hours.map((day, index) => (
                        <Grid container spacing={2} alignItems="center" key={index}>
                          <Grid item xs={2}>
                            {dateHelpers.getDayLabel(day.dayIndex)}
                          </Grid>
                          <Grid item xs={3}>
                            <Box my={3}>
                              <SwitchInput
                                label={day.isClosed ? 'Closed' : 'Open'}
                                name={`hours.${index}.isClosed`}
                                checked={!day.isClosed}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={7}>
                            {!day.isClosed && (
                              <Grid container alignItems="center" spacing={0}>
                                <Grid item xs={5}>
                                  <TimePicker
                                    ampm
                                    label="Open"
                                    value={day.open ? parseTime(day.open) : null} // Handle missing open time
                                    onChange={(date) => {
                                      const newOpen = formatTimeForFormik(date);
                                      arrayHelpers.replace(index, {
                                        ...day,
                                        open: newOpen,
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={1} align="center">
                                  <Divider />
                                </Grid>
                                <Grid item xs={5}>
                                  <TimePicker
                                    ampm
                                    label="Close"
                                    value={day.close ? parseTime(day.close) : null} // Handle missing close time
                                    onChange={(date) => {
                                      const newClose = date ? formatTimeForFormik(date) : '';
                                      arrayHelpers.replace(index, {
                                        ...day,
                                        close: newClose,
                                      });
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => {
                          for (let i = 0; i < 7; i++) {
                            arrayHelpers.push({
                              dayIndex: i,
                              day: dateHelpers.getDayLabel(i),
                              open: '0900',
                              close: '1700',
                              isClosed: false,
                            });
                          }
                        }}
                      >
                        Add hours
                      </Button>
                    )}
                  </>
                )}
              </FieldArray>

              <Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isSubmitting}
                  fullWidth
                >
                  Save
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </LocalizationProvider>
    </EditDrawer>
  );
}
