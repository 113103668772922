import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";

export default function AddBundleQualifiersButton({
  handleClick,
  filterGroup,
  setFilterGroup,
}) {
  const formik = useFormikContext();
  const [qualifiersValue, qualifiersMeta, qualifiersHelpers] =
    useField("bundleQualifiers");
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [isMenuItemDisabled, setIsMenuItemDisabled] = useState({
    item: false,
    brand: false,
    category: false,
    weight: false,
    quantity: false,
    spend: false,
  });

  useEffect(() => {
    const newIsMenuItemDisabled = { ...isMenuItemDisabled };
    Object.keys(newIsMenuItemDisabled).forEach((key) => {
      newIsMenuItemDisabled[key] = isMenuItemDisabledFn(key);
    });
    setIsMenuItemDisabled(newIsMenuItemDisabled);
  }, [filterGroup, qualifiersValue.value, qualifiersMeta.value]);

  const handleButtonClick = (event, qualifier) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    if (qualifier) {
      handleClick(qualifier);
      if (
        qualifier === "item" ||
        qualifier === "brand" ||
        qualifier === "category"
      ) {
        formik.setFieldValue("filterGroup", qualifier);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  function isMenuItemDisabledFn(menuItem) {
    switch (menuItem) {
      case "item":
        return Boolean(filterGroup !== menuItem && filterGroup);
      case "brand":
        return Boolean(filterGroup !== menuItem && filterGroup);
      case "category":
        return Boolean(filterGroup !== menuItem && filterGroup);
      case "weight":
        return (
          qualifiersValue.value[0].requiredValue !== null ||
          qualifiersValue.value[0].requiredWeight !== null
        );
      case "quantity":
        return qualifiersValue.value[0].requiredQuantity !== null;
      case "spend":
        return (
          qualifiersValue.value[0].requiredValue !== null ||
          qualifiersValue.value[0].requiredWeight !== null
        );
      default:
        return false;
    }
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleButtonClick}
        variant="outlined"
        sx={{ margin: "16px auto", maxWidth: "150px" }}
      >
        Add Qualifier
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={(e) => handleButtonClick(e, "item")}
          disabled={isMenuItemDisabled.item}
        >
          Individual Items
        </MenuItem>
        <MenuItem
          onClick={(e) => handleButtonClick(e, "brand")}
          disabled={isMenuItemDisabled.brand}
        >
          Items from a Brand
        </MenuItem>
        <MenuItem
          onClick={(e) => handleButtonClick(e, "category")}
          disabled={isMenuItemDisabled.category}
        >
          Items from a category
        </MenuItem>
        <MenuItem
          onClick={(e) => handleButtonClick(e, "weight")}
          disabled={isMenuItemDisabled.weight}
        >
          Total Weight
        </MenuItem>
        <MenuItem
          onClick={(e) => handleButtonClick(e, "quantity")}
          disabled={isMenuItemDisabled.quantity}
        >
          Total Quantity
        </MenuItem>
        <MenuItem
          onClick={(e) => handleButtonClick(e, "spend")}
          disabled={isMenuItemDisabled.spend}
        >
          Total Spend
        </MenuItem>
      </Menu>
    </div>
  );
}
