import React, { useRef, useEffect, useState } from 'react';
import config from '../../config';
import GoogleMapReact from 'google-map-react';
import { BusinessMapMarker } from '.';
import { useSelector } from 'react-redux';


export function BusinessMap({ business, ...props }) {
  
  const mapRef = useRef(null);
  const [mapKey, setMapKey] = useState(0);
  const themeMode = useSelector(state => state.theme.mode);

const mapContainerStyles = {
  height: 400,
  width: '100%',
}

  const getMapStyles = () => {
    if (themeMode === 'dark') {
      return [
        {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#242f3e"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#746855"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#242f3e"
              }
            ]
          },
          {
            "featureType": "administrative.locality",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#d59563"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#d59563"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#263c3f"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#6b9a76"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#38414e"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#212a37"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9ca5b3"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#746855"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#1f2835"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#f3d19c"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#2f3948"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#d59563"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#17263c"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#515c6d"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#17263c"
              }
            ]
            
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road.local",
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          }
      ];
    } else {
      return [
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        }
      ];
    }
  };

  // Use useEffect to update the map key when the theme changes
  useEffect(() => {
    setMapKey(prevKey => prevKey + 1);
  }, [themeMode]);

  if (business.latitude && business.longitude) {
    const center = {
      lat: parseFloat(business.latitude),
      lng: parseFloat(business.longitude),
    };
    const zoom = 13;

    return (
      <>
        <div style={mapContainerStyles}>
          <GoogleMapReact
            key={mapKey} // Change the key to trigger a re-render
            bootstrapURLKeys={{ key: config.googleMapsApiKey }}
            defaultCenter={center}
            defaultZoom={zoom}
            options={{
              scrollwheel: false,
              styles: getMapStyles(),
            }}
          >
            <BusinessMapMarker
              key={business.id}
              lat={business.latitude}
              lng={business.longitude}
              business={business}
              singleItem
            />
          </GoogleMapReact>
        </div>
      </>
    );
  } else {
    return (
      <>
        <p>Map not available.</p>
      </>
    );
  }
}
