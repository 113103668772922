import InfoIcon from "@mui/icons-material/Info";
import { Chip, DialogActions, Grid, Skeleton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "components/shared";
import { useState } from "react";
import { useDispatch } from "react-redux";

export default function BundleRewardPreview({
  bundleReward,
  businessId,
  index,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [reward, setRewards] = useState(bundleReward);
  const [modalOpen, setModalOpen] = useState(false);

  const discountTypeLabels = {
    DOLLAR_AMOUNT: "Dollar amount off",
    PERCENTAGE: "Percentage off",
    TARGET_PRICE: "Specific price",
  };

  const discountTypeSymbol = {
    DOLLAR_AMOUNT: "$",
    PERCENTAGE: "%",
    TARGET_PRICE: "$",
  };

  const menuItems = {
    PRODUCT: "Individual Items",
    BRAND: "Items from a Brand",
    CATEGORY: "Items from a Category",
    PRICE: "Bundled Price",
    BUNDLED_DISCOUNT: "Bundled Discount",
    CART_DISCOUNT: "Discount to Cart",
  };

  const handleCollectionClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  function renderDiscountLabel(type) {
    switch (type) {
      case "PERCENTAGE":
        return (
          <Typography variant="body2" gutterBottom>
            {discountTypeLabels[type]}:{" "}
            {`${reward.value}${discountTypeSymbol[type]}`}
          </Typography>
        );
        case "BUNDLED":
        return (
          <Typography variant="body2" gutterBottom>
            {`$${reward.value}`}
          </Typography>
        );
      case "DOLLAR_AMOUNT":
      case "TARGET_PRICE":
        return (
          <Typography variant="body2" gutterBottom>
            {discountTypeLabels[type]}:{" "}
            {`${discountTypeSymbol[type]}${reward.value}`}
          </Typography>
        );
      default:
        return (
          <Typography variant="body2" gutterBottom>
            No valid reward type selected.
          </Typography>
        );
    }
  }
  

  return (
    <Grid container marginY={1}>
      <Grid item xs={3}>
        {loading ? (
          // Show a skeleton while loading
          <Skeleton variant="text" />
        ) : (
          // Show the collection name if available
          <Chip
            label={`Reward ${index + 1}`}
            icon={<InfoIcon />}
            onClick={handleCollectionClick}
          />
        )}
      </Grid>
      {reward && (
        <Dialog
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            minWidth: "200px",
          }}
        >
          <DialogTitle id="alert-dialog-title" fontWeight={"bold"}>
            {`Reward ${index + 1}`}
          </DialogTitle>
          <DialogContent>
            <div key={`filter-${index}`}>
              {reward.brandId && (
                <Typography variant="body2" gutterBottom>
                  Brand: {reward.brandId}
                </Typography>
              )}
              {reward.category && (
                <Typography variant="body2" gutterBottom>
                  Category: {reward.category}
                </Typography>
              )}
              {reward.productId && (
                <Typography variant="body2" gutterBottom>
                  Product: {reward?.productId?._source?.name || "Unknown"}
                </Typography>
              )}
              {reward.weight && (
                <Typography variant="body2" gutterBottom>
                  Weight: {reward.weight}
                </Typography>
              )}
              {reward.quantity && (
                <Typography variant="body2" gutterBottom>
                  Quantity: {reward.quantity}
                </Typography>
              )}
              {reward.type && (
                <Typography variant="body2" gutterBottom>
                  {menuItems[reward.type]}
                </Typography>
              )}
              {reward.discountType && (
                <Typography variant="body2" gutterBottom>
                  {renderDiscountLabel(reward.discountType)}
                </Typography>
              )}
              {!reward.discountType && reward.type === "PRICE" && (
                <Typography variant="body2" gutterBottom>
                  {renderDiscountLabel("BUNDLED")}
                </Typography>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Grid>
  );
}
