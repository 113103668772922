import { Remove } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import InputField from "components/shared/special/InputField";
import { useFormikContext } from "formik";
import specialFormModel from "components/SpecialCreate/FormModel/specialFormModel";
const { formField } = specialFormModel;

export default function PriceRewardOption({ rewardIndex, reward, rewards }) {
  // * Formik
  const formik = useFormikContext();
  const { setFieldValue } = formik;
  const { bundleRewards } = formField;
  const qtyFieldName = `bundleRewards[${rewardIndex}].quantity`;
  const formFieldName = `bundleRewards[${rewardIndex}].value`;
  const formFieldLabel = "All items for";

  // * Function to remove reward from array
  function handleRemoveReward() {
    const newRewards = [...rewards];
    newRewards.splice(rewardIndex, 1);
    setFieldValue([bundleRewards.name], newRewards);
  }

  return (
    <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <Typography variant="subtitle2" fontWeight={"bold"}>{formFieldLabel}</Typography>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FormControl fullWidth sx={{ flex: 1, mr: 1 }}>
          <InputField
            error={false}
            helperText=""
            name={formFieldName}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl sx={{ flex: 1, mr: 1 }}>
          <InputField
            error={false}
            helperText=""
            name={qtyFieldName}
            label="Up to qty*"
            type="number"
          />
        </FormControl>
        <div>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Tooltip title="Remove this reward" placement="top">
              <IconButton
                color="error"
                sx={{ backgroundColor: "#ff4d4f15" }}
                onClick={handleRemoveReward}
              >
                <Remove />
              </IconButton>
            </Tooltip>
          </Stack>
        </div>
      </div>
    </div>
  );
}
