import { Box, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { addBusiness } from "../../redux/businessSlice";
import { useAppDispatch } from "../../redux/hooks";
import { Button, Error, TextInput } from "../shared";



export function AddBusinessForm({...props}) {
  
  const dispatch = useAppDispatch();
  const [error] = React.useState(null);

  const handleSubmit = async (values, { setSubmitting }) => {
    const action = dispatch(addBusiness(values));

    const result = await action;

    if (addBusiness.fulfilled.match(result)) {
      setSubmitting(false);
      toast.success("Business added successfully.");
      if(props.onSubmit){
        props.onSubmit();
      }
    } else if (addBusiness.rejected.match(result)) {
      const error = result.error;
      console.error("Error:", error);
      toast.error("Error adding business.");
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        address1: "",
        city: "",
        stateAbbr: "",
        postalCode: "",
        latitude: "",
        longitude: "",
        websiteUrl: "",
        isAddressVerified: true,
        isActive: true,
      }}
      validationSchema={Yup.object({
        name: Yup.string().required("Business name is required."),
        address1: Yup.string().required("Address 1 is required."),
        city: Yup.string().required("City is required."),
        stateAbbr: Yup.string().required("State is required."),
        postalCode: Yup.string().required("Postal code is required."),
        latitude: Yup.string().required("The latitude is required."),
        longitude: Yup.string().required("The longitude is required."),
        websiteUrl: Yup.string().required("The website URL is required."),
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, ...formik }) => (
        <Form>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="name"
                  label="Business Name"
                  name="name"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="address1"
                  label="Address"
                  name="address1"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput id="city" label="City" name="city" required />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="stateAbbr"
                  label="State/Province Abbreviation"
                  name="stateAbbr"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="postalCode"
                  label="Postal Code"
                  name="postalCode"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="latitude"
                  label="Latitude"
                  name="latitude"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="longitude"
                  label="Longitude"
                  name="longitude"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="websiteUrl"
                  label="Website URL"
                  name="websiteUrl"
                  required
                />
              </Grid>
              <Grid item xs={12} align="right"></Grid>
            </Grid>
            {error && <Error>{error}</Error>}
            <Box >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                Save
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
