export const apiHelper = {
    handleResponse,
    handleError
};

/**
 * handle api response
 * @param  {[type]} response [description]
 * @return {[type]}          [description]
 */
function handleResponse(response: any) {
    if (response.data) {
        return response.data;
    } else {
        throw new Error("Response data is empty.");
    }
}

/**
 * handle api error
 * @param  {[type]} error [description]
 * @return {[type]}       [description]
 */
function handleError(error: any) {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("API Response Error:", error.response.data);
        throw error;
    } else if (error.request) {
        console.error("Request Error:", error.request, 'error request');
        throw error;
    } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error:', error.message);
        throw error;
    }
}

