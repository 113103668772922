// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

export default function Typography() {
    return {
      MuiTypography: {
        styleOverrides: {
          gutterBottom: {
            marginBottom: 12
          }
        }
      }
    };
  }