import { Collapse, Grid, Typography, useMediaQuery } from "@mui/material";
import InputField from "components/shared/special/InputField";
import SwitchField from "components/shared/special/SwitchField";
import { useFormikContext } from "formik";
import React, { useEffect, useState, ReactNode } from "react";
import Label from "../Extras/Label";
import MenuDesignForm from "./MenuDesignForm";
import TagAutocomplete from "components/shared/tag-autocomplete/tag-autocomplete.component";

interface FlexBoxProps {
  top?: boolean;
  children: ReactNode;
}

const FlexBox: React.FC<FlexBoxProps> = ({ top, children }) => {
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const [firstChild, ...restChildren] = React.Children.toArray(children);
  return (
    <Grid container alignItems={top ? "top" : "center"} columnGap={1}>
      <Grid
        item
        xs={4}
        sm={3}
        style={{ textAlign: isSmallScreen ? "left" : "left" }}
      >
        {firstChild}
      </Grid>
      {restChildren.map((child, index) => (
        <Grid item xs={8} sm={8} key={index}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

interface FormField {
  tags: { name: string; label: string };
  enabled: { name: string; label: string };
  priority: { name: string; label: string };
  promoCodeEnabled: { name: string; label: string };
  promoCode: { name: string; label: string };
  promoCodeUsesPerUser: { name: string; label: string };
  maxUsesPerCart: { name: string; label: string };
  terms: { name: string; label: string };
}

interface InfoFormProps {
  type: string;
  formField: FormField;
}

const InfoForm: React.FC<InfoFormProps> = (props) => {
  const formik = useFormikContext<any>();
  const {
    type,
    formField,
    formField: {
      tags,
      enabled,
      priority,
      promoCodeEnabled,
      promoCode,
      promoCodeUsesPerUser,
      maxUsesPerCart,
      terms,
    },
  } = props;

  const [showPromoCode, setShowPromoCode] = useState(false);

  const presetTags1: string[] = []; // WIP: Switch to actual tags

  useEffect(() => {
    setShowPromoCode(!!formik.values.promoCodeEnabled);
  }, [formik.values.promoCodeEnabled]);

  // * Handle when tags change
  const handleTagsChange = (event: React.SyntheticEvent, value: string[]) => {
    formik.setFieldValue(tags.name, value);
  };
  return (
    <React.Fragment>
      <Typography variant="h4" component="h4" gutterBottom>
        Basic Info
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <MenuDesignForm formField={formField} type={type} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FlexBox>
            <Label label={`${tags.label}:`} />
            <TagAutocomplete
              options={presetTags1}
              label="Special Tags"
              placeholder="Add special tags"
              value={formik.values[tags.name]}
              onChange={handleTagsChange}
            />
          </FlexBox>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FlexBox>
            <Label label={`${promoCodeEnabled.label}:`} />
            <SwitchField name={promoCodeEnabled.name} />
          </FlexBox>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Collapse in={showPromoCode} timeout="auto" unmountOnExit>
            <FlexBox>
              <Label label={`${promoCode.label}:`} />
              <InputField
                name={promoCode.name}
                label={promoCode.label}
                fullWidth
              />
            </FlexBox>
            <div style={{ margin: "1rem 0" }}></div>
            <FlexBox>
              <Label label={`${promoCodeUsesPerUser.label}:`} />
              <InputField
                name={promoCodeUsesPerUser.name}
                label={promoCodeUsesPerUser.label}
                type="number"
                fullWidth
              />
            </FlexBox>
          </Collapse>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FlexBox>
            <Label label={`${maxUsesPerCart.label}:`} />
            <InputField
              name={maxUsesPerCart.name}
              label={maxUsesPerCart.label}
              fullWidth
              type="number"
            />
          </FlexBox>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FlexBox>
            <Label label={`${terms.label}:`} />
            <InputField name={terms.name} label={terms.label} fullWidth />
          </FlexBox>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FlexBox>
            <Label label={`${priority.label}:`} />
            <InputField
              name={priority.name}
              label={priority.label}
              fullWidth
              type="number"
            />
          </FlexBox>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FlexBox top>
            <Label label={`${enabled.label}:`} />
            <SwitchField name={enabled.name} />
          </FlexBox>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default InfoForm;
