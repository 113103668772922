import * as Yup from "yup";
import specialFormModel from "./specialFormModel";

const {
  formField: {
    name,
    schedule,
    startDate,
    endDate,
    priority,
    enabled,
    promoCodeEnabled,
    promoCodeUsesPerUser,
    promoCode,
    maxUsesPerCart,
    terms,
    menuTypes,
    discounts,
    bundleQualifiers,
  },
} = specialFormModel;

const startDateValidation = Yup.date()
  .min(0, "Start date cannot be in the past.")
  .required("Please select a start date.");

  const endDateValidation = Yup.date()
  .nullable()
  .when(
    startDate.name,
    (startDateValue, schema) =>
      startDateValue &&
      schema.min(
        new Date(startDateValue.getTime() - 24 * 60 * 60 * 1000),
        "End date must be the same or after the start date."
      )
  );

const validationSchema = [
  // Basic Info
  Yup.object().shape({
    [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
    [priority.name]: Yup.number()
      .min(1, priority.requiredErrorMsg)
      .required(priority.requiredErrorMsg),
    [promoCodeEnabled.name]: Yup.boolean(),
    [enabled.name]: Yup.boolean(),
    [promoCode.name]: Yup.string().when(promoCodeEnabled.name, {
      is: true,
      then: Yup.string().required(`${promoCode.requiredErrorMsg}`),
      otherwise: Yup.string(),
    }),
    [promoCodeUsesPerUser.name]: Yup.number().when(promoCodeEnabled.name, {
      is: true,
      then: Yup.number()
        .min(1, promoCodeUsesPerUser.invalidErrorMsg)
        .required(`${promoCodeUsesPerUser.requiredErrorMsg}`),
      otherwise: Yup.number(),
    }),
    [maxUsesPerCart.name]: Yup.number()
      .min(1, maxUsesPerCart.invalidErrorMsg)
      .required(maxUsesPerCart.requiredErrorMsg),
    [terms.name]: Yup.string().nullable(),
  }),
  // Menu Preview
  Yup.object().shape({
    [schedule.name]: Yup.object()
      .test("at-least-one-day", schedule.requiredErrorMsg, (value) =>
        Object.values(value).some((day) => day === true)
      )
      .required(schedule.requiredErrorMsg),
    [startDate.name]: startDateValidation,
    [endDate.name]: endDateValidation,
  }),
  // Products
  Yup.object().shape({
    [menuTypes.name]: Yup.array()
      .min(1, menuTypes.requiredErrorMsg)
      .required(menuTypes.requiredErrorMsg),
    [discounts.name]: Yup.array().of(
      Yup.object().shape({
        collectionId: Yup.string().when("isCustomCollection", {
          is: true,
          then: Yup.string().nullable(),
          otherwise: Yup.string().required("Please select a collection."),
        }),
        isCustomCollection: Yup.boolean().required(),
        customCollection: Yup.object().shape({
          name: Yup.string().required("Custom collection name is required"),
          filters: Yup.array().of(
            Yup.object()
              .shape({
                includedProductIds: Yup.array(),
                includedCategories: Yup.array(),
                includedSubCategories: Yup.array(),
                includedBrandIds: Yup.array(),
                weightFilterOperator: Yup.string(),
                weightFilterValue: Yup.string(),
              })
              .test(
                "atLeastOneIncluded",
                "Please include at least one product, category, or brand, and specify weight options.",
                function (value) {
                  return (
                    (value.includedProductIds?.length > 0 ||
                      value.includedCategories?.length > 0 ||
                      value.includedSubCategories?.length > 0 ||
                      value.includedBrandIds?.length > 0) &&
                    value.weightFilterOperator &&
                    value.weightFilterValue
                  );
                }
              )
          ),
        }),
        type: Yup.string()
          .nullable()
          .required("Please select a discount type."),
        value: Yup.number()
          .min(0, "Value must be a non-negative number")
          .required("Value is required."),
        maxValue: Yup.number().nullable(),
      })
    ),
  }),
];

const validationSchemaEditing = [
  // Basic Info
  Yup.object().shape({
    [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
    [enabled.name]: Yup.boolean(),
    [priority.name]: Yup.number()
      .min(1, priority.requiredErrorMsg)
      .required(priority.requiredErrorMsg),
    [promoCodeEnabled.name]: Yup.boolean(),
    [promoCode.name]: Yup.string().when(promoCodeEnabled.name, {
      is: true,
      then: Yup.string().required(`${promoCode.requiredErrorMsg}`),
      otherwise: Yup.string(),
    }),
    [promoCodeUsesPerUser.name]: Yup.number().when(promoCodeEnabled.name, {
      is: true,
      then: Yup.number()
        .min(1, promoCodeUsesPerUser.invalidErrorMsg)
        .required(`${promoCodeUsesPerUser.requiredErrorMsg}`),
      otherwise: Yup.number(),
    }),
    [maxUsesPerCart.name]: Yup.number()
      .min(1, maxUsesPerCart.invalidErrorMsg)
      .required(maxUsesPerCart.requiredErrorMsg),
    [terms.name]: Yup.string().nullable(),
  }),
  // Menu Preview
  Yup.object().shape({
    [schedule.name]: Yup.object()
      .test("at-least-one-day", schedule.requiredErrorMsg, (value) =>
        Object.values(value).some((day) => day === true)
      )
      .required(schedule.requiredErrorMsg),
    [startDate.name]: startDateValidation,
    [endDate.name]: endDateValidation,
  }),
  // Products
  Yup.object().shape({
    [menuTypes.name]: Yup.array()
      .min(1, menuTypes.requiredErrorMsg)
      .required(menuTypes.requiredErrorMsg),
    [discounts.name]: Yup.array().of(
      Yup.object().shape({
        collectionId: Yup.string().when("isCustomCollection", {
          is: true,
          then: Yup.string().nullable(),
          otherwise: Yup.string().required("Please select a collection."),
        }),
        isCustomCollection: Yup.boolean().required(),
        collection: Yup.object().shape({
          name: Yup.string().required("Custom collection name is required"),
          filters: Yup.array().of(
            Yup.object()
              .shape({
                includedProductIds: Yup.array(),
                includedCategories: Yup.array(),
                includedBrandIds: Yup.array(),
                includedSubCategories: Yup.array(),
                weightFilterOperator: Yup.string(),
                weightFilterValue: Yup.string(),
              })
              .test(
                "atLeastOneIncluded",
                "Please include at least one product, category, or brand, and specify weight options.",
                function (value) {
                  return (
                    (value.includedProductIds?.length > 0 ||
                      value.includedCategories?.length > 0 ||
                      value.includedSubCategories?.length > 0 ||
                      value.includedBrandIds?.length > 0) &&
                    value.weightFilterOperator &&
                    value.weightFilterValue
                  );
                }
              )
          ),
        }),
        type: Yup.string()
          .nullable()
          .required("Please select a discount type."),
        value: Yup.number()
          .min(0, "Value must be a non-negative number")
          .required("Value is required."),
        maxValue: Yup.number().nullable(),
      })
    ),
  }),
];

const validationSchemaBundle = [
  // Basic Info
  Yup.object().shape({
    [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
    [priority.name]: Yup.number()
      .min(1, priority.requiredErrorMsg)
      .required(priority.requiredErrorMsg),
    [promoCodeEnabled.name]: Yup.boolean(),
    [enabled.name]: Yup.boolean(),
    [promoCode.name]: Yup.string().when(promoCodeEnabled.name, {
      is: true,
      then: Yup.string().required(`${promoCode.requiredErrorMsg}`),
      otherwise: Yup.string(),
    }),
    [promoCodeUsesPerUser.name]: Yup.number().when(promoCodeEnabled.name, {
      is: true,
      then: Yup.number()
        .min(1, promoCodeUsesPerUser.invalidErrorMsg)
        .required(`${promoCodeUsesPerUser.requiredErrorMsg}`),
      otherwise: Yup.number(),
    }),
    [maxUsesPerCart.name]: Yup.number()
      .min(1, maxUsesPerCart.invalidErrorMsg)
      .required(maxUsesPerCart.requiredErrorMsg),
    [terms.name]: Yup.string().nullable(),
  }),
  // * Menu Preview
  Yup.object().shape({
    [schedule.name]: Yup.object()
      .test("at-least-one-day", schedule.requiredErrorMsg, (value) =>
        Object.values(value).some((day) => day === true)
      )
      .required(schedule.requiredErrorMsg),
    [startDate.name]: startDateValidation,
    [endDate.name]: endDateValidation,
  }),
  // * Qualifiers
  Yup.object().shape({
    [menuTypes.name]: Yup.array()
      .min(1, menuTypes.requiredErrorMsg)
      .required(menuTypes.requiredErrorMsg),
    [bundleQualifiers.name]: Yup.array().of(
      Yup.object().shape({
        isCustomCollection: Yup.boolean().required(),
        _filtersNotEmpty: Yup.boolean().when(['requiredWeight', 'requiredValue', 'requiredQuantity', 'customCollection.filters'], {
          is: (requiredWeight, requiredValue, requiredQuantity, filters) => 
            requiredWeight === null && requiredValue === null && requiredQuantity === null && (!filters || filters.length === 0),
          then: Yup.boolean().required('At least one filter must be set.'),
          otherwise: Yup.boolean().notRequired()
        }),
        customCollection: Yup.object().shape({
          filters: Yup.array().of(
            Yup.object()
              .shape({
                includedProductIds: Yup.array(),
                includedCategories: Yup.array(),
                includedBrandIds: Yup.array(),
                includedSubCategories: Yup.array(),
                weightFilterOperator: Yup.string(),
                weightFilterValue: Yup.string(),
              })
              .test(
                "atLeastOneIncluded",
                "Please select all required items.",
                function (value) {
                  const parentObject = this.options.context;
                  const requiredWeight = Boolean(
                    parentObject?.bundleQualifiers?.[0]?.requiredWeight
                  );
                  return (
                    (value.includedProductIds?.length > 0 ||
                      value.includedCategories?.length > 0 ||
                      value.includedSubCategories?.length > 0 ||
                      value.includedBrandIds?.length > 0) &&
                    ((!requiredWeight &&
                      value.weightFilterOperator &&
                      value.weightFilterValue) ||
                      requiredWeight)
                  );
                }
              )
          ),
        }),
      })
    ),
  }),
  // Rewards
  Yup.object().shape({
    bundleRewards: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required("Type is required"),
        discountType: Yup.string().when("type", {
          is: (val) =>
            [
              "PRODUCT",
              "BRAND",
              "CATEGORY",
              "CART_DISCOUNT",
              "BUNDLED_DISCOUNT",
            ].includes(val),
          then: Yup.string().required("Discount Type is required"),
          otherwise: Yup.string().notRequired(),
        }),
        value: Yup.mixed().when("type", {
          is: (val) =>
            [
              "PRODUCT",
              "BRAND",
              "CATEGORY",
              "PRICE",
              "CART_DISCOUNT",
              "BUNDLED_DISCOUNT",
            ].includes(val),
          then: Yup.mixed().required("Value is required"),
          otherwise: Yup.mixed(),
        }),
        productId: Yup.mixed().when("type", {
          is: "PRODUCT",
          then: Yup.mixed().nullable(false).required("Product ID is required"),
          otherwise: Yup.mixed(),
        }),
        brandId: Yup.string().when("type", {
          is: "BRAND",
          then: Yup.string().required("Brand ID is required"),
          otherwise: Yup.string(),
        }),
        category: Yup.string().when("type", {
          is: "CATEGORY",
          then: Yup.string().required("Category is required"),
          otherwise: Yup.string(),
        }),
        weight: Yup.mixed().when("type", {
          is: (val) => ["PRODUCT", "BRAND", "CATEGORY"].includes(val),
          then: Yup.mixed().required("Weight is required"),
          otherwise: Yup.mixed(),
        }),
        quantity: Yup.number().min(1, "Quantity must be a number greater than 0").required("Quantity is required"),
      })
    ).min(1, "At least one reward is required"),
  }),
];

export { validationSchema, validationSchemaBundle, validationSchemaEditing };
