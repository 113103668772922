import axios, { AxiosRequestConfig } from "axios";
import config from "../config";
import { businessConstants } from "../constants";
import { apiHelper, authHeader, axiosRequestConfig } from "../helpers";
import { BusinessCreate, BusinessUpdate } from "../types/business";
import BusinessSettings from "../types/businessSettings";
import { CreateTaxSetting, UpdateTaxSetting } from "../types/native-ecommerce";

interface UpdateBusinessLogoDto {
  file: BusinessUpdate["file"];
  id: BusinessUpdate["id"];
}

interface UpdateDeliveryAreaDto {
  deliveryAreaFile: BusinessUpdate["deliveryAreaFile"];
  id: BusinessUpdate["id"];
}

export const businessService = {
  getBusiness,
  getBusinesses,
  getReviews,
  getPredictions,
  updateBusiness,
  addBusiness,
  generateApiKey,
  revokeApiKey,
  generatePhoneNumber,
  revokePhoneNumber,
  updateBusinessDeliveryArea,
  deleteBusinessDeliveryArea,
  getBusinessSettings,
  updateBusinessSettings,
  getGmbLocations,
  getAllByPostalCode,
  addBusinessTax,
  updateBusinessTax,
  deleteBusinessTax,
};

/**
 * Get account details
 * @param id
 */
function getBusiness(id: number) {
  const requestConfig: AxiosRequestConfig = {
    method: "GET",
    headers: authHeader(),
  };

  return axios
    .get(`${config.apiUrl}/business/${id}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function getBusinesses(queryString: string) {
  const requestConfig: AxiosRequestConfig = {
    method: "GET",
    headers: authHeader(),
  };
  return axios
    .get(
      `${config.apiUrl}/business${
        (queryString ?? "").length > 0 ? `?${queryString}` : ""
      }`,
      requestConfig
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Get reviews for a given dispensary
 * @param  {[type]} businessId [description]
 * @return {[type]}              [description]
 */
function getReviews(businessId: number) {
  const auth = authHeader();
  const requestOptions: AxiosRequestConfig = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...auth,
    },
    params: {
      dispensaryId: businessId,
    },
  };
  return axios
    .get(`${config.apiUrl}/search/internal/reviews`, requestOptions)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Get search predictions
 * @param query
 */
function getPredictions(query: string) {
  const requestConfig: AxiosRequestConfig = {
    method: "GET",
    headers: authHeader(),
    params: !query
      ? undefined
      : {
          q: query,
        },
  };

  return axios
    .get(`${config.apiUrl}/search/internal/name`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Update business data
 * @param newData
 */
function updateBusiness(newData: Partial<BusinessUpdate>) {
  if (newData.file && newData.id) {
    return updateBusinessLogo({ id: newData.id, file: newData.file });
  }

  if (newData.menuSettings?.deliveryArea && newData.id) {
    return updateBusinessDeliveryArea({
      deliveryAreaFile: newData.menuSettings?.deliveryArea,
      id: newData.id,
    });
  }

  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      "Content-Type": newData.file ? "multipart/form-data" : "application/json",
      ...auth,
    },
  };

  return axios
    .patch(`${config.apiUrl}/business`, newData, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function addBusiness(newData: BusinessCreate) {
  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      ...auth,
    },
  };

  return axios
    .post(`${config.apiUrl}/business`, newData, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function updateBusinessTax(newData: UpdateTaxSetting) {
  const requestConfig = axiosRequestConfig();

  return axios
    .patch(
      `${config.apiUrl}/menus/${newData.businessId}/tax`,
      newData,
      requestConfig
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function addBusinessTax(newData: CreateTaxSetting) {
  const requestConfig = axiosRequestConfig();

  return axios
    .post(
      `${config.apiUrl}/menus/${newData.businessId}/tax`,
      newData,
      requestConfig
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function deleteBusinessTax(businessId: number, taxId: number) {
  const requestConfig = axiosRequestConfig();

  return axios
    .delete(`${config.apiUrl}/menus/${businessId}/tax/${taxId}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Update Business Logo
 * @param newData
 */
function updateBusinessLogo(newData: UpdateBusinessLogoDto) {
  const auth = authHeader();
  let formData = new FormData();
  formData.append("file", newData.file);

  const requestConfig: AxiosRequestConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      ...auth,
    },
    params: {
      businessId: newData.id,
    },
  };

  return axios
    .patch(
      `${config.apiUrl}/business/${newData.id}/logo`,
      formData,
      requestConfig
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Update Business Delivery Area
 * @param newData
 */
function updateBusinessDeliveryArea(newData: UpdateDeliveryAreaDto) {
  const auth = authHeader();
  let formData = new FormData();
  formData.append("file", newData.deliveryAreaFile);

  const requestConfig: AxiosRequestConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      ...auth,
    },
    params: {
      businessId: newData.id,
    },
  };

  return axios
    .patch(
      `${config.apiUrl}/business/${newData.id}/delivery`,
      formData,
      requestConfig
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Delete Delivery Area
 */
function deleteBusinessDeliveryArea(newData: Partial<BusinessSettings>) {
  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      ...auth,
    },
  };

  return axios
    .delete(`${config.apiUrl}/business/${newData.id}/delivery`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Get business settings
 * @param businessId
 */
function getBusinessSettings(businessId: number) {
  const requestConfig: AxiosRequestConfig = {
    headers: authHeader(),
  };

  return axios
    .get(`${config.apiUrl}/business-settings/get/${businessId}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Update Business Settings
 * @param newData
 */
function updateBusinessSettings(newData: Partial<BusinessSettings>) {
  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      ...auth,
    },
  };
  return axios
    .patch(`${config.apiUrl}/business-settings/update`, newData, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Generate API Key
 */
function generateApiKey(newData: Partial<BusinessSettings>) {
  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      ...auth,
    },
  };
  return axios
    .post(
      `${config.apiUrl}/business-settings/${newData.businessId}/keygen`,
      newData,
      requestConfig
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Revoke API Key
 */
function revokeApiKey(newData: Partial<BusinessSettings>) {
  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      ...auth,
    },
  };

  return axios
    .delete(
      `${config.apiUrl}/business-settings/${newData.businessId}/keyrevoke`,
      requestConfig
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Generate API Key
 */
function generatePhoneNumber(newData: Partial<BusinessSettings>) {
  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      ...auth,
    },
  };
  return axios
    .post(
      `${config.apiUrl}/business-settings/${newData.businessId}/smsgen`,
      newData,
      requestConfig
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Revoke API Key
 */
function revokePhoneNumber(newData: Partial<BusinessSettings>) {
  const auth = authHeader();
  const requestConfig: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      ...auth,
    },
  };

  return axios
    .delete(
      `${config.apiUrl}/business-settings/${newData.businessId}/smsrevoke`,
      requestConfig
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Get available GMB locations
 */
async function getGmbLocations() {
  const requestConfig: AxiosRequestConfig = {
    headers: authHeader(),
  };
  return axios
    .get(`${config.apiUrl}/gmb/locations`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

interface RequestConfig {
  requestType: string;
  postalCode: string;
  includeMap: boolean;
  page: number;
  distance?: number | boolean;
}

/**
 * Get all businesses by zip code
 * @param  {[type]} zip [description]
 * @return {[type]}     [description]
 */
function getAllByPostalCode(requestConfig: RequestConfig) {
  const auth = authHeader();
  const requestOptions: AxiosRequestConfig = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...auth,
    },
    params: {
      page: requestConfig.page || 1,
      take: businessConstants.DEFAULT_API_TAKE,
      distance: requestConfig.distance || false,
    },
  };

  if (requestConfig.includeMap) {
    requestOptions.params.includeMap = true;
  }

  return axios
    .get(
      `${config.apiUrl}/search/internal/postalCode/${requestConfig.postalCode}`,
      requestOptions
    )
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}
