import {Box as MuiBox, Typography} from "@mui/material";


export function WidgetBoxTitle(props) {
  

  return (
      <MuiBox >
        <Typography  variant={props.variant || 'h6'} component="div">{props.children}</Typography>
      </MuiBox>
  );
}
