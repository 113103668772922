import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {locationService} from '../services';

export const getDeviceLocation = createAsyncThunk(
    'location/getDeviceLocation',
    async () => {
        let locationResponse = null;
        const navigatorResponse = await locationService.getDeviceLocation();
        if (navigatorResponse) {
            locationResponse = await locationService.getLocationByLatLong(navigatorResponse.latitude, navigatorResponse.longitude);
        }
        return (navigatorResponse && locationResponse) ? { navigator: navigatorResponse, locationDetails: locationResponse[0] } : null;
    }
);

export const getIpLocation = createAsyncThunk(
    'location/getIpLocation',
    async () => {
        let locationResponse = null;
        const ipResponse= await locationService.getIpLocation();
        if (ipResponse) {
            locationResponse = await locationService.getLocationByLatLong(ipResponse.latitude, ipResponse.longitude);
        }
        return (ipResponse && locationResponse) ? { locationDetails:locationResponse[0] } : null;
    }
);

export const locationSlice = createSlice({
    name: 'location',
    initialState: {
        selectedLocation: null,
        isLoading: false,
        error: null,
        deviceLocation: null,
        deviceLocationStatus: 'idle',
    },
    reducers: {
        changeLocation: (state, action) => {
            state.selectedLocation = action.payload;
        },
    },
    extraReducers: {
        [getIpLocation.pending]: (state, action) => {
            state.error = null;
        },
        [getIpLocation.fulfilled]: (state, action) => {
            if (action.payload) {
                state.deviceLocation = action.payload;
                if (!state.selectedLocation) {
                    state.selectedLocation = action.payload.locationDetails;
                }
            } else {
                state.deviceLocationStatus = 'error';
            }
        },
        [getIpLocation.rejected]: (state, action) => {
            state.deviceLocationStatus = 'error';
        },
        [getDeviceLocation.pending]: (state, action) => {
            state.deviceLocationStatus = 'loading';
            state.error = null;
        },
        [getDeviceLocation.fulfilled]: (state, action) => {
            if (action.payload) {
                state.deviceLocationStatus = 'succeeded';
                state.deviceLocation = action.payload ;
            } else {
                state.deviceLocationStatus = 'error';
                alert("You need to enable geolocation services in your browser to use this feature.");
            }
        },
        [getDeviceLocation.rejected]: (state, action) => {
            state.deviceLocationStatus = 'error';
            alert("You need to enable geolocation services in your browser to use this feature.");
        },
    }
});

export const { changeLocation } = locationSlice.actions;

export default locationSlice.reducer;
