import React, { useEffect, useState } from "react";

// project import
import SpecialEdit from "components/SpecialCreate/SpecialEdit";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useParams } from "react-router-dom";
import { getBusiness, getBusinessSettings } from "redux/businessSlice";
import { getSpecialById } from "redux/nativeEcommerceSlice";
import BundleEdit from "components/SpecialCreate/BundleEdit";


// ==============================|| DASHBOARD - DEFAULT ||============================== //

const BusinessEcommerceSpecialsEdit = () => {
  // Setup dispatch
  const dispatch = useAppDispatch();
  // Get business information from Redux
  const business = useAppSelector((state) => state.business.info);
  // Get special ID from params
  const { specialId } = useParams();
  // Make a special state
  const [special, setSpecial] = useState(null);
  // Function to refresh business information
  const refreshBusiness = async () => {
    // Get business information
    dispatch(getBusiness(business.id));
    // Get business settings
    dispatch(getBusinessSettings(business.id));
    // Get special by ID
    const publicKey = business.menuSettings?.jointEcommercePublicKey || "defaultPublicKey";
    const response = await dispatch(getSpecialById({ specialId, key: publicKey }));
    if (response.payload) {
      setSpecial(response.payload);
    }
  };
  // Use effect to refresh business information
  useEffect(() => {
    refreshBusiness();
  }, []);

  return (
    <React.Fragment>
      <React.Fragment>
        {special?.specialType === "PRODUCT" && (
          <SpecialEdit business={business} special={special} />
        )}
        {special?.specialType === "BUNDLE" && (
          <BundleEdit business={business} special={special} />
        )}
      </React.Fragment>

    </React.Fragment>
  );
};

export default BusinessEcommerceSpecialsEdit;
