export const locationConstants = {
  STATE_DETAILS: {
    'alaska': {
      displayName: 'Alaska',
      abbr: 'ak',
    },
    'arizona': {
      displayName: 'Arizona',
      abbr: 'az',
    },
    'arkansas': {
      displayName: 'Arkansas',
      abbr: 'ar',
    },
    'california': {
      displayName: 'California',
      abbr: 'ca',
    },
    'colorado': {
      displayName: 'Colorado',
      abbr: 'co',
    },
    'connecticut': {
      displayName: 'Connecticut',
      abbr: 'ct',
    },
    'delaware': {
      displayName: 'Delaware',
      abbr: 'de',
    },
    'district of columbia': {
      displayName: 'District of Columbia',
      abbr: 'dc',
    },
    'florida': {
      displayName: 'Florida',
      abbr: 'fl',
    },
    'hawaii': {
      displayName: 'Hawaii',
      abbr: 'hi',
    },
    'illinois': {
      displayName: 'Illinois',
      abbr: 'il',
    },
    'iowa': {
      displayName: 'Iowa',
      abbr: 'ia',
    },
    'louisiana': {
      displayName: 'Louisiana',
      abbr: 'la',
    },
    'maine': {
      displayName: 'Maine',
      abbr: 'me',
    },
    'maryland': {
      displayName: 'Maryland',
      abbr: 'md',
    },
    'massachusetts': {
      displayName: 'Massachusetts',
      abbr: 'ma',
    },
    'michigan': {
      displayName: 'Michigan',
      abbr: 'mi',
    },
    'minnesota': {
      displayName: 'Minnesota',
      abbr: 'mn',
    },
    'mississippi': {
      displayName: 'Mississippi',
      abbr: 'ms',
    },
    'missouri': {
      displayName: 'Missouri',
      abbr: 'mo',
    },
    'montana': {
      displayName: 'Montana',
      abbr: 'mt',
    },
    'nevada': {
      displayName: 'Nevada',
      abbr: 'nv',
    },
    'new hampshire': {
      displayName: 'New Hampshire',
      abbr: 'nh',
    },
    'new jersey': {
      displayName: 'New Jersey',
      abbr: 'nj',
    },
    'new mexico': {
      displayName: 'New Mexico',
      abbr: 'nm',
    },
    'new york': {
      displayName: 'New York',
      abbr: 'ny',
    },
    'north dakota': {
      displayName: 'North Dakota',
      abbr: 'nd',
    },
    'ohio': {
      displayName: 'Ohio',
      abbr: 'oh',
    },
    'oklahoma': {
      displayName: 'Oklahoma',
      abbr: 'ok',
    },
    'oregon': {
      displayName: 'Oregon',
      abbr: 'or',
    },
    'pennsylvania': {
      displayName: 'Pennsylvania',
      abbr: 'pa',
    },
    'rhode island': {
      displayName: 'Rhode Island',
      abbr: 'ri',
    },
    'south dakota': {
      displayName: 'South Dakota',
      abbr: 'sd',
    },
    'utah': {
      displayName: 'Utah',
      abbr: 'ut',
    },
    'vermont': {
      displayName: 'Vermont',
      abbr: 'vt',
    },
    'virginia': {
      displayName: 'Virginia',
      abbr: 'va',
    },
    'washington': {
      displayName: 'Washington',
      abbr: 'wa',
    },
    'west virginia': {
      displayName: 'West Virginia',
      abbr: 'wv',
    },
  }
}
