import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface LayoutState {
  mobileOpen: boolean;
}

const initialState: LayoutState = {
  mobileOpen: false,
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setMobileOpen: (state, action: PayloadAction<boolean>) => {
      state.mobileOpen = action.payload
    },
  },
});

// Export action creators
export const { setMobileOpen } = layoutSlice.actions;

// Export reducer
export default layoutSlice.reducer;
