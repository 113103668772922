import { InfoOutlined } from "@mui/icons-material";
import {
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { AlertType } from "types/alertTypes";
import TransitionAlerts from "./TransitionAlert";

const InfoSection = ({ title, description }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "1rem 0 0",
        }}
      >
        <Typography
          variant="caption"
          component="p"
          style={{ marginRight: "0.25rem" }}
        >
          {title}
        </Typography>
        <IconButton
          onClick={() => setOpen(!open)}
          size="small"
          color="info"
        >
          <InfoOutlined fontSize="small" />
        </IconButton>
      </div>
      <div>
        <TransitionAlerts
          title={title}
          description={description}
          type={AlertType.Info} // Assuming you have AlertType defined somewhere
          transitionOpen={open}
          setTransitionOpen={setOpen}
        />
      </div>
    </div>
  );
};

export default InfoSection;
