import { lazy } from "react";
import Loadable from "components/Loadable";

const AdminDashboardPage = Loadable(lazy(() => import("pages/admin/admin-dashboard.page")));
const AdminUsersPage = Loadable(lazy(() => import("pages/admin/admin-users.page")));
const AdminApiPage = Loadable(lazy(() => import("pages/admin/admin-api.page")));
const AdminEcommercePage = Loadable(
  lazy(() => import("pages/admin/admin-ecommerce.page"))
);
const AdminCatalogBatchManagerPage = Loadable(
  lazy(() => import("pages/admin/admin-catalog-batch-manager.page"))
);
const AdminCatalogMasterManagerPage = Loadable(
  lazy(() => import("pages/admin/admin-catalog-master-manager.page"))
);
const AdminMccPage = Loadable(lazy(() => import("pages/admin/admin-mcc.page")));

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = [
  {
    index: true,
    element: <AdminDashboardPage title="Admin" />,
  },
  {
    path: "users",
    element: <AdminUsersPage title="Admin Users" />,
    handle: {
      crumb: () => 'Users',
    },
  },
  {
    path: "api",
    element: <AdminApiPage title="API Keys" />,
    handle: {
      crumb: () => 'Api',
    },
  },
  {
    path: "api/access-logs",
    element: <AdminApiPage title="Access Logs" />,
    handle: {
      crumb: () => 'Access Logs',
    },
  },
  {
    path: "ecommerce",
    element: <AdminEcommercePage title="Ecommerce Schedule" />,
    handle: {
      crumb: () => 'Ecommerce',
    },
  },
  {
    path: "catalog-batch-manager",
    element: <AdminCatalogBatchManagerPage title="Batch Catalog Manager" />,
    handle: {
      crumb: () => 'Batch Catalog Manager',
    },
  },
  {
    path: "catalog-master-manager",
    element: <AdminCatalogMasterManagerPage title="Master Catalog Manager" />,
    handle: {
      crumb: () => 'Master Catalog Manager',
    },
  },
  {
    path: "mcc",
    element: <AdminMccPage title="Manage MCC's" />,
    handle: {
      crumb: () => 'MCC',
    },
  },
];

export default AdminRoutes;
