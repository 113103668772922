interface Config {
  apiUrl: string;
  ecommerceApiUrl: string;
  tomTomApiUrl: string;
  tomTomApiKey: string;
  abstractApiKey: string;
  abstractApiUrl: string;
  googleMapsApiKey: string;
  hubspotAppId: string;
  hubspotClientId: string;
  hubspotClientSecret: string;
  hubspotScope: string;
  jointApiKey: string;
  jointEcommerceApiKey: string;
  dutchiePlusUri: string;
  defaultPath: string;
  elasticSearchUrl: string;
  elasticSearchApiKey: string;
  elasticSearchIndex: string;
}

const config: Config = {
  apiUrl: process.env.REACT_APP_API_URL!,
  ecommerceApiUrl: process.env.REACT_APP_ECOMMERCE_API_URL!,
  tomTomApiUrl: 'https://api.tomtom.com'!,
  tomTomApiKey: process.env.REACT_APP_TOM_TOM_API_KEY!,
  abstractApiKey: process.env.REACT_APP_ABSTRACT_API_KEY!,
  abstractApiUrl: process.env.REACT_APP_ABSTRACT_API_URL!,
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  hubspotAppId: process.env.REACT_APP_HUBSPOT_APP_ID!,
  hubspotClientId: process.env.REACT_APP_HUBSPOT_CLIENT_ID!,
  hubspotClientSecret: process.env.REACT_APP_HUBSPOT_CLIENT_SECRET!,
  hubspotScope: process.env.REACT_APP_HUBSPOT_SCOPE!,
  jointApiKey: process.env.REACT_APP_JOINT_API_KEY!,
  jointEcommerceApiKey: process.env.REACT_APP_JOINT_ECOMMERCE_API_KEY!,
  dutchiePlusUri: process.env.REACT_APP_DUTCHIE_PLUS_URI!,
  defaultPath: "/businesses",
  elasticSearchUrl: process.env.REACT_APP_ELASTIC_SEARCH_URL!,
  elasticSearchApiKey: process.env.REACT_APP_ELASTIC_SEARCH_API_KEY!,
  elasticSearchIndex: process.env.REACT_APP_ELASTIC_SEARCH_INDEX!,
};
export default config;
