import axios, { AxiosRequestConfig } from 'axios';
import config from '../config';
import { apiHelper, authHeader } from '../helpers';

export const managementService = {
  getApiKeys,
  addApiKey,
  deleteApiKey
}

function getApiKeys() {
  const requestConfig: AxiosRequestConfig = {
    headers: {
      method: 'GET',
      ...authHeader()
    }
  };

  return axios.get(`${config.apiUrl}/external/keys`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function addApiKey(name: string) {
  const requestConfig: AxiosRequestConfig = {
    headers: {
      method: 'GET',
      ...authHeader()
    }
  };

  return axios.post(`${config.apiUrl}/external/keys?name=${name}`, {}, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function deleteApiKey(name: string) {
  const requestConfig: AxiosRequestConfig = {
    headers: {
      method: 'GET',
      ...authHeader()
    }
  };

  return axios.delete(`${config.apiUrl}/external/keys?name=${name}`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}
