import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  LogoutOutlined,
  UserOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

interface MyAccountMenuProps {
  handleLogout: () => void;
  buttonClickedFn?: () => void;
  closeDrawerOnClick?: boolean;
}

export const MyAccountMenu = ({ handleLogout, buttonClickedFn, closeDrawerOnClick }: MyAccountMenuProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (link: string, index: number) => {
    if(closeDrawerOnClick) {
      buttonClickedFn && buttonClickedFn();
    }
    setSelectedIndex(index);
    navigate(link);
  };

  return (
    <List
      component="nav"
      sx={{
        p: 0,
        "& .MuiListItemIcon-root": {
          minWidth: 32,
          color: theme.palette.grey[500],
        },
      }}
    >
      <ListItemButton
        selected={selectedIndex === 1}
        onClick={(event) => handleListItemClick("/my-account", 1)}
      >
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary="View Profile" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 2}
        onClick={(event) => handleListItemClick("/support", 1)}
      >
        <ListItemIcon>
          <CustomerServiceOutlined />
        </ListItemIcon>
        <ListItemText primary="Support" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 4} onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

export default MyAccountMenu;
