import { Box } from "@mui/material";
import { useLayoutState } from "hooks/use-layout-state.hooks";
import { AppLayout } from "layout/app-layout";
import { APP_DRAWER_WIDTH, NAVIGATION_TYPE, NAVIGATION_WIDTH } from "layout/constants";
import { LayoutBox } from "layout/layout-box";
import { Navigation } from "layout/navigation";
import { useOrganizationNav } from "layout/navigation/hooks";
import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getOrganization } from "redux/organizationSlice";

export function OrganizationLayout() {
  const dispatch = useAppDispatch();
  const { orgId: urlOrgId } = useParams();
  const stateOrgId = useAppSelector((state) => state.organization.info?.id);
  const { navItems } = useOrganizationNav();

  React.useEffect(() => {
    if ((urlOrgId && !stateOrgId) || (urlOrgId && +urlOrgId !== stateOrgId)) {
      dispatch(getOrganization(+urlOrgId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlOrgId, stateOrgId]);

  return (
    <AppLayout>
      <Navigation navItems={navItems} navigationType={"DEFAULT"}/>
      <LayoutBox>
        {stateOrgId && <Outlet />}
      </LayoutBox>
    </AppLayout>
  );
}
