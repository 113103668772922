import InfoIcon from "@mui/icons-material/Info";
import { Box, Chip, Grid, Skeleton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getBusiness } from "redux/businessSlice";
import { nativeEcommerceService } from "services";
import { getProducts } from "services/productService";
import { Button } from "components/shared";
import { createProductIdSearchData } from "components/businesses/ecommerce/specials/modals/data/productSearchData";

const OPERATOR_LABEL = {
  EQUALS: "Equal to",
  GREATER_THAN_OR_EQUAL_TO: "Greater than or equal to",
  LESS_THAN_OR_EQUAL_TO: "Less than or equal to",
};

export default function BundleQualifierPreview({ bundle, businessId, index }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [collection, setCollection] = useState(null);
  const [includedProducts, setIncludedProducts] = useState([]);
  const [excludedProducts, setExcludedProducts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const collectionKey = "customCollection";

  const handleCollectionClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const fetchCollection = async () => {
      try {
        setLoading(true); // Set loading to true when starting the fetch
        const businessResponse = await dispatch(getBusiness(businessId));
        const publicKey =
          businessResponse.payload.menuSettings?.jointEcommercePublicKey ||
          "defaultPublicKey";
        const collectionResponse =
          await nativeEcommerceService.getCollectionById(
            bundle.collectionId,
            publicKey
          );
        setCollection(collectionResponse);
      } catch (error) {
        console.error("Error fetching collection:", error);
      } finally {
        setLoading(false); // Set loading to false when the fetch is done (whether successful or not)
      }
    };

    const mapProducts = (items) => {
      if (!Array.isArray(items)) {
        return [];
      }
      return items.map((item) => (typeof item === "string" ? item : item._id));
    };

    const mapIdsToKeys = (items) => {
      if (!Array.isArray(items)) {
        return [];
      }
      return items.map((item) => (item ? (typeof item === "string" ? item : item.key) : null)).filter(Boolean);
    };
    

    if (bundle.isCustomCollection) {
      const updatedCollection = {
        ...bundle?.[collectionKey],
        filters: bundle?.[collectionKey].filters.map((filter) => ({
          ...filter,
          includedProductIds: mapProducts(filter.includedProductIds || []),
          excludedProductIds: mapProducts(filter.excludedProductIds || []),
          includedCategories: mapIdsToKeys(filter.includedCategories || []),
          excludedCategories: mapIdsToKeys(filter.excludedCategories || []),
          includedBrandIds: mapIdsToKeys(filter.includedBrandIds || []),
          excludedBrandIds: mapIdsToKeys(filter.excludedBrandIds || []),
        })),
      };
      setCollection(updatedCollection);
    } else if (!bundle.isCustomCollection && bundle.collectionId) {
      fetchCollection();
    }
  }, [bundle.collectionId]);

  useEffect(() => {
    let active = true;
    if (!loading && collection && collection.filters) {
      const productIds = collection.filters.flatMap((filter) => [
        ...filter.includedProductIds,
        ...filter.excludedProductIds,
      ]);
      const data = createProductIdSearchData(businessId, productIds);

      (async () => {
        try {
          const response = await getProducts(data);
          const allProducts = response?.hits?.hits;
          const includedProducts = allProducts.filter((product) =>
            collection.filters.some((filter) =>
              filter.includedProductIds.includes(product._source.jointId)
            )
          );
          const excludedProducts = allProducts.filter((product) =>
            collection.filters.some((filter) =>
              filter.excludedProductIds.includes(product._source.jointId)
            )
          );
          if (active) {
            setIncludedProducts(includedProducts);
            setExcludedProducts(excludedProducts);
          }
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      })();
    }

    return () => {
      active = false;
    };
  }, [loading, collection, businessId]);
  return (
    <Grid container marginY={1}>
      <Grid item xs={3}>
        <div>
          <Typography variant={"h6"} fontWeight={"bold"}>
            Bundle Qualifiers:
          </Typography>
        </div>
        {loading ? (
          // Show a skeleton while loading
          <Skeleton variant="text" />
        ) : (
          // Show the collection name if available
          // Show the collection name if available
          bundle.customCollection.filters.length > 0 && (
            <Chip
              label={`Qualifier ${index + 1}`}
              icon={<InfoIcon />}
              onClick={handleCollectionClick}
            />
          )
        )}
      </Grid>
      <Grid container my={1}>
        <Box display="flex" alignItems="center" columnGap={1} flexWrap={"wrap"}>
          {bundle.requiredWeight && (
            <Chip label={`Required Weight: ${OPERATOR_LABEL[bundle.operator]} ${bundle.requiredWeight}`} />
          )}
          {bundle.requiredValue && (
            <Chip label={`Required Spend: $${bundle.requiredValue}`} />
          )}
          {bundle.requiredQuantity !== null && bundle.requiredQuantity !== undefined && (
            <Chip label={`Required Quantity: ${bundle.requiredQuantity}`} />
          )}
        </Box>
      </Grid>

      {collection && (
        <Dialog
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            minWidth: "200px",
          }}
        >
          <DialogTitle id="alert-dialog-title" fontWeight={"bold"}>
            {bundle.isCustomCollection ? "Custom Collection" : collection.name}
          </DialogTitle>
          <DialogContent>
            {collection.filters.map((filter, index) => (
              <div key={`filter-${index}`}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  fontWeight={"bold"}
                >
                  Filter {index + 1}:
                </Typography>
                {filter.includedBrandIds &&
                  filter.includedBrandIds.length > 0 && (
                    <Typography variant="body2" gutterBottom>
                      Included Brands: {filter.includedBrandIds.join(", ")}
                    </Typography>
                  )}
                {filter.excludedBrandIds &&
                  filter.excludedBrandIds.length > 0 && (
                    <Typography variant="body2" gutterBottom>
                      Excluded Brands: {filter.excludedBrandIds.join(", ")}
                    </Typography>
                  )}
                {filter.includedCategories &&
                  filter.includedCategories.length > 0 && (
                    <Typography variant="body2" gutterBottom>
                      Included Categories:{" "}
                      {filter.includedCategories.join(", ")}
                    </Typography>
                  )}
                {filter.excludedCategories &&
                  filter.excludedCategories.length > 0 && (
                    <Typography variant="body2" gutterBottom>
                      Excluded Categories:{" "}
                      {filter.excludedCategories.join(", ")}
                    </Typography>
                  )}
                {filter.includedProductIds &&
                  filter.includedProductIds.length > 0 && (
                    <Typography variant="body2" gutterBottom>
                      Included Products:{" "}
                      {filter.includedProductIds
                        .map((productId) => {
                          const product = includedProducts.find(
                            (p) => p._source.jointId === productId
                          );
                          return product ? product._source?.name : "Unknown";
                        })
                        .join(", ")}
                    </Typography>
                  )}

                {filter.excludedProductIds &&
                  filter.excludedProductIds.length > 0 && (
                    <Typography variant="body2" gutterBottom>
                      Excluded Products:{" "}
                      {filter.excludedProductIds
                        .map((productId) => {
                          const product = excludedProducts.find(
                            (p) => p._source.jointId === productId
                          );
                          return product ? product._source?.name : "Unknown";
                        })
                        .join(", ")}
                    </Typography>
                  )}
                {filter.weightFilterOperator && filter.weightFilterValue && (
                  <Typography variant="body2" gutterBottom>
                    Weight: {filter.weightFilterOperator}{" "}
                    {filter.weightFilterValue}
                  </Typography>
                )}
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Grid>
  );
}
