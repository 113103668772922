import { useFormikContext } from "formik";
import specialFormModel from "components/SpecialCreate/FormModel/specialFormModel";
import styles from "./FilterOptions.module.css";

import BrandRewardOption from "./rewards/BrandRewardOption";
import BundledRewardOption from "./rewards/BundledRewardOption";
import CartRewardOption from "./rewards/CartRewardOption";
import CategoryRewardOption from "./rewards/CategoryRewardOption";
import PriceRewardOption from "./rewards/PriceRewardOption";
import ProductRewardOption from "./rewards/ProductRewardOption";
import { JSXElementConstructor } from "react";
const { formField } = specialFormModel;

interface RewardComponents {
  [key: string]: JSXElementConstructor<any>;
}

const rewardComponents: RewardComponents = {
  PRODUCT: ProductRewardOption,
  BRAND: BrandRewardOption,
  CATEGORY: CategoryRewardOption,
  BUNDLED_DISCOUNT: BundledRewardOption,
  PRICE: PriceRewardOption,
  CART_DISCOUNT: CartRewardOption,
};

interface BundleRewardOptionsProps {
  rewardIndex: number;
  rewardType: string;
  rewards: any;
  reward: any;
}

export function BundleRewardOptions({
  rewardIndex,
  rewardType,
  rewards,
  reward,
}: BundleRewardOptionsProps) {
  const rewardId = `filter-${rewardIndex}`;
  // Function to render out correct form fields
  function renderRewardOptions() {
    const RewardComponent = rewardComponents[rewardType];
    if (RewardComponent) {
      return (
        <RewardComponent
          rewardIndex={rewardIndex}
          reward={reward}
          rewards={rewards}
        />
      );
    }
    return null; // Fallback component for unknown types
  }

  return (
    <div id={rewardId}>
      <div className={styles.filterContainer}>
        <div className={styles.filterOptions} style={{ width: "100%" }}>
          {renderRewardOptions()}
        </div>
      </div>
    </div>
  );
}

export default BundleRewardOptions;
