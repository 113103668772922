import { Box, Chip, Typography } from "@mui/material";
import { Button } from "components/shared";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import BundleCollectionWeight from "components/businesses/ecommerce/specials/collections/BundleCollectionWeight";
import SelectDialog from "../dialogs/select-dialog/select-dialog.component";
import { createBrandSearchData, createCategorySearchData } from "../modals/data/productSearchData";
import { getProducts } from "services/productService";
import BrandSelectDialog from "../dialogs/select-dialog/brand-select-dialog.component";

export default function CategoryOption({
  filterIndex,
  setSelectedSubCategories,
  setSelectedCategories,
  setSelectedBrands,
  weightFilter,
  setWeightFilter,
  weightValue,
  setWeightValue,
  weightFilterErrorMessage,
  setWeightFilterErrorMessage,
  weightValueErrorMessage,
  setWeightValueErrorMessage,
}) {
  // * Formik Context
  const formik = useFormikContext();
  const { values, setFieldValue } = formik;
  // * Getting business id for elastic search
  const business = useAppSelector((state) => state.business.info);
  const businessId = business?.id;
  // * Setting state for autocomplete values
  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
  const [brandDialogOpen, setBrandDialogOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  // * Handle category dialog open
  const handleOpenCategoryDialog = () => {
    setCategoryDialogOpen(true);

  };
  // * Handle category dialog close
  const handleCloseCategoryDialog = () => {
    setCategoryDialogOpen(false);
  };

  const handleOpenBrandDialog = () => {
    setBrandDialogOpen(true);
  };

  const handleCloseBrandDialog = () => {
    setBrandDialogOpen(false);
  };

  // * Handle category change
  const handleCategoryChange = (selectedCategories, selectedSubCategories) => {
    if (selectedCategories.length === 0 && selectedSubCategories.length === 0) {
      setSelectedBrands([]);
    };
    setSelectedCategories(selectedCategories);
    setSelectedSubCategories(selectedSubCategories);
  };

  const handleBrandChange = (selectedBrands) => {
    setSelectedBrands(selectedBrands);
  };

  const fetchData = async () => {
    const includedCategories = (values.bundleQualifiers[0]?.customCollection?.filters[filterIndex]?.includedCategories || []).filter(category => category !== null);
    const includedSubCategories = values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedSubCategories || [];
    const includedBrandKeys = values.bundleQualifiers[0]?.customCollection?.filters[filterIndex]?.includedBrandIds.map(brand => brand.key) || [];

    const categoryData = createCategorySearchData(businessId, includedBrandKeys);
    const categoryResponse = await getProducts(categoryData);
    setCategories(categoryResponse.aggregations.categories.buckets);



    let brandsFound = [];
    let commonBrands = [];
    const brandData = createBrandSearchData(businessId, includedCategories, includedSubCategories);
    const brandsResponse = await getProducts(brandData);
    // Identify common brands
    brandsResponse.aggregations.brands.buckets.forEach((brand) => {
      brandsFound.push(brand);
      if (brandsFound.filter((b) => b.key === brand.key).length >= brandsResponse.aggregations.brands.buckets.length) {
        commonBrands.push(brand);
      }
    });
    if (includedCategories.length > 1) {
      setBrands(commonBrands);
    } else {
      setBrands(brandsResponse.aggregations.brands.buckets);
    }
  };

  useEffect(() => {
    fetchData();
  }, [categoryDialogOpen, brandDialogOpen]);
  console.log("values", values);

  return (
    <div>
      <Box display="flex" alignItems="center">
        <Typography variant="h5" component="p">
          Categories
        </Typography>
        {(values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedCategories?.length > 0 || values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedSubCategories?.length > 0) && (
          <Button onClick={() => handleOpenCategoryDialog()}>Edit</Button>
        )}
      </Box>
      <div style={{ display: "flex", columnGap: 10, alignItems: "center" }}>
        <div style={{ flex: 1, margin: "8px 0", minWidth: "200px", maxWidth: "200px" }}>
          {values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedCategories?.length > 0 || values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedSubCategories?.length > 0 ? (
            <div>
              {values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedCategories?.concat(values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedSubCategories || []).slice(0, 1).map((category) => (
                <Box key={category} my={1}>
                  <Chip label={category || "Unknown Category"} />
                </Box>
              ))}
              {values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedCategories?.concat(values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedSubCategories || []).length > 1 && (
                <Box my={1}>
                  <Chip label={`${values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedCategories?.concat(values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedSubCategories || []).length - 1} more...`} />
                </Box>
              )}
            </div>
          ) : (
            <Box>
              <Button onClick={() => handleOpenCategoryDialog()} variant="outlined" fullWidth style={{ height: "100%", minHeight: "53px" }}>Add Categories</Button>
            </Box>
          )}

        </div>
        <div>
          <BundleCollectionWeight
            weightFilter={weightFilter}
            setWeightFilter={setWeightFilter}
            weightValue={weightValue}
            setWeightValue={setWeightValue}
            weightFilterErrorMessage={weightFilterErrorMessage}
            weightValueErrorMessage={weightValueErrorMessage}
            setWeightValueErrorMessage={setWeightValueErrorMessage}
            setWeightFilterErrorMessage={setWeightFilterErrorMessage}
          />
        </div>

      </div>
      <Box display="flex" gap={2} alignItems="center">
        {(values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedCategories?.length || values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedSubCategories?.length > 0) && (
          <div>
            <Box display="flex" alignItems="center">
              <Typography variant="h5" component="p" pb={1.2}>
                Brands
              </Typography>
              {values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedBrandIds?.length > 0 && (
                <Button onClick={() => handleOpenBrandDialog()}>Edit</Button>
              )}
            </Box>

            <div style={{ display: "flex", alignItems: "center", columnGap: 10 }}>
              {values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedBrandIds?.length > 0 ? (
                <div>
                  {values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedBrandIds?.slice(0, 1).map((brand) => (
                    <Box key={brand.key} my={1}>
                      <Chip label={brand.key || "Unknown Brand"} />
                    </Box>
                  ))}
                  {values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedBrandIds?.length > 1 && (
                    <Box my={1}>
                      <Chip label={`${values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedBrandIds?.length - 1} more...`} />
                    </Box>
                  )}
                </div>
              ) : (
                <Box>
                  <Button onClick={() => handleOpenBrandDialog()} variant="outlined" fullWidth style={{ height: "100%", minHeight: "53px" }}>Add Brands</Button>
                </Box>
              )}

            </div>
          </div>
        )}
      </Box>
      <BrandSelectDialog
        brands={brands}
        selectedBrands={values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedBrandIds || []}
        filterIndex={filterIndex}
        onChange={(selectedBrands) => handleBrandChange(selectedBrands)}
        open={brandDialogOpen}
        onClose={handleCloseBrandDialog}
      />
      <SelectDialog
        open={categoryDialogOpen}
        onClose={handleCloseCategoryDialog}
        categories={categories}
        selectedCategories={values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedCategories || []}
        selectedSubCategories={values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedSubCategories || []}
        filterIndex={filterIndex}
        onChange={(selectedCategories, selectedSubCategories) =>
          handleCategoryChange(selectedCategories, selectedSubCategories)
        }
      />
    </div>
  );
}
