import * as React from "react";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classes from "./editor-accordion.module.css";
import { Box } from "@mui/material";

interface EditorAccordionProps {
  title: string;
  keepOpen?: boolean;
  product?: any;
  children: React.ReactNode;
}

function EditorAccordion({
  title,
  keepOpen = false,
  product,
  children,
}: EditorAccordionProps) {
  const [expanded, setExpanded] = useState(keepOpen ? true : false); // Initially closed

  const handleAccordionChange = () => {
    if (!keepOpen) {
      setExpanded(!expanded);
    }
  };

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={() => handleAccordionChange()}
        className={classes.accordion}
      >
        <AccordionSummary
          expandIcon={keepOpen ? null : <ExpandMoreIcon />}
          className={`${classes.accordionTitle} ${expanded ? classes.expanded : ""}`}
          aria-controls="panel-header"
          id="panel-header"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography variant="h4" component="h4">
              {title}
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="end"
              position="absolute"
              right="12px"
            >
              {product?.createdAt && (
                <Typography
                  variant="body2"
                  component="p"
                  sx={{ fontSize: "10px", color: "#A0A7AF" }}
                >
                  Created: {new Date(product.createdAt).toLocaleDateString()}
                </Typography>
              )}
              {product?.updatedAt && (
                <Typography
                  variant="body2"
                  component="p"
                  sx={{ fontSize: "10px", color: "#A0A7AF" }}
                >
                  Last Edited:{" "}
                  {new Date(product.updatedAt).toLocaleDateString()}
                </Typography>
              )}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
}

export default EditorAccordion;
