import { useMatches } from "react-router-dom";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Box,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export const Breadcrumbs = () => {
  const matches = useMatches();

  let crumbs = matches
    //@ts-ignore
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => {
      //@ts-ignore
      const label = match.handle.crumb(match.params);
      return (
        <Link
          component={RouterLink}
          underline="hover"
          color="inherit"
          to={match.pathname}
          sx={{ textTransform: "capitalize" }}
          key={match.pathname}
        >
          {label}
        </Link>
      );
    });

  return (
    <Box sx={{ mb: 1 }}>
      <MuiBreadcrumbs aria-label="breadcrumb">
        {crumbs}
      </MuiBreadcrumbs>
    </Box>
  );
};
