import React from 'react';
import { ErrorMessage, useField } from 'formik';
import TextField from '@mui/material/TextField';



export const TextInput = ({ label,...props }) => {

   const [field, meta] = useField(props);
   return (
     <>
       <TextField
         variant="outlined"
         margin="dense"
         fullWidth
         label={label}
         error={false || (meta.touched && !!meta.error)}
         helperText={(meta.touched && !!meta.error) && meta.error}
         {...field}
         {...props} />

     </>
   );
 };
