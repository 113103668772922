// project import
import admin from './admin';
import business from './business';
import dashboard from './dashboard';
import organization from './organization';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [admin, dashboard, business(), organization]
};

export default menuItems;
