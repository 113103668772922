// assets
import { HomeOutlined } from "@ant-design/icons";

// icons
const icons = {
  HomeOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const admin = {
  id: "organizations",
  title: "Organizations",
  type: "group",
  children: [
    {
      id: "organization",
      title: "Organization",
      type: "item",
      url: "/organizations",
      icon: icons.HomeOutlined,
      breadcrumbs: true,
      userRoles: []
    },
    {
      id: "organizations",
      title: "Organizations",
      type: "item",
      url: "/organizations",
      icon: icons.HomeOutlined,
      breadcrumbs: true,
      userRoles: []
    },
  ],
};

export default admin;
