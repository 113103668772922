import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { businessConstants } from "../../constants";
import { updateBusiness } from "../../redux/businessSlice";
import { useAppDispatch } from "../../redux/hooks";

import styled from "@emotion/styled";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import { EditDrawer } from "components/shared/drawer";
import toast from "react-hot-toast";
import { Button, Error } from "../shared";


export function EditBusinessLogoDialog({ business, ...props }) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [logoImage, setLogoImage] = React.useState(business.logoUrl || null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setLogoImage(null);
    setOpen(false);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const updateData = { id: business.id, ...values };
    console.log(updateData)
    const action = dispatch(updateBusiness(updateData));
    const result = await action;

    if (updateBusiness.fulfilled.match(result)) {
      setSubmitting(false);
      handleClose();
      toast.success("Business logo updated successfully.");
    } else if (updateBusiness.rejected.match(result)) {
      const error = result.error;
      console.error("Error:", error);
      toast.error("Error updating business logo.");
    }
  };

  const BusinessImage = styled("img")(({ theme }) => ({
    width: "100%",
    height: 150,
    objectFit: "contain",
    display: "block",
    margin: theme.spacing(2, "auto"),
    [theme.breakpoints.down("md")]: {
      height: 150,
    },
    [theme.breakpoints.down("sm")]: {
      height: 75,
    },
  }));


  return (
    <EditDrawer
      title="Edit business logo"
      open={open}
      setOpen={setOpen}
      width="400px"
    >
      <Formik
        initialValues={{
          file: null,
        }}
        validationSchema={Yup.object({
          file: Yup.mixed()
            .test(
              "fileSize",
              "File Size is too large",
              (value) => value.size <= businessConstants.MAX_IMAGE_FILE_SIZE
            )
            .test("fileType", "Unsupported File Format", (value) =>
              businessConstants.SUPPORTED_IMAGE_FORMATS.includes(value.type)
            ),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, errors, touched, ...formik }) => (
          <Form>
            <Box>
              <BusinessImage src={logoImage} />
            </Box>

            <input
              id="file"
              name="file"
              type="file"
              accept="image/*"
              onChange={(event) => {
                setFieldValue("file", event.currentTarget.files[0]);
                let reader = new FileReader();
                let file = event.currentTarget.files[0];
                reader.onloadend = () => {
                  setLogoImage(reader.result);
                };
                reader.readAsDataURL(file);
              }}
            />
            {errors.file && touched.file ? <Error>{errors.file}</Error> : null}

            {error && <Error>{error}</Error>}
            <Box>
              <Divider sx={{ marginBottom: "1rem" }} />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={isSubmitting}
                fullWidth
              >
                Save
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </EditDrawer>
  );
}
