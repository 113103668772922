import { createAsyncThunk, createEntityAdapter, createSlice, } from "@reduxjs/toolkit";
import { userService } from "../services";
import User, { ChangePasswordDto, CreateUserDto, CreateUserResponseDto, SetUserPasswordDto, UpdateUserDto, UpdateUserProfileDto } from "../types/user";
import { RootState } from "./store";
import { PURGE } from "redux-persist";

export const getUsers = createAsyncThunk("users/getAll", async () => {
  const response = await userService.getAll();
  return response.data as Array<User>;
});

export const getUser = createAsyncThunk("user/getUser", async (id: number) => {
  const response = await userService.getUser(id);
  return response as User;
});

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (data: { userId: number, dto: Partial<UpdateUserDto> }) => {
    const response = await userService.updateUser(data.userId, data.dto);
    return response as User;
  }
);
export const updateUserProfile = createAsyncThunk(
  "user/updateUserProfile",
  async (data: { userId: number, dto: Partial<UpdateUserProfileDto> }) => {
    const response = await userService.updateUserProfile(data.userId, data.dto);
    return response as User;
  }
);

export const updateUserOrganization = createAsyncThunk(
  "user/updateUserOrganization",
  async (data: { userId: number; organizationId: number }) => {
    const response = await userService.updateUserOrganization(data.userId, data.organizationId);
    return response;
  }
);
export const deleteUserOrganization = createAsyncThunk(
  "user/updateUserOrganization",
  async (data: { userId: number; organizationId: number }) => {
    const response = await userService.deleteUserOrganization(data.userId, data.organizationId);
    return response;
  }
);

export const inviteUser = createAsyncThunk(
  "user/inviteUsers",
  async (newUsers: CreateUserDto[]) => {
    const response = await userService.invite(newUsers);
    return response as CreateUserResponseDto;
  }
);
export const setUserPassword = createAsyncThunk(
  "user/setUserPassword",
  async (data: { userId: number, dto: SetUserPasswordDto }) => {
    const response = await userService.setUserPassword(data.userId, data.dto);
    return response;
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (data: { userId: number, dto: ChangePasswordDto }) => {
    const response = await userService.changePassword(data.userId, data.dto);
    return response;
  }
);

export const usersAdapter = createEntityAdapter<User>();

interface AdditionalState {
  loading: string;
  activeRequestId: number | null;
  error: string | null | { email: string, message: string }[] | undefined;
}

const additionalState: AdditionalState = {
  loading: "idle",
  activeRequestId: null,
  error: null,
};

const initialState = usersAdapter.getInitialState(additionalState);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    removeUser: usersAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state, action) => {
        state.loading = "loading";
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        usersAdapter.upsertMany(state, action.payload);
        state.loading = "idle";
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.loading = "failed";
        state.error = "Failed to load user";
      })
      .addCase(getUser.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = "idle";
        state.error = null;
        usersAdapter.upsertOne(state, action.payload);
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = "failed";
        state.error = "Failed to load user";
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        usersAdapter.updateOne(state, { id, changes });
        state.loading = "idle";
        state.error = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = "failed";
        state.error = "Failed to update user";
      })
      .addCase(updateUserOrganization.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(updateUserOrganization.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        usersAdapter.updateOne(state, { id, changes });
        state.loading = "idle";
        state.error = null;
      })
      .addCase(updateUserOrganization.rejected, (state, action) => {
        state.loading = "failed";
        state.error = "Failed to update user organization";
      })
      .addCase(inviteUser.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(inviteUser.fulfilled, (state, action) => {
        usersAdapter.setMany(state, action.payload.createdUsers ?? []);
        state.loading = "idle";
        state.error = (action.payload.errors ?? []).length === 0 ? null : action.payload.errors;
      })
      .addCase(inviteUser.rejected, (state, action) => {
        state.loading = "failed";
        state.error = "Failed to invite user";
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        usersAdapter.setOne(state, action.payload);
        state.loading = "idle";
        state.error = null
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = "failed";
        state.error = "Failed to update user profile";
      })
      .addCase(setUserPassword.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(setUserPassword.fulfilled, (state, action) => {
        state.loading = "idle";
        state.error = null
      })
      .addCase(setUserPassword.rejected, (state, action) => {
        state.loading = "failed";
        state.error = "Failed to set user password";
      })
      .addCase(changePassword.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = "idle";
        state.error = null
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = "failed";
        state.error = "Failed to change password";
      })
      .addCase(PURGE, (state) => {
        usersAdapter.removeAll(state);
      });
  },
});

export default usersSlice.reducer;

export const { removeUser } = usersSlice.actions;

export const {
  selectById: selectUserById,
  selectIds: selectUserIds,
  selectEntities: selectUserEntities,
  selectAll: selectAllUsers,
  selectTotal: selectTotalUsers,
} = usersAdapter.getSelectors<RootState>((state) => state.users);
