import { useEffect, useRef, useState } from "react";
import config from "../../config";
import GoogleMapReact from "google-map-react";
import { BusinessMapMarker } from "./";
import { Box, useTheme } from "@mui/material";
import { Button } from "components/shared";
import { BusinessSelectDrawerItem } from "./business-select";

export function BusinessesMapSelect({
  businesses,
  activeBusinessId,
  setActiveBusinessId,
  handleBusinessSelect,
  ...props
}) {
  const [map, setMap] = useState(null);
  const markers = useRef({});
  const theme = useTheme();

  const mapContainerStyles = {
    height: "calc(100vh - 175px)",
    width: "100%",
  };
  const businessBoxStyles = {
    position: "absolute",
    bottom: "50px",
    background:
      theme.palette.mode == "light"
        ? "rgba(255, 255, 255, 0.76)"
        : "rgba(0, 0, 0, 0.4)",
    backdropFilter: "blur(5.1px)",
    width: "90%",
    left: "5%",
    zIndex: "10",
    padding: theme.spacing(1),
    borderRadius: "8px",
  };
  const updateMarkers = () => {
    if (map) {
        const bounds = new window.google.maps.LatLngBounds();
        businesses.forEach(business => {
            if (business.latitude && business.longitude) {
                const latLng = new window.google.maps.LatLng(
                    parseFloat(business.latitude),
                    parseFloat(business.longitude)
                );
                bounds.extend(latLng);
            }
        });
        map.fitBounds(bounds);
    }
};
  // Effect to update markers when businesses change
  useEffect(() => {
    updateMarkers();
}, [businesses]);

  // Effect to update markers when map becomes available
  useEffect(() => {
    updateMarkers();
  }, [map]);

  // Function to handle when the map instance is ready
  const handleMapReady = ({ map }) => {
    setMap(map);
  };

  // Function to handle marker click
  const handleMarkerClick = (business) => {
    setActiveBusinessId(business.id);
  };

  return (
    <div style={mapContainerStyles}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: config.googleMapsApiKey }}
        defaultCenter={{ lat: 0, lng: 0 }}
        defaultZoom={10}
        options={{
          scrollwheel: true,
          zoomControl: false,
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleMapReady}
      >
        {businesses.map((business) => (
          <BusinessMapMarker
            key={business.id}
            lat={parseFloat(business.latitude)}
            lng={parseFloat(business.longitude)}
            business={business}
            singleItem
            handleMarkerClick={() => handleMarkerClick(business)}
            isActive={business.id === activeBusinessId}
          />
        ))}
      </GoogleMapReact>
      {businesses.some((business) => business.id === activeBusinessId) && (
        <Box style={businessBoxStyles}>
          <BusinessSelectDrawerItem
            business={businesses.find(
              (business) => business.id === activeBusinessId
            )}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={handleBusinessSelect}
          >
            Select
          </Button>
        </Box>
      )}
    </div>
  );
}