import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import Tooltip from "@mui/material/Tooltip";
import { setMobileOpen } from "redux/layoutSlice";
import { useAppDispatch } from "redux/hooks";

const TabButton = styled("button")(({ theme }) => ({
  textAlign: "center",
  background: "none",
  border: "0",
  //@ts-ignore
  color: theme.palette.secondary.contrastText,
  cursor: "pointer",
  font: "inherit",
  lineHeight: "inherit",
  margin: "0",
  overflow: "initial",
  padding: "0",
  verticalAlign: "initial",
  borderRadius: "4px",
  fontSize: "15px",
  fontWeight: "400",

  "&.disabled": {
    pointerEvents: "none",
  },

  "&:hover .tabButtonIcon": {
    //@ts-ignore
    backgroundColor: theme.palette.secondary.light,
    //@ts-ignore
    color: theme.palette.secondary.main,
  },

  "&:hover .tabButtonIconInner": {
    transform: "scale(1.15)",
  },
}));

const TabContent = styled("span")(({ theme }) => ({
  alignItems: "center",
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  fontSize: "11px",
  fontWeight: "700",
  justifyContent: "center",
  lineHeight: "12px",
  padding: "8px 0",
  position: "relative",
  rowGap: "4px",
  //@ts-ignore
  transition: `color ${theme.transitions.easing.easeInOut}`,
  width: "52px",

  "&.disabled": {
    opacity: 0.7,
  },
}));

//@ts-ignore
const TabButtonIcon = styled("div")(({ theme, isActive }) => ({
  alignItems: "center",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  flexShrink: 0,
  height: "36px",
  justifyContent: "center",
  //@ts-ignore
  transition: `background ${theme.transitions.easing.easeInOut}`,
  width: "36px",

  ...(isActive && {
    //@ts-ignore
    backgroundColor: theme.palette.secondary.light,
    //@ts-ignore
    color: theme.palette.secondary.main,
  }),
}));

interface RailTabProps {
  title?: string;
  outlinedIcon?: React.ReactNode;
  filledIcon?: React.ReactNode;
  link?: string;
  action?: () => void;
  disabled?: boolean;
  hoverMessage?: string;
  closeDrawerOnClick?: boolean;
}

export const RailTab = ({
  title,
  outlinedIcon,
  filledIcon,
  link,
  action,
  disabled,
  hoverMessage,
  closeDrawerOnClick,
}:RailTabProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = React.useMemo(() => {
    return location.pathname === link;
  }, [location.pathname, link]);

  const handleClick = () => {
    if (link) {
      navigate(link);
    } else if (action) {
      action();
    }
    if (closeDrawerOnClick) {
     dispatch(setMobileOpen(false));
    }
  };

  return (
    <Tooltip title={hoverMessage} placement="right">
      <div>
        <TabButton
          className={`tabButton ${disabled ? "disabled" : ""}`}
          onClick={handleClick}
        >
          <TabContent className={`tabContent ${disabled ? "disabled" : ""}`}>
            <TabButtonIcon
              className={`tabButtonIcon`}
              //@ts-ignore
              isActive={isActive}
            >
              <div className={`tabButtonIconInner`}>
                {isActive ? filledIcon : outlinedIcon}
              </div>
            </TabButtonIcon>
            {title}
          </TabContent>
        </TabButton>
      </div>
    </Tooltip>
  );
};
