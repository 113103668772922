import React from "react";
import {
  Autocomplete,
  TextField,
  Typography,
  Box,
  Tooltip,
  Button,
} from "@mui/material";
import UndoIcon from "components/shared/undo-icon.component";

const CustomAutocomplete = ({
  label,
  additionalContent,
  tooltipTitle,
  onRefreshClick,
  isRefreshDisabled,
  options,
  getOptionLabel,
  defaultValue,
  onChange,
  ...props
}: any) => {
  return (
    <Box display="flex" flexDirection="column" gap={0}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {/* Container for label and tooltip */}
        <Typography variant="body2" fontWeight="700" color="textSecondary">
          {label} {additionalContent && `(${additionalContent})`}
        </Typography>
        {tooltipTitle && onRefreshClick && (
          <Tooltip title={tooltipTitle}>
            <span>
              <Button
                onClick={onRefreshClick}
                disabled={isRefreshDisabled}
                color="primary"
                sx={{
                  minWidth: 30,
                  color: "#FF5789",
                }}
              >
                <UndoIcon fontSize="inherit" />
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
      <Autocomplete
        multiple
        id={props.id}
        options={options}
        getOptionLabel={getOptionLabel}
        defaultValue={defaultValue}
        renderInput={(params) => <TextField size="small" {...params} />}
        onChange={onChange}
        {...props}
      />
    </Box>
  );
};

export default CustomAutocomplete;
