import React from "react";
import { Grid, useMediaQuery } from "@mui/material";

const FlexBox = ({ top, children }: any) => {
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );
  const [firstChild, ...restChildren] = React.Children.toArray(children);
  return (
    <Grid container alignItems={top ? "top" : "center"} columnGap={1}>
      <Grid
        item
        xs={4}
        sm={3}
        style={{ textAlign: isSmallScreen ? "left" : "left" }}
      >
        {firstChild}
      </Grid>
      {restChildren.map((child, index) => (
        <Grid item xs={8} sm={8} key={index}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

export default FlexBox;
