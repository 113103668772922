import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
const LoginPage = Loadable(lazy(() => import("pages/auth/login.page")));
const RegisterPage = Loadable(
  lazy(() => import("pages/auth/register.page"))
);
const ForgotPasswordPage = Loadable(
  lazy(() => import("pages/auth/forgot-password.page"))
);
const EmailConfirmationPage = Loadable(
  lazy(() => import("pages/auth/email-confirmation.page"))
);
const ResetPasswordPage = Loadable(
  lazy(() => import("pages/auth/reset-password.page"))
);
const PasswordConfirmationPage = Loadable(
  lazy(() => import("pages/auth/password-confirmation.page"))
);

// ==============================|| AUTH ROUTING ||============================== //

const AuthRoutes = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "register",
    element: <RegisterPage />,
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "confirm",
    element: <EmailConfirmationPage />,
  },
  {
    path: "set-password",
    element: <ResetPasswordPage />,
  },
  {
    path: "password-confirmation",
    element: <PasswordConfirmationPage />,
  },
];

export default AuthRoutes;
