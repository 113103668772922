import React from "react";
import {
  Select,
  MenuItem,
  Typography,
  Box,
  Tooltip,
  Button,
  FormControl,
} from "@mui/material";
import UndoIcon from "components/shared/undo-icon.component";

const CustomSelect = ({
  label,
  additionalContent,
  tooltipTitle,
  onRefreshClick,
  isRefreshDisabled,
  options,
  ...props
}: any) => {
  return (
    <Box display="flex" flexDirection="column" gap={0}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {/* Container for label and tooltip */}
        <Typography variant="body2" fontWeight="700" color="textSecondary">
          {label} {additionalContent && `(${additionalContent})`}
        </Typography>
        {tooltipTitle && onRefreshClick && (
          <Tooltip title={tooltipTitle}>
            <span>
              <Button
                onClick={onRefreshClick}
                disabled={isRefreshDisabled}
                color="primary"
                sx={{
                  minWidth: 30,
                  color: "#FF5789",
                }}
              >
                <UndoIcon fontSize="inherit" />
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
      <FormControl fullWidth size="small">
        <Select labelId={`${props.name}-label`} {...props}>
          {options.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomSelect;
