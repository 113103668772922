import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { format, formatDate } from "./InventoryManagementConstants";
import DiffDisplay from "./DiffDisplay";
interface ProductLog {
  updatedAt: string;
  action: string;
  entity: string;
  businessId: number;
  providerBusinessId: string;
  entityId: string;
  diffs?: {
    field: string;
    oldValue: string;
    newValue: string;
  }[];
}

interface ProductLogTableProps {
  data: ProductLog[];
}

const ProductLogTable = ({ data }: ProductLogTableProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDiffs, setSelectedDiffs] = useState<any>(null);

  const handleOpenDialog = (diffs: any) => {
    setSelectedDiffs(diffs);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = data
    .slice()
    .reverse()
    .filter((row) => {
      const filteredDiffs =
        row.diffs?.filter(
          (diff) =>
            diff.field !== "hasCustomizedData" && diff.field !== "images" // Hiding images for release due to bug with saving images currently - TODO: JT-591
        ) || [];
      return filteredDiffs.length > 0 || row.action !== "update";
    });

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="product log table">
          <TableHead>
            <TableRow>
              <TableCell>Action</TableCell>
              <TableCell>Change</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row, index) => {
              const filteredDiffs =
                row.diffs?.filter(
                  (diff) =>
                    diff.field !== "hasCustomizedData" &&
                    diff.field !== "images"
                ) || [];
              return (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {format(row.action)}
                  </TableCell>
                  <TableCell>
                    {row.action === "update" && filteredDiffs.length > 0 && (
                      <Button onClick={() => handleOpenDialog(filteredDiffs)}>
                        View Detailed Changes
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>{formatDate(row?.updatedAt)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            width: "600px",
            maxHeight: "80vh",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h5" component="h5" fontWeight="bold">
            Changes Made
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            overflowY: "auto",
            maxHeight: "calc(80vh - 64px)",
          }}
        >
          <DiffDisplay diffs={[{ diffs: selectedDiffs }]} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProductLogTable;
