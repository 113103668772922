import React from 'react';
import {useField} from 'formik';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';




export const SelectInput = ({ label, options, ...props }) => {

    const [field, meta] = useField(props);
    return (
        <>
            <TextField
                select
                variant="outlined"
                fullWidth
                margin="dense"
                label={label}
                helperText={(meta.touched && !!meta.error) && meta.error}
                {...field}
                {...props} >
                {options && options.length > 0 &&
                    options.map((option, index) => (
                        <MenuItem key={index} value={option.value}>{option.name}</MenuItem>
                    ))
                }
            </TextField>
            
        </>
    );
};
