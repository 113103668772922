import { Box, Chip, Typography, useTheme } from "@mui/material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Business from "types/business";
import { StoreOpenClosed } from "../settings/info/hours/store-open-closed.component";

interface BusinessSelectDrawerItemProps {
  business: Business;
}

export const BusinessSelectDrawerItem: React.FC<
  BusinessSelectDrawerItemProps
> = ({ business }) => {
  const theme = useTheme();

  return (
    <Box py={2}>
      <Typography variant="h5" style={{ fontWeight: "bold" }}>
        {business.name}
      </Typography>
      <Typography variant="body2">
        {business.address1}, {business.city}, {business.stateAbbr},{" "}
        {business.postalCode}
      </Typography>
      <Box display="flex" alignItems="center" columnGap={0.5}>
        <ScheduleIcon fontSize="small" />
        <Typography variant="caption">
          <Typography
            variant="body2"
            component="span"
            style={{ fontWeight: "bold", color: theme.palette.primary.main }}
          >
            <StoreOpenClosed business={business} />
          </Typography>
        </Typography>
      </Box>
      <Typography variant="body2">
        <Chip
          variant="filled"
          color="primary"
          label={business.id}
          sx={{ mr: 1.25, mt: 1 }}
          size="small"
        />
      </Typography>
    </Box>
  );
};
