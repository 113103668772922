import { Close, Undo } from "@mui/icons-material";
import { Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import InfoSection from "components/shared/InfoSection";
import InputField from "components/shared/special/InputField";
import PhotoUploadButton from "components/shared/special/PhotoUploadButton";
import { useField, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import Label from "../Extras/Label";
import MenuPreview from "../Extras/MenuPreview";

export default function MenuDesignForm(props) {
  const {
    editing,
    type,
    formField: { name, description, photoFile, photoUrl },
  } = props;
  const formik = useFormikContext();
  const [, meta, helpers] = useField(photoFile.name);
  const [, nameMeta] = useField(name.name);
  const [, photoUrlMeta, photoUrlHelpers] = useField(photoUrl.name);
  const [, descriptionMeta] = useField(description.name);
  const { setValue } = helpers;
  const [imageSrc, setImageSrc] = useState("");
  const [oldImageSrc, setOldImageSrc] = useState(editing && photoUrlMeta.value ? photoUrlMeta.value : "");

  function _handleRemoveImage() {
    setValue(null);
    if (editing) {
      setOldImageSrc(photoUrlMeta.value);
      photoUrlHelpers.setValue(null);
    }
  }

  function _handleUndoRemoveImage() {
    setImageSrc(oldImageSrc);
    photoUrlHelpers.setValue(oldImageSrc);
  }

  useEffect(() => {
    if (meta.value instanceof File) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(meta.value);
    } else if (photoUrlMeta.value) {
      setImageSrc(photoUrlMeta.value);
    } else {
      setImageSrc("");
    }
  }, [meta, photoUrlMeta]);

  return (
    <React.Fragment>
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} sm={5} pr={2}>
          <div style={{ marginBottom: "1rem" }}>
            <Label label={`${name.label}:`} />
            <InputField
              name={name.name}
              fullWidth
              onChange={(e) => {
                formik.setFieldValue(name.name, e.target.value);
                const fieldPath = type === 'SPECIAL' ? 'discounts[0].customCollection.name' : 'bundleQualifiers[0].customCollection.name';
                formik.setFieldValue(fieldPath, e.target.value);
              }}
            />
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <Label label={`${description.label}:`} />
            <InputField name={description.name} fullWidth />
          </div>
          <div>
            <Label label={`${photoFile.label}:`} />
            <Typography variant="body2">{photoFile.description}</Typography>
            {imageSrc && (
              <div
                style={{
                  position: "relative",
                  objectFit: "cover",
                  width: "200px",
                  height: "120px",
                  borderRadius: "9px",
                  marginTop: "1rem",
                }}
              >
                <img
                  src={imageSrc}
                  alt="Preview"
                  style={{
                    objectFit: "cover",
                    width: "200px",
                    height: "120px",
                    borderRadius: "9px",
                  }}
                />
                <IconButton
                  aria-label="Remove"
                  onClick={_handleRemoveImage}
                  sx={{
                    position: "absolute",
                    top: -10,
                    right: -10,
                    color: "black",
                    borderRadius: "50%",
                    width: 20,
                    height: 20,
                    backgroundColor: "rgb(227, 232, 237)",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      color: "white",
                    },
                  }}
                >
                  <Close />
                </IconButton>
              </div>
            )}
            <div
              style={{
                display: "flex",
                columnGap: "6px",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <PhotoUploadButton name={photoFile.name} />
              {editing && (
                <Tooltip title="Undo your changes" arrow>
                  <IconButton
                    color="primary"
                    variant="contained"
                    aria-label="Undo"
                    onClick={_handleUndoRemoveImage}
                  >
                    <Undo />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ display: { xs: "none", sm: "block" } }}
        />
        <Grid
          item
          xs={12}
          sm={6}
          pl={2}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          <Label label="Menu Preview" />
          <InfoSection
            title="Specials Card"
            description="This object will be displayed on the “Specials” page on your menu, where customers can view all specials at once. Styling of this card will vary between sites."
          />
          <MenuPreview
            name={nameMeta.value}
            description={descriptionMeta.value}
            imageSrc={imageSrc}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
