// assets
import {
  AimOutlined,
  CodepenOutlined,
  ControlOutlined,
  FormatPainterOutlined,
  HomeOutlined,
  LikeOutlined,
  SnippetsOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { USER_ROLES } from "constants";

// icons
const icons = {
  AimOutlined,
  CodepenOutlined,
  ControlOutlined,
  FormatPainterOutlined,
  HomeOutlined,
  LikeOutlined,
  SnippetsOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
};

const business = (businessId) => ({
  id: "businesses",
  title: "Business",
  type: "group",
  children: [
    {
      id: "businessHome",
      title: "Business Home",
      type: "item",
      url: `/businesses/${businessId}`,
      icon: icons.HomeOutlined,
      breadcrumbs: true,
      userRoles: []
    },
    {
      id: "ad",
      title: "Ads",
      type: "item",
      url: `/businesses/${businessId}/ads`,
      icon: icons.ControlOutlined,
      breadcrumbs: true,
      userRoles: [USER_ROLES.ADMIN]
    },
    {
      id: "reviews",
      title: "Reviews",
      type: "item",
      url: `/businesses/${businessId}/reviews`,
      icon: icons.LikeOutlined,
      breadcrumbs: true,
      userRoles: []
    },
    {
      id: "reports",
      title: "Reports",
      type: "item",
      url: `/businesses/${businessId}/reports`,
      icon: icons.SnippetsOutlined,
      breadcrumbs: true,
      userRoles: []
    },
    // {
    //   id: "competitors",
    //   title: "Competitors",
    //   type: "item",
    //   url: `/businesses/${businessId}/competitors`,
    //   icon: icons.AimOutlined,
    //   breadcrumbs: true,
    //   userRoles: []
    // },
    {
      id: "settings",
      title: "Settings",
      type: "item",
      url: `/businesses/${businessId}/settings`,
      icon: icons.SettingOutlined,
      breadcrumbs: true,
      userRoles: []
    },
    {
      id: "ecommerce",
      title: "Ecommerce",
      type: "item",
      url: `/businesses/${businessId}/ecommerce`,
      icon: icons.ShoppingCartOutlined,
      breadcrumbs: true,
      userRoles: []
    },
    {
      id: "integrations",
      title: "Integrations",
      type: "item",
      url: `/businesses/${businessId}/integrations`,
      icon: icons.CodepenOutlined,
      breadcrumbs: true,
      userRoles: []
    },
    {
      id: "theme",
      title: "Theme",
      type: "item",
      url: `/businesses/${businessId}/theme`,
      icon: icons.FormatPainterOutlined,
      breadcrumbs: true,
      userRoles: []
    },
  ],
});

export default business;
