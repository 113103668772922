import { Box, Chip } from "@mui/material";
import { Logo } from "components/shared/logo";

export function DefaultNavHeader() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "nowrap",
        width: "100%",
      }}
    >
      <Box sx={{ width: "130px", pr: 2 }}>
        <Logo  />
      </Box>
      
      <Chip
        label={process.env.REACT_APP_STAGE}
        size="small"
        sx={{
          height: 16,
          "& .MuiChip-label": { fontSize: "0.625rem", py: 0.25 },
        }}
        component="a"
        href="https://rankreallyhigh.com"
        target="_blank"
        clickable
      />
    </Box>
  );
}
