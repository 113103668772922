import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { getProducts } from "services/productService";
import BundleCollectionWeight from "components/businesses/ecommerce/specials/collections/BundleCollectionWeight";
import { createProductSearchData } from "components/businesses/ecommerce/specials/modals/data/productSearchData";
import ProductSelectDialog from "../dialogs/select-dialog/product-select-dialog";
import { useFormikContext } from "formik";
import { Button } from "components/shared";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ProductOption({
  filter,
  filterIndex,
  selectedProducts,
  setSelectedProducts,
  weightFilter,
  setWeightFilter,
  weightValue,
  setWeightValue,
  weightFilterErrorMessage,
  setWeightFilterErrorMessage,
  weightValueErrorMessage,
  setWeightValueErrorMessage,
  editing
}) {
  const formik = useFormikContext();
  const { values, setFieldValue } = formik;
  // * Getting business id for elastic search
  const business = useAppSelector((state) => state.business.info);
  const businessId = business?.id;
  // * Setting state for autocomplete values
  const [open, setOpen] = useState(false);
  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  // * Fetch data when autocomplete is opened
  useEffect(() => {
    let active = true;
    const data = createProductSearchData(businessId);
    (async () => {
      try {
        const response = await getProducts(data);
        const products = response?.hits?.hits;
        if (active) {
          setOptions(products);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    })();

    return () => {
      active = false;
    };
  }, [productDialogOpen, businessId]);

  // * Limit the list to 10 items at a time
  const OPTIONS_LIMIT = 10;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
  });

  const handleProductChange = (selectedProducts) => {
    setSelectedProducts(selectedProducts);
  };

  const handleOpenProductDialog = () => {
    setProductDialogOpen(true);
  };

  const handleCloseProductDialog = () => {
    setProductDialogOpen(false);
  };

  // Safely access nested properties with optional chaining and default to empty array if undefined
  const includedProductIds = values.bundleQualifiers?.[0]?.customCollection?.filters?.[filterIndex]?.includedProductIds || [];

  return (
    <div>
      <Box display="flex" alignItems="center">
        <Typography variant="h5" component="p">
          Products
        </Typography>
        {values.bundleQualifiers[0].customCollection.filters[filterIndex]?.includedProductIds?.length > 0 && (
          <Button onClick={() => handleOpenProductDialog()}>Edit</Button>
        )}
      </Box>
      <div style={{ display: "flex", columnGap: 10 }}>
        <div style={{ flex: 1, margin: "8px 0", minWidth: "200px", maxWidth: "200px" }}>
          {includedProductIds.length > 0 ? (
            <div>
              {includedProductIds.slice(0, 1).map((product) => (
                <Box key={product._id} my={1}>
                  <Chip label={product._source?.name || "Unknown Product"} />
                </Box>
              ))}
              {includedProductIds.length > 1 && (
                <Box my={1}>
                  <Chip label={`${includedProductIds.length - 1} more...`} />
                </Box>
              )}
            </div>
          ) : (
            <Box>
              <Button onClick={() => handleOpenProductDialog()} variant="outlined" fullWidth style={{ height: "100%", minHeight: "53px" }}>Add Products</Button>
            </Box>
          )}
          <ProductSelectDialog
            products={options}
            selectedProducts={includedProductIds}
            filterIndex={filterIndex}
            onChange={handleProductChange}
            open={productDialogOpen}
            onClose={handleCloseProductDialog}
          />
        </div>
        <div>
          <BundleCollectionWeight
            weightFilter={weightFilter}
            setWeightFilter={setWeightFilter}
            weightValue={weightValue}
            setWeightValue={setWeightValue}
            weightFilterErrorMessage={weightFilterErrorMessage}
            weightValueErrorMessage={weightValueErrorMessage}
            setWeightValueErrorMessage={setWeightValueErrorMessage}
            setWeightFilterErrorMessage={setWeightFilterErrorMessage}
            editing={editing}
          />
        </div>
      </div>
    </div>
  );
}
