import {businessConstants} from "../constants";

export const displayHelpers = {
  getOptionName,
  formatTaxCategories
};

/**
 *
 * @param option
 * @param lookupArray
 * @returns {string}
 */
function getOptionName(option: any, lookupArray: any) {
  if(!option || !lookupArray) {
    return '';
  }

  let name = '';
  for(const search of lookupArray) {
    if(search.value === option) {
      name = search.name;
      break;
    }
  }
  return name;
}

function formatTaxCategories(categories: [string]) {
  const nonCannabis = ['ACCESSORIES', 'APPAREL', 'MERCH', 'NONE']
  const cannabis = ['CBD', 'CLONES', 'CONCENTRATES', 'EDIBLES', 'FLOWER', 'ORAL', 'PLANT', 'PRE_ROLLS', 'SEEDS', 'TOPICALS', 'TINCTURES', 'VAPORIZERS'];
  const allCategories = businessConstants.CATEGORIES.map((x) => x.value);

  if (arrayEquals(categories, nonCannabis)) {
    return 'Non-Cannabis Items';
  }

  if (arrayEquals(categories, cannabis)) {
    return 'Cannabis Items';
  }

  if (arrayEquals(categories, allCategories)) {
    return 'All Categories';
  }


  const categoryNames = categories.map((c: string) => {
    const category = businessConstants.CATEGORIES.find((x) => x.value === c);
    return category?.name;
  })

  return categoryNames.join(', ');
}

function arrayEquals(a: any, b: any) {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index]);
}

/**
 * Loads a js file on the page
 * @param src
 * @param position
 * @param id
 * @returns
 */
export function loadScript(
  src: string,
  position: HTMLElement | null,
  id: string
) {
  if (!position) {
      return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}
