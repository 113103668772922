import { lazy } from "react";
import Loadable from "components/Loadable";
import { Navigate } from "react-router-dom";

const OrganizationsPage = Loadable(
  lazy(() => import("pages/organizations/organizations.page"))
);
const BusinessesPage = Loadable(
  lazy(() => import("pages/businesses/businesses.page"))
);
const MyAccountPage = Loadable(
  lazy(() => import("pages/user/my-account.page"))
);
const SupportPage = Loadable(lazy(() => import("pages/user/support.page")));

const MainRoutes = [
  {
    index: true,
    element: <Navigate to="/businesses" />,
  },
  {
    path: "businesses",
    element: <BusinessesPage title="Businesses" />,
    handle: {
      crumb: () => 'All Businesses',
    },
  },
  {
    path: "organizations",
    element: <OrganizationsPage title="Organizations" />,
    handle: {
      crumb: () => 'All Organizations',
    },
  },
  {
    path: "my-account",
    element: <MyAccountPage />,
    handle: {
      crumb: () => 'My Account',
    },
  },
  {
    path: "support",
    element: <SupportPage />,
    handle: {
      crumb: () => 'Support',
    },
  },
];

export default MainRoutes;
