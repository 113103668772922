export const locationHelper = {
  googleDirectionsUrl,
  milesToMeters,
};

/**
 * Get google directions url
 * @param  {[type]} origin      [description]
 * @param  {[type]} destination [description]
 * @return {[type]}             [description]
 */
function googleDirectionsUrl({origin, destination}) {
  let googleUrl = 'https://www.google.com/maps/dir/?api=1';
  if (origin) {
    googleUrl += `&origin=${origin}`;
  }
  if (destination) {
    googleUrl += `&destination=${destination}`;
  }
  return googleUrl;
}

/**
 * Simple miles to meters calculation
 * @param miles
 * @returns {number}
 */
function milesToMeters(miles) {
  return Number(miles) * 1609.344;
}
