import { useAppSelector } from "redux/hooks";

// assets
import {
  HomeOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { USER_ROLES } from "constants/user.constants";
import { useLocation } from "react-router-dom";
import React from "react";
import { NavItem } from "../types";


// icons
const icons = {
  HomeOutlined,
  SettingOutlined,
};

export const useOrganizationNav = () => {
  const [activeItem, setActiveItem] = React.useState<NavItem | null>(null);
  const [items, setItems] = React.useState<NavItem[]>([]);
  const orgId = useAppSelector(state => state.organization.info?.id);
  const {pathname} = useLocation();

  React.useEffect(() => {
    const navItems = [
      {
        id: "organizationHome",
        title: "Businesses",
        type: "item",
        url: `/organization/${orgId}`,
        icon: icons.HomeOutlined,
        breadcrumbs: true,
        userRoles: []
      },
      {
        id: "settings",
        title: "Settings",
        type: "item",
        url: `/organization/${orgId}/settings`,
        icon: icons.SettingOutlined,
        breadcrumbs: true,
        userRoles: []
      },
    ];

    // Set Nav Items
    setItems(navItems);
    
    // Find and set active nav item
    const currentNavItem = navItems.find((item) => item.url === pathname);
    setActiveItem(currentNavItem || null);

  }, [pathname, orgId]);

  return {
    activeItem: activeItem,
    navItems: items,
  };
} 