import { Remove } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import InputField from "components/shared/special/InputField";
import SelectField from "components/shared/special/SelectField";
import { useField, useFormikContext } from "formik";
import { at } from "lodash";
import specialFormModel from "components/SpecialCreate/FormModel/specialFormModel";
const { formField } = specialFormModel;

export default function BundledRewardOption({ rewardIndex, reward, rewards }) {
  // * Formik
  const formik = useFormikContext();
  const { setFieldValue } = formik;
  const { bundleRewards } = formField;
  const valueFieldName = `bundleRewards[${rewardIndex}].value`;
  const qtyFieldName = `bundleRewards[${rewardIndex}].quantity`;
  const [, selectMeta, helpers] = useField(
    `bundleRewards[${rewardIndex}].discountType`
  );
  const valueFieldLabel = "Each item for";

  // * Function to remove reward from array
  function handleRemoveReward() {
    const newRewards = [...rewards];
    newRewards.splice(rewardIndex, 1);
    setFieldValue([bundleRewards.name], newRewards);
  }

  function renderHelperText(meta) {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return <FormHelperText error>{error}</FormHelperText>;
    }
  }

  return (
    <div style={{ margin: "1rem 0" }}>
      <Typography variant="subtitle2" fontWeight={"bold"}>
        Each item for
      </Typography>
      <div style={{ display: "flex", alignItems: "center", margin: "6px 0" }}>
        <FormControl fullWidth sx={{ mr: 1, maxWidth: "150px" }}>
          <InputLabel id="discount-type-value-label">Discount Type*</InputLabel>
          <SelectField
            error={false}
            helperText=""
            labelId={`currency-type-label-${rewardIndex}`}
            id={`bundleRewards[${rewardIndex}].discountType`}
            name={`bundleRewards[${rewardIndex}].discountType`}
            renderValue={(selected) => {
              if (selected === "TARGET_PRICE") {
                return "$ specific price";
              } else if (selected === "PERCENTAGE") {
                return "%";
              } else if (selected === "DOLLAR_AMOUNT") {
                return "$";
              } else {
                return null;
              }
            }}
            MenuProps={{
              style: {
                zIndex: 2000,
              },
            }}
          >
            <MenuItem value="DOLLAR_AMOUNT">
              This item for a dollar amount off
            </MenuItem>
            <MenuItem value="PERCENTAGE">
              This item for a percentage off
            </MenuItem>
            <MenuItem value="TARGET_PRICE">
              This item for a specific price
            </MenuItem>
          </SelectField>
        </FormControl>
        <FormControl sx={{ mr: 1, flex: 1 }}>
          <InputField
            error={false}
            helperText=""
            name={valueFieldName}
            label="Discount Value*"
            type="number"
            fullWidth
          />
        </FormControl>
        <FormControl fullWidth sx={{ flex: 1, mr: 1 }}>
          <InputField
            error={false}
            helperText=""
            name={qtyFieldName}
            label="Up to qty*"
            type="number"
            fullWidth
          />
        </FormControl>
        <div>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Tooltip title="Remove this reward" placement="top">
              <IconButton
                color="error"
                sx={{ backgroundColor: "#ff4d4f15" }}
                onClick={handleRemoveReward}
              >
                <Remove />
              </IconButton>
            </Tooltip>
          </Stack>
        </div>
      </div>
    </div>
  );
}
