import {Box, Grid} from "@mui/material";
import React from "react";


export const SettingsRow = ({...props}) => {
    
    return (
        <Box >
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={4}>
                    {props.label}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {props.children}
                </Grid>
                {props.editDialog &&
                <Grid item xs={2} align="right">
                    {props.editDialog}
                </Grid>
                }
            </Grid>
        </Box>
    );
};
