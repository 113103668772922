import { InfoOutlined } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import TransitionAlerts from "components/shared/TransitionAlert";
import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { AlertType } from "types/alertTypes";
import AddBundleRewardsButton from "components/businesses/ecommerce/specials/filters/AddBundleRewardsMenu";
import BundleRewardOptions from "components/businesses/ecommerce/specials/filters/BundleRewardOptions";
import NoBundleRewards from "components/businesses/ecommerce/specials/filters/NoBundleRewards";

export default function CustomerRecievesFilterForm({
  formField: { bundleRewards },
}) {
  // * Formik Context
  const formik = useFormikContext();
  const [rewardsValue, rewardsMeta, rewardsHelpers] = useField(
    bundleRewards.name
  );
  const { setValue } = rewardsHelpers;
  // * Errors
  const hasErrors = formik.errors?.bundleRewards && formik.touched?.bundleRewards;
  // * Current rewards state
  const [canDisplayRewards, setCanDisplayRewards] = useState(checkIfRewards());
  const [rewardType, setRewardType] = useState(null);
  const [rewards, setRewards] = useState([]);

  // * Helper states
  const [transitionOpen, setTransitionOpen] = useState(false);

  // * Function to add empty reward state based on reward type
  function handleAddRewards(rewardParam) {
    const defaults = {
      discountType: ["BUNDLED_DISCOUNT", "PRICE", "CART_DISCOUNT"].includes(rewardParam) ? "TARGET_PRICE" : "",
      weight: ["BUNDLED_DISCOUNT", "PRICE", "CART_DISCOUNT"].includes(rewardParam) ? "ANY" : "",
      category: "",
      brandId: "",
      productId: "",
      value: 0,
      quantity: null,
    };    
    setValue([...rewardsValue.value, { ...defaults, type: rewardParam }]);
  }

  // * Helper function to check if there are rewards in bundleRewards
  function checkIfRewards() {
    return Array.isArray(rewardsValue.value) && rewardsValue.value.length > 0;
  }

  // * Recheck if there are rewards when bundlerewards changes
  useEffect(() => {
    if (checkIfRewards()) {
      setRewards(rewardsValue.value);
    }
    setCanDisplayRewards(checkIfRewards());
  }, [rewardsMeta]);

  // * Reset reward type if no rewards selected
  useEffect(() => {
    if (!canDisplayRewards) {
      formik.setFieldValue("rewardType", null);
    }
  }, [canDisplayRewards]);

  useEffect(() => {
    setRewardType(formik.values.rewardType);
  }, [formik.values.rewardType]);

  return (
    <div>
      <div style={{ margin: "1rem 0 0" }}>
        <div
          style={{ display: "flex", alignItems: "center", margin: "1rem 0 0" }}
        >
          <Typography
            variant="body1"
            component="p"
            style={{ fontWeight: "bold", marginRight: "0.25rem" }}
          >
            Customer Recieves
          </Typography>
          <IconButton
            onClick={() => setTransitionOpen(!transitionOpen)}
            size="small"
            color="info"
          >
            <InfoOutlined fontSize="small" />
          </IconButton>
        </div>
      </div>
      <div>
        <TransitionAlerts
          title="How to Add Rewards to Bundle"
          description="To customize your special offer, select brands, categories, and products to include or exclude. Use the weight filter/value for further refinement. Chain filters with AND/OR operators as needed."
          type={AlertType.Info}
          transitionOpen={transitionOpen}
          setTransitionOpen={setTransitionOpen}
        />
      </div>

      {canDisplayRewards ? (
        <div>
          {rewards.map((reward, index) => (
            <div key={index}>
              <BundleRewardOptions
                rewardIndex={index}
                rewardType={rewardType}
                rewards={rewards}
                reward={reward}
              />
            </div>
          ))}
          {!["CART_DISCOUNT", "BUNDLED_DISCOUNT", "PRICE"].includes(
            rewardType
          ) && (
              <AddBundleRewardsButton
                handleClick={handleAddRewards}
                filterGroup={rewardType}
              />
            )}
        </div>
      ) : (
        <div>
          <NoBundleRewards
            handleAddRewards={handleAddRewards}
            filterGroup={rewardType}
            setFilterGroup={setRewardType}
          />
          <TransitionAlerts
            title="Please Add a Reward"
            description={
              formik.errors?.bundleRewards
                ? formik.errors.bundleRewards
                : ""
            }
            type={AlertType.Error}
            transitionOpen={hasErrors}
            cannotExit
          />
        </div>
      )}
    </div>
  );
}
