import { Remove } from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import InputField from "components/shared/special/InputField";
import SelectField from "components/shared/special/SelectField";
import { useFormikContext } from "formik";
import specialFormModel from "components/SpecialCreate/FormModel/specialFormModel";
import { useEffect, useState } from "react";
import { createBrandSearchData, createCategorySearchData } from "../../modals/data/productSearchData";
import { useAppSelector } from "redux/hooks";
import { getProducts } from "services/productService";
import { Button } from "components/shared";
import BrandSelectDialog from "../../dialogs/select-dialog/brand-select-dialog.component";
import CategoryParentSelectDialog from "../../dialogs/select-dialog/category-select-dialog.componet";
const { formField } = specialFormModel;

export default function BrandRewardOption({ rewardIndex, reward, rewards }) {
  const business = useAppSelector((state) => state.business.info);
  const businessId = business?.id;
  const formik = useFormikContext();
  const { values, setFieldValue } = formik;
  const { bundleRewards } = formField;
  const valueFieldName = `bundleRewards[${rewardIndex}].value`;
  const weightFieldName = `bundleRewards[${rewardIndex}].weight`;
  const qtyFieldName = `bundleRewards[${rewardIndex}].quantity`;
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brandDialogOpen, setBrandDialogOpen] = useState(false);
  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
  const selectedBrand = [values.bundleRewards[rewardIndex]?.brandId || ""].filter(Boolean);
  const selectedCategory = [values.bundleRewards[rewardIndex]?.category || ""].filter(Boolean);


  console.log(formik)

  const handleOpenBrandDialog = () => {
    setBrandDialogOpen(true);
  };

  const handleCloseBrandDialog = () => {
    setBrandDialogOpen(false);
  };

  const handleOpenCategoryDialog = () => {
    setCategoryDialogOpen(true);
  };

  const handleCloseCategoryDialog = () => {
    setCategoryDialogOpen(false);
  };


  const handleBrandChange = (selectedBrand) => {
    setFieldValue(`bundleRewards[${rewardIndex}].category`, "");
    if (selectedBrand.length === 0) {
      setFieldValue(`bundleRewards[${rewardIndex}].brandId`, "");
    } else {
      const selected = selectedBrand[0];
      if (selected) {
        setFieldValue(`bundleRewards[${rewardIndex}].brandId`, selected.key);
      }
    }
  };

  const handleCategoryChange = (selectedCategory) => {
    if (selectedCategory.length === 0) {
      setFieldValue(`bundleRewards[${rewardIndex}].category`, "");
    } else {
      const selected = selectedCategory[0];
      if (selected) {
        setFieldValue(`bundleRewards[${rewardIndex}].category`, selected);
      }
    }
  };


  // * Function to remove reward from array
  function handleRemoveReward() {
    const newRewards = [...rewards];
    newRewards.splice(rewardIndex, 1);
    setFieldValue([bundleRewards.name], newRewards);
  }

  const fetchProductData = async () => {
    if (businessId) {
      const brandsData = createBrandSearchData(
        businessId,
      );
      const brandsResponse = await getProducts(brandsData);
      setBrands(brandsResponse.aggregations.brands.buckets);
    }
  };

  const fetchCategoryData = async () => {
    const includedBrandKeys = selectedBrand || [];
    if (businessId) {
      const categoryData = createCategorySearchData(
        businessId,
        includedBrandKeys,
      );
      const categoryResponse = await getProducts(categoryData);
      setCategories(categoryResponse.aggregations.categories.buckets);
    }
  };


  useEffect(() => {
    fetchCategoryData();
    fetchProductData();
  }, [businessId, brandDialogOpen, categoryDialogOpen]);



  const brandId = values.bundleRewards?.[rewardIndex]?.brandId;
  const category = values.bundleRewards?.[rewardIndex]?.category;
  console.log(selectedBrand)
  return (
    <div style={{ margin: "1rem 0" }}>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "8px 0",
            width: "100%"
          }}
        >
          <div
            style={{
              display: "flex",
              margin: "8px 0",
              columnGap: "8px",
            }}
          >
            <div style={{ flex: 1 }}>
              {brandId ? (
                <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} fullWidth border={"1px solid rgba(0, 0, 0, 0.23)"} borderRadius={"4px"} px={2} height={"100%"} minHeight="50px">
                  <Typography variant="h5" component="p">
                    {selectedBrand[0] || "Cannot find brand name"}
                  </Typography>
                  <Button onClick={handleOpenBrandDialog}>Edit</Button>
                </Box>
              ) : (
                <Button fullWidth style={{ height: '100%', minHeight: '50px' }} variant="outlined" onClick={handleOpenBrandDialog}>Add Brand</Button>
              )}
              {brands.length > 0 && (
                <BrandSelectDialog
                  brands={brands}
                  selectedBrands={selectedBrand.map(brand => ({ key: brand }))}
                  filterIndex={rewardIndex}
                  onChange={(selectedBrands) => handleBrandChange(selectedBrands)}
                  open={brandDialogOpen}
                  onClose={handleCloseBrandDialog}
                  isSingleSelect
                />
              )}

              <CategoryParentSelectDialog
                open={categoryDialogOpen}
                onClose={handleCloseCategoryDialog}
                categories={categories}
                selectedCategories={selectedCategory}
                filterIndex={rewardIndex}
                onChange={(selectedCategories) =>
                  handleCategoryChange(selectedCategories)
                }
                isSingleSelect
              />
            </div>
            <div style={{ flex: 1 }}>
              {category ? (
                <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} fullWidth border={"1px solid rgba(0, 0, 0, 0.23)"} borderRadius={"4px"} px={2} height={"100%"} minHeight="50px">
                  <Typography variant="h5" component="p">
                    {category || "Cannot find category name"}
                  </Typography>
                  <Button onClick={handleOpenCategoryDialog}>Edit</Button>
                </Box>
              ) : (
                <Button disabled={!brandId} fullWidth style={{ height: '100%', minHeight: '50px' }} variant="outlined" onClick={handleOpenCategoryDialog}>Add Category - leave empty for any category</Button>
              )}
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "8px 0", columnGap: "8px" }}
          >
            <FormControl fullWidth sx={{ flex: 1 }}>
              <InputLabel id="discount-type-value-label">
                Discount Type*
              </InputLabel>
              <SelectField
                error={false}
                labelId={`currency-type-label-${rewardIndex}`}
                id={`bundleRewards[${rewardIndex}].discountType`}
                name={`bundleRewards[${rewardIndex}].discountType`}
                sx={{ height: "52px" }}
                renderValue={(selected) => {
                  if (selected === "TARGET_PRICE") {
                    return "$ specific price";
                  } else if (selected === "PERCENTAGE") {
                    return "%";
                  } else if (selected === "DOLLAR_AMOUNT") {
                    return "$";
                  } else {
                    return null;
                  }
                }}
                MenuProps={{
                  style: {
                    zIndex: 2000,
                  },
                }}
              >
                <MenuItem value="DOLLAR_AMOUNT">
                  This item for a dollar amount off
                </MenuItem>
                <MenuItem value="PERCENTAGE">
                  This item for a percentage off
                </MenuItem>
                <MenuItem value="TARGET_PRICE">
                  This item for a specific price
                </MenuItem>
              </SelectField>
            </FormControl>
            <FormControl fullWidth sx={{ flex: 2 }}>
              <InputField
                error={false}
                helperText=""
                name={valueFieldName}
                label="Discount Value*"
                type="number"
                fullWidth
                InputProps={{
                  sx: { height: 52 },
                }}
              />
            </FormControl>
            <FormControl fullWidth sx={{ flex: 2 }}>
              <InputLabel id="weight-value-label">Weight Value*</InputLabel>
              <SelectField
                error={false}
                labelId="weight-value-label"
                id="weight-value-select"
                label="Weight Value"
                name={weightFieldName}
                sx={{ height: "52px" }}
              >
                <MenuItem value="1g">1g or Gram</MenuItem>
                <MenuItem value="3.5g">3.5g or Eighth</MenuItem>
                <MenuItem value="7g">7g or Quarter</MenuItem>
                <MenuItem value="14g">14g or Half</MenuItem>
                <MenuItem value="28g">28g or Ounce</MenuItem>
                <MenuItem value="EACH">Each</MenuItem>
                <MenuItem value="ANY">Any</MenuItem>
              </SelectField>
            </FormControl>
            <FormControl fullWidth sx={{ flex: 1 }}>
              <InputField
                error={false}
                helperText=""
                name={qtyFieldName}
                label="Up to qty*"
                type="number"
                fullWidth
                InputProps={{
                  sx: { height: 52 },
                }}
              />
            </FormControl>
          </div>
        </div>
        <div style={{ marginLeft: "4px", padding: "0 8px" }}>
          <Stack direction="column" justifyContent="center">
            <Tooltip title="Remove this reward" placement="top">
              <IconButton
                color="error"
                sx={{ backgroundColor: "#ff4d4f15" }}
                onClick={handleRemoveReward}
              >
                <Remove />
              </IconButton>
            </Tooltip>
          </Stack>
        </div>
      </div>
    </div>
  );
}
