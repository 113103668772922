import { Box } from "@mui/material";
import { PageTitle } from "./page-title";
export interface PageProps {
  title?: string;
  children: React.ReactNode;
}
export function PageLayout(props: PageProps) {
  return (
    <Box
      sx={{
        p: { xs: 2, md: 3 },
        flexGrow: 1,
      }}
    >
      <PageTitle title={props.title} />
      {props.children}
    </Box>
  );
}

export default PageLayout;
