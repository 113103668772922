import { Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { useField } from 'formik';
import { useEffect, useState } from 'react';

export default function DatePickerField({ label, fullWidth, isEndDate = false, ...props }) {
  const [field, meta, helper] = useField(props);
  const { touched, error } = meta;
  const { setValue } = helper;

  const isError = touched && error;
  const { value } = field;
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setSelectedDate(date);
    }
  }, [value]);

  function _onChange(date) {
    if (date) {
      const newDate = new Date(date);
      if (isEndDate) {
        // Set time to 23:59:59 for end dates
        newDate.setHours(23, 59, 59, 999);
      } else {
        // Set time to 00:00:00 for start dates
        newDate.setHours(0, 0, 0, 0);
      }
      setSelectedDate(newDate);
      try {
        const ISODateString = newDate.toISOString();
        setValue(ISODateString);
      } catch (error) {
        setValue(newDate);
      }
    } else {
      setValue(date);
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            {...field}
            {...props}
            value={selectedDate}
            onChange={_onChange}
            slotProps={{
              textField: {
                fullWidth: fullWidth,
                variant: 'outlined',
                error: !!isError,
                helperText: isError ? error : null,
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}
