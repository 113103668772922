import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import { useField } from "formik";
import { at } from "lodash";

export default function CheckboxGroup(props) {
  const { label, items, isArray, ...rest } = props;
  const [field, meta, { setValue }] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return <FormHelperText error>{error}</FormHelperText>;
    }
  }

  const _isChecked = (key) => {
    if (isArray) {
      return field.value.includes(key);
    } else {
      return field.value[key] || false;
    }
  };

  const _handleChange = (key) => (event) => {
    if (isArray) {
      const newValue = event.target.checked
        ? [...field.value, key]
        : field.value.filter((item) => item !== key);
      setValue(newValue);
    } else {
      setValue({
        ...field.value,
        [key]: event.target.checked,
      });
    }
  };

  return (
    <FormControl style={{ display: "flex", flexDirection: "column" }}>
      <FormLabel>{label}</FormLabel>
      <FormGroup style={{ display: "flex", flexDirection: "row" }}>
        {items.map(({ key, label }) => (
          <FormControlLabel
            key={key}
            control={
              <Checkbox
                checked={_isChecked(key)}
                onChange={_handleChange(key)}
              />
            }
            label={label}
            style={{ width: "110px" }}
          />
        ))}
      </FormGroup>
      {_renderHelperText()}
    </FormControl>
  );
}
