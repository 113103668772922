import { Remove } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";

export default function BundleTotalWeight() {
  // * Formik Context
  const formik = useFormikContext();
  const { setFieldValue, values } = formik;
  const weightFilter = values.bundleQualifiers?.[0]?.operator;
  const weightValue = values.bundleQualifiers?.[0]?.requiredWeight;

  // * Weight Filter disabled
  const [isDisabled, setIsDisabled] = useState(false);
  const handleWeightFilterDisable = () =>
    weightValue === "ANY" || weightValue === "EACH" ? true : false;
  useEffect(() => {
    setIsDisabled(handleWeightFilterDisable());
  }, [weightValue]);
  useEffect(() => {
    setIsDisabled(handleWeightFilterDisable());
  }, []);

  // * Handle filter changes
  function handleFilterChange(event) {
    const value = event.target.value;
    setFieldValue(`bundleQualifiers[0].operator`, value);
  }
  function handleRemoveFilter() {
    setFieldValue(`bundleQualifiers[0].operator`, null);
    setFieldValue(`bundleQualifiers[0].requiredWeight`, null);
  }

  // * Handle value changes
  function handleValueChange(event) {
    const value = event.target.value;
    setFieldValue(`bundleQualifiers[0].requiredWeight`, value);
    if (value === "ANY" || value === "EACH") {
      setFieldValue(`bundleQualifiers[0].operator`, "EQUALS");
    }
  }
  return (
    <div>
      <Typography variant="subtitle2" fontWeight={"bold"}>
        Required Weight
      </Typography>
      <div
        style={{
          display: "flex",
          columnGap: "10px",
          alignItems: "center",
          margin: "16px 0",
          height: "100%",
        }}
      >
        <div>
          <FormControl fullWidth>
            <InputLabel id="weight-filter-label">Weight Filter</InputLabel>
            <Select
              labelId="weight-filter-label"
              id="weight-filter-select"
              label="Weight Filter"
              name={`bundleQualifiers[0].operator`}
              disabled={isDisabled}
              value={weightFilter}
              onChange={handleFilterChange}
              sx={{ width: "200px", height: "54px" }}
            >
              <MenuItem value="EQUALS">Equals</MenuItem>
              <MenuItem value="GREATER_THAN_OR_EQUAL_TO">
                Greater than or equal to
              </MenuItem>
              <MenuItem value="LESS_THAN_OR_EQUAL_TO">
                Less than or equal to
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <FormControl fullWidth>
            <InputLabel id="weight-value-label">Weight Value</InputLabel>
            <Select
              labelId="weight-value-label"
              id="weight-value-select"
              label="Weight Value"
              value={weightValue}
              onChange={handleValueChange}
              sx={{ width: "200px", height: "54px" }}
            >
              <MenuItem value="1g">1g or Gram</MenuItem>
              <MenuItem value="3.5g">3.5g or Eighth</MenuItem>
              <MenuItem value="7g">7g or Quarter</MenuItem>
              <MenuItem value="14g">14g or Half</MenuItem>
              <MenuItem value="28g">28g or Ounce</MenuItem>
              <MenuItem value="EACH">Each</MenuItem>
              <MenuItem value="ANY">Any</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Tooltip title="Remove this filter" placement="top">
              <IconButton
                color="error"
                sx={{ backgroundColor: "#ff4d4f15" }}
                onClick={handleRemoveFilter}
              >
                <Remove />
              </IconButton>
            </Tooltip>
          </Stack>
        </div>
      </div>
    </div>
  );
}
