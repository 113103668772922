import { useState } from "react";

// mui import
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Drawer, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";

// ==============================|| DRAWER - EDIT ||============================== //

export function EditDrawer(props) {
  const theme = useTheme();

  const [error, setError] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const {
    title,
    subtitle,
    initialValues,
    validationSchema,
    handleSubmit,
    open,
    setOpen,
    width,
    ...others
  } = props;

  const drawerStyle = {
    height: "100%",
    width: width ? width : "450px",
    background: theme.palette.background.paper,
  };

  const drawerHeaderStyle = {
    height: "75px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    position: "sticky",
    background:
      theme.palette.mode === "light"
        ? theme.palette.secondary.main
        : theme.palette.grey[900],
    top: "0",
    color: theme.palette.primary.contrastText,
    zIndex: "10",
  };

  const drawerContentStyle = {
    padding: theme.spacing(2),
    width: "100%",
  };

  return (
    <div>
      {!props.noIcon && (
        <IconButton onClick={handleClickOpen} size="large">
          <EditIcon />
        </IconButton>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div style={drawerStyle}>
          <Box style={drawerHeaderStyle}>
            <Typography variant="h6">{title}</Typography>
            <IconButton
              color="inherit"
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box style={drawerContentStyle}>{others.children}</Box>
        </div>
      </Drawer>
    </div>
  );
}
