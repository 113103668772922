import axios, { AxiosRequestConfig } from 'axios';
import config from '../config';
import { apiHelper, authHeader } from '../helpers';
import b64ToBlob from 'b64-to-blob';
import fileSaver from 'file-saver';
import {BuilderOptionsDto} from "../types/BuilderOptionsDto";
import Mcc, {NewMcc} from '../types/mcc';

export const adsService = {
    syncBrands,
    getMccs,
    addMcc,
    updateMcc,
    googleAdsBuilder,
    microsoftAdsBuilder
};

function syncBrands() {
  const requestConfig: AxiosRequestConfig = {
    headers: {
      method: 'POST',
      ...authHeader()
    },
  };

  return axios.post(`${config.apiUrl}/brands/sync`, null, requestConfig)
    .then((r) => {
      if (r.status === 201 || r.status === 200) {
        return 'OK';
      }
      return r.statusText;
    })
    .catch(apiHelper.handleError);
}

function getMccs() {
  const requestConfig: AxiosRequestConfig = {
    headers: {
      method: 'GET',
      ...authHeader()
    }
  };

  return axios.get(`${config.apiUrl}/ads/mcc`, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

function addMcc(mcc: NewMcc) {
  const requestConfig: AxiosRequestConfig = {
    headers: {
      method: 'POST',
      ...authHeader()
    }
  };

  return axios.post(`${config.apiUrl}/ads/mcc`, mcc, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError)
}

function updateMcc(mcc: Partial<Mcc>) {
  const requestConfig: AxiosRequestConfig = {
    headers: {
      method: 'PATCH',
      ...authHeader()
    }
  };

  return axios.patch(`${config.apiUrl}/ads/mcc`, mcc, requestConfig)
    .then(apiHelper.handleResponse)
    .catch(apiHelper.handleError);
}

/**
 * Generate Google Ads Zip
 * @param businessId
 */
function googleAdsBuilder(businessId: number, settings: BuilderOptionsDto, type: string) {
    const requestConfig: AxiosRequestConfig = {
        headers: {
          method: 'POST',
          ...authHeader()
        },
    };

    const buildSettings: BuilderOptionsDto = {
      ...settings,
      businessId,
      builderType: type
    };

    return axios.post(`${config.apiUrl}/ads/${businessId}/google/generate`, buildSettings, requestConfig)
        .then((response) => {
            return response.data;
        })
        .then((zipAsBase64) => {
            const blob = b64ToBlob(zipAsBase64, "application/zip");
            fileSaver.saveAs(blob, `google-ads-${businessId}.zip`);
        })
        .catch(apiHelper.handleError);
}

/**
 * Generate Microsoft Ads Zip
 * @param businessId
 */
function microsoftAdsBuilder(businessId: number, settings: BuilderOptionsDto) {
  const requestConfig: AxiosRequestConfig = {
    headers: {
      method: 'POST',
      ...authHeader()
    },
  };

  return axios.post(`${config.apiUrl}/ads/${businessId}/microsoft/generate`, settings, requestConfig)
    .then((response) => {
      return response.data;
    })
    .then((zipAsBase64) => {
      const blob = b64ToBlob(zipAsBase64, "application/zip");
      fileSaver.saveAs(blob, `microsoft-ads-${businessId}.zip`);
    })
    .catch(apiHelper.handleError);
}
