import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const UndoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="12"
        height="11"
        viewBox="0 0 12 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor"
      >
        <path d="M7.875 3.1875H2.16323L3.84483 1.50661L3.1875 0.84375L0.375 3.65625L3.1875 6.46875L3.84483 5.80566L2.16464 4.125H7.875C8.62092 4.125 9.33629 4.42132 9.86374 4.94876C10.3912 5.47621 10.6875 6.19158 10.6875 6.9375C10.6875 7.68342 10.3912 8.39879 9.86374 8.92624C9.33629 9.45368 8.62092 9.75 7.875 9.75H4.125V10.6875H7.875C8.86956 10.6875 9.82339 10.2924 10.5267 9.58915C11.2299 8.88589 11.625 7.93206 11.625 6.9375C11.625 5.94294 11.2299 4.98911 10.5267 4.28585C9.82339 3.58259 8.86956 3.1875 7.875 3.1875Z" />
      </svg>
    </SvgIcon>
  );
};

export default UndoIcon;
