import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import User from '../types/user';
import { userService } from '../services';

interface Token {
  accessToken: string;
  expiresIn: number;
}

interface LoginRequest {
  email?: string;
  password?: string;
}
interface EmailRequest {
  email: string;
}

interface SetPasswordRequest {
  token: string;
  password: string;
}
interface ErrorResponse {
  statusCode: number;
  message: string;
  error: string;
}
interface AuthResponse {
  user: User;
  token: Token;
}
interface UserRegisterDto {
  firstName: string;
  lastName: string;
  phone: string;
  token: string;
  password: string;
}

interface AuthState {
  status: 'idle' | 'loading' | 'failed';
  loggedIn: boolean;
  user: User | null;
  error: string | null;
}

const initialState: AuthState = {
  status: 'idle',
  loggedIn: false,
  user: null,
  error: null,
};


export const login = createAsyncThunk<
  AuthResponse,
  LoginRequest,
  {
    rejectValue: ErrorResponse
  }
>(
  'auth/login',
  async (loginRequest, thunkApi) => {
    const response: any = await userService.login(loginRequest);

    if (response.statusCode === 404) {
      // Return the known error for future handling
      return thunkApi.rejectWithValue((await response.json()) as ErrorResponse)
    }
    return response as AuthResponse;
  }
);
export const completeRegistration = createAsyncThunk(
  "auth/completeRegistration",
  async (dto: UserRegisterDto, thunkApi): Promise<any> => {
    try {
      const response = await userService.register(dto);
      return response;
    }
    catch (e: any) {
      return thunkApi.rejectWithValue(e)
    }
  },
);

export const getRegistrationContext = createAsyncThunk(
  "auth/validateRegistrationToken",
  async (token: string, thunkApi): Promise<any> => {
    try {
      const response = await userService.getRegistrationContext(token);
      return response;
    }
    catch (e: any) {
      return thunkApi.rejectWithValue(e)
    }
  },
);

export const resendRegistration = createAsyncThunk(
  "auth/resendRegistrationByToken",
  async (token: string, thunkApi): Promise<any> => {
    try {
      const response = await userService.resendRegistration(token);
      return response;
    }
    catch (e: any) {
      return thunkApi.rejectWithValue(e)
    }
  },
);

export const getForgotPasswordContext = createAsyncThunk(
  "auth/validateForgotPasswordToken",
  async (token: string, thunkApi): Promise<any> => {
    try {
      const response = await userService.getForgotPasswordContext(token);
      return response;
    }
    catch (e: any) {
      return thunkApi.rejectWithValue(e)
    }
  },
);

export const resendForgotPassword = createAsyncThunk(
  "auth/resendForgotPasswordByToken",
  async (token: string, thunkApi): Promise<any> => {
    try {
      const response = await userService.resendForgotPassword(token);
      return response;
    }
    catch (e: any) {
      return thunkApi.rejectWithValue(e)
    }
  },
);

export const forgotPassword = (emailRequest: EmailRequest) => async () => {
  const response: any = await userService.forgotPassword(emailRequest);

  if (response.statusCode === 404) {
    return (await response.json()) as ErrorResponse;
  }
  return response;
};

export const setPassword = (setPasswordRequest: SetPasswordRequest) => async () => {
  const response : any = await userService.setPassword(setPasswordRequest);

  if (response.statusCode === 404) {
    return (await response.json()) as ErrorResponse;
  }
  return response;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userLogout: (state) => {
      state.status = 'idle';
      state.loggedIn = false;
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedIn = true;
        state.user = action.payload.user;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.loggedIn = false;
        state.user = null;
        state.error = 'Login attempt failed';
      })
      .addCase(completeRegistration.pending, (state) => {
        state.status = 'loading';
        state.loggedIn = false;
        state.user = null;
        state.error = null;
      })
      .addCase(completeRegistration.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedIn = false;
        state.user = null;
        state.error = null;
      })
      .addCase(completeRegistration.rejected, (state, action) => {
        state.status = 'failed';
        state.loggedIn = false;
        state.user = null;
        state.error = 'Registration attempt failed';
      })
      .addCase(getRegistrationContext.pending, (state) => {
        state.status = 'loading';
        state.loggedIn = false;
        state.user = null;
        state.error = null;
      })
      .addCase(getRegistrationContext.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedIn = false;
        state.user = null;
        state.error = null;
      })
      .addCase(getRegistrationContext.rejected, (state, action) => {
        state.status = 'failed';
        state.loggedIn = false;
        state.user = null;
        state.error = 'Get registration context failed';
      })
      .addCase(resendRegistration.pending, (state) => {
        state.status = 'loading';
        state.loggedIn = false;
        state.user = null;
        state.error = null;
      })
      .addCase(resendRegistration.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedIn = false;
        state.user = null;
        state.error = null;
      })
      .addCase(resendRegistration.rejected, (state, action) => {
        state.status = 'failed';
        state.loggedIn = false;
        state.user = null;
        state.error = 'Resend registration link attempt failed';
      })
      .addCase(getForgotPasswordContext.pending, (state) => {
        state.status = 'loading';
        state.loggedIn = false;
        state.user = null;
        state.error = null;
      })
      .addCase(getForgotPasswordContext.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedIn = false;
        state.user = null;
        state.error = null;
      })
      .addCase(getForgotPasswordContext.rejected, (state, action) => {
        state.status = 'failed';
        state.loggedIn = false;
        state.user = null;
        state.error = 'Get forgot password context failed';
      })
      .addCase(resendForgotPassword.pending, (state) => {
        state.status = 'loading';
        state.loggedIn = false;
        state.user = null;
        state.error = null;
      })
      .addCase(resendForgotPassword.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedIn = false;
        state.user = null;
        state.error = null;
      })
      .addCase(resendForgotPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.loggedIn = false;
        state.user = null;
        state.error = 'Resend forgot password link attempt failed';
      });
  },
});

export const { userLogout } = authSlice.actions;


export const logout = (): AppThunk => (
  dispatch
) => {
  userService.logout();
  dispatch(userLogout());
};


export default authSlice.reducer;
