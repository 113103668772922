import { Grid } from "@mui/material";
import CollectionDiscountType from "components/businesses/ecommerce/specials/collections/CollectionDiscountType";
import FilterForm from "components/businesses/ecommerce/specials/filters/FilterForm";
import CheckboxGroup from "components/shared/special/CheckboxGroup";
import React, { useState } from "react";
import { useAppSelector } from "redux/hooks";
import Label from "../Extras/Label";
import SelectDialog from "components/businesses/ecommerce/specials/dialogs/select-dialog/select-dialog.component";
import { useFormikContext } from "formik";

export default function ProductsForm(props) {
  const business = useAppSelector((state) => state.business.info);
  const {
    formField,
    categories,
    brands,
    editing,
    formField: { menuTypes, discounts },
  } = props;  
  
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={7}>
          <Label label={menuTypes.label} />
          <CheckboxGroup
            name={menuTypes.name}
            items={menuTypes.options}
            isArray
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <CollectionDiscountType key={`collection-discount-type-0`} index="0" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FilterForm
            businessId={business.id}
            formField={formField}
            editing={editing}
            categories={categories}
            brands={brands}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
