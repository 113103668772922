import Loadable from "components/Loadable";
import { lazy } from "react";

const OrganizationBusinessesPage = Loadable(
  lazy(() => import("pages/organizations/organization-businesses.page"))
);
const OrganizationSettingsPage = Loadable(
  lazy(() => import("pages/organizations/organization-settings.page"))
);

const OrganizationRoutes = [
  {
    index: true,
    element: <OrganizationBusinessesPage title="Organization Businesses" />
  },
  {
    path: "settings",
    element: <OrganizationSettingsPage title="Organization Settings" />,
    handle: {
      crumb: () => 'Settings',
    },
  }
];

export default OrganizationRoutes;