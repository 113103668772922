import React from "react";
import { formatDiffField } from "./InventoryManagementConstants";
import { Box, ListItemText } from "@mui/material";

const DiffDisplay = ({ diffs }: any) => {
  // Mainly for cannabinoids & terpenes, in case their values are changed by the PoS as of now.
  const compareArrays = (oldArray: any, newArray: any, fieldName: any) => {
    return newArray.map((newItem: any, index: any) => {
      const oldItem = oldArray[index];
      const changes: any = [];
      const itemName =
        newItem[fieldName.slice(0, -1)]?.name || `${fieldName} ${index + 1}`;

      Object.keys(newItem).forEach((key) => {
        if (JSON.stringify(newItem[key]) !== JSON.stringify(oldItem[key])) {
          if (typeof newItem[key] === "object" && newItem[key] !== null) {
            Object.keys(newItem[key]).forEach((subKey) => {
              if (
                JSON.stringify(newItem[key][subKey]) !==
                JSON.stringify(oldItem[key][subKey])
              ) {
                changes.push(
                  <div key={`${index}-${key}-${subKey}`}>
                    <Box>{subKey}:</Box>
                    <Box>Old value: {JSON.stringify(oldItem[key][subKey])}</Box>
                    <Box>New value: {JSON.stringify(newItem[key][subKey])}</Box>
                  </div>
                );
              }
            });
          } else {
            changes.push(
              <div key={`${index}-${key}`}>
                <Box>
                  {key}: Old value: {JSON.stringify(oldItem[key])}
                </Box>
                <Box>
                  {key}: New value: {JSON.stringify(newItem[key])}
                </Box>
              </div>
            );
          }
        }
      });

      return changes.length > 0 ? (
        <div key={index}>
          <ListItemText
            primary={`Changes to ${itemName}`}
            secondary={<>{changes}</>}
          ></ListItemText>
        </div>
      ) : null;
    });
  };

  const renderDiff = (diff: any) => {
    if (diff.field === "terpenes" || diff.field === "cannabinoids") {
      const oldArray = JSON.parse(diff.oldValue);
      const newArray = JSON.parse(diff.newValue);

      return compareArrays(oldArray, newArray, diff.field);
    } else {
      return (
        <div>
          <ListItemText
            primary={formatDiffField(diff.field)}
            secondary={
              <>
                <Box>{`Old value: ${diff.oldValue || "Empty"}`}</Box>
                <Box>{`New value: ${diff.newValue || "Empty"}`}</Box>
              </>
            }
          ></ListItemText>
        </div>
      );
    }
  };

  return (
    <div>
      {diffs.map((item: any, index: any) => (
        <div key={index}>
          {item.diffs.map((diff: any, diffIndex: any) => (
            <React.Fragment key={diffIndex}>{renderDiff(diff)}</React.Fragment>
          ))}
        </div>
      ))}
    </div>
  );
};

export default DiffDisplay;
